export enum ElementClassEnum {
  ELEMENT_CLASS_SIGN = 'SIGN',
  ELEMENT_CLASS_SIGN_FR = 'SIGN.FR',
  ELEMENT_CLASS_GROUND = 'GROUND',
  ELEMENT_CLASS_STREETLIGHT = 'STREETLIGHT',
  ELEMENT_CLASS_TRASH = 'TRASH',
  ELEMENT_CLASS_REFLECTO_AXE = 'reflecto.axe',
  ELEMENT_CLASS_MARKER = 'marker',
  ELEMENT_CLASS_CRASH = 'CRASH',
  ELEMENT_CLASS_SIGN_FR_TRAD = 'Panneau',
  ELEMENT_CLASS_GROUND_FR_TRAD = 'Marquage',
  ELEMENT_CLASS_STREETLIGHT_FR_TRAD = 'Candélabre',
}
