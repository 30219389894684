<p-dialog header="Advanced Search" [(visible)]="display" [modal]="true" [responsive]="true"
          [style]="{'width': '800px', 'minWidth': '300px', 'max-height': '90%', 'overflow-y': 'auto'}" (onHide)="onHide()" [transitionOptions]="'200ms'">
  <form [formGroup]="searchForm" (ngSubmit)="searchSnapshots()" class="globalSearch ui-g ui-fluid snapshotForm">
    <div class="ui-g-12 paddingDiv">
      <div class="appendFormTitle ui-g-12">
        <p class="advancedSearchTitle">BY SNAPSHOT</p>
      </div>
      <hr>
      <div class="appendFormName ui-g-12">
        <div class="ui-inputgroup searchName">
          <input type="text" pInputText placeholder="Snapshot Name" formControlName="research"
                 class="ui-inputtext ui-corner-all ui-state-default ui-widget">
        </div>
      </div>
      <div class="appendFormName ui-g-12">
        <div class="ui-inputgroup searchName">
          <input type="text" pInputText placeholder="Snapshot Identifier" formControlName="identifier"
                 class="ui-inputtext ui-corner-all ui-state-default ui-widget">
        </div>
      </div>
    </div>
    <div class="ui-g-12 paddingDiv">
      <div class="appendFormTitle ui-g-12">
        <p class="advancedSearchTitle">BY STATUS</p>
      </div>
      <hr>
      <div class="appendFormStatus ui-g-12">
          <p-dropdown [options]="statusList" [(ngModel)]="selectedStatus" placeholder="Choose a status"
                      [showClear]="true" formControlName="statusCode" optionLabel="statusCode"></p-dropdown>
      </div>
      <div class="appendFormStatus ui-g-12">
        <p-checkbox name="group1" [(ngModel)]="statusCheck" [value]="selectedStatus" formControlName="checkedStatus"
                    label="Status in snapshot history?" class="checkBoxStyle"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-12 paddingDiv">
      <div class="appendFormTitle ui-g-12">
        <p class="advancedSearchTitle">BY TYPE</p>
      </div>
      <hr>
      <div class="appendFormStatus ui-g-12">
        <p-checkbox name="group2" [(ngModel)]="car360Check" [value]="selectedCar360" formControlName="checkedCar360"
                    label="CAR_360" binary="true" class="checkBoxStyle"></p-checkbox>
        <p-checkbox name="group3" [(ngModel)]="reflectoCheck" [value]="selectedReflecto" formControlName="checkedReflecto"
                    label="REFLECTO" binary="true" class="checkBoxStyle"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-12 paddingDiv">
      <div class="appendFormTitle ui-g-12">
        <p class="advancedSearchTitle">BY PERIOD</p>
      </div>
      <hr>
      <div class="appendForm appendFormDate ui-g-12">
        <p-calendar [(ngModel)]="rangeDatesSnapshot" selectionMode="range" [readonlyInput]="true"
                    [maxDate]="maxDateValue" dateFormat="dd MM yy" showButtonBar="true" [locale]="fr"
                    placeholder="Snapshot Date" formControlName="rangeDateSnapshot"></p-calendar>
      </div>
      <div class="appendForm appendFormDate ui-g-12">
        <p-calendar [(ngModel)]="rangeDatesStatus" selectionMode="range" [readonlyInput]="true"
                    [maxDate]="maxDateValue" dateFormat="dd MM yy" showButtonBar="true" [locale]="fr"
                    placeholder="Status Date" formControlName="rangeDateStatus"></p-calendar>
      </div>
    </div>
    <div class="submitButton ui-inputgroup">
      <button pButton type="submit" label="Search"></button>
    </div>
  </form>
</p-dialog>
