import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SecurityService} from '../../../../services/security.service';
import {MessageService} from 'primeng/api';
import {Session} from '../../../../globals/session';
import { UserModel } from 'src/app/models/session/user-model';
import * as jwt from "jwt-decode";

@Component({
  selector: 'app-users-edit-modify',
  templateUrl: './users-edit-modify.component.html',
  styleUrls: ['./users-edit-modify.component.css']
})
export class UsersEditModifyComponent implements OnInit {

  editForm: FormGroup;

  @Input() currentUser: UserModel;
  constructor(private formBuilder: FormBuilder,
              private session: Session,
              private securityService: SecurityService,
              private messageService: MessageService) {

              }
  canModifyAdminPermission = false;
  ngOnInit() {
    const decodedToken = jwt(this.session.token);
    this.canModifyAdminPermission = decodedToken["https://www.citymagine.com/userId"] != this.currentUser.userId && (decodedToken["https://www.citymagine.com/isAdmin"] || false);
    this.canModifyAdminPermission = false;
    this.editForm = this.formBuilder.group({
      userEmail: [this.currentUser.userEmail, Validators.pattern('^[a-z0-9._-]+@[a-z0-9._-]{2,}\\.[a-z]{2,4}$')],
      userFirstName: [this.currentUser.userFirstName, Validators.required],
      userLastName: [this.currentUser.userLastName, Validators.required],
      userAdmin: [this.currentUser.isAdmin],
    });
  }

  onSubmitEditUser() {
    if (this.f.userEmail.value && this.f.userFirstName.value && this.f.userLastName.value) {
      if (!this.f.userEmail.errors) {
        this.currentUser.userEmail = this.f.userEmail.value;
        this.currentUser.userFirstName = this.f.userFirstName.value.charAt(0).toUpperCase() + this.f.userFirstName.value.slice(1);
        this.currentUser.userLastName = this.f.userLastName.value.toUpperCase();
        this.currentUser.isAdmin = this.f.userAdmin.value;
        this.securityService.updateUser(this.currentUser).subscribe( resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Utilisateur modifié',
              detail: this.currentUser.userName});
          }
        });
      } else {
        console.log(this.f.email.errors);
      }
    }
  }
  get f() {
    return this.editForm.controls;
  }

}
