<p-dialog id="listCreate" header="{{'settings.other-lists.create-list-value' | translate}}" [(visible)]="displayCreate"
          [style]="{width: '300px', height: '200px'}" (onHide)="onHide()">
  <form [formGroup]="createListValueForm" (ngSubmit)="createListValue()" class="updateForm ui-fluid">
    <div class="ui-inputgroup">
      <input type="text" pInputText placeholder="{{'settings.other-lists.value-code' | translate}}" formControlName="valueCode"
             [pKeyFilter]="blockSpace" class="ui-inputtext ui-corner-all ui-state-default ui-widget updateInput">
    </div>
    <div class="ui-inputgroup">
      <input type="text" pInputText placeholder="{{'settings.other-lists.value-text' | translate}}" formControlName="valueText"
             class="ui-inputtext ui-corner-all ui-state-default ui-widget updateInput">
    </div>
    <json-editor [options]="options" [(ngModel)]="data" (change)="getData($event)"
                 style="width:100%;" class="jsonConfiguration"
                 formControlName="listValueParameters"></json-editor>
    <p *ngIf="isParsingError" class="errorMessage">{{errorMessage}}</p>
    <button pButton type="submit" label="{{'settings.other-lists.create' | translate}}" [disabled]="isParsingError"
            class="ui-button ui-button-text-only ui-button-text updateButton"></button>
  </form>
</p-dialog>
