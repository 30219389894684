<div *ngIf="loading" class="loaderContainer">
  <p-progressSpinner></p-progressSpinner>
</div>
<div id="header">
  <app-domains-add (addDomain)="addDomainFunction()" [currentDomain]="currentDomain"></app-domains-add>
  <ul [ngClass]="{'phoneDisplay': isMatched, 'computerDisplay': !isMatched}">
    <li *ngIf="isMatched" class="logoOut">
      <button pButton type="button" (click)="visibleSidebar = true" icon="fa fa-bars fa-2x"></button>
    </li>
    <li class="title" routerLink="/home/snapshot/snapshotProcessed">
      <img src="assets/favicon.ico" routerLink="/home/snapshot/snapshotProcessed"/>
      <p><span class="city">City</span><span style="opacity:0.7;">Designer</span></p>
    </li>
    <div [tabIndex]="1" id="domains">
      <ul>
        <li class="user-infos hoverable">
          <div>
            <i class="pi pi-user"></i>
            <span>
              {{domain}}
            </span>
            <i class="pi pi-caret-down"></i>
          </div>
        </li>
        <ul id="menu-items" *ngIf="!loading">
          <li>
            <div class="hoverable" (click)="logout()">
              <i class="pi pi-sign-out"></i>
              <span>
                 {{"header.logout"|translate}}
              </span>
            </div>
          </li>
          <li *ngIf="domainList && domainList.length > 0" [tabIndex]="2" class="domain-switcher">
            <div class="switch-item hoverable">
              <span>
               {{"header.switch-domain"|translate}}
              </span>
              <i class="pi pi-angle-right"></i>
            </div>
            <div class="domain-list">
              <span *ngFor="let domain of domainList" class="hoverable">
                <span class="selectedDomainMarker" (click)="selectCurrentDomain(domain.domainId)">
                  <i class="pi pi-check" [ngClass]="{canbeCheck:domain.domainId != currentDomain}"></i>
                  {{domain.domainName}}
                </span>
              </span>
              <span *ngIf="isAdmin" class="hoverable" (click)="onAddDomain()">
                <span class="selectedDomainMarker defaultDomainMarker">
                  <i class="pi pi-plus"></i>
                </span>
                {{ "header.new-domain" | translate }}
              </span>
            </div>
          </li>
          <li class="locale-switcher hoverable">
            <div class="locale-menu"
                 (click)="localeList.style.display === 'flex' ? localeList.style.display='none' : localeList.style.display = 'flex'">
              <span>
               {{"locale.title"|translate}}
              </span>
              <i class="pi pi-angle-right"></i>
            </div>
          </li>
          <div class="locale-list" #localeList>
            <div class="hoverable locale" (click)="changeLocale('fr')">
              {{'locale.fr'|translate}}
            </div>
            <div class="hoverable locale" (click)="changeLocale('en')">
              {{'locale.en'|translate}}
            </div>
          </div>
        </ul>
      </ul>
    </div>
  </ul>
</div>
<app-side-menu></app-side-menu>
<p-sidebar [(visible)]="visibleSidebar" position="left" [baseZIndex]="10000">
  <p-panelMenu [model]="menuItems" [multiple]="false"></p-panelMenu>
</p-sidebar>

