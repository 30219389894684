import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MenuItem, MessageService} from 'primeng/api';
import {MapFunctionsService} from '../../snapshot/services/mapfunctions.service';
import {SnapshotService} from '../../snapshot/services/snapshot.service';
import {SecurityService} from '../../../services/security.service';
import {ListTypesEnum} from '../../../enums/list-types-enum'
import {GeojsonService} from '../../../services/geojson.service';
import {ListValueModel} from '../../../models/masterData/list-value-model';
import {AsyncService} from '../../../services/async.service';
import {ListCollectionModel} from '../../../models/masterData/list-collection-model';
import {MasterDataService} from '../../../services/master-data.service';
import {ElementClassEnum} from '../../../enums/element-class-enum.enum';
import {GeometryModel} from "../../../models/geojson/geometry-model";


interface Resp {
  features: any;
  lists: any;
}

@Component({
  selector: 'app-deduplicate',
  templateUrl: './deduplicate.component.html',
  styleUrls: ['./deduplicate.component.css']
})
export class DeduplicateComponent implements OnInit {

  coordinates: any[];
  currentUser: any;
  elements = [];
  elementType: string;
  filteredResults: string[];
  geoForm: FormGroup;
  isActiveDraw: boolean;
  items: MenuItem[];
  listValues: ListValueModel[];
  objFilteredResults: any[];
  response: any;
  string: string;
  typeCoord: string;
  zoneSearch: string;

  constructor(private formBuilder: FormBuilder,
              private mapFunctionsService: MapFunctionsService,
              private masterDataService: MasterDataService,
              private messageService: MessageService,
              private snapshotService: SnapshotService,
              private geojsonService: GeojsonService,
              private asyncService: AsyncService,
              private securityService: SecurityService) {
  }

  ngOnInit() {
    this.securityService.currentTransferUser.subscribe(user => this.currentUser = user);
    this.mapFunctionsService.changeDrawState.subscribe(isActiveDraw => {
      this.isActiveDraw = isActiveDraw;
      this.zoneSearch = '';
    });
    this.mapFunctionsService.changeDataGeo.subscribe((geo: GeometryModel) => {
      this.coordinates = geo.coordinates;
      this.typeCoord = geo.type
    });
    this.snapshotService.changeStatus.subscribe(error => {
      if (error.error) {
        this.messageService.add({
          key: 'bl', severity: 'error', summary: error.error.code + ' (' + error.status + ')',
          detail: error.error.message, closable: false
        });
      }
    });
    this.geoForm = this.formBuilder.group({
      autocomplete: ['', Validators.required],
      elementType: ['', Validators.required],
    });

    this.masterDataService.getList(ListTypesEnum.ELEMENT_TYPE).subscribe((resp: ListCollectionModel) => {
      if (resp) {
        if (resp.lists.length > 0) {
          resp.lists[0].listValues.forEach(e => {
            this.elements.push({label: e.valueCode, value: e.valueCode})
          })
        } else {
          this.elements = [
            {label: ElementClassEnum.ELEMENT_CLASS_SIGN_FR_TRAD, value: ElementClassEnum.ELEMENT_CLASS_SIGN},
            {label: ElementClassEnum.ELEMENT_CLASS_GROUND_FR_TRAD, value: ElementClassEnum.ELEMENT_CLASS_GROUND},
          ];
        }
      }
    });
    this.zoneSearch = '';
    this.mapFunctionsService.onInitMapUser();
  }

  onDrawPolygon() {
    this.isActiveDraw = !this.isActiveDraw;
    if (this.isActiveDraw) {
      this.mapFunctionsService.onDrawPolygon();
    }
  }

  search(event) {
    this.string = event.query;
    this.geojsonService.getNominatim(this.string).subscribe((message: Resp) => {
      this.response = message;
      this.filteredResults = [];
      this.objFilteredResults = [];
      this.response.forEach(result => {
        let type = '';
        if (result.display_name) {
          type = result.display_name;
        }
        if (this.filteredResults[0]) {
          let differentElement = 0;
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.filteredResults.length; i++) {
            if (type !== this.filteredResults[i]) {
              differentElement++;
              if (differentElement === this.filteredResults.length) {
                if (type !== '') {
                  this.filteredResults.push(type);
                  this.objFilteredResults.push(result);
                }
              }
            }
          }
        } else {
          this.filteredResults.push(type);
          this.objFilteredResults.push(result);
        }
      });
    });
  }

  onSelectArea() {
    this.string = this.g.autocomplete.value;
    this.geojsonService.getNominatim(this.string).subscribe((message: Resp) => {
      this.response = message;
      this.typeCoord = this.response[0].geojson.type;
      this.coordinates = this.response[0].geojson.coordinates;
      this.elementType = this.g.elementType.value;
      this.mapFunctionsService.typeCoord = this.response[0].geojson.type;
      this.mapFunctionsService.dataGeojson = this.response[0].geojson.coordinates;
      this.mapFunctionsService.drawPolygon(this.response[0].geojson.coordinates);
      this.mapFunctionsService.fitToContent();
    });
  }

  onSubmitGeo() {
    this.asyncService.deduplicateByArea(this.elementType, this.typeCoord, this.coordinates).subscribe(() => {
      this.messageService.add({key: 'bl', severity: 'success', summary: 'Dédoublonnement en cours'});
    });
  }

  clearAll() {
    this.mapFunctionsService.onDeletePolygon();
    this.zoneSearch = '';
  }

  get g() {
    return this.geoForm.controls;
  }
}
