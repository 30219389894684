import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {SecurityService} from '../../../../services/security.service';
import {MessageService} from 'primeng/api';
import {ListboxModel} from '../../../../models/masterData/listbox-model';
import {MasterDataService} from '../../../../services/master-data.service';
import {ListCollectionModel} from '../../../../models/masterData/list-collection-model';
import {ListModel} from '../../../../models/masterData/list-model';
import {ListValueModel} from '../../../../models/masterData/list-value-model';
import {UserModel} from '../../../../models/session/user-model';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'app-users-edit-roles',
  templateUrl: './users-edit-roles.component.html',
  styleUrls: ['./users-edit-roles.component.css']
})
export class UsersEditRolesComponent implements OnInit {

  currentRoles: ListboxModel[];
  displayMaxRoles: boolean;
  isMatched: boolean;
  lists: ListModel[];
  listValues: ListValueModel[];
  numberOfRoles: number;
  results: ListboxModel[];
  roleElement: ListboxModel;
  selectedRole: string;
  @Input() currentUser: UserModel;
  constructor(public breakpointObserver: BreakpointObserver,
              private formBuilder: FormBuilder,
              private securityService: SecurityService,
              private messageService: MessageService,
              private masterDataService: MasterDataService) { }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        this.isMatched = !state.matches;
      });
    this.numberOfRoles = 0;
    this.masterDataService.getList('ROLE').subscribe( (resp: ListCollectionModel) => {
      if (resp) {
        this.lists = resp.lists;
        this.listValues = resp.lists[0].listValues;
        if (!this.listValues) {
          this.listValues = resp.lists[0].values;
        }
        this.currentRoles = [];
        if (this.currentUser.userRoles) {
          this.currentUser.userRoles.forEach( role => {
            this.roleElement = new ListboxModel();
            this.roleElement.value = role;
            this.roleElement.label = role;
            this.currentRoles.push(this.roleElement);
          });
        }
        this.results = [];
        this.listValues.forEach( (role: ListValueModel) => {
          this.roleElement = new ListboxModel();
          this.roleElement.value = role.valueCode;
          this.roleElement.label = role.valueCode;
          this.currentRoles.forEach( roleUser => {
            if (role.valueCode === roleUser.value) {
              this.roleElement.disabled = true;
            }
          });
          if (!this.roleElement.disabled) {
            this.roleElement.disabled = false;
          }
          this.results.push(this.roleElement);
          this.numberOfRoles++;
        });
        this.checkMax();
      }
    });
  }

  onSubmitRole() {
    this.currentUser.userRoles = [];
    this.currentRoles.forEach( (role: ListboxModel) => {
      this.currentUser.userRoles.push(role.value);
    });
    this.securityService.updateUser(this.currentUser).subscribe( resp => {
      if (resp) {
        this.messageService.add({key: 'bl', severity: 'success', summary: 'Utilisateur modifié',
          detail: this.currentUser.userName});
      }
    });
  }

  updateRoleList() {
    const currentRole = this.selectedRole;
    this.results.forEach( (role: ListboxModel) => {
      if (role.value === this.selectedRole) {
        role.disabled = false;
      }
    });
    let i = 0;
    this.currentRoles.forEach( (role: ListboxModel) => {
      if (role.value === currentRole) {
        this.currentRoles.splice(i, 1);
      }
      i++;
    });
    this.checkMax();
  }
  updateCurrentList() {
    this.roleElement = new ListboxModel();
    this.roleElement.value = this.selectedRole;
    this.roleElement.label = this.selectedRole;
    this.currentRoles.push(this.roleElement);
    this.results.forEach( (role: ListboxModel) => {
      if (role.value === this.selectedRole) {
        role.disabled = true;
      }
    });
    this.checkMax();
  }


  checkMax() {
    this.displayMaxRoles = this.currentRoles.length === this.numberOfRoles;
  }

}
