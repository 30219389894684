<!--<div *ngIf="displayDetails" class="info ui-g-8" [ngClass]="{'detailsContainer': isMatched}">-->
  <p>SNAPSHOT TYPE<br/><span class="displayInfo">{{currentSnapshot.snapshotType}}</span></p>
  <hr>
  <p>DATE<br/><span class="displayInfo">{{currentSnapshot.snapshotDate | date : 'dd/MM/yyyy HH:mm:ss'}}</span></p>
  <hr>
  <p>DURATION<br/><span class="displayInfo">{{currentSnapshot.timeSpend}}</span></p>
  <hr>
  <p>LENGTH<br/><span class="displayInfo">{{currentSnapshot.distance}}</span></p>
  <hr>
  <p>STATUS<br/><span class="displayInfo">{{currentSnapshot.statusCode}}</span></p>
  <hr>
  <p>STATUS REASON<br/><span class="displayInfo">{{currentSnapshot.statusReason}}</span></p>
  <hr>
  <p>DONE<br/><span class="displayInfo">{{currentSnapshot.dateFromStatus}}</span></p>
  <hr>
  <p>SNAPSHOT PARENT PART<br/><span class="displayInfo">{{currentSnapshot.snapshotParentPart}}</span></p>
  <hr>
  <p>SNAPSHOT PARENT IDENTIFIER<br/><span class="displayInfo">{{currentSnapshot.snapshotParentIdentifier}}</span></p>
  <hr>
  <p>SNAPSHOT IDENTIFIER<br/><span class="displayInfo">{{currentSnapshot.snapshotIdentifier}}</span></p>
<!--</div>-->
