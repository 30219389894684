import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MasterDataService} from '../../../../services/master-data.service';
import {MessageService} from 'primeng/api';
import {StatusModel} from '../../../../models/masterData/status-model';
import {StatusCollectionModel} from '../../../../models/masterData/status-collection-model';
import {ListCollectionModel} from '../../../../models/masterData/list-collection-model';
import {ListValueModel} from '../../../../models/masterData/list-value-model';
import {RuleModel} from '../../../../models/task/rule-model';
import {TaskService} from '../../../../services/task.service';

@Component({
  selector: 'app-rules-create',
  templateUrl: './rules-create.component.html',
  styleUrls: ['./rules-create.component.css']
})
export class RulesCreateComponent implements OnInit {

  displayCreate: boolean;
  createRuleForm: FormGroup;
  selectedStatus: StatusModel;
  statusCheck: boolean;
  stitchedCheck: boolean;
  enabledCheck: boolean;
  inIsStitched: boolean;
  statusList: StatusModel[];

  arrayStatus: StatusModel[];
  processList: ListValueModel[];
  processRules: RuleModel[];
  selectedProcess: ListValueModel;
  isParsingError: boolean;
  enabled: boolean;
  @Output() getListValuesEvent = new EventEmitter();

  constructor(private masterDataService: MasterDataService, private formBuilder: FormBuilder,
              private messageService: MessageService, private taskService: TaskService) {

  }

  ngOnInit() {
    this.displayCreate = true;
    this.enabled = false;
    this.inIsStitched = false;
    this.createRuleForm = this.formBuilder.group({
      ruleName: ['', Validators.required],
      inCurrent: ['', Validators.required],
      isStitched: ['', Validators.required],
      outProcess: ['', Validators.required],
      enabled: ['', Validators.required]
    });
    this.arrayStatus = [];
    this.masterDataService.getStatusList().subscribe(
      (resp: StatusCollectionModel) => {
        if (resp) {
          this.arrayStatus = resp.statusList;
          this.statusList = [];
          this.statusList = this.arrayStatus;
        }
      }
    );
    this.processList = [];
    this.masterDataService.getList('PROCESS').subscribe( (resp: ListCollectionModel) => {
      if (resp) {
        this.processList = resp.lists[0].listValues;
        if (!this.processList) {
          this.processList = resp.lists[0].values;
        }
      }
    });
  }

  createListValue() {
    const newRule = new RuleModel();
    newRule.ruleName = this.f.ruleName.value;
    newRule.inCurrentStatusCode = this.selectedStatus.statusCode;
    newRule.outProcessCode = this.selectedProcess.valueCode;
    newRule.outProcessType = this.selectedProcess.valueText;
    if (this.selectedProcess.listValueParameters) {
      newRule.outEventChannel = this.selectedProcess.listValueParameters.eventChannel;
    } else if (this.selectedProcess.valueParameters) {
      newRule.outEventChannel = this.selectedProcess.valueParameters.eventChannel;
    }
    newRule.inIsStitched = this.inIsStitched;
    newRule.enabled = this.enabled;

    this.processRules = [];
    this.processRules.push(newRule);

    this.taskService.createProcessRules(this.processRules).subscribe(resp => {
      if (resp) {
        this.messageService.add({key: 'bl', severity: 'success', summary: 'Règle créée',
          detail: newRule.ruleName});
        this.getListValuesEvent.emit();
        this.displayCreate = false;
      }
    });
  }

  get f() {
    return this.createRuleForm.controls;
  }

  changeStitched() {
    this.inIsStitched = !this.inIsStitched;
  }
  changeEnabled() {
    this.enabled = !this.enabled;
  }
  onHide() {
    this.getListValuesEvent.emit();
  }

}
