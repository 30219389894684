import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeduplicateComponent } from './deduplicate/deduplicate.component';
import { ChangeConfigComponent } from '../tools/change-config/change-config.component';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgJsonEditorModule} from "ang-jsoneditor";
import {ToastModule} from "primeng/toast";
import { RulesComponent } from './rules/rules.component';
import { RulesCreateComponent } from './rules/rules-create/rules-create.component';
import {TableModule} from "primeng/table";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';
import { MasterdataComponent } from './masterdata/masterdata.component';
import { TranslateModule } from '@ngx-translate/core';
import {InputNumberModule, MenuModule, SharedModule, ToggleButtonModule} from 'primeng';
import { MasterdataEditComponent } from './masterdata/masterdata-edit/masterdata-edit.component';
import { MasterdataAddComponent } from './masterdata/masterdata-add/masterdata-add.component';
import { MasterdataEditDetailsComponent } from './masterdata/masterdata-edit/masterdata-edit-details/masterdata-edit-details.component';
import {
  MasterdataEditPropertiesComponent
} from './masterdata/masterdata-edit/masterdata-edit-properties/masterdata-edit-properties.component';
import { MasterdataAddDetailsComponent } from './masterdata/masterdata-add/masterdata-add-details/masterdata-add-details.component';
import { MasterdataAddPropertiesComponent } from './masterdata/masterdata-add/masterdata-add-properties/masterdata-add-properties.component';
@NgModule({
  declarations: [DeduplicateComponent, ChangeConfigComponent, RulesComponent, MasterdataComponent, MasterdataEditComponent, MasterdataEditDetailsComponent, MasterdataAddComponent, RulesCreateComponent, MasterdataEditPropertiesComponent, MasterdataAddDetailsComponent, MasterdataAddPropertiesComponent],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CheckboxModule,
    AutoCompleteModule,
    TooltipModule,
    ButtonModule,
    ReactiveFormsModule,
    SelectButtonModule,
    DropdownModule,
    NgJsonEditorModule,
    FormsModule,
    ToastModule,
    DialogModule,
    InputSwitchModule,
    ConfirmDialogModule,
    SplitButtonModule,
    TableModule,
    ProgressSpinnerModule,
    PaginatorModule,
    TranslateModule,
    PaginatorModule,
    MenuModule,
    ToggleButtonModule,
    InputNumberModule
  ]
})
export class ToolsModule { }
