import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelingComponent } from './modeling.component';

@NgModule({
  declarations: [ModelingComponent],
  imports: [
    CommonModule
  ]
})
export class ModelingModule { }
