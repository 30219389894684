import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {MasterDataService} from '../../../../services/master-data.service';
import {ListCollectionModel} from '../../../../models/masterData/list-collection-model';
import {TaskService} from '../../../../services/task.service';
import {ListValueModel} from '../../../../models/masterData/list-value-model';
import {SnapshotModel} from '../../../../models/processing/snapshot-model';
import {ListTypesEnum} from '../../../../enums/list-types-enum';

@Component({
  selector: 'app-create-process',
  templateUrl: './create-process.component.html',
  styleUrls: ['./create-process.component.css']
})
export class CreateProcessComponent implements OnInit {

  listProcess: ListValueModel[];
  processes: ListValueModel[];
  selectedProcess: ListValueModel;
  currentSnapshotIdentifier: string;
  displayProcess: boolean;
  selectedSnapshots: SnapshotModel[];

  constructor(private taskService: TaskService,
              private masterDataService: MasterDataService,
              private messageService: MessageService) {
    this.processes = [];
  }

  ngOnInit() {
    this.masterDataService.getList(ListTypesEnum.PROCESS).subscribe( (resp: ListCollectionModel) => {
      this.listProcess = resp.lists[0].listValues;
      if (!this.listProcess) {
        this.listProcess = resp.lists[0].values;
      }
      this.processes = this.listProcess;
    });
    this.displayProcess = false;
  }

  onCreateProcess() {
    this.selectedSnapshots = JSON.parse(localStorage.getItem('selectedSnapshot'));
    if (this.selectedProcess) {
      const processCode = this.selectedProcess.valueCode;
      const processType = this.selectedProcess.valueText;
      this.selectedSnapshots.forEach( snapshot => {
        this.taskService.createProcessTask(snapshot.snapshotIdentifier, processCode, processType).subscribe(
          (resp) => {
            if (resp) {
              this.messageService.add({key: 'bl', severity: 'success', summary: 'Nouvelle tâche', detail: processCode});
              this.displayProcess = false;
            }
          }
        );
      });
    }
  }

  onHide() {
    this.displayProcess = false;
  }
}
