import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MenuItem, MessageService} from 'primeng/api';
import {SecurityService} from '../../../../services/security.service';
import {TranslateService} from '@ngx-translate/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng';
import { ElementClassModel } from 'src/app/models/masterData/element-class-model';
import {MasterDataService} from "../../../../services/master-data.service";


@Component({
  selector: 'app-masterdata-edit',
  templateUrl: './masterdata-edit.component.html',
  styleUrls: ['./masterdata-edit.component.css']
})
export class MasterdataEditComponent implements OnInit {


  @Input() currentMasterdata: ElementClassModel;
  displayOne: string;
  items: MenuItem[];
  @ViewChild('editDetailComponent') editDetailComponent;
  @ViewChild('editPropertiesComponent') editPropertiesComponent;

  constructor(
              private formBuilder: FormBuilder,
              private masterDataService: MasterDataService,
              private translate: TranslateService,
              private messageService: MessageService
  ) { }

  ngOnInit() {
    this.translate.get(['masterdata.edit.details', 'masterdata.edit.icon',
      'masterdata.edit.properties', 'masterdata.edit.advanced']).subscribe(
      translations => {
        this.items = [
          {label: translations['masterdata.edit.details'], icon: 'pi fa fa-info-circle', command: () => {
              this.selectItem(0);
              this.displayOne = 'detailsEdit';
            }},
           {label: translations['masterdata.edit.icon'], icon: 'pi fa fa-pie-chart', command: () => {
               this.selectItem(1);
               this.displayOne = 'iconEdit';
           }},
          {label: translations['masterdata.edit.properties'], icon: 'pi fa fa-home', command: () => {
              this.selectItem(2);
              this.displayOne = 'propertiesEdit';
          }},
          /*{label: translations['masterdata.edit.advanced'], icon: 'pi pi-money-bill', command: () => {
            this.displayOne = 'advancedEdit';
          }},*/
        ];
      }
    );
    this.selectItem(0);
    this.displayOne = 'detailsEdit';
  }

  selectItem(index: number) {
    if (this.displayOne === 'detailsEdit') {
      this.currentMasterdata = this.editDetailComponent.updateMasterData(this.currentMasterdata);
    } else if (this.displayOne === 'propertiesEdit') {
      this.currentMasterdata = this.editPropertiesComponent.updateMasterData(this.currentMasterdata);
    }
    this.items.forEach(item => item.style = {});
    this.items[index].style = {'background-color': '#a7d2fc'};
  }

  onSubmitForm() {
    if (this.displayOne === 'detailsEdit') {
      this.currentMasterdata = this.editDetailComponent.updateMasterData(this.currentMasterdata);
    } else if (this.displayOne === 'propertiesEdit') {
      this.currentMasterdata = this.editPropertiesComponent.updateMasterData(this.currentMasterdata);
    }
    this.masterDataService.updateElementClass(this.currentMasterdata).subscribe(resp => {
      if (resp) {
        this.translate.get('masterdata.edit.edited').subscribe((translateResp: string) => {
          this.messageService.add({key: 'bl', severity: 'success', summary: translateResp,
            detail: this.currentMasterdata.elementClass});
        });
      }
    });
  }
}
