import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {SecurityService} from '../../../services/security.service';
import {RoleEnum} from '../../../enums/role-enum.enum';
import {UserCollectionModel} from "../../../models/session/user-collection-model";
import {DomainService} from "../../../services/domain.service";
import {DomainCollectionModel} from "../../../models/session/domain-collection-model";
import {Observable, of} from "rxjs";
import {DomainModel} from "../../../models/session/domain-model";
import {DomainModelLegacy} from "../../../models/session/domain-model-legacy";
import * as jwt from "jwt-decode";
import {SessionService} from "../../../services/session.service";
import {Session} from "../../../globals/session";
import {TranslateService} from "@ngx-translate/core";

interface Domain {
  name: string;
  id: number;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  mail: string;
}

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.css']
})
export class UsersAddComponent implements OnInit {

  existingUsers: User[] = [];
  mailAlreadyExists = false;
  addForm: FormGroup;
  display: boolean;
  isFormValid = false;
  displayMailAdvertiser = false;
  domains: DomainCollectionModel;
  domainArray: Observable<Domain[]>;
  allDomains: Domain[] = [];
  displayDefault: string;
  translations: any;
  @Input() isAdmin: boolean;
  @Output() addUser: EventEmitter<boolean> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private messageService: MessageService,
              private securityService: SecurityService,
              private sessionService: SessionService,
              private session: Session,
              private domainService: DomainService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get(['users.chooseDomains', 'users.emailNotValid', 'users.missingField'])
      .subscribe( translations => {
        this.translations = translations;
        this.displayDefault = translations['users.chooseDomains'];
      });
    if (this.isAdmin) {
      this.initExistingUserNames();
      this.domainService.getDomains().subscribe((response: DomainCollectionModel) => {
        if (response) {
          this.domains = response;
          this.domains.domains.forEach((domain: DomainModel) => {
            this.allDomains.push({name: domain.name, id: domain.id});
          });
          this.domainArray = of(this.allDomains);
        }
      });
      this.selectCurrentDomain();
    }
    this.addForm = this.formBuilder.group({
      email: ['', Validators.required],
      // email: ['', Validators.pattern('^[a-z0-9._-]+@[a-z0-9._-]{2,}\\.[a-z]{2,4}$')],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      domains: [[], Validators.required]
    });
  }

  async selectCurrentDomain() {
    const defaultDomain: { defaultDomain: number } = await this.domainService.getDefaultDomain().toPromise();
    const decodedToken = jwt(this.session.token);
    const currentDomainFromToken =  decodedToken['https://www.citymagine.com/domainId'] || 1;
    const domains = await this.sessionService.getDomains().toPromise();
    let currentDomain: DomainModelLegacy;
    if (currentDomainFromToken) {
      currentDomain = domains.domains.find(p => p.domainId === currentDomainFromToken);
    } else {
      currentDomain = domains.domains.find(p => p.domainId === defaultDomain.defaultDomain);
    }
    const domainsController: FormControl = this.addForm.get('domains') as FormControl;
    domainsController.setValue([{name: currentDomain.domainName, id: currentDomain.domainId}]);
    this.displayDefault = currentDomain.domainName;

  }

  initExistingUserNames() {
    this.securityService.getUsers(10000, 0, true).subscribe(
      (resp: UserCollectionModel) => {
        if (resp) {
          resp.users.forEach((user) => {
            this.existingUsers.push({id: user.userId, firstName: user.userFirstName, lastName: user.userLastName, mail: user.userEmail});
          });
        }
      }
    );
  }

  selectDomain(event) {
    this.onFormChanges();
    if (event.value.length === 0) {
      this.displayDefault = this.translations['users.chooseDomains'];
    }
  }

  onFormChanges() {
    this.mailAlreadyExists = this.existingUsers.map(user => user.mail).includes(this.f.email.value);
    const firstNameControl: FormControl = this.addForm.get('firstName') as FormControl;
    const lastNameControl: FormControl = this.addForm.get('lastName') as FormControl;
    if (this.mailAlreadyExists) {
      const associatedUser: User = this.existingUsers.find(user => user.mail === this.f.email.value);
      firstNameControl.setValue(associatedUser.firstName);
      firstNameControl.disable();
      lastNameControl.setValue(associatedUser.lastName);
      lastNameControl.disable();
    } else {
      firstNameControl.enable();
      lastNameControl.enable();
    }
    const firstNameAlreadyExists = this.existingUsers.map(user => user.mail).includes(this.f.firstName.value);
    const isMailValid = this.f.email.value !== '';
    const isLastNameValid = this.f.lastName.value !== '';
    const isDomainsValid = this.f.domains.value.length > 0 || !this.isAdmin;
    const isFirstNameValid = (!firstNameAlreadyExists || this.mailAlreadyExists) && this.f.firstName.value !== '';
    this.isFormValid = isMailValid && isFirstNameValid && isLastNameValid && isDomainsValid;
    this.displayMailAdvertiser = this.mailAlreadyExists;

    // display only domains on which user already exist
    if (this.isAdmin) {
      this.filterDomains();
    }
  }

  filterDomains() {
    if (this.mailAlreadyExists) {
      const domainsOfUser: Domain[] = [];
      const associatedUserId: number = this.existingUsers.find(user => user.mail === this.f.email.value).id;
      this.securityService.getDomainsOfUser(associatedUserId).subscribe((domains: DomainCollectionModel) => {
        domains.domains.forEach((domain: DomainModel) => {
          domainsOfUser.push({name: domain.name, id: domain.id});
        });
        const domainsToShow: Domain[] = [];
        this.allDomains.forEach((domain: Domain) => {
          if (!domainsOfUser.map(domainOfUser => domainOfUser.id).includes(domain.id)) {
            domainsToShow.push(domain);
          }
        });
        this.domainArray = of(domainsToShow);
        const domainsControl: FormControl = this.addForm.get('domains') as FormControl;
        const selectedDomains: Domain[] = [];
        domainsControl.value.forEach((domain: Domain) => {
          if (domainsToShow.map(domainToShow => domainToShow.id).includes(domain.id)) {
            selectedDomains.push(domain);
          }
        });
        domainsControl.setValue(selectedDomains);
        if (!selectedDomains.map(domain => domain.name).includes(this.displayDefault)) {
          this.displayDefault = this.translations['users.chooseDomains'];
        }
      });
    } else {
      this.domainArray = of(this.allDomains);
    }
  }

  onAddUser() {
    if (this.f.email.value && this.f.firstName.value && this.f.lastName.value &&
      (this.f.domains.value.length > 0 || !this.isAdmin)) {
      if (!this.f.email.errors) {
        const email = this.f.email.value;
        const firstName = this.f.firstName.value.charAt(0).toUpperCase() + this.f.firstName.value.slice(1);
        const lastName = this.f.lastName.value.toUpperCase();
        const selectedDomains: Domain[] = this.f.domains.value;
        const roles = [RoleEnum.ROLE_ANNOTATION, RoleEnum.ROLE_GEOJSON, RoleEnum.ROLE_MAP_CRASH, RoleEnum.ROLE_MAP_GROUND,
          RoleEnum.ROLE_MAP_HELIOS, RoleEnum.ROLE_MAP_PR, RoleEnum.ROLE_MAP_QUALITY, RoleEnum.ROLE_MAP_SIGN,
          RoleEnum.ROLE_MAP_USER, RoleEnum.ROLE_MASTERDATA, RoleEnum.ROLE_PROCESSING, RoleEnum.ROLE_SECURITY,
          RoleEnum.ROLE_TASK, RoleEnum.ROLE_USER, RoleEnum.ROLE_MAP_STATS];
        if (this.mailAlreadyExists) {
          const userId: number = this.existingUsers.find(user => user.mail === this.f.email.value).id;
          this.securityService.addRoles(userId, selectedDomains.map(d => d.id), roles).subscribe( resp => {
            if (resp) {
              this.addUser.emit(true);
              this.display = false;
              window.location.reload();
            }
          });
        } else {
          // TODO si l'utilisateur a été supprimé, le réactiver ?

          this.securityService.createUser(email, firstName, lastName, roles).subscribe( resp => {
            if (resp) {
              this.addUser.emit(true);
              this.display = false;
              window.location.reload();
            }
          });
        }
      } else {
        this.messageService.add({key: 'bl', severity: 'warn', summary: this.translations['users.emailNotValid']});
      }
    } else {
      this.messageService.add({key: 'bl', severity: 'warn', summary: this.translations['users.missingField']});
    }

  }
  onCancel() {
    this.display = false;
  }
  onHide() {
    this.display = false;
  }
  get f() {
    return this.addForm.controls;
  }
}
