import {ISerializable} from '../iserializable';
import {HeaderModel} from "../header-model";
import {SnapshotModel} from "./snapshot-model";

export class SnapshotCollectionModel implements ISerializable {

  public header: HeaderModel;
  public snapshots: SnapshotModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.header = new HeaderModel().deserialize(input.header);
    this.snapshots = input.snapshots.map(item => new SnapshotModel().deserialize(item));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
