<p style="font-size: 14px">HARDWARE</p>
<hr>
<div class="ui-g-10 ui-g-offset-1"  style="margin-bottom: 10px">
  <p>CAPTURE ANGLE<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.hardwareCaptureAngle}}</span></p>
  <hr>
  <p>CAPTURE HEIGHT<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.hardwareCaptureHeight}}</span></p>
  <hr>
  <p>SERIAL<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.hardwareSerial}}</span></p>
  <hr>
  <p>VERSION<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.hardwareVersion}}</span></p>
</div>
<p *ngIf="currentSnapshot.snapshotProperties.citymapper"  style="font-size: 14px">CITYMAPPER</p>
<hr *ngIf="currentSnapshot.snapshotProperties.citymapper">
<div *ngIf="currentSnapshot.snapshotProperties.citymapper" class="ui-g-10 ui-g-offset-1">
  <p>AZIMUT CORRECTION<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.citymapper.azimut_corr}}</span></p>
  <hr>
  <p>CAMSTRIP DISTANCE<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.citymapper.dist_camstrip}}</span></p>
  <hr>
  <p>LATERAL FACTOR<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.citymapper.lateral_factor}}</span></p>
  <hr>
  <p>PERSPECTIVE FACTOR<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.citymapper.perspective_factor}}</span></p>
  <hr>
  <p>PIXM SCALE<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.citymapper.scale_pixm}}</span></p>
  <hr>
  <p>UPM SCALE<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.citymapper.scale_upm}}</span></p>
  <hr>
  <p>TRANSITION<br/><span class="displayInfo">{{currentSnapshot.snapshotProperties.citymapper.transition}}</span></p>
</div>
