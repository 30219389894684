import {ISerializable} from '../iserializable';

export class RuleModel implements ISerializable {

  public ruleId: number;
  public ruleName: string;
  public ruleOrder: number;
  public ruleComment: string;
  public outProcessCode: string;
  public outProcessType: string;
  public outEventChannel: string;
  public inIsStitched: boolean;
  public inSnapshotType: string;
  public inCurrentStatusCode: string;
  public inLastStatusCode: string;
  public inContainsStatusCode: string;
  public enabled: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
