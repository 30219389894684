export enum MapSourceEnum {
  SOURCE_SATELLITE = 'satelliteSource',
  SOURCE_WORKSPACE = 'workspaceSource',
  SOURCE_MEASURE = 'measure',
  SOURCE_CAMERA = 'cameraSource',
  SOURCE_JOURNEY = 'journeySource',
  SOURCE_LOCATION_MARKER = 'locationMarkerSource',
  SOURCE_GPS_RGP = 'gpsRpgSource',
  SOURCE_IMAGE = 'view_geo',
  SOURCE_STREETLIGHT = 'streetlightSource',

  SOURCE_QUALITY_RED = 'qualityRedSource',
  SOURCE_QUALITY_GREEN = 'qualityGreenSource',
  SOURCE_QUALITY_ORANGE = 'qualityOrangeSource',
  SOURCE_QUALITY_WHITE = 'qualityWhiteSource',
  SOURCE_LAYER_MARKER = 'data',
  SOURCE_ELEMENT = 'element_geo',
}
