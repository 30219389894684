import {Component, OnInit} from '@angular/core';
import {SnapshotService} from '../services/snapshot.service';
import {Router} from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {FormBuilder} from '@angular/forms';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {TaskService} from '../../../services/task.service';
import {ProcessingService} from '../../../services/processing.service';
import { DialogService } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-snapshot-event',
  templateUrl: './snapshot-event.component.html',
  styleUrls: ['./snapshot-event.component.css']
})

export class SnapshotEventComponent implements OnInit {

  currentSnapshot: any;
  displayDetails: boolean;
  displayEdition: boolean;
  displayGantt: boolean;
  displayProperties: boolean;
  displayStatus: boolean;
  isMatched: boolean;
  items: MenuItem[];

  constructor(public breakpointObserver: BreakpointObserver,
              private confirmationService: ConfirmationService,
              private dialogService: DialogService,
              private formBuilder: FormBuilder,
              private messageService: MessageService,
              private processingService: ProcessingService,
              private taskService: TaskService,
              private router: Router,
              private snapshotService: SnapshotService) {
  }

  ngOnInit() {
    this.snapshotService.currentTransferSnapshot.subscribe((snapshot) => {
      this.currentSnapshot = snapshot;
    });
    if (!this.currentSnapshot || this.currentSnapshot === 'none') {
      this.currentSnapshot = JSON.parse(window.localStorage.getItem('localSnapshot'));
    }
    if (!this.currentSnapshot) {
      alert('Invalid action.');
      this.router.navigate(['userList']);
      return;
    }
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        this.isMatched = !state.matches;
      });
    this.displayDetails = true;
    this.displayEdition = false;
    this.displayProperties = false;
    this.displayGantt = false;
    this.displayStatus = false;
    this.items = [
      {
        label: 'Informations', icon: 'pi fa fa-info-circle', command: () => {
          this.displayDetails = true;
          this.displayStatus = false;
          this.displayEdition = false;
          this.displayGantt = false;
          this.displayProperties = false;
        }
      },
      {
        label: 'Propriétés', icon: 'pi fa fa-cog', command: () => {
          this.displayDetails = false;
          this.displayStatus = false;
          this.displayEdition = false;
          this.displayGantt = false;
          this.displayProperties = true;
        }
      },
      {
        label: 'Statuts', icon: 'pi fa fa-pie-chart', command: () => {
          this.displayStatus = true;
          this.displayDetails = false;
          this.displayEdition = false;
          this.displayGantt = false;
          this.displayProperties = false;
        }
      },
      {label: 'Gantt', icon: 'pi fa fa-list', command: () => {
          this.displayStatus = false;
          this.displayDetails = false;
          this.displayEdition = false;
          this.displayGantt = true;
          this.displayProperties = false;
        }},
      {
        label: 'Éditer', icon: 'pi fa fa-pencil', command: () => {
          this.displayEdition = true;
          this.displayStatus = false;
          this.displayDetails = false;
          this.displayGantt = false;
          this.displayProperties = false;
        }
      }
    ];
  }
}
