<div class="globalSearch ui-g ui-fluid snapshotForm">
  <div class="ui-g-12 ui-md-6">
    <p class="pRole">{{'users.edit.available-grids' | translate}} :</p>
    <p-listbox  [options]="availableGridsOptions" optionLabel="gridName"
               [style]="{'width':'100%'}" (onDblClick)="addGridToSelectedOnes($event)"></p-listbox>
  </div>
  <div class="ui-g-12 ui-md-6">
    <p class="pRole">{{'users.edit.user-grids' | translate}} :</p>
    <p *ngIf="!usersGridsOptions">{{'users.edit.no-grids' | translate}}</p>
    <p-listbox *ngIf="usersGridsOptions" [options]="usersGridsOptions" optionLabel="gridName"
               [style]="{'width':'100%'}" (onDblClick)="removeGridfromSelectedOnes($event)"></p-listbox>
  </div>
  <div class="ui-inputgroup">
    <button pButton type="submit" label="{{'users.edit.update-grids' | translate}}" (click)="saveUsersGrids()"></button>
  </div>
</div>
