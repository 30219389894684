<h2>{{'settings.other-lists.title' | translate}}</h2>
<hr>
<div class="globalSearch ui-g" style="position: relative">
    <p-dropdown [options]="listCodes" [(ngModel)]="selectedListCode" placeholder="{{'settings.other-lists.select' | translate}}" optionLabel="code"
                (onChange)="getList()" style="margin-left: auto; margin-right: 0;"></p-dropdown>
</div>
<hr>
<div class="ui-g ui-fluid">
  <div class="test" *ngIf="validSearch">
    <h2>{{listName}}</h2>
    <hr>
    <div class="globalSearch ui-g">
      <button *ngIf="isMatched" pButton type="button" label="{{'settings.other-lists.add-field' | translate}}" class="widthButton"
              (click)="onAddField()"></button>
      <button *ngIf="numberSelection === 0" pButton type="button" label="{{'settings.other-lists.delete-button' | translate:{numberSelection:numberSelection} }}"
              class="ui-button-secondary marginLeft forbidden" disabled="true" [ngClass]="{'widthButton': isMatched}"></button>
      <button *ngIf="numberSelection !== 0" pButton type="button" label="{{'settings.other-lists.delete-button' | translate:{numberSelection:numberSelection} }}"
              class="ui-button-danger marginLeft" (click)="confirmDeleteAll()" [ngClass]="{'widthButton': isMatched}"></button>
    </div>
    <hr>
    <div class="userList">
      <p-table [columns]="cols" [value]="listValues" [(selection)]="selectedValues" (onRowSelect)="onRowSelect()"
               (onRowUnselect)="onRowUnselect()" [responsive]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="checkBoxStyle" style="width: 3em">
              <p-tableHeaderCheckbox (click)="selectAll()"></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns">
              {{col.header}}
            </th>
            <th>
              <button pButton type="button" icon="fa fa-plus" (click)="onAddField()" style="display:block;"
                      class="ui-button-primary marginLeft" pTooltip="{{'settings.other-lists.add-field' | translate}}" tooltipPosition="top"></button>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-listValue let-columns="columns">
          <tr>
            <td class="checkBoxStyle">
              <p-tableCheckbox [value]="listValue"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" class="phoneSizeTab">
              <span class="ui-column-title">{{col.header}}</span>
              {{listValue[col.field]}}
            </td>
            <td>
              <div class="ui-inputgroup ui-rtl" dir="rtl">
                <div class="ui-rtl" dir="ltr">
                  <button pButton type="button" label="{{'settings.other-lists.modify' | translate}}" (click)="changeValue(listValue)" class="btn-secondary"></button>
                  <button pButton type="button" icon="fa fa-close" (click)="confirmDeleteSelf(listValue)"
                          class="ui-button-danger" pTooltip="{{'settings.other-lists.delete' | translate}}" tooltipPosition="top"></button>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <p-dialog id="listUpdate" header="Change list values" [(visible)]="displayUpdate"
                [style]="{width: '300px', height: '200px'}">
        <form [formGroup]="updateListValueForm" (ngSubmit)="updateListValue()" class="updateForm ui-fluid">
          <div *ngFor="let data of listValue; let i = index" class="ui-inputgroup">
            <input *ngIf="i === 0" type="text" pInputText [placeholder]="data" [formControlName]="data"
                   class="ui-inputtext ui-corner-all ui-state-default ui-widget updateInput" disabled="true">
            <input *ngIf="i === 1" type="text" pInputText [placeholder]="data" [formControlName]="data"
                   class="ui-inputtext ui-corner-all ui-state-default ui-widget updateInput" (ngModelChange)="getText($event)">
            <json-editor *ngIf="displayEditor && i === 2" [options]="options"
                         style="width:100%;" class="jsonConfiguration" [(ngModel)]="displayData"
                         [formControlName]="data"></json-editor>
          </div>
          <p *ngIf="isParsingError && displayEditor" class="errorMessage">{{errorMessage}}</p>
          <button pButton type="submit" label="{{'settings.other-lists.update' | translate}}" [disabled]="isParsingError"
                  class="ui-button ui-button-text-only ui-button-text updateButton"></button>
        </form>
      </p-dialog>
      <p-dialog id="listCreate" header="{{'settings.other-lists.create-list-value' | translate}}" [(visible)]="displayCreate"
                [style]="{width: '300px', height: '200px'}">
        <form [formGroup]="createListValueForm" (ngSubmit)="createListValue()" class="updateForm ui-fluid">
          <div class="ui-inputgroup">
            <input type="text" pInputText placeholder="{{'settings.other-lists.value-code' | translate}}" formControlName="valueCode"
                   [pKeyFilter]="blockSpace" class="ui-inputtext ui-corner-all ui-state-default ui-widget updateInput">
          </div>
          <div class="ui-inputgroup">
            <input type="text" pInputText placeholder="{{'settings.other-lists.value-text' | translate}}" formControlName="valueText"
                   class="ui-inputtext ui-corner-all ui-state-default ui-widget updateInput">
          </div>
          <json-editor [options]="options"
                       style="width:100%;" class="jsonConfiguration"
                       formControlName="listValueParameters"></json-editor>
          <p *ngIf="isParsingError" class="errorMessage">{{errorMessage}}</p>
          <button pButton type="submit" label="{{'settings.other-lists.create' | translate}}" [disabled]="isParsingError"
                  class="ui-button ui-button-text-only ui-button-text updateButton"></button>
        </form>
      </p-dialog>
    </div>
    <p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
    <p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>

  </div>
</div>
