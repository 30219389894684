import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from '../globals/session';
import {TaskCommentEnum} from '../enums/task-comment-enum.enum';
import {EventChannelEnum} from '../enums/event-channel-enum.enum';
import {ResultEnum} from '../enums/result-enum.enum';
import {DoneResponseModel} from '../models/done-response-model';
import {TaskModel} from '../models/task/task-model';
import {TaskCollectionModel} from '../models/task/task-collection-model';
import {ProcessCodeEnum} from '../enums/process-code-enum';
import {ProcessTypeEnum} from '../enums/process-type-enum';
import {RequestHelper} from '../helpers/request.helper';
import {RulesCollectionModel} from '../models/task/rules-collection-model';
import {RuleModel} from '../models/task/rule-model';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class TaskService {

  cancelDateBegin: string;
  cancelDateEnd: string;
  closeDateBegin: string;
  closeDateEnd: string;
  processCode: string;
  processType: string;
  requestDateBegin: string;
  requestDateEnd: string;
  taskResultCodes: string[];

  constructor(private session: Session, private httpService: HttpClient, private requestHelper: RequestHelper) {
  }

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  displayStatus(errorMessage) {
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  ackProcessTask(taskId: string): Observable<DoneResponseModel> {
    const url = environment.urlAckProcessTask;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      taskIdentifier: taskId,

    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Process task was not possible to create');
      })
    );
  }

  getProcessTasks(processType: string, snapshotIdentifierList: string[]): Observable<TaskModel> {
    const url = environment.urlGetProcessTasks;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
        ],
        limitCount: 8,
        limitOffset: 0,
      },
      processType,
      snapshotIdentifierList
    }, options).pipe(map(data => new TaskModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Collection not found');
      })
    );
  }

  getProcessTaskBySnapshotIdentifier(snapshotId): Observable<TaskCollectionModel> {
    const url = environment.urlSearchProcessTasks;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
        ]
      },
      reverseOrder: true,
      snapshotIdentifier: snapshotId
    }, options).pipe(map(data => new TaskCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Collection task not found');
      })
    );
  }

  getOnHoldTask(offset, limitCount): Observable<TaskCollectionModel> {
    const url = environment.urlSearchProcessTasks;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
          ResponseLevelEnum.PAGING
        ],
        limitCount,
        limitOffset: offset,
      },
      isAck: false,
      isClose: false,
      isCancel: false,
      processType: this.processType,
      processCode: this.processCode,
      requestDateBegin: this.requestDateBegin,
      requestDateEnd: this.requestDateEnd
    }, options).pipe(map(data => new TaskCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Collection task on hold not found');
      })
    );
  }
  getAckTask(offset, limitCount): Observable<TaskCollectionModel> {
    const url = environment.urlSearchProcessTasks;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
          ResponseLevelEnum.PAGING
        ],
        limitCount,
        limitOffset: offset,
      },
      isAck: true,
      isCancel: false,
      isClose: false,
      processType: this.processType,
      processCode: this.processCode,
      requestDateBegin: this.requestDateBegin,
      requestDateEnd: this.requestDateEnd
    }, options).pipe(map(data => new TaskCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Collection task ack not found');
      })
    );
  }
  getCancelTask(offset, limitCount): Observable<TaskCollectionModel> {
    const url = environment.urlSearchProcessTasks;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
          ResponseLevelEnum.PAGING
        ],
        limitCount,
        limitOffset: offset,
      },
      isCancel: true,
      processType: this.processType,
      processCode: this.processCode,
      requestDateBegin: this.requestDateBegin,
      requestDateEnd: this.requestDateEnd,
      cancelDateBegin: this.cancelDateBegin,
      cancelDateEnd: this.cancelDateEnd,
    }, options).pipe(map(data => new TaskCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Collection task cancel not found');
      })
    );
  }
  getCloseTask(offset, limitCount): Observable<TaskCollectionModel> {
    const url = environment.urlSearchProcessTasks;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
          ResponseLevelEnum.PAGING
        ],
        limitCount,
        limitOffset: offset,
      },
      isClose: true,
      processType: this.processType,
      processCode: this.processCode,
      requestDateBegin: this.requestDateBegin,
      requestDateEnd: this.requestDateEnd,
      closeDateBegin: this.closeDateBegin,
      closeDateEnd: this.closeDateEnd,
      taskResultCodes: this.taskResultCodes
    }, options).pipe(map(data => new TaskCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Collection task close not found');
      })
    );
  }

  createProcessTask(snapshotIdentifier: string, processCode: string, processType: string): Observable<DoneResponseModel> {
    const url = environment.urlCreateProcessTask;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      snapshotIdentifier,
      processCode,
      processType,
      taskComment: TaskCommentEnum.NEW_MANUAL_TASK,
      eventChannel: EventChannelEnum.SOCKET_IO_TASK
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Process task was not possible to create');
      })
    );
  }

  changeDomainOnDock(snapshotIdentifier: string, domainCode: string): Observable<DoneResponseModel> {
    const url = environment.urlCreateProcessTask;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      snapshotIdentifier,
      processCode: ProcessCodeEnum.CHANGE_DOMAIN,
      processType: ProcessTypeEnum.DOCK,
      taskProperties: {
        targetDomain: domainCode
      }
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Change domain is not possible');
      })
    );
  }
  changeDomainWithoutDock(snapshotIdentifier: string, domainCode: string): Observable<DoneResponseModel> {
    const url = environment.urlChangeSnapshotDomain;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      domainCode,
      snapshotIdentifier,
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Change domain is not possible');
      })
    );
  }

  closeProcessTask(taskIdentifier: string): Observable<DoneResponseModel> {
    const url = environment.urlCloseProcessTask;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      taskIdentifier,
      resultCode: ResultEnum.RESULT_CODE_CANCEL,
      resultMessage: ResultEnum.RESULT_MESSAGE_CANCEL,
      done: false
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Process task was not possible to close');
      })
    );
  }

  createProcessRules(processRules: RuleModel[]): Observable<DoneResponseModel> {
    const url = environment.urlCreateProcessRules;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
        ]
      },
      processRules
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot create rule');
      })
    );
  }

  getProcessRules(): Observable<RulesCollectionModel> {
    const url = environment.urlGetProcessRules;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
        ]
      }
    }, options).pipe(map(data => new RulesCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Collection rules not found');
      })
    );
  }

  updateProcessRules(processRules: RuleModel[]): Observable<DoneResponseModel> {
    const url = environment.urlUpdateProcessRules;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE,
        ]
      },
      processRules
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot update rule');
      })
    );
  }

  deleteProcessRules(ruleId: number): Observable<DoneResponseModel> {
    const url = environment.urlDeleteProcessRules;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      ruleId
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot delete rule');
      })
    );
  }
}
