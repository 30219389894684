import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {MasterDataService} from '../../../services/master-data.service';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {ListCollectionModel} from "../../../models/masterData/list-collection-model";

interface Resp {
  lists: any;
}

@Component({
  selector: 'app-settings-config',
  templateUrl: './settings-config.component.html',
  styleUrls: ['./settings-config.component.css']
})
export class SettingsConfigComponent implements OnInit {

  public data: any;
  public newData: any;
  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;

  config: any;
  isMatched: boolean;
  listCode: string;
  selectedListCode: {code: string};
  listCodes: {code: string}[];
  searchForm: FormGroup;
  validSearch = false;

  blockSpace: RegExp = /[^\s]/;
  cols: any[];
  confirmMessage: string;
  counter: number;
  createListValueForm: FormGroup;
  displayCreate = false;
  displayUpdate = false;
  displayData: any;
  listName: any;
  listValues: any;
  listValue: any;
  numberOfValues: number;
  numberSelection: number;
  selectedValues: any;
  updateListValueForm: FormGroup;

  // options: JsonEditorOptions;
  options: any;
  isParsingError: boolean;
  errorMessage: string;
  displayEditor: boolean;

  currentValueCode: string;
  currentValueText: string;

  constructor(public breakpointObserver: BreakpointObserver,
              private masterDataService: MasterDataService,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initListCodes();
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        this.isMatched = !state.matches;
      });
    this.isParsingError = false;
    this.errorMessage = '*Parsing error';
    // this.options.modes = ['code', 'tree', 'view'];
    this.options = {
      mode: 'code',
      onError: (err) => {
        alert(err.toString());
      },
      onChange: () => {
        try {
          this.isParsingError = false;
          // JSON.stringify(this.editor.get());
          this.newData = JSON.stringify(this.editor.get());

        } catch (err) {
          this.isParsingError = true;
        }
      }
    };
    this.searchForm = this.formBuilder.group({
      listCode: ['', Validators.required],
    });
    this.numberSelection = 0;
    this.cols = [
      { field: 'valueCode', header: 'Code' },
      { field: 'valueText', header: 'Texte' }
    ];
    this.listValue = [];
    this.updateListValueForm = this.formBuilder.group({
      research: ['', Validators.required]
    });
    this.createListValueForm = this.formBuilder.group({
      valueCode: ['', Validators.required],
      valueText: ['', Validators.required],
      listValueParameters: ['', Validators.required],
    });
  }
  async initListCodes() {
    const listCodesTmp: string[] = await this.getListCodes();
    listCodesTmp.sort((a, b) => a.localeCompare(b));
    this.listCodes = [];
    listCodesTmp.forEach(listCode => this.listCodes.push({code: listCode}));
  }
  async getListCodes(): Promise<string[]> {
    const lists: ListCollectionModel = await this.masterDataService.getList().toPromise();
    return lists.lists.map(list => list.listCode);
  }
  getList() {
    this.listCode = this.selectedListCode.code.toUpperCase();
    this.getListValues();
  }
  onRowSelect() {
    this.numberSelection++;
  }
  onRowUnselect() {
    this.numberSelection--;
  }
  selectAll() {
    if (this.numberSelection !== this.numberOfValues) {
      this.numberSelection = this.numberOfValues;
    } else {
      this.numberSelection = 0;
    }
  }
  changeValue(listValue) {
    this.listValue = [];
    const formGroup = new FormGroup({});
    this.displayEditor = !!listValue.valueParameters;
    this.displayData = listValue.valueParameters;
    for (const key in listValue) {
      this.listValue.push(listValue[key]);
      const control: FormControl = new FormControl(listValue[key], Validators.required);
      formGroup.addControl(listValue[key], control);
    }
    this.currentValueCode = this.listValue[0];
    this.currentValueText = this.listValue[1];
    this.updateListValueForm = formGroup;
    this.displayUpdate = true;
  }
  onAddField() {
    this.displayCreate = true;
  }
  createListValue() {
    if (this.f.valueCode.value && this.f.valueText.value) {
      const valueCode = this.f.valueCode.value.toUpperCase();
      const valueText = this.f.valueText.value;
      let listValueParameters;
      if (this.newData !== undefined) {
        listValueParameters = JSON.parse(this.newData);
        // listValueParameters = this.newData;
      } else {
        listValueParameters = JSON.parse('{}');
      }
      this.masterDataService.createListValue(this.listCode, valueCode, valueText, listValueParameters).subscribe(resp => {
        if (resp) {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ créé',
            detail: valueCode});
          this.getListValues();
        }
      });
      this.displayCreate = false;
    }
  }
  getListValues() {
    this.numberOfValues = 0;
    this.listValues = [];
    this.masterDataService.getList(this.listCode).subscribe( (resp: Resp) => {
      if (resp) {
        this.listName = resp.lists[0].listName;
        this.listValues = resp.lists[0].listValues;
        if (!this.listValues) {
          this.listValues = resp.lists[0].values;
        }
        this.listValues.forEach( () => {
          this.numberOfValues++;
        });
        this.validSearch = true;
      }
    });
  }
  updateListValue() {
    if (this.newData !== undefined) {
      const listValueParameters = JSON.parse(this.newData);
      // const listValueParameters = this.newData;

      if (this.listValue[1] !== this.currentValueText) {
        this.listValue[1] = this.currentValueText;
      }
      this.masterDataService.updateListValue(this.listCode, this.listValue[0], this.listValue[1], listValueParameters).subscribe(resp => {
        if (resp) {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ mis à jour',
            detail: this.currentValueCode});
          this.getListValues();
        }
      });
    } else {
      if (this.listValue[1] !== this.currentValueText) {
        this.listValue[1] = this.currentValueText;
      }
      this.masterDataService.updateListValue(this.listCode, this.listValue[0], this.listValue[1]).subscribe(resp => {
        if (resp) {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ mis à jour',
            detail: this.currentValueCode});
          this.getListValues();
        }
      });
    }
  }
  confirmDeleteSelf(listValue) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer ce champ : </b><br/><ul class="confirmList"><li>'
        + listValue.valueCode + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.masterDataService.deleteListValue(this.listCode, listValue.valueCode).subscribe(resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ supprimé de ' + this.listCode,
              detail: listValue.valueCode});
            this.getListValues();
          }
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
  confirmDeleteAll() {
    if (this.numberSelection > 0) {
      this.confirmMessage = '<ul class="confirmList">';
      this.selectedValues.forEach( listValue => {
        this.confirmMessage += '<li>' + listValue.valueCode + '</li>';
      });
      this.confirmMessage += '</ul>';
    }
    this.counter = 0;
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer tous ces champs : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.selectedValues.forEach( listValue => {
          this.masterDataService.deleteListValue(this.listCode, listValue.valueCode).subscribe(resp => {
            if (resp) {
              this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ supprimé de ' + this.listCode,
                detail: listValue.valueCode});
              this.counter++;
              if (this.counter === this.numberSelection) {
                this.getListValues();
              }
            }
          });
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
  getData(event) {
    this.newData = event;
  }
  getText(event) {
    this.currentValueText = event;
  }
  get f() {
    return this.createListValueForm.controls;
  }
  get g() {
    return this.searchForm.controls;
  }
}
