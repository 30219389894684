<p-dialog header="New user" [(visible)]="display" [modal]="true" [responsive]="true"
          [contentStyle]="{maxHeight: '100%', overflow: 'auto'}" [draggable]="false" modal="true"
          [style]="{'width': '500px', 'minWidth': '300px'}" (onHide)="onHide()" [transitionOptions]="'200ms'">
  <form [formGroup]="addForm" (ngSubmit)="onAddUser()" class="globalSearch ui-g ui-fluid snapshotForm">

    <span class="ui-float-label ui-g-12 inputFloat">
      <input type="email" id="email" pInputText formControlName="email" class="form-control ui-g-12"
      (change)="onFormChanges()"/>
      <label class="labels" for="email">Email</label>
      <i class="advertiser" [hidden]="!displayMailAdvertiser">{{'users.mailAlreadyExists' | translate}}</i>
    </span>

    <span class="ui-float-label ui-g-12 inputFloat">
      <input type="text" id="firstname" pInputText formControlName="firstName" class="form-control ui-g-12"
             (change)="onFormChanges()"/>
      <label class="labels" for="firstname" >{{'users.firstName' | translate}}</label>
    </span>

    <span class="ui-float-label ui-g-12 inputFloat">
      <input type="text" id="lastname" pInputText formControlName="lastName" class="form-control ui-g-12"
             (change)="onFormChanges()"/>
      <label class="labels" for="lastname">{{'users.lastName' | translate}}</label>
    </span>

    <span class="ui-float-label ui-g-12 inputFloat">
      <p-multiSelect [options]="domainArray | async" formControlName="domains" optionLabel="name"
                     defaultLabel="{{ displayDefault }}" class="form-control ui-g-12" id="domains"
                     (onChange)="selectDomain($event)" *ngIf="isAdmin"></p-multiSelect>
    </span>

  </form>
  <p-footer>
    <p-button icon="pi pi-times"  label="{{'users.cancel' | translate}}" (click)="onCancel()"></p-button>
    <p-button icon="pi pi-check" type="submit" label="{{'users.confirm' | translate}}" (click)="onAddUser()"
              styleClass="p-button-text" [disabled]="!isFormValid"></p-button>
  </p-footer>
</p-dialog>
