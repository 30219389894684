import {ISerializable} from '../iserializable';
import {DomainModelLegacy} from './domain-model-legacy';

export class DomainCollectionModelLegacy implements ISerializable {

  public domains: DomainModelLegacy[];

  deserialize(input: any): this {
    Object.assign(this, input);

    this.domains = input.domains.map(item => new DomainModelLegacy().deserialize(item));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
