import {ISerializable} from '../iserializable';
import {ListModel} from "./list-model";
import {FeatureObjectViewModel} from "../geojson/feature-object-view-model";
import {ConfigModel} from "./config-model";

export class ConfigCollectionModel implements ISerializable {

  configurations: ConfigModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.configurations = input.configurations.map(list => new ConfigModel().deserialize(list));

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
