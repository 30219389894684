import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {UsersListComponent} from './users-list/users-list.component';
import {UsersComponent} from './users.component';
import {UsersEditComponent} from './users-edit/users-edit.component';

import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PanelModule} from 'primeng/panel';
import {MessageService} from 'primeng/api';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {CardModule} from 'primeng/card';
import {AutoCompleteModule} from 'primeng/autocomplete';


import {InputTextModule, ListboxModule, MenuModule, MultiSelectModule} from 'primeng';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {PaginatorModule} from 'primeng/paginator';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TooltipModule} from 'primeng/tooltip';


import {UsersAddComponent} from "./users-add/users-add.component";
import {DynamicDialogModule} from "primeng/dynamicdialog";
import {UsersEditModifyComponent} from './users-edit/users-edit-modify/users-edit-modify.component';
import {UsersEditRolesComponent} from './users-edit/users-edit-roles/users-edit-roles.component';
import {UsersEditLocationComponent} from './users-edit/users-edit-location/users-edit-location.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UsersEditGridsComponent} from './users-edit/users-edit-grids/users-edit-grids.component';
import {TranslateModule} from "@ngx-translate/core";
import { UsersDeleteComponent } from './users-delete/users-delete.component';


@NgModule({
  declarations: [UsersComponent, UsersListComponent, UsersEditComponent, UsersAddComponent, UsersEditModifyComponent, UsersEditRolesComponent, UsersEditLocationComponent, UsersEditGridsComponent, UsersDeleteComponent],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    TableModule,
    ButtonModule,
    ReactiveFormsModule,
    ToastModule,
    PanelModule,
    MessagesModule,
    MessageModule,
    DynamicDialogModule,
    DialogModule,
    CardModule,
    AutoCompleteModule,
    SplitButtonModule,
    PaginatorModule,
    ProgressSpinnerModule,
    TooltipModule,
    ConfirmDialogModule,
    MenuModule,
    CheckboxModule,
    ListboxModule,
    InputTextModule,
    TranslateModule,
    MultiSelectModule,

  ],
  providers: [
    MessageService
  ]
})
export class UsersModule {
}
