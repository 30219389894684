import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from '../globals/session';
import {StatusEnum} from '../enums/status-enum.enum';
import {ListCollectionModel} from '../models/masterData/list-collection-model';
import {DoneResponseModel} from '../models/done-response-model';
import {ConfigCollectionModel} from '../models/masterData/config-collection-model';
import {StatusCollectionModel} from '../models/masterData/status-collection-model';
import {RequestHelper} from '../helpers/request.helper';
import {ElementClassCollectionModel} from "../models/masterData/element-class-collection-model";


@Directive()
@Injectable({
  providedIn: 'root'
})

export class MasterDataService {

  codeConfig: string;

  constructor(private session: Session, private httpService: HttpClient, private requestHelper: RequestHelper) {
  }

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  displayStatus(errorMessage) {
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  getElementClass(elementType: string, category: string = undefined, classCode: string[] = []): Observable<ElementClassCollectionModel> {
    const url = environment.urlGetElementClass;
    const params = {};
    if(elementType) { params['elementType'] = elementType ;}
    if(category) { params['classCategory'] = category ;}
    if(classCode.length) { params['elementClassList'] = classCode ;}

    return this.httpService.get(url, {params}).pipe(map(data => {
        return new ElementClassCollectionModel().deserialize(data);
      }),
      catchError(err => {
        this.displayStatus(err);
        return throwError('Element class list not found');
      })
    );
  }

  getStatusList(): Observable<StatusCollectionModel> {
    const url = environment.urlGetStatusList;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      statusGroup: StatusEnum.STATUS_GROUP_PROCESSING
    }, options).pipe(map(data => new StatusCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Status List not found');
      })
    );
  }

  getList(listCode = null): Observable<ListCollectionModel> {
    const url = environment.urlMasterdataGetLists;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      listCode,
    }, options).pipe(map(data => new ListCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError(listCode + ' : List not found');
      })
    );
  }
  createListValue(code, valueCode, valueText, listValueParameters?): Observable<DoneResponseModel> {
    const url = environment.urlMasterdataCreateListValue;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      listCode: code,
      listValueCode: valueCode,
      listValueText: valueText,
      listValueParameters
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Create list value not possible');
      })
    );
  }
  updateListValue(code, valueCode, valueText, listValueParameters?): Observable<DoneResponseModel> {
    const url = environment.urlMasterdataUpdateListValue;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      listCode: code,
      listValueCode: valueCode,
      listValueText: valueText,
      listValueParameters
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Update list value not possible');
      })
    );
  }
  deleteListValue(code, valueCode): Observable<DoneResponseModel> {
    const url = environment.urlMasterdataDeleteListValue;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      listCode: code,
      listValueCode: valueCode
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Delete list value not possible');
      })
    );
  }
  getElementClassList(offset: number, count: number, elementClass: string = null): Observable<ElementClassCollectionModel> {
    const url = environment.urlMasterdataGetElementClass;
    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL,
          ResponseLevelEnum.PAGING
        ],
        limitCount: count,
        limitOffset: offset
      },
      elementClass
    }, options).pipe(map(data => new ElementClassCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Element list not found');
      })
    );
  }
  createElementClass(elementClass): Observable<DoneResponseModel> {
    //const url = environment.urlMasterdataCreateElementClass;
    const url = 'https://domain-api-dev.citymagine.biz/api/v1/elementClasses/';

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      elementType: elementClass.elementType,
      classCode: elementClass.classCode,
      classParent: elementClass.classParent,
      classCategory: elementClass.classCategory,
      classFilter01: elementClass.classFilter01,
      classFilter02: elementClass.classFilter02,
      classFilter03: elementClass.classFilter03,
      classDescription: elementClass.classDescription,
      classCallbackFunction: elementClass.classCallBackFunction,
      classProperties: elementClass.classProperties,
      classViewType: elementClass.classViewType,
      classGeoType: elementClass.classGeoType,
      classGeoZoom: elementClass.classGeoZoom,
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Create element class not possible');
      })
    );
  }
  updateElementClass(elementClass): Observable<DoneResponseModel> {
    //  const url = environment.urlMasterdataUpdateElementClass;
    const url = 'https://domain-api-dev.citymagine.biz/api/v1/elementClasses/' + elementClass.classId;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.put(url, {
      elementType: elementClass.elementType,
      classCode: elementClass.classCode,
      classParent: elementClass.classParent,
      classCategory: elementClass.classCategory,
      classFilter01: elementClass.classFilter01,
      classFilter02: elementClass.classFilter02,
      classFilter03: elementClass.classFilter03,
      classDescription: elementClass.classDescription,
      classCallbackFunction: elementClass.classCallBackFunction,
      classProperties: elementClass.classProperties,
      classViewType: elementClass.classViewType,
      classGeoType: elementClass.classGeoType,
      classGeoZoom: elementClass.classGeoZoom,
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Update element class not possible');
      })
    );
  }
  deleteElementClass(elementClass): Observable<DoneResponseModel> {
    const url = environment.urlMasterdataDeleteElementClass;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      classCode: elementClass.classCode
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Delete element class not possible');
      })
    );
  }
  getAllConfig(): Observable<ConfigCollectionModel> {
    const url = environment.urlMasterdataGetConfig;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
    }, options).pipe(map(data => new ConfigCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot get config list');
      })
    );
  }
  getConfigurations(codeConfig): Observable<ConfigCollectionModel> {
    this.codeConfig = codeConfig;
    const url = environment.urlMasterdataGetConfig;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      configCode: codeConfig
    }, options).pipe(map(data => new ConfigCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Config not displayable');
      })
    );
  }

  updateConfigurationText(codeConfig, textData): Observable<DoneResponseModel> {
    const url = environment.urlMasterDataUpdateConfig;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      configCode: codeConfig,
      valueText: textData
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Update config not possible');
      })
    );
  }
  updateConfigurationJson(codeConfig, jsonData): Observable<DoneResponseModel> {
    const url = environment.urlMasterDataUpdateConfig;


    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      configCode: codeConfig,
      valueJson: jsonData
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Update config not possible');
      })
    );
  }
}
