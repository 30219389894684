import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SnapshotService} from '../../snapshot/services/snapshot.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {ConfirmationService, MenuItem, SelectItem} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {MessageService} from 'primeng/api';
import {SnapshotEventComponent} from '../../snapshot/snapshot-event/snapshot-event.component';
import {TaskService} from '../../../services/task.service';
import {TaskCollectionModel} from '../../../models/task/task-collection-model';
import {ProcessingService} from '../../../services/processing.service';
import {SnapshotCollectionModel} from '../../../models/processing/snapshot-collection-model';
import {TaskModel} from '../../../models/task/task-model';
import {ProcessSearchFormComponent} from '../process-search-form/process-search-form.component';
import { Paginator } from 'primeng/paginator';
@Component({
  selector: 'app-task-done-list',
  templateUrl: './task-done-list.component.html',
  styleUrls: ['./task-done-list.component.css']
})
export class TaskDoneListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('p') paginator: Paginator;

  @ViewChild(ProcessSearchFormComponent)
  private processSearchFormComponent: ProcessSearchFormComponent;

  actionItems: MenuItem[];
  all = '';
  arrayProcesses: TaskModel[];
  cols: any[];
  confirmMessage: string;
  counterAction: number;
  display: boolean;
  firstDisplay: boolean;
  init: number;
  isMatched: boolean;
  isResult: boolean;
  items: MenuItem[];
  last: number;
  limitCount: number;
  numberOfProcesses: number;
  numberSelection: number;
  offset: number;
  process: TaskModel;
  processes: TaskModel[];
  processesLoaded: boolean;
  searchForm: FormGroup;
  selectedProcesses: TaskModel[];
  selectedType: string;
  totalCount: number;
  types: SelectItem[];
  taskDoneListComponent: boolean;


  constructor(private confirmationService: ConfirmationService,
              private dialogService: DialogService,
              private formBuilder: FormBuilder,
              private messageService: MessageService,
              private processingService: ProcessingService,
              private taskService: TaskService,
              private router: Router,
              private snapshotService: SnapshotService,
              public breakpointObserver: BreakpointObserver) {
    this.types = [
      {label: 'CPU', value: 'CPU'},
      {label: 'GPU', value: 'GPU'},
      {label: 'DOCK', value: 'DOCK'},
      {label: 'TILES', value: 'TILES'}
    ];
  }
  ngAfterViewInit(): void {
    this.selectedProcesses = [];
    this.counterAction = 0;
    this.processSearchFormComponent.searchDone.subscribe( () => {
      this.paginator.changePage(0);
    });
    this.taskDoneListComponent = true;

    this.searchForm = this.formBuilder.group({
      selectedType: ['', Validators.required],
      research: ['', Validators.required]
    });
    this.cols = [
      {field: 'processCode', header: 'Code'},
      {field: 'processType', header: 'Type'},
      {field: 'snapshotName', header: 'Nom du relevé'},
      {field: 'taskResultCode', header: 'Résultat'},
    ];
    this.actionItems = [
      {label: 'Rafraîchir', icon: 'fa fa-refresh', command: () => {
          this.callToGetProcess();
        }},
      {label: 'Reset tous les filtres', icon: 'fa fa-backward', command: () => {
          this.resetFilter();
        }},
    ];
    this.items = [
      {label: 'Relancer', icon: 'fa fa-refresh', command: () => {
          this.confirmRestart(this.process);
        }}
    ];
    this.offset = 0;
    this.init = 0;
    this.last = 12;
    this.limitCount = 12;
    this.numberOfProcesses = 0;
    this.numberSelection = 0;
    this.taskService.processCode = '';
    this.taskService.processType = '';
    this.taskService.requestDateBegin = null;
    this.taskService.requestDateEnd = null;
    this.taskService.closeDateBegin = null;
    this.taskService.closeDateEnd = null;
    this.taskService.cancelDateBegin = null;
    this.taskService.cancelDateEnd = null;
    this.taskService.taskResultCodes = null;

    this.taskService.getCloseTask(this.offset, this.limitCount).subscribe((resp: TaskCollectionModel) => {
      if (resp) {
        this.totalCount = resp.header.totalCount;
        this.processes = resp.tasks;
        if (this.processes !== undefined) {
          this.processes.forEach( () => {
            this.numberOfProcesses++;
          });
          this.processesLoaded = true;
          this.isResult = true;
        } else {
          this.isResult = false;
          this.processesLoaded = true;
        }
        this.firstDisplay = false;
      } else {
        this.isResult = false;
        this.processesLoaded = true;
      }

    });
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
        }
      });  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.taskDoneListComponent = false;
  }

  displayForm() {
    this.processSearchFormComponent.display = true;
  }

  onEventSnapshot(process) {
    this.processesLoaded = false;
    this.processingService.getSnapshot(process.snapshotIdentifier).subscribe((resp: SnapshotCollectionModel) => {
      if (resp) {
        let snapshot = resp.snapshots[0];
        snapshot.timeSpend = this.snapshotService.dateDiff(snapshot);
        snapshot.distance = this.snapshotService.getDistance(snapshot).toString() + 'km';
        snapshot.dateFromStatus = moment(new Date(snapshot.statusDate)).fromNow();
        snapshot = this.snapshotService.getFunctions(snapshot);
        window.localStorage.removeItem('localSnapshot');
        window.localStorage.setItem('localSnapshot', JSON.stringify(snapshot));
        this.snapshotService.transferSnapshot(snapshot);
        this.processesLoaded = true;
        const ref = this.dialogService.open(SnapshotEventComponent, {
          header: snapshot.snapshotName,
          width: '80%',
          height: '80%',
          transitionOptions: '200ms',
          contentStyle: {'max-height': '100%', overflow: 'auto'},
        });
      }
    });
  }
  setProcess(process) {
    this.process = process;
  }

  resetFilter() {
    this.taskService.processCode = '';
    this.taskService.processType = '';
    this.taskService.requestDateBegin = null;
    this.taskService.requestDateEnd = null;
    this.taskService.closeDateBegin = null;
    this.taskService.closeDateEnd = null;
    this.taskService.cancelDateBegin = null;
    this.taskService.cancelDateEnd = null;
    this.taskService.taskResultCodes = null;
    this.paginator.changePage(0);
  }

  paginate(event) {
    this.limitCount = event.rows;
    this.init = event.first;
    this.last = event.first + event.rows;
    this.offset = event.first / this.limitCount;
    this.snapshotService.isSearch = false;
    this.callToGetProcess();
  }

  callToGetProcess() {
    this.numberOfProcesses = 0;
    this.processesLoaded = false;
    this.arrayProcesses = [];
    this.numberSelection = 0;
    this.selectedProcesses = [];
    this.taskService.getCloseTask(this.offset, this.limitCount).subscribe((resp: TaskCollectionModel) => {
        if (resp) {
          this.totalCount = resp.header.totalCount;
          resp.tasks.forEach(process => {
            this.arrayProcesses.push(process);
            this.numberOfProcesses++;
          });
          this.processes = this.arrayProcesses;
          this.processesLoaded = true;
        }
        if (this.numberOfProcesses === 0) {
          this.isResult = false;
        } else {
          this.isResult = true;
        }
      }
    );
  }
  searchBy(selectedType) {
    this.init = 0;
    this.last = 12;
    this.offset = 0;
    if  (selectedType !== undefined) {
      this.taskService.processType = selectedType;
    } else {
      this.taskService.processType = '';
      this.selectedType = null;
    }
    this.paginator.changePage(0);
  }
  get f() {
    return this.searchForm.controls;
  }
  onRowSelect() {
    this.numberSelection++;
  }

  onRowUnselect() {
    this.numberSelection--;
  }
  selectAll() {
    if (this.numberSelection !== this.numberOfProcesses) {
      this.numberSelection = this.numberOfProcesses;
    } else {
      this.numberSelection = 0;
    }
  }
  confirmRestart(process) {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr de vouloir relancer cette tâche ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.taskService.createProcessTask(process.snapshotIdentifier, process.processCode, process.processType)
          .subscribe( () => {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Tâche relancée',
              detail: process.processCode});
            this.callToGetProcess();
          });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Annulé'});
      }
    });
  }
  confirmRestartAll() {
    if (this.selectedProcesses.length > 0) {
      if (this.numberSelection > 0) {
        this.confirmMessage = '<ul class="confirmList">';
        this.selectedProcesses.forEach( process => {
          this.confirmMessage += '<li>' + process.processCode + ' - ' + process.snapshotName + '</li>';
        });
        this.confirmMessage += '</ul>';
      }
      this.confirmationService.confirm({
        message: '<b>Voulez-vous relancer toutes ces tâches : </b><br/>' + this.confirmMessage,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        blockScroll: false,
        accept: () => {
          this.selectedProcesses.forEach( process => {
            this.taskService.createProcessTask(process.snapshotIdentifier, process.processCode, process.processType)
              .subscribe( () => {
                this.messageService.add({key: 'bl', severity: 'success', summary: 'Tâche relancée',
                  detail: process.processCode});
                this.counterAction++;
                if (this.numberSelection === this.counterAction) {
                  this.callToGetProcess();
                  this.counterAction = 0;
                }
            });
          });
        },
        reject: () => {
          this.messageService.add({key: 'bl', severity: 'warn', summary: 'Annulé'});
        }
      });
    } else {
      this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucune tâche sélectionnée'});
    }
  }
}
