import {ISerializable} from '../iserializable';
import {ListValueModel} from "./list-value-model";

export class ListModel implements ISerializable {

  listCode: string;
  listName: string;
  listValues: ListValueModel[];
  values: ListValueModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.listValues = input.listValues.map(listValue => new ListValueModel().deserialize(listValue));

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
