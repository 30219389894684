import {Component, OnInit, ViewChild} from '@angular/core';
import {SnapshotSearchFormComponent} from '../snapshot-received/snapshot-search-form/snapshot-search-form.component';
import {CreateProcessComponent} from '../snapshot-received/create-process/create-process.component';
import {ChangeDomainComponent} from '../change-domain/change-domain.component';
import {AddTagComponent} from '../add-tag/add-tag.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {SnapshotService} from '../services/snapshot.service';
import {ProcessingService} from '../../../services/processing.service';
import {SnapshotCollectionModel} from '../../../models/processing/snapshot-collection-model';
import * as moment from 'moment';
import {SnapshotModel} from '../../../models/processing/snapshot-model';
import {SnapshotEventComponent} from '../snapshot-event/snapshot-event.component';
import {SnapshotMapComponent} from '../snapshot-map/snapshot-map.component';
import {MasterDataService} from '../../../services/master-data.service';
import {ListTypesEnum} from '../../../enums/list-types-enum';
import {ListCollectionModel} from '../../../models/masterData/list-collection-model';
import {ListValueModel} from '../../../models/masterData/list-value-model';
import { Paginator } from 'primeng/paginator';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-snapshot-project',
  templateUrl: './snapshot-project.component.html',
  styleUrls: ['./snapshot-project.component.css']
})
export class SnapshotProjectComponent implements OnInit {

  @ViewChild('p') paginator: Paginator;
  @ViewChild(SnapshotSearchFormComponent) private snapshotSearchFormComponent: SnapshotSearchFormComponent;
  @ViewChild(CreateProcessComponent) private createProcessComponent: CreateProcessComponent;
  @ViewChild(ChangeDomainComponent) private changeDomainComponent: ChangeDomainComponent;
  @ViewChild(AddTagComponent) private addTagComponent: AddTagComponent;


  actionItems: MenuItem[];
  arraySnapshots: any [];
  checkValue: any;
  cols: any;
  confirmMessage: any;
  counter: number;
  currentSnapshot: any;
  display: boolean;
  firstDisplay = true;
  init: number;
  isMatched: boolean;
  isResult: boolean;
  last: number;
  limitCount: number;
  multipleItems: MenuItem[];
  numberOfSnapshots: number;
  numberSelection: number;
  offset: number;
  searchForm: FormGroup;
  selectedName: any;
  selectedSnapshot: any;
  selectedSnapshots: any;
  snapshot: any;
  snapshotList: any[];
  snapshotName: string;
  snapshots: any [];
  snapshotsLoaded = false;
  tableItems: MenuItem[];
  tags: string[];
  tagSearchForm: FormGroup;
  totalCount: number;
  totalDistance: number;

  constructor(public breakpointObserver: BreakpointObserver,
              private router: Router,
              private snapshotService: SnapshotService,
              private formBuilder: FormBuilder,
              private dialogService: DialogService,
              private processingService: ProcessingService,
              private masterdataService: MasterDataService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) {}

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
        }
      });
    this.totalDistance = 0;
    this.snapshotService.isSearch = false;
    this.snapshotService.changeStatusRequest.subscribe(endedRequest  => {
      this.snapshots = [];
      this.snapshotsLoaded = endedRequest;
      this.isResult = false;
    });
    this.snapshotService.changeStatus.subscribe( error => {
      if (error.error) {
        this.messageService.add({key: 'bl', severity: 'error', summary: error.error.code + ' (' + error.status + ')',
          detail: error.error.message, closable: false});
      } else if (error.statusText) {
        this.messageService.add({key: 'bl', severity: 'error', summary: error.statusText + ' (' + error.status + ')',
          closable: false});
      }
    });
    // this.processingService.changeIsNewProcess.subscribe( isNewProcess => {
    //   this.validMessage(isNewProcess);
    // });
    this.selectedSnapshots = [];
    this.searchForm = this.formBuilder.group({
      research: ['', Validators.required]
    });
    this.tagSearchForm = this.formBuilder.group({
      tags: ['', Validators.required],
    });
    this.masterdataService.getList(ListTypesEnum.PROCESS).subscribe( (response: ListCollectionModel) => {
      this.cols = [];
      response.lists[0].listValues.forEach( (element: ListValueModel) => {
        if (element.valueCode !== 'DEDUPLICATE_TRASH' && element.valueCode !== 'CHANGE_DOMAIN') {
          const item = {
            field: element.valueParameters.field,
            header: element.valueParameters.shortName,
            displayName: element.valueParameters.displayName,
            statusCode: element.valueCode,
            doneStatus: element.valueParameters.doneStatus,
            failedStatus: element.valueParameters.failedStatus,
            wipStatus: element.valueParameters.wipStatus,
            order: element.valueParameters.order
          };
          if (element.valueParameters.defaultDisplay) {
            this.cols.push(item);
            this.cols.sort(this.getSortOrder('order'));
          }
        }
      });
    });
    this.actionItems = [
      {label: 'Rafraîchir', icon: 'fa fa-refresh', command: () => {
          this.callToGetSnapshotsDetails();
        }},
      {label: 'Reset tous les filtres', icon: 'fa fa-backward', command: () => {
          this.resetSnapshots();
        }},
    ];
    this.multipleItems = [
      {label: 'Ajouter des étiquettes', icon: 'fa fa-tag', command: () => {
          if (this.selectedSnapshots.length > 0) {
            this.addTagComponent.displayTag = true;
          } else {
            this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
          }
        }},
      {label: 'Nouvelle tâche pour tous les relevés', icon: 'fa fa-plus', command: () => {
          if (this.selectedSnapshots.length > 0) {
            this.createProcessComponent.displayProcess = true;
          } else {
            this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
          }
        }},
      {label: 'Charger les relevés sur un autre domaine', icon: 'fa fa-map-pin', command: () => {
          if (this.selectedSnapshots.length > 0) {
            this.changeDomainComponent.displayDomain = true;
          } else {
            this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
          }
        }},
      {label: 'Supprimer les fichiers des relevés', icon: 'fa fa-close', command: () => {
          if (this.selectedSnapshots.length > 0) {
            this.confirmCancelAll();
          } else {
            this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
          }
        }},
      {label: 'Supprimer les données des relevés', icon: 'fa fa-close', command: () => {
          if (this.selectedSnapshots.length > 0) {
            this.confirmDeleteAll();
          } else {
            this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
          }
        }}
    ];
    this.tableItems = [
      {label: 'Consulter les résultats des traitements', icon: 'fa fa-location-arrow', command: () => {
          this.onMaps(this.snapshot);
        }},
      {label: 'Nouvelle tâche', icon: 'fa fa-plus', command: () => {
          this.createProcessComponent.displayProcess = true;
        }},
      {label: 'Charger le relevé sur un autre domaine', icon: 'fa fa-map-pin', command: () => {
          this.changeDomainComponent.displayDomain = true;
        }},
      {label: 'Réinitialiser tous les traitements', icon: 'fa fa-refresh', command: () => {
          this.confirmReset(this.snapshot.snapshotIdentifier);
        }},
      {label: 'Supprimer les fichiers du relevé', icon: 'fa fa-close', command: () => {
          this.confirmCancel(this.snapshot);
        }},
      {label: 'Supprimer les données du relevé', icon: 'fa fa-close', command: () => {
          this.confirmDeleteSelf(this.snapshot.snapshotIdentifier);
        }},
      {label: 'Supprimer les données de tous les relevés liés', icon: 'fa fa-close', command: () => {
          this.confirmDelete(this.snapshot.snapshotParentIdentifier, this.snapshot.snapshotIdentifier);
        }}
    ];
    this.checkValue = {};
    this.snapshotService.currentTransferSnapshot.subscribe(snapshot => this.currentSnapshot = snapshot);
    this.init = 0;
    this.last = 12;
    this.arraySnapshots = [];
    this.snapshots = [];
    this.limitCount = 12;
    this.totalCount = 0;
    this.offset = 0;
    this.resetSearchValues();
    this.processingService.statusCodeExcludeList = ['JOURNEY_RECEIVED', 'JOURNEY_DONE'];
    this.numberSelection = 0;
    this.numberOfSnapshots = 0;
    this.counter = 0;

    this.processingService.getSnapshotsDetails(this.offset, this.limitCount).subscribe(
      (resp: SnapshotCollectionModel) => {
        if (resp) {
          this.totalCount = resp.header.totalCount;
          resp.snapshots.forEach((snapshot) => {
            snapshot = this.snapshotService.getFunctions(snapshot);
            snapshot = this.getCurrentStatus(snapshot);
            snapshot.snapshotDisplayName = snapshot.snapshotName + '_' + snapshot.snapshotParentPart;
            this.snapshots.push(snapshot);
            this.numberOfSnapshots++;
          });
          this.snapshotsLoaded = true;
          this.isResult = true;
          this.firstDisplay = false;
        }
      }
    );
  }

  getSortOrder(prop) {
    return ((a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    });
  }

  getCurrentStatus(snapshot) {
    this.cols.forEach( col => {
      snapshot.snapshotEvents.forEach( event => {
        if  (col.doneStatus === event.eventStatusCode) {
          snapshot[col.field] = 'done';
        }
      });
    });

    const snapshotEvents = snapshot.snapshotEvents.reverse();
    this.cols.forEach( colBis => {
      snapshotEvents.forEach( event => {
        if (colBis.failedStatus === event.eventStatusCode) {
          if (snapshot[colBis.field] === undefined) {
            snapshot[colBis.field] = 'failed';
          }
        } else if (colBis.wipStatus === event.eventStatusCode) {
          if (snapshot[colBis.field] === undefined) {
            snapshot[colBis.field] = 'wip';
          }
        }
      });
    });

    this.cols.forEach( colTer => {
      if  (snapshot[colTer.field] === undefined) {
        snapshot[colTer.field] = 'null';
      }
    });
    return snapshot;
  }

  paginate(event) {
    this.limitCount = event.rows;
    this.numberSelection = 0;
    this.totalDistance = 0;
    this.selectedSnapshots = [];
    this.init = event.first;
    this.last = event.first + event.rows;
    this.offset = event.first / this.limitCount;
    this.callToGetSnapshotsDetails();
  }

  closeSnapshotSearch(show: boolean) {
    this.display = show;
    if (this.snapshotService.isSearch) {
      if (!this.display && !this.firstDisplay) {
        this.paginator.changePage(0);
      }
      this.snapshotService.isSearch = false;
    }

  }
  callToGetSnapshotsDetails() {
    this.numberOfSnapshots = 0;
    this.snapshotsLoaded = false;
    this.arraySnapshots = [];
    this.processingService.getSnapshotsDetails(this.offset, this.limitCount).subscribe(
      (resp: SnapshotCollectionModel) => {
        if (resp) {
          this.totalCount = resp.header.totalCount;
          resp.snapshots.forEach((snapshot) => {
            snapshot = this.snapshotService.getFunctions(snapshot);
            snapshot = this.getCurrentStatus(snapshot);
            snapshot.snapshotDisplayName = snapshot.snapshotName + '_' + snapshot.snapshotParentPart;
            this.arraySnapshots.push(snapshot);
            this.numberOfSnapshots++;
          });
          this.snapshots = this.arraySnapshots;
          this.snapshotsLoaded = true;
          this.isResult = true;
          this.firstDisplay = false;
        }
      }
    );
    // this.processingService.getSnapshotsDetails(this.offset, this.limitCount).subscribe(
    //   (resp: SnapshotCollectionModel) => {
    //     if (resp) {
    //       this.totalCount = resp.header.totalCount;
    //       resp.snapshots.forEach((snapshot) => {
    //         snapshot = this.snapshotService.getFunctions(snapshot);
    //         snapshot = this.getCurrentStatus(snapshot);
    //         snapshot.snapshotDisplayName = snapshot.snapshotName + '_' + snapshot.snapshotParentPart;
    //         this.snapshots.push(snapshot);
    //         this.numberOfSnapshots++;
    //       });
    //       this.snapshots = this.arraySnapshots;
    //       this.snapshotsLoaded = true;
    //     } else {
    //       this.snapshotsLoaded = true;
    //     }
    //
    //     if (this.numberOfSnapshots === 0) {
    //       this.isResult = false;
    //     } else {
    //       this.isResult = true;
    //     }
    //     this.selectedName = '';
    //   }
    // );
  }
  searchSnapshots() {
    this.resetSearchValues();
    this.processingService.snapshotName = this.f.research.value;
    this.paginator.changePage(0);
  }
  searchSnapshotsByTag() {
    this.resetSearchValues();
    this.processingService.snapshotTags = this.g.tags.value;
    this.paginator.changePage(0);
  }
  get f() {
    return this.searchForm.controls;
  }
  get g() {
    return this.tagSearchForm.controls;
  }
  displayForm() {
    this.snapshotSearchFormComponent.display = true;
  }
  setSnapshot(snapshot) {
    this.counter = 0;
    this.snapshotService.transferSnapshot(snapshot);
    this.snapshotService.currentTransferSnapshot.subscribe(currentSnapshot => this.currentSnapshot = currentSnapshot);
    if (!this.currentSnapshot || this.currentSnapshot === 'none') {
      this.currentSnapshot = JSON.parse(window.localStorage.getItem('localSnapshot'));
    }
    this.snapshotList = [];
    this.confirmMessage = '<ul class="confirmList">';
    if (this.currentSnapshot.snapshotParentIdentifier) {  // if it's a child
      this.processingService.getSnapshotChilds(this.currentSnapshot.snapshotParentIdentifier).subscribe((resp: SnapshotCollectionModel) => {
        if (resp) {
          resp.snapshots.forEach( (child) => {
            child.snapshotName = child.snapshotName + '_' + child.snapshotParentPart;
            this.confirmMessage += '<li>' + child.snapshotName + '</li>';
            this.snapshotList.push(child.snapshotName);
          });
          this.confirmMessage += '</ul>';
        }
      });
    } else {  // if it's a parent
      this.processingService.getSnapshotChilds(this.currentSnapshot.snapshotIdentifier).subscribe((resp: SnapshotCollectionModel) => {
        if (resp) {
          resp.snapshots.forEach( (child) => {
            child.snapshotName = child.snapshotName + '_' + child.snapshotParentPart;
            this.confirmMessage += '<li>' + child.snapshotName + '</li>';
          });
          this.confirmMessage += '</ul>';
        }
      });
    }
    window.localStorage.removeItem('localSnapshot');
    window.localStorage.setItem('localSnapshot', JSON.stringify(snapshot));
    this.selectedSnapshot = [];
    this.selectedSnapshot.push(snapshot);
    window.localStorage.removeItem('selectedSnapshot');
    window.localStorage.setItem('selectedSnapshot', JSON.stringify(this.selectedSnapshot));
    window.localStorage.removeItem('currentSnapshotIdentifier');
    window.localStorage.setItem('currentSnapshotIdentifier', snapshot.snapshotIdentifier);
    this.snapshot = snapshot;
  }
  setSnapshots() {
    window.localStorage.removeItem('selectedSnapshot');
    window.localStorage.setItem('selectedSnapshot', JSON.stringify(this.selectedSnapshots));
  }
  resetSearchValues() {
    this.selectedName = this.f.research.value;
    this.processingService.snapshotName = '';
    this.processingService.snapshotIdentifier = null;
    this.processingService.statusCode = '';
    this.processingService.statusCodeContains = '';
    this.processingService.snapshotDateBegin = null;
    this.processingService.statusDateBegin = null;
    this.processingService.snapshotDateEnd = null;
    this.processingService.statusDateEnd = null;
    this.processingService.snapshotType = '';
    this.processingService.snapshotTags = [];
  }
  resetSnapshots() {
    this.resetSearchValues();
    this.paginator.changePage(0);
  }
  onRowSelect(event) {
    this.totalDistance += this.snapshotService.getDistance(event.data);
    this.totalDistance = Math.round(this.totalDistance * 1000) / 1000;
    this.numberSelection++;
  }
  onRowUnselect(event) {
    this.totalDistance -= this.snapshotService.getDistance(event.data);
    this.totalDistance = Math.round(this.totalDistance * 1000) / 1000;
    this.numberSelection--;
  }
  selectAll() {
    if (this.numberSelection !== this.numberOfSnapshots) {
      this.numberSelection = this.numberOfSnapshots;
      this.totalDistance = 0;
      this.selectedSnapshots.forEach( snapshot => {
        this.totalDistance += this.snapshotService.getDistance(snapshot);
        this.totalDistance = Math.round(this.totalDistance * 1000) / 1000;
      });
    } else {
      this.numberSelection = 0;
      this.totalDistance =  0;
    }
  }
  onEventSnapshot(snapshot: SnapshotModel) {
    this.snapshotsLoaded = false;
    this.processingService.getSnapshot(snapshot.snapshotIdentifier).subscribe((resp: SnapshotCollectionModel) => {
      if (resp) {
        let currentSnapshot = resp.snapshots[0];
        currentSnapshot.timeSpend = this.snapshotService.dateDiff(currentSnapshot);
        currentSnapshot.distance = this.snapshotService.getDistance(currentSnapshot).toString() + 'km';
        currentSnapshot.dateFromStatus = moment(new Date(currentSnapshot.statusDate)).fromNow();
        currentSnapshot = this.snapshotService.getFunctions(currentSnapshot);

        window.localStorage.removeItem('localSnapshot');
        window.localStorage.setItem('localSnapshot', JSON.stringify(currentSnapshot));
        this.snapshotService.transferSnapshot(currentSnapshot);
        const ref = this.dialogService.open(SnapshotEventComponent, {
          header: currentSnapshot.snapshotName,
          width: '80%',
          height: '80%',
          transitionOptions: '200ms',
          contentStyle: {'max-height': '100%', overflow: 'auto'},
        });
        this.snapshotsLoaded = true;
      }
    });
  }
  onMaps(currentSnapshot) {
    this.processingService.snapshotList = false;
    const ref = this.dialogService.open(SnapshotMapComponent, {
      header: currentSnapshot.snapshotName,
      width: '100%',
      height: '100%',
      transitionOptions: '200ms',
      contentStyle: {
        overflow: 'auto'},
    });
  }
  // validMessage(message) {
  //   if (this.counter === 0) {
  //     this.messageService.add({key: 'bl', severity: 'success', summary: 'Nouvelle tâche', detail: message});
  //     this.counter++;
  //   }
  // }
  cancelSnapshot(snapshot) {
    this.processingService.cancelSnapshot(snapshot.snapshotIdentifier).subscribe( () => {
      this.callToGetSnapshotsDetails();
    });
  }
  confirmReset(snapshotIdentifier) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous réinitialiser tous les traitements de ce snapshot : </b><br/><ul class="confirmList"><li>'
        + this.currentSnapshot.snapshotName + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.processingService.resetSnapshot(snapshotIdentifier).subscribe(resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Réinitialisation des traitements demandée',
              detail: this.currentSnapshot.snapshotName});
            this.paginator.changePage(0);
          }
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Reset annulé'});
      }
    });
  }
  confirmResetAll() {
    if (this.selectedSnapshots.length > 0) {
      if (this.numberSelection > 0) {
        this.confirmMessage = '<ul class="confirmList">';
        this.selectedSnapshots.forEach( snapshot => {
          this.confirmMessage += '<li>' + snapshot.snapshotName + '</li>';
        });
        this.confirmMessage += '</ul>';
      }
      this.confirmationService.confirm({
        message: '<b>Voulez-vous réinitialiser tous les traitements de ces relevés : </b><br/>' + this.confirmMessage,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: () => {
          this.selectedSnapshots.forEach( snapshot => {
            this.processingService.resetSnapshot(snapshot.snapshotIdentifier).subscribe(resp => {
              if (resp) {
                this.messageService.add({key: 'bl', severity: 'success', summary: 'Réinitialisation des traitements demandée',
                  detail: snapshot.snapshotName});
                this.paginator.changePage(0);
              }
            });
          });
        },
        reject: () => {
          this.messageService.add({key: 'bl', severity: 'warn', summary: 'Reset annulé'});
        }
      });
    } else {
      this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
    }
  }
  confirmCancel(snapshot: SnapshotModel) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer les fichiers de ce relevé : </b><br/><ul class="confirmList"><li>'
        + snapshot.snapshotName + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.cancelSnapshot(snapshot);
        this.messageService.add({key: 'bl', severity: 'success', summary: 'Suppression des fichiers demandée',
          detail: snapshot.snapshotName});
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
  confirmCancelAll() {
    if (this.numberSelection > 0) {
      this.confirmMessage = '<ul class="confirmList">';
      this.selectedSnapshots.forEach( snapshot => {
        this.confirmMessage += '<li>' + snapshot.snapshotName + '</li>';
      });
      this.confirmMessage += '</ul>';
    }
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer les fichiers de ces relevés : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.selectedSnapshots.forEach( snapshot => {
          this.cancelSnapshot(snapshot);
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Suppression des fichiers demandée',
            detail: snapshot.snapshotName});
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
  confirmDeleteSelf(snapshotIdentifier) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer les données de ce relevé : </b><br/><ul class="confirmList"><li>'
        + this.currentSnapshot.snapshotName + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.processingService.removeSnapshot(snapshotIdentifier).subscribe(resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Suppression des données demandée',
              detail: this.currentSnapshot.snapshotName});
            this.paginator.changePage(0);
          }
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
  confirmDelete(parentIdentifier, snapshotIdentifier) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer les données de tous ces relevés : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        if (parentIdentifier) {
          this.processingService.removeChildSnapshot(parentIdentifier).subscribe(resp => {
            if (resp) {
              this.processingService.removeSnapshot(parentIdentifier).subscribe(secondResp => {
                if (secondResp) {
                  this.paginator.changePage(0);
                }
              });
            }
          });
        } else {
          this.processingService.removeSnapshot(snapshotIdentifier).subscribe(secondResp => {
            if (secondResp) {
              this.processingService.removeChildSnapshot(snapshotIdentifier).subscribe(() => {
                this.paginator.changePage(0);
              });
            }
          });
        }
        this.snapshotList.forEach( snapshotName => {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Suppression des données demandée', detail: snapshotName});
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
  confirmDeleteAll() {
    if (this.numberSelection > 0) {
      this.confirmMessage = '<ul class="confirmList">';
      this.selectedSnapshots.forEach( snapshot => {
        this.confirmMessage += '<li>' + snapshot.snapshotName + '</li>';
      });
      this.confirmMessage += '</ul>';
    }
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer les données de tous ces relevés : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.selectedSnapshots.forEach( snapshot => {
          this.processingService.removeSnapshot(snapshot.snapshotIdentifier).subscribe(resp => {
            if (resp) {
              this.paginator.changePage(0);
              this.messageService.add({key: 'bl', severity: 'success', summary: 'Suppression des données demandée',
                detail: snapshot.snapshotName});
            }
          });
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
}
