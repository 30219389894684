import {ISerializable} from '../iserializable';
import {SnapshotModel} from "../processing/snapshot-model";
import {ProcessConfigModel} from "./process-config-model";

export class TaskModel implements ISerializable {

  public isTaskAck: boolean;
  public isTaskCancel: boolean;
  public isTaskClose: boolean;
  public taskAck: boolean;
  public taskCancel: boolean;
  public taskClose: boolean;
  public processCode: string;
  public processType: string;
  public processConfig: ProcessConfigModel;
  public taskIdentifier: string;
  public taskRequestDate: string;
  public taskAckDate: string;
  public taskCancelDate: string;
  public taskCloseDate: string;
  public snapshot: SnapshotModel;
  public snapshotIdentifier: string;
  public snapshotName: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.processConfig = new ProcessConfigModel().deserialize(input.processConfig);
    this.snapshot = new SnapshotModel().deserialize(input.snapshot);

    return this;
  }

  serialize() {
     return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
