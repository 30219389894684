import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {HomeComponent} from '../home.component';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  constructor(private homeComponent: HomeComponent, public breakpointObserver: BreakpointObserver, private translate: TranslateService) {

  }

  items: MenuItem[];
  isMatched: boolean;

  ngOnInit() {

    this.translate.get(['menu.users', 'menu.domains', 'menu.modeling', 'menu.tasks.title', 'menu.tasks.hold',
      'menu.tasks.ack', 'menu.tasks.canceled', 'menu.tasks.closed', 'menu.snapshots.title', 'menu.snapshots.new',
      'menu.snapshots.processed', 'menu.snapshots.project', 'menu.areas', 'menu.parameters.title', 'menu.parameters.other', 'menu.parameters.config-codes',
      'menu.parameters.config-groups', 'menu.parameters.process', 'menu.parameters.roles', 'menu.parameters.process-types', 'menu.tools.title',
      'menu.tools.config', 'menu.tools.masterdata', 'menu.tools.deduplicate', 'menu.tools.rules', 'menu.pricing-grids'
    ])
      .subscribe(translations => {
        this.items = [
          {
            label: translations['menu.users'],
            icon: 'pi pi-users',
            routerLink: ['users/usersList'],
            visible: this.homeComponent.visibleUsers
          },
          {
            label: translations['menu.domains'],
            icon: 'pi pi-sitemap',
            routerLink: ['domain'],
            visible: this.homeComponent.visibleDomain
          },
          {
            label: translations['menu.modeling'],
            icon: 'pi pi-fw pi-refresh',
            routerLink: ['modeling'],
            visible: this.homeComponent.visibleModeling
          },
          {
            label: translations['menu.tasks.title'], icon: 'pi pi-list', items: [
              {label: translations['menu.tasks.hold'], routerLink: ['processing/onHoldList']},
              {label: translations['menu.tasks.ack'], routerLink: ['processing/ackList']},
              {label: translations['menu.tasks.canceled'], routerLink: ['processing/cancelList']},
              {label: translations['menu.tasks.closed'], routerLink: ['processing/closeList']}
            ],
            visible: this.homeComponent.visibleProcessing
          },
          {
            label: translations['menu.snapshots.title'], icon: 'pi pi-camera', items: [
              {label: translations['menu.snapshots.new'], routerLink: ['snapshot/snapshotList']},
              {label: translations['menu.snapshots.processed'], routerLink: ['snapshot/snapshotProcessed']},
              {label: translations['menu.snapshots.project'], routerLink: ['snapshot/snapshotProject']}
            ],
            visible: this.homeComponent.visibleSnapshots
          },
          {
            label: translations['menu.areas'],
            icon: 'pi pi-globe',
            routerLink: ['areas/areasList'],
            visible: this.homeComponent.visibleAreas
          },
          {
            label: translations['menu.parameters.title'], icon: 'pi pi-cog', items: [
              {label: translations['menu.parameters.other'], routerLink: ['settings/settingsConfig']},
              {
                label: translations['menu.parameters.config-codes'],
                routerLink: ['settings/settingsLists', 'CONFIG_CODE']
              },
              {
                label: translations['menu.parameters.config-groups'],
                routerLink: ['settings/settingsLists', 'CONFIG_GROUP']
              },
              {label: translations['menu.parameters.process'], routerLink: ['settings/settingsLists', 'PROCESS']},
              {label: translations['menu.parameters.roles'], routerLink: ['settings/settingsLists', 'ROLE']},
              {
                label: translations['menu.parameters.process-types'],
                routerLink: ['settings/settingsLists', 'PROCESS_TYPE']
              },
            ],
            visible: this.homeComponent.visibleSettings
          },
          {
            label: translations['menu.tools.title'], icon: 'pi pi-pencil', items: [
              {label: translations['menu.tools.config'], routerLink: ['tools/changeConfig']},
              {label: translations['menu.tools.masterdata'], routerLink: ['tools/masterdata']},
              {label: translations['menu.tools.deduplicate'], routerLink: ['tools/deduplicate']},
              {label: translations['menu.tools.rules'], routerLink: ['tools/rules']},
            ],
            visible: this.homeComponent.visibleTools
          },
          {
            label: translations['menu.pricing-grids'],
            icon: 'pi pi-money-bill',
            routerLink: ['grids'],
            visible: this.homeComponent.visibleGrids
          }
        ]
      });

    this.breakpointObserver
      .observe(['(min-width: 1023px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
        }
      });

  }


}
