import {ISerializable} from '../iserializable';
import {TaskModel} from './task-model';
import {HeaderModel} from '../header-model';

export class TaskCollectionModel implements ISerializable {

  header: HeaderModel;
  tasks: TaskModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.header = new HeaderModel().deserialize(input.header);

    this.tasks = input.tasks.map(list => new TaskModel().deserialize(list));

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
