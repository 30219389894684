import {ISerializable} from '../iserializable';
import {GeometryModel} from '../geojson/geometry-model';
import {AreaModel} from "./area-model";

export class AreaArrayModel implements ISerializable {

  public areas: AreaModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.areas = input.map(area => new AreaModel().deserialize(area));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

// toTurf() : any {
//   return turf.helpers.geometry(this.type, this.coordinates);
// }
}
