import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MasterDataService} from '../../../../services/master-data.service';
import {MessageService} from 'primeng/api';
import {JsonEditorComponent} from 'ang-jsoneditor';
import {ToastsComponent} from '../../../main/toasts/toasts.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-settings-list-create',
  templateUrl: './settings-list-create.component.html',
  styleUrls: ['../settings-lists.component.css']
})
export class SettingsListCreateComponent implements OnInit {

  @Input() toast: ToastsComponent;

  public data: any;
  public newData: any;
  @ViewChild(JsonEditorComponent,{static:false}) editor: JsonEditorComponent;

  displayCreate: boolean;

  @Output() getListValuesEvent = new EventEmitter();

  @Input() listCode: string;
  @Input() createListValueForm: FormGroup;

  blockSpace: RegExp = /[^\s]/;

  options: any;

  isParsingError: boolean;
  errorMessage: string;

  constructor(private masterDataService: MasterDataService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.displayCreate = true;
    this.options = {
      mode: 'code',
      onError: (err) => {
        alert(err.toString());
      },
      onChange: () => {
        try {
          this.isParsingError = false;
          this.newData = JSON.stringify(this.editor.get());
        } catch (err) {
          this.isParsingError = true;
        }
      }
    };
  }

  createListValue() {
    if (this.f.valueCode.value && this.f.valueText.value) {
      const valueCode = this.f.valueCode.value.toUpperCase();
      const valueText = this.f.valueText.value;
      let listValueParameters;
      if (this.newData !== undefined) {
        listValueParameters = JSON.parse(this.newData);
      } else {
        listValueParameters = JSON.parse('{}');
      }
      this.masterDataService.createListValue(this.listCode, valueCode, valueText, listValueParameters).subscribe(resp => {
        if (resp) {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ créé', detail: valueCode});
          this.getListValuesEvent.emit();
        }
      });
      // if (this.newData !== undefined) {
      //   const listValueParameters = JSON.parse(this.newData);
      //   this.masterDataService.createListValue(this.listCode, valueCode, valueText, listValueParameters).subscribe(resp => {
      //     if (resp) {
      //       this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ créé',
      //         detail: valueCode});
      //       this.getListValuesEvent.emit();
      //     }
      //   });
      // } else {
      //   this.masterDataService.createListValue(this.listCode, valueCode, valueText).subscribe(resp => {
      //     if (resp) {
      //       this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ créé',
      //         detail: valueCode});
      //       this.getListValuesEvent.emit();
      //     }
      //   });
      // }
    }
  }

  get f() {
    return this.createListValueForm.controls;
  }

  getData(event) {
    this.newData = event;
  }

  onHide() {
    this.getListValuesEvent.emit();
  }

}
