import {ISerializable} from '../iserializable';
import {DomainModelLegacy} from './domain-model-legacy';
import {DomainModel} from './domain-model';

export class DomainCollectionModel implements ISerializable {

  public domains: DomainModel[];

  deserialize(input: any): this {
    Object.assign(this, input);

    this.domains = input.map(item => new DomainModelLegacy().deserialize(item));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
