<p-dialog header="Load on another domain" [(visible)]="displayDomain" [modal]="true" [responsive]="true"
          [style]="{'width': '800px', 'minWidth': '300px'}" (onHide)="onHide()" id="createProcess"
          [transitionOptions]="'200ms'">
  <form (ngSubmit)="onChangeDomain()">
    <div class="snapshotForm ui-g">
      <div class="ui-g-12 ui-md-6">
        <p>Current domain</p>
        <div class="selectedDiv">
          <p class="selectedProcess">{{currentDomain}}</p>
        </div>
        <p>New domain</p>
        <p-listbox [options]="domains" [(ngModel)]="selectedDomain" [ngModelOptions]="{standalone: true}" optionLabel="domainCode"
                   [style]="{'width':'100%'}"></p-listbox>
      </div>
      <div class="ui-g-12 ui-md-4 ui-md-offset-1 selectedCol">
        <div class="selectedDiv">
          <p class="selectedProcess" *ngIf="selectedDomain">{{selectedDomain}}</p>
          <p class="selectedProcess" *ngIf="!selectedDomain">...</p>
        </div>
        <div class="form-group buttons">
          <button pButton type="submit" class="ui-button-primary ui-g-6" icon="fa fa-floppy-o" label="Start"></button>
          <button pButton type="button" class="ui-button-danger ui-g-6" icon="fa fa-times" label="Cancel"
                  (click)="onCancel()"></button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
