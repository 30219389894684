import {Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe(isAuth=>{
      if(!isAuth){
        this.login();
      } else {
        this.router.navigate(['home/snapshot/snapshotProcessed']);
      }
    })
  }

  login(){
    this.authService.loginWithRedirect({redirect_uri: window.location.href})
  }

}
