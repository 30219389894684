<h2>{{'users.title' | translate}}</h2>
<hr>
<form [formGroup]="searchUserForm" (ngSubmit)="searchUsers()" class="globalSearch ui-g">
  <div class="ui-inputgroup detailButton" [ngClass]="{'ui-g-12': isMatched, 'marginRight': !isMatched}">
    <input type="text" pInputText placeholder="{{'users.username' | translate}}" formControlName="research"
           class="ui-inputtext ui-corner-all ui-state-default ui-widget" [ngModel]="selectedName">
      <button pButton type="submit" label="{{'users.search' | translate}}" class="searchButton"></button>
  </div>
  <button *ngIf="isMatched" pButton type="button" label="{{'users.add-user' | translate}}" class="ui-g-12 marginAll"
          (click)="onAddUser()"></button>
  <button *ngIf="numberSelection === 0" pButton type="button" label="{{'users.delete' | translate:{numberSelection: numberSelection} }}"
          class="ui-button-secondary forbidden" [ngClass]="{'ui-g-12': isMatched, 'marginAll': isMatched}"
          disabled="true" ></button>
  <button *ngIf="numberSelection !== 0" pButton type="button" label="{{'users.delete' | translate:{numberSelection: numberSelection} }}"
          class="ui-button-danger" [ngClass]="{'ui-g-12': isMatched, 'marginAll': isMatched}"
          (click)="confirmDeleteAll()" ></button>
</form>
<p-paginator [rows]="12" [totalRecords]="totalCount" [rowsPerPageOptions]="[12, 25, 50]" (onPageChange)="paginate($event)" #p></p-paginator>
<p *ngIf="!isMatched" class="countRow">{{'users.count' | translate}} : {{totalCount}}</p>
<div *ngIf="!usersLoaded" class="loaderContainer">
  <p-progressSpinner></p-progressSpinner>
</div>
<div class="userList usersList">
  <p-table [columns]="cols" [value]="users" selectionMode="multiple" [(selection)]="selectedUsers" (onRowSelect)="onRowSelect()"
           (onRowUnselect)="onRowUnselect()" [responsive]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="checkBoxStyle" style="width: 3em">
          <p-tableHeaderCheckbox (click)="selectAll()"></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns">
          {{col.header}}
        </th>
        <th>
          <button pButton type="button" icon="fa fa-plus" (click)="onAddUser()" style="display:block;"
                  class="ui-button-primary marginLeft"></button>
          <app-users-add *ngIf="loadAddUser" (addUser)="addUserFunction()" [isAdmin]="isAdmin" ></app-users-add>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user let-columns="columns">
      <tr>
        <td class="checkBoxStyle">
          <p-tableCheckbox [value]="user"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" class="phoneSizeTab">
          <span class="ui-column-title">{{col.header}}</span>
          {{user[col.field]}}
        </td>
        <td>
          <div class="ui-inputgroup ui-rtl" dir="rtl">
            <div class="ui-rtl" dir="ltr">
              <p-splitButton *ngIf="!user.blocked" label="{{'users.modify' | translate}}" (onClick)="onEditUser(user)" (onDropdownClick)="setUser(user)"
                             [model]="tableItems" class="splitUser"></p-splitButton>
              <p-splitButton *ngIf="user.blocked" label="{{'users.modify' | translate}}" (onClick)="onEditUser(user)" (onDropdownClick)="setUser(user)"
                             [model]="tableItemsBlocked" class="splitUser"></p-splitButton>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
</div>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
<p *ngIf="!isResult" class="disclaimer">{{'users.no-results' | translate}}</p>

<p-dialog *ngIf="displayDeleteUser" header="{{this.user.userEmail}}"
          [(visible)]="displayDeleteUser" [style]="{width: '60%', height: '400px'}" [contentStyle]="{maxHeight: '100%', overflow: 'auto'}"
          transitionOptions="200ms" [draggable]="false" modal="true">
  <app-users-delete #usersDeleteComponent [user]="user" [isAdmin]="isAdmin"></app-users-delete>
  <p-footer>
    <p-button icon="pi pi-times" (click)="cancelDeleteUser()" label="{{'users.cancel' | translate}}"></p-button>
    <p-button icon="pi pi-check" type="submit" (click)="confirmDeleteUser()" [label]="deleteButtonLabel"
              styleClass="p-button-text" [disabled]="usersDeleteComponent.selectedDomains.length === 0"></p-button>
  </p-footer>
</p-dialog>
