import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from './components/home/home.component';

import {UsersComponent} from './components/users/users.component';
import {DomainComponent} from './components/domain/domain.component';
import {ModelingComponent} from './components/modeling/modeling.component';
import {UsersListComponent} from './components/users/users-list/users-list.component';
import {RoleGuard} from './guards/role-guard.service';
import {UsersEditComponent} from './components/users/users-edit/users-edit.component';
import {SnapshotComponent} from './components/snapshot/snapshot.component';
import {SnapshotReceivedComponent} from './components/snapshot/snapshot-received/snapshot-received.component';
import {SnapshotListComponent} from './components/snapshot/snapshot-list/snapshot-list.component';
import {SnapshotEventComponent} from './components/snapshot/snapshot-event/snapshot-event.component';
import {SnapshotMapComponent} from './components/snapshot/snapshot-map/snapshot-map.component';
import {TaskOnholdListComponent} from './components/processing/task-onhold-list/task-onhold-list.component';
import {SettingsComponent} from './components/settings/settings.component';
import {ChangeConfigComponent} from './components/tools/change-config/change-config.component';
import {SettingsListsComponent} from './components/settings/settings-lists/settings-lists.component';
import {ProcessingComponent} from './components/processing/processing.component';
import {TaskAckListComponent} from './components/processing/task-ack-list/task-ack-list.component';
import {TaskCancelListComponent} from './components/processing/task-cancel-list/task-cancel-list.component';
import {TaskDoneListComponent} from './components/processing/task-done-list/task-done-list.component';
import {ToolsComponent} from './components/tools/tools.component';
import {DeduplicateComponent} from './components/tools/deduplicate/deduplicate.component';
import {SettingsConfigComponent} from './components/settings/settings-config/settings-config.component';
import {RulesComponent} from './components/tools/rules/rules.component';
import {SnapshotProjectComponent} from './components/snapshot/snapshot-project/snapshot-project.component';
import {AreasComponent} from './components/areas/areas.component';
import {AreasAddComponent} from './components/areas/areas-add/areas-add.component';
import {AreasListComponent} from './components/areas/areas-list/areas-list.component';
import { MasterdataComponent } from './components/tools/masterdata/masterdata.component';
import {
  MasterdataEditDetailsComponent
} from "./components/tools/masterdata/masterdata-edit/masterdata-edit-details/masterdata-edit-details.component";


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'home', component: HomeComponent,

    children: [
      {path: 'users', component: UsersComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_USERS'}, children: [
          {path: 'usersList', component: UsersListComponent},
          {path: 'userEdit', component: UsersEditComponent},
        ]},
      {path: 'domain',  component: DomainComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_DOMAINS'}},
      {path: 'modeling',  component: ModelingComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_MODELING'}},
      {path: 'processing',  component: ProcessingComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_PROCESSING'},
        children: [
          {path: 'onHoldList', component: TaskOnholdListComponent},
          {path: 'ackList', component: TaskAckListComponent},
          {path: 'cancelList', component: TaskCancelListComponent},
          {path: 'closeList', component: TaskDoneListComponent}
        ]
      },
      {path: 'snapshot',  component: SnapshotComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_SNAPSHOTS'},
        children: [
          {path: 'snapshotList', component: SnapshotListComponent},
          {path: 'snapshotProcessed', component: SnapshotReceivedComponent},
          {path: 'snapshotEvent', component: SnapshotEventComponent},
          {path: 'snapshotMap', component: SnapshotMapComponent},
          {path: 'snapshotProject', component: SnapshotProjectComponent}
        ]
      },
      {
        path: 'areas', component: AreasComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_SNAPSHOTS'}, children: [
          {path: 'areasAdd', component: AreasAddComponent},
          {path: 'areasList', component: AreasListComponent}
        ]
      },
      {path: 'settings', component: SettingsComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_SETTINGS'},
      children: [
        {path: 'settingsLists/:listCode', component: SettingsListsComponent},
        {path: 'settingsConfig', component: SettingsConfigComponent}
      ]},
      {path: 'tools', component: ToolsComponent, canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_TOOLS'},
      children: [
        {path: 'changeConfig', component: ChangeConfigComponent},
        {path: 'deduplicate', component: DeduplicateComponent},
        {path: 'rules', component: RulesComponent},
        {path: 'masterdata', component: MasterdataComponent},
        {path: 'masterdataEditDetails', component: MasterdataEditDetailsComponent},
      ]},
      { path: 'grids', loadChildren: () => import('./components/grids/grids.module').then(m => m.GridsModule),
        canActivate: [RoleGuard], data: { expectedRole: 'ROLE_DESIGN_GRIDS'} }
    ]},
  // otherwise redirect to home
  { path: '**', redirectTo: 'users', }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
