import {Component, Input, OnInit} from '@angular/core';
import {Grid} from "../../../grids/domain/grid";
import {HttpClient} from "@angular/common/http";
import {SessionService} from "../../../../services/session.service";
import {mergeMap} from "rxjs/operators";
import {GridsResponse} from "../../../grids/domain/grids-response";
import {environment} from "../../../../../environments/environment";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-users-edit-grids',
  templateUrl: './users-edit-grids.component.html',
  styleUrls: ['./users-edit-grids.component.css']
})
export class UsersEditGridsComponent implements OnInit {
  @Input() currentUser: any;

  usersGridsOptions: Grid[];
  availableGridsOptions: Grid[];

  userId: number;


  constructor(private http: HttpClient, private sessionService: SessionService, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.userId = this.currentUser.userId;
    this.http.post<GridsResponse>(`${environment.urlApi}/citymagine-api-1.0/rest/pricing/getPricingGrids`, {}).pipe(mergeMap(grids => {
      this.availableGridsOptions = grids.pricingGrids;
      return this.http.post<GridsResponse>(`${environment.urlApi}/citymagine-api-1.0/rest/pricing/users/${this.userId}`, {})
    }))
      .subscribe(userGrids => {
        this.usersGridsOptions = userGrids.pricingGrids;
        this.availableGridsOptions = this.availableGridsOptions.filter(availableGrid => this.usersGridsOptions.findIndex(userGrid => userGrid.gridCode === availableGrid.gridCode) == -1)
      }, error => {
        console.error(error);
      });
  }

  addGridToSelectedOnes($event: any) {
    this.usersGridsOptions = [...this.usersGridsOptions, $event.value];
    this.availableGridsOptions.splice(this.availableGridsOptions.findIndex(grid => grid.gridCode === $event.value.gridCode), 1);
    this.availableGridsOptions = [...this.availableGridsOptions];
  }

  removeGridfromSelectedOnes($event: any) {
    this.availableGridsOptions = [...this.availableGridsOptions, $event.value];
    this.usersGridsOptions.splice(this.usersGridsOptions.findIndex(grid => grid.gridCode === $event.value.gridCode), 1);
    this.usersGridsOptions = [...this.usersGridsOptions];
  }

  saveUsersGrids() {
    return this.http.post<GridsResponse>(`${environment.urlApi}/citymagine-api-1.0/rest/pricing/users/${this.userId}/setPricingGrids`, {
      "pricingGrids": this.usersGridsOptions,
      "removedPricingGrids": this.availableGridsOptions
    }).subscribe(() => {
      this.messageService.add({
        key: 'bl', severity: 'success', summary: 'Utilisateur modifié',
        detail: this.currentUser.userName
      });
    });
  }
}
