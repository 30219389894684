import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {FeatureCollectionModel} from '../models/geojson/feature-collection-model';
import {catchError, map} from 'rxjs/operators';
import {Session} from '../globals/session';
import {RequestHelper} from '../helpers/request.helper';
import {SnapshotModel} from '../models/processing/snapshot-model';
import {GeometryModel} from "../models/geojson/geometry-model";

@Directive()
@Injectable({
  providedIn: 'root'
})
export class GeojsonService {

  constructor(private session: Session, private requestHelper: RequestHelper, private httpService: HttpClient) {}

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  displayStatus(errorMessage) {
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  getSnapshotCollection(snapshot: SnapshotModel): Observable<FeatureCollectionModel>  {
    const url = environment.urlGeoJsonGetSnapshotCollection + 'snapshotIdentifier=' + snapshot.snapshotIdentifier;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.get(url , options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('SnapshotCollection not found');
      })
    );
  }

  getModelPoiCollection(typePoi, distance, typeCoord, objCoords) {
    const url = environment.urlGeoJsonGetModelPoiCollection;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
        limitOffset: 0,
        limitCount: 25,
      },
      poiType: typePoi,
      distance,
      geometry: {
        type: typeCoord,
        coordinates: objCoords
      }
    }, options);
  }

  getObjectElementCollection(snapshot: SnapshotModel, elementType?: string) {
    const url = environment.urlGeoJsonGetObjectElementCollection;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      snapshotIdentifier: snapshot.snapshotIdentifier,
      elementType,
    }, options);
  }

  getObjectViewCollection(viewType: string, viewIdentifier: string = undefined, geometry: GeometryModel = undefined, distance: number = 15): Observable<FeatureCollectionModel> {
    const url = environment.urlGeojsonGetObjectViewCollection;


    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};

    return this.httpService.post(url, {
      header: {
        connectionOrigin: 'CITYDESIGNER',
        sessionIdentifier: this.session.getSessionIdentifier(),
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      viewType,
      viewIdentifier,
      geometry,
      distance
    }, options).pipe(map(data => {
        return new FeatureCollectionModel().deserialize(data);
      }),
      catchError(error => {
        return throwError('View not found');
      })
    );
  }

  getNominatim(name: string) {
    return this.httpService.get(environment.urlNominatim + name + environment.urlNominatimParam);
  }
}
