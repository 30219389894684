import {ISerializable} from '../iserializable';
import {AreaModel} from './area-model';
import {FeatureModel} from "../geojson/feature-model";

export class AreasModel implements ISerializable {

  public totalPages: number;
  public totalElements: number;
  public size: number;
  public content: AreaModel[];
  public number: number;
//   public sort": {
//     "empty": true,
//     "sorted": true,
//     "unsorted": true
//   },
// public pageable": {
//     "offset": 0,
//     "sort": {
//       "empty": true,
//       "sorted": true,
//       "unsorted": true
//     },
//     "paged": true,
//     "unpaged": true,
//     "pageNumber": 0,
//     "pageSize": 0
//   },
public first: boolean;
public last: boolean;
public numberOfElements: number;
public empty: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.content = input.content.map(area => new AreaModel().deserialize(area));
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  // toTurf() : any {
  //   return turf.helpers.geometry(this.type, this.coordinates);
  // }
}
