import {AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Paginator} from 'primeng/paginator';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserModel} from '../../../models/session/user-model';
import {ConfirmationService, MessageService, TreeNode} from 'primeng/api';
import {BreakpointObserver} from '@angular/cdk/layout';
import {AreasModel} from '../../../models/domain/areas-model';
import {DomainService} from '../../../services/domain.service';
import {AreaModel} from '../../../models/domain/area-model';
import {HttpResponse} from "@angular/common/http";


@Component({
  selector: 'app-areas-list',
  templateUrl: './areas-list.component.html',
  styleUrls: ['./areas-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AreasListComponent implements OnInit, AfterViewInit {

  @ViewChild('p') paginator: Paginator;

  arrayAreas: UserModel[];
  cols: any;
  confirmMessage: string;
  init: number;
  isMatched: boolean;
  isResult: boolean;
  last: number;
  limitCount: number;
  numberOfAreas: number;
  numberSelection: number;
  offset: number;
  searchAreasForm: FormGroup;
  selectedName: string;
  selectedAreas: UserModel[];
  totalCount: number;


  _rootNode: any[] = [];
  _treeNode: TreeNode[] = [];
  loading = true;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              public domainService: DomainService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              public breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.limitCount = 12;
    this.numberOfAreas = 0;
    this.numberSelection = 0;
    this.offset = 0;
    this.arrayAreas = [];
    this.selectedName = '';
    this.searchAreasForm = this.formBuilder.group({
      research: ['', Validators.required]
    });
    this.cols = [
      {field: 'areaName', header: 'Nom du secteur'},
      {field: 'userNumber', header: 'Nombre d\'utilisateur'},
    ];
  }

  ngAfterViewInit() {
    this.loadNodes();
  }

  loadNodes(event?) {
    this.loading = true;
    this.domainService.getParentAreas(this.paginator.getPage(), this.paginator.rows).subscribe((response: HttpResponse<AreasModel>) => {
      this._treeNode = [];
      this._rootNode = [];
      this.totalCount = response.body.totalElements;
      response.body.content.forEach((area: AreaModel) => {
        const node: TreeNode = {};
        node.data = area;
        node.data.childrens = [];
        node.leaf = false;
        const areaGroup: string = node.data.id;
        this._rootNode.push(areaGroup);

        this._rootNode[areaGroup] = node;

        const rootNode: TreeNode = {};
        rootNode.data = {};
        rootNode.children = [];

        this._rootNode[areaGroup].children = [];
        this._rootNode[areaGroup].children.push(rootNode);
      });
      this._rootNode.forEach((areaGroup) => {
        this._treeNode.push(this._rootNode[areaGroup]);
      });
      this.loading = false;
    });
  }

  onNodeExpand(e) {
    this.loading = true;

    // this.getChildrens(e.node.data.id);
    this.domainService.getArea(e.node.data.id).subscribe((response: AreaModel) => {
      const node = this.retrieveNode(e.node.data.id);
      if (response.children !== null) {
        node.children = this.mapAreasToNodes(response.children);
      } else {
        node.children = null;
      }
      this.loading = false;
    });
  }

  onAddArea(areaId?: string, parentId?: string) {
    if (areaId !== undefined) {
      this.router.navigate(['home/areas/areasAdd'], {
        queryParams: {
          areaId,
          parentId
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['home/areas/areasAdd']);
    }
  }

  onDeleteArea(areaId: string, areaName: string) {
    this.domainService.getArea(areaId).subscribe((response: AreaModel) => {
      if (response.children !== null && response.children.length > 0) {
        this.messageService.add({
          key: 'bl',
          severity: 'error',
          summary: 'Suppression impossible : un secteur enfant bloque la suppression'
        });
      } else {
        this.confirmDelete(areaId, areaName);
      }
    });
  }

  confirmDelete(areaId: string, areaName: string) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer ce secteur : </b><br/><ul class="confirmList"><li>' + areaName + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        if (areaId) {
          this.domainService.deleteArea(areaId).subscribe((resp) => {
            this.loadNodes();
          }, error => {
            this.messageService.add({key: 'bl', severity: 'error', summary: 'Modification impossible', detail: error.error.message});
          });
        }
        this.messageService.add({
          key: 'bl',
          severity: 'success',
          summary: 'Suppression du secteur demandée',
          detail: areaName
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }

  onRowSelect() {
    this.numberSelection++;
  }

  onRowUnselect() {
    this.numberSelection--;
  }

  selectAll() {
    if (this.numberSelection !== this.numberOfAreas) {
      this.numberSelection = this.numberOfAreas;
    } else {
      this.numberSelection = 0;
    }
  }

  paginate(event) {
    this.limitCount = event.rows;
    this.numberSelection = 0;
    this.selectedAreas = [];
    this.init = event.first;
    this.last = event.first + event.rows;
    this.offset = event.first / this.limitCount;
    this.loadNodes()
  }

  get f() {
    return this.searchAreasForm.controls;
  }

  mapAreasToNodes(areas: AreaModel[]) {
    if (areas != null && areas.length > 0) {
      return areas.map(area => this.mapAreaToNode(area));
    }
    return [];
  }

  mapAreaToNode(area: AreaModel) {
    let children = this.mapAreasToNodes(area.children);
    return {
      id: area.id,
      name: area.name,
      data: area,
      leaf: false,
      children
    }
  }

  private retrieveNode(id) {
    return this.recursiveSearch(this._treeNode, id);
  }

  private recursiveSearch(treeNode: TreeNode[], id) {
    if (treeNode == null || treeNode.length == 0) {
      return null;
    }
    let foundNodeIndex = treeNode.findIndex(node => node.data.id === id);
    if (foundNodeIndex > -1) {
      return treeNode[foundNodeIndex];
    } else {
      for (let node of treeNode) {
        let searchResult = this.recursiveSearch(node.children, id);
        if (searchResult != null) {
          return searchResult;
        }
      }
      return null;
    }

  }
}
