
<div class="ui-g ui-g-nopad">
   <div id="menu"[ngClass]="{'invisible': snapshotList}">
     <p-card>
         <div class="align">
           <p>Journey</p>
           <p-inputSwitch [(ngModel)]="Journey" (ngModelChange)="onChangeJourney()" [disabled]="currentSnapshot.disableJPR"></p-inputSwitch>
         </div>
         <div class="align">
           <p>PR</p>
           <p-inputSwitch [(ngModel)]="PR" (ngModelChange)="onChangePR()" [disabled]="currentSnapshot.disableJPR"></p-inputSwitch>
         </div>
         <div class="align">
           <p>Viewer</p>
           <p-inputSwitch [(ngModel)]="Viewer" (ngModelChange)="onChangeViewer()" [disabled]="currentSnapshot.disableViewer"></p-inputSwitch>
         </div>
         <div class="align">
          <p>Satellite</p>
          <p-inputSwitch [(ngModel)]="Satellite" (ngModelChange)="onChangeSatellite()"></p-inputSwitch>
         </div>
         <div class="align">
           <p>Sign</p>
           <p-inputSwitch [(ngModel)]="Sign" (ngModelChange)="onChangeSign()" [disabled]="currentSnapshot.disableSign"></p-inputSwitch>
         </div>
         <div class="align">
           <p>River sign</p>
           <p-inputSwitch [(ngModel)]="riverSign" (ngModelChange)="onChangeRiverSign()" [disabled]="currentSnapshot.disableRiverSign"></p-inputSwitch>
         </div>
         <div class="align">
           <p>Streetlight</p>
           <p-inputSwitch [(ngModel)]="Streetlight" (ngModelChange)="onChangeStreetlight()" [disabled]="currentSnapshot.disableSign"></p-inputSwitch>
         </div>
         <div class="align">
           <p>Ground</p>
           <p-inputSwitch [(ngModel)]="Ground" (ngModelChange)="onChangeGround()" [disabled]="currentSnapshot.disableGround"></p-inputSwitch>
         </div>
     </p-card>
   </div>
   <div class="ui-g-12 ui-g-nopad snapshotMap">
     <div id="map">
     </div>
     <iframe id="city-viewer"
                          title="CityViewer"
                          [src]="iFrameUrl | safe"
                          [hidden]="iFrameHidden" allowfullscreen>
     </iframe>
   </div>
</div>
