import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-snapshot-event-info',
  templateUrl: './snapshot-event-info.component.html',
  styleUrls: ['./snapshot-event-info.component.css']
})
export class SnapshotEventInfoComponent implements OnInit {

  @Input() currentSnapshot: any;

  constructor() { }

  ngOnInit() {
  }

}
