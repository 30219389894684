import {ISerializable} from '../iserializable';
import {ElementClassModel} from './element-class-model';
import {HeaderModel} from '../header-model';

export class ElementClassCollectionModel implements ISerializable {

  public header: HeaderModel;
  public elementClasses: ElementClassModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.header = new HeaderModel().deserialize(input.header);
    this.elementClasses = input.elementClasses.map(elementClass => new ElementClassModel().deserialize(elementClass));

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
