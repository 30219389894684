<h2>{{listName}}</h2>
<hr>
<div class="globalSearch ui-g">
  <button *ngIf="isMatched" pButton type="button" label="Add list value" class="widthButton"
          (click)="onAddField()"></button>
  <button *ngIf="numberSelection === 0" pButton type="button" label="{{'settings.lists.delete-button' | translate:{numberSelection:numberSelection} }}"
          class="ui-button-secondary marginLeft forbidden" disabled="true" [ngClass]="{'widthButton': isMatched}"></button>
  <button *ngIf="numberSelection !== 0" pButton type="button" label="{{'settings.lists.delete-button' | translate:{numberSelection:numberSelection} }}"
          class="ui-button-danger marginLeft" (click)="confirmDeleteAll()" [ngClass]="{'widthButton': isMatched}"></button>
</div>
<hr>
<div class="userList">
  <p-table [columns]="cols" [value]="listValues" [(selection)]="selectedValues" (onRowSelect)="onRowSelect()"
           (onRowUnselect)="onRowUnselect()" [responsive]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="checkBoxStyle" style="width: 3em">
          <p-tableHeaderCheckbox (click)="selectAll()"></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns">
          {{col.header}}
        </th>
        <th>
          <button pButton type="button" icon="fa fa-plus" (click)="onAddField()" style="display:block;"
                  class="ui-button-primary marginLeft" [pTooltip]="'settings.lists.add-field' | translate" tooltipPosition="top"></button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-listValue let-columns="columns">
      <tr>
        <td class="checkBoxStyle">
          <p-tableCheckbox [value]="listValue"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" class="phoneSizeTab">
          <span class="ui-column-title">{{col.header}}</span>
          {{listValue[col.field]}}
        </td>
        <td>
          <div class="ui-inputgroup ui-rtl" dir="rtl">
            <div class="ui-rtl" dir="ltr">
              <button pButton type="button" label="{{'settings.lists.modify' | translate}}" (click)="changeValue(listValue)" class="btn-secondary"></button>
              <button pButton type="button" icon="fa fa-close" (click)="confirmDeleteSelf(listValue)"
                      class="ui-button-danger" pTooltip="{{'settings.lists.delete' | translate}}" tooltipPosition="top"></button>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <app-settings-list-update  *ngIf="displayUpdate"  (getListValuesEvent)="updateListValue()"
                           [listValue]="listValue"
                           [listCode]="listCode"
                           [updateListValueForm]="updateListValueForm"></app-settings-list-update>

  <app-settings-list-create *ngIf="displayCreate"
                            (getListValuesEvent)="createListValue()"
                            [listCode]="listCode"
                            [createListValueForm]="createListValueForm"></app-settings-list-create>


</div>
<p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
