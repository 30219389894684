<div class="p-grid">
  <div class="p-col-12 header">
    <app-header></app-header>
  </div>
</div>
<div class="ui-g ui-g-nopad">
  <div class="ui-g-12 layout-content">
    <div class="mainContainer">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

