import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {SafeUrlPipe} from './globals/pipes/safe-url.pipe';

// main router
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';

// primeNg
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {MenubarModule} from 'primeng/menubar';
import {ConfirmationService, MessageService} from 'primeng/api';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarModule} from 'primeng/sidebar';
import {MenuModule} from 'primeng/menu';
import {CardModule} from 'primeng/card';
import {ListboxModule} from 'primeng/listbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {AccordionModule} from 'primeng/accordion';
import {PanelModule} from 'primeng/panel';
import {ToastModule} from 'primeng/toast';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FieldsetModule} from 'primeng/fieldset';
import {CalendarModule} from 'primeng/calendar';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SplitButtonModule} from 'primeng/splitbutton';
import {RoleGuard} from './guards/role-guard.service';
import {LayoutModule} from '@angular/cdk/layout';
import {ToolsComponent} from './components/tools/tools.component';
import {ToastsComponent} from './components/main/toasts/toasts.component';
import {SnapshotModule} from './components/snapshot/snapshot.module';
import {HomeModule} from './components/home/home.module';
import {ToolsModule} from './components/tools/tools.module';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {HttpCredentialsRequestInterceptor} from './globals/interceptors/HttpCredentialsRequestInterceptor';
import {environment} from 'src/environments/environment';
import {AreasComponent} from './components/areas/areas.component';
import {AreasAddComponent} from './components/areas/areas-add/areas-add.component';
import {AreasListComponent} from './components/areas/areas-list/areas-list.component';
import {
  AutoCompleteModule,
  FileUploadModule,
  MultiSelectModule,
  PaginatorModule,
  TableModule,
  TabViewModule,
  TreeModule,
  TreeTableModule
} from "primeng";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ToolsComponent,
    ToastsComponent,
    SafeUrlPipe,
    AreasComponent,
    AreasAddComponent,
    AreasListComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule, FormsModule, ReactiveFormsModule, AppRoutingModule, InputTextModule,
    ButtonModule, DialogModule, CheckboxModule, MenubarModule, DropdownModule,
    BrowserAnimationsModule, HttpClientModule, CardModule, ListboxModule, ConfirmDialogModule,
    MessagesModule, MessageModule, AccordionModule, PanelModule, SelectButtonModule, FieldsetModule,
    CalendarModule, DynamicDialogModule, TooltipModule, ProgressSpinnerModule, SidebarModule, LayoutModule,
    SplitButtonModule, ToastModule,
    SnapshotModule,
    HomeModule,
    ToolsModule,
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      useRefreshTokens: true,
      redirectUri: window.location.href,
      cacheLocation: 'localstorage',
      audience: environment.auth.audience,
      scope: 'create:users',
      httpInterceptor: {
        allowedList: [
          {
            uri: environment.auth.apiUri,
            tokenOptions: {
              audience: environment.auth.audience,
            }

          }
        ]
      }
    }),
    PaginatorModule,
    TableModule,
    AutoCompleteModule,
    MultiSelectModule,
    TreeTableModule,
    TabViewModule,
    FileUploadModule,
    TreeModule,
    TranslateModule.forRoot({
      loader:{
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
  ],
  exports: [SidebarModule,
    MenuModule,
    BrowserAnimationsModule
  ],
  providers: [
    ConfirmationService,
    MessageService,
    DialogService,
    RoleGuard,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpCredentialsRequestInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
