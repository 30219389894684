import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MenuItem} from 'primeng/api';
import {SecurityService} from '../../../services/security.service';
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})
export class UsersEditComponent implements OnInit {


  currentUser: any;
  displayOne: string;
  displayGeo: boolean;
  items: MenuItem[];

  constructor(private formBuilder: FormBuilder,
              private translate:TranslateService,
              private securityService: SecurityService,
  ) { }

  ngOnInit() {
    this.securityService.currentTransferUser.subscribe(user => {
      this.currentUser = user;
    });
    this.displayOne = 'baseEdit';
    this.translate.get(['users.edit.modify','users.edit.roles','users.edit.defaultArea', 'users.edit.grids']).subscribe(
      translations => {
        this.items = [
          {label: translations['users.edit.modify'], icon: 'pi fa fa-info-circle', command: () => {
              this.displayOne = 'baseEdit';
              this.displayGeo = false;
            }},
          {label: translations['users.edit.roles'], icon: 'pi fa fa-pie-chart', command: () => {
              this.displayOne = 'roleEdit';
              this.displayGeo = false;
            }},
          {label: translations['users.edit.defaultArea'], icon: 'pi fa fa-home', command: () => {
              this.displayOne = 'geoEdit';
              this.displayGeo = true;
            }},
          {label: translations['users.edit.grids'], icon: 'pi pi-money-bill', command: () => {
              this.displayOne = 'gridEdit';
              this.displayGeo = false;
            }},
        ]
      }
    );
  }

}
