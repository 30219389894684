import {BehaviorSubject} from 'rxjs';
import * as turf from '@turf/turf';
import {Directive, EventEmitter, Injectable, Output} from '@angular/core';

interface Diff {
  sec: number;
  min: number;
  hour: number;
}

@Directive()
@Injectable({
  providedIn: 'root'
})
export class SnapshotService {

  finishedRequest: boolean;
  @Output() changeStatusRequest: EventEmitter<boolean> = new EventEmitter();
  errStatus: any;
  @Output() changeStatus: EventEmitter<any> = new EventEmitter();

  isSearch: boolean;
  private snapshotMatches = new BehaviorSubject('');
  currentSnapshot = this.snapshotMatches.asObservable();
  private snapshotSource = new BehaviorSubject('none');
  currentTransferSnapshot = this.snapshotSource.asObservable();

  constructor() {
  }

  dateDiff(element) {
    const date1 = +new Date(element.snapshotDate);
    const date2 = +new Date(element.snapshotDateEnd);

    const diff: Diff = {
      sec: 0,
      min: 0,
      hour: 0,
    };
    let diff2;
    let tmp = date2 - date1;
    tmp = Math.floor(tmp / 1000);
    diff.sec = tmp % 60;
    tmp = Math.floor((tmp - diff.sec) / 60);
    diff.min = tmp % 60;
    tmp = Math.floor((tmp - diff.min) / 60);
    diff.hour = tmp % 24;
    if (diff.hour === 0) {
      diff2 = diff.min + 'min' + diff.sec + 'sec';
    } else {
      diff2 = diff.hour + 'h' + diff.min + 'min' + diff.sec + 'sec';
    }
    return diff2;
  }

  getDistance(snapshot) {
    let distance = 0;
    // let distanceShow = '';
    if (snapshot.snapshotGeometry.coordinates) {
      distance = turf.length(snapshot.snapshotGeometry);
      distance *= 1000;
      distance = Math.trunc(distance);
      distance /= 1000;
      // distanceShow = distance.toString() + 'km';
    } else {
      distance = 0;
      // distanceShow = '0';
    }
    // return distanceShow;
    return distance;
  }

  getFunctions(snapshot) {
    snapshot.disableJPR = true;
    snapshot.disableViewer = true;
    snapshot.disableSign = true;
    snapshot.disableRiverSign = true;
    snapshot.disableGround = true;
    snapshot.eventsList = '';

    snapshot.snapshotEvents.forEach(event => {
      switch (event.eventStatusCode) {
        case 'HORIZONTAL_ANALYSIS_PERFORMED':
          snapshot.disableGround = false;
          break;
        case 'VERTICAL_ANALYSIS_PERFORMED':
          snapshot.disableSign = false;
          snapshot.disableRiverSign = false;
          break;
        case 'STITCHED':
          snapshot.disableViewer = false;
          break;
        case 'JOURNEY_RECEIVED':
        case 'CAPTURE_RECEIVED':
          snapshot.disableJPR = false;
          break;
      }
    });
    return snapshot;
  }

  transferSnapshot(snapshot: any) {
    this.snapshotSource.next(snapshot);
  }
}
