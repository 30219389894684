// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let urlApi =  window['env']['apiUrl']|| 'https://api-dev.citymagine.biz';
let urlDomainApi =  window['env']['apiDomainUrl']|| 'https://api-dev.citymagine.biz/v2';
let oauthClientId = window['env']['oauthClientId'] || "ooGduOKPU2BGlxUcY9z4DBXmV3Ahawr7";
let oauthIssuerUrl = window['env']['oauthIssuerUrl'] || "dev-citymagine.eu.auth0.com";
let urlMedia = window['env']['mediaUrl'] || "https://media-dev.citymagine.biz/";
let mapIgnAuth = window['env']['mapIgnAuth'] || "Y2l0eW1hZ2luZTp0eWhqa2xpdTg3NjV0eWg=";
let accessMapboxToken = window['env']['accessMapboxToken'] || "pk.eyJ1IjoiY2l0eW1hZ2luZSIsImEiOiJjajZwNG1yZTEwandtMzJwczd2eGszbXdyIn0.W909QH_JtJ9YRSIlBdkUZg";
let urlNominatim = window['env']['urlNominatim'] || "https://open-street-api.citymagine.com/search/?q=";
let urlCityview = window['env']['cityviewUrl'] || "https://cityviewer-dev.citymagine.biz/";
let production = window['env']['production'] || false;

export const environment = {
  production,
  auth: {
    domain: oauthIssuerUrl,
    clientId: oauthClientId,
    audience: 'ApiMain',
    apiUri: `${urlApi}/*`
  },

  accessMapboxToken,
  urlMedia,
  mapIgnAuth,
  urlApi,
  urlDomainApi,
  urlCityview,

  getAreas: `${urlDomainApi}/areas`,
  getAreasParent: `${urlDomainApi}/areas/parents`,
  putAreas: `${urlDomainApi}/areas`,
  getCurrentUserAreas: `${urlDomainApi}/users/areas`,
  getUserAreas: `${urlDomainApi}/users/`,

  mapStyleSatellite: 'mapbox://mapbox.satellite',

  urlTilesPR: `${urlApi}/citymagine-datasource-3.0/tiles/pr/{z}/{x}/{y}.mvt`,
  urlTilesElements: `${urlApi}/citymagine-api-1.0/rest/tile/elements/{z}/{x}/{y}.mvt`,
  urlTilesViews: `${urlApi}/citymagine-api-1.0/rest/tile/views/{z}/{x}/{y}.mvt`,

  urlNominatim,
  urlNominatimParam: '&format=jsonv2&polygon_geojson=1&addressdetails=1&extratags=1&countrycodes=FR',

  urlSearchProcessTasks: `${urlApi}/citymagine-api-1.0/rest/tasks/searchProcessTasks`,
  urlGetProcessTasks: `${urlApi}/citymagine-api-1.0/rest/tasks/getProcessTasks`,
  urlCreateProcessTask: `${urlApi}/citymagine-api-1.0/rest/tasks/createProcessTask`,
  urlAckProcessTask: `${urlApi}/citymagine-api-1.0/rest/tasks/ackProcessTask`,
  urlCloseProcessTask: `${urlApi}/citymagine-api-1.0/rest/tasks/closeProcessTask`,
  urlChangeSnapshotDomain: `${urlApi}/citymagine-api-1.0/rest/tasks/changeSnapshotDomain`,
  urlCreateProcessRules: `${urlApi}/citymagine-api-1.0/rest/tasks/createProcessRules`,
  urlGetProcessRules: `${urlApi}/citymagine-api-1.0/rest/tasks/getProcessRules`,
  urlUpdateProcessRules: `${urlApi}/citymagine-api-1.0/rest/tasks/updateProcessRules`,
  urlDeleteProcessRules: `${urlApi}/citymagine-api-1.0/rest/tasks/deleteProcessRules`,

  urlProcessingGetSnapshots: `${urlApi}/citymagine-api-1.0/rest/processing/getSnapshots`,
  urlProcessingUpdateSnapshot: `${urlApi}/citymagine-api-1.0/rest/processing/updateSnapshot`,
  urlProcessingUpdateSnapshotStatus: `${urlApi}/citymagine-api-1.0/rest/processing/updateSnapshotStatus`,
  urlProcessingResetSnapshot: `${urlApi}/citymagine-api-1.0/rest/processing/resetSnapshot`,
  urlProcessingRemoveSnapshot: `${urlApi}/citymagine-api-1.0/rest/processing/removeSnapshot`,

  urlGeoJsonGetSnapshotCollection: `${urlApi}/citymagine-api-1.0/rest/geojson/getSnapshotCollection?`,
  urlGeoJsonGetModelPoiCollection: `${urlApi}/citymagine-api-1.0/rest/geojson/getModelPoiCollection`,
  urlGeoJsonGetObjectElementCollection: `${urlApi}/citymagine-api-1.0/rest/geojson/getObjectElementCollection`,
  urlGeojsonGetObjectViewCollection: `${urlApi}/citymagine-api-1.0/rest/geojson/getObjectViewCollection`,

  urlSecurityCreateUser: `${urlApi}/citymagine-api-1.0/rest/security/createUser`,
  urlSecurityGetUsers: `${urlApi}/citymagine-api-1.0/rest/security/getUsers`,
  urlSecurityUpdateUser: `${urlApi}/citymagine-api-1.0/rest/security/updateUser`,
  urlDeleteUser: (user) => `${urlDomainApi}/users/${user}`,
  urlAddRole: (user) => `${urlDomainApi}/users/${user}`,
  urlSecurityDeleteUser: `${urlApi}/citymagine-api-1.0/rest/security/deleteUser`,
  urlSecurityResetPassword: `${urlApi}/citymagine-api-1.0/rest/security/resetPassword`,

  urlGetDefaultDomain: `${urlApi}/citymagine-api-1.0/rest/user/defaultDomain`,
  urlGetUsersOfDomain: `${urlDomainApi}/users`,
  urlGetDomainsOfUser: (user) => `${urlDomainApi}/users/${user}/domains`,
  urlgetAllDomains: `${urlDomainApi}/domains`,
  urlSetDefaultDomain: `${urlApi}/citymagine-api-1.0/rest/user/profile`,
  urlSetCurrentDomain: (domain) => `${urlApi}/citymagine-api-1.0/rest/user/domain/${domain}`,
  urlCreateDomain: `${urlDomainApi}/domains`,

  urlGetElementClass: `${urlApi}/citymagine-api-1.0/rest/masterdata/elementClass`,
  urlMasterdataCreateElementClass: `${urlApi}/citymagine-api-1.0/rest/masterdata/createElementClass`,
  urlMasterdataGetElementClass: `${urlApi}/citymagine-api-1.0/rest/masterdata/getElementClass`,
  urlMasterdataUpdateElementClass: `${urlApi}/citymagine-api-1.0/rest/masterdata/updateElementClass`,
  urlMasterdataDeleteElementClass: `${urlApi}/citymagine-api-1.0/rest/masterdata/deleteElementClass`,
  urlMasterdataCreateListValue: `${urlApi}/citymagine-api-1.0/rest/masterdata/createListValue`,
  urlMasterdataGetLists: `${urlApi}/citymagine-api-1.0/rest/masterdata/getLists`,
  urlMasterdataUpdateListValue: `${urlApi}/citymagine-api-1.0/rest/masterdata/updateListValue`,
  urlMasterdataDeleteListValue: `${urlApi}/citymagine-api-1.0/rest/masterdata/deleteListValue`,
  urlMasterdataGetConfig: `${urlApi}/citymagine-api-1.0/rest/masterdata/getConfigurations`,
  urlMasterDataUpdateConfig: `${urlApi}/citymagine-api-1.0/rest/masterdata/updateConfiguration`,
  urlGetStatusList: `${urlApi}/citymagine-api-1.0/rest/masterdata/getStatus`,

  urlSessionGetDomains: `${urlApi}/citymagine-api-1.0/rest/session/getDomains`,
  urlRevokeSession: `${urlApi}/citymagine-api-1.0/rest/session/revokeSession`,
  urlGetSession: `${urlApi}/citymagine-api-1.0/rest/session/getSession`,

  urlAsyncDeduplicateObjectElements: `${urlApi}/citymagine-api-1.0/rest/async/deduplicateObjectElements`,

};
