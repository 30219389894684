import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {JsonEditorOptions} from "ang-jsoneditor";
import {MasterDataService} from "../../../../../services/master-data.service";
import {TranslateService} from "@ngx-translate/core";
import {MessageService} from "primeng/api";
import {ElementClassModel} from "../../../../../models/masterData/element-class-model";

@Component({
  selector: 'app-masterdata-add-properties',
  templateUrl: './masterdata-add-properties.component.html',
  styleUrls: ['./masterdata-add-properties.component.css']
})
export class MasterdataAddPropertiesComponent implements OnInit {

  @Input() newMasterdata: ElementClassModel;
  editForm: FormGroup;
  editorOptions: JsonEditorOptions;
  properties: any;

  constructor() {
  }

  ngOnInit(): void {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'tree', 'view'];
    this.properties = this.newMasterdata.classProperties;
  }

  updateMasterData(masterData: ElementClassModel): ElementClassModel {
    masterData.classProperties = this.properties;
    return masterData;
  }
  get f() {
    return this.editForm.controls;
  }

}
