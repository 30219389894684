export enum RoleEnum {

  // Designer roles
  ROLE_DESIGN_TOOLS = 'ROLE_DESIGN_TOOLS',
  ROLE_DESIGN_SETTINGS = 'ROLE_DESIGN_SETTINGS',
  ROLE_DESIGN_USERS = 'ROLE_DESIGN_USERS',
  ROLE_DESIGN_DOMAINS = 'ROLE_DESIGN_DOMAINS',
  ROLE_DESIGN_MODELING = 'ROLE_DESIGN_MODELING',
  ROLE_DESIGN_PROCESSING = 'ROLE_DESIGN_PROCESSING',
  ROLE_DESIGN_SNAPSHOTS = 'ROLE_DESIGN_SNAPSHOTS',
  ROLE_DESIGN_GRIDS = 'ROLE_DESIGN_GRIDS',
  ROLE_DESIGN_AREAS = 'ROLE_DESIGN_AREAS',

  // Map roles,
  ROLE_MAP_CRASH = 'ROLE_MAP_CRASH',
  ROLE_MAP_GROUND = 'ROLE_MAP_GROUND',
  ROLE_MAP_HELIOS = 'ROLE_MAP_HELIOS',
  ROLE_MAP_PR = 'ROLE_MAP_PR',
  ROLE_MAP_QUALITY = 'ROLE_MAP_QUALITY',
  ROLE_MAP_RGP = 'ROLE_MAP_RGP',
  ROLE_MAP_SIGN = 'ROLE_MAP_SIGN',
  ROLE_MAP_TRASH = 'ROLE_MAP_TRASH',
  ROLE_MAP_USER = 'ROLE_MAP_USER',
  ROLE_MAP_STATS= 'ROLE_MAP_STATS',

  // API roles
  ROLE_ANNOTATION = 'ROLE_ANNOTATION',
  ROLE_GEOJSON = 'ROLE_GEOJSON',
  ROLE_MASTERDATA = 'ROLE_MASTERDATA',
  ROLE_PROCESSING = 'ROLE_PROCESSING',
  ROLE_SECURITY = 'ROLE_SECURITY',
  ROLE_TASK = 'ROLE_TASK',
  ROLE_USER = 'ROLE_USER',
}
