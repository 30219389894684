import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {SecurityService} from '../../../services/security.service';
import {MessageService} from 'primeng/api';
import {DomainCollectionModel} from "../../../models/session/domain-collection-model";
import {DomainModel} from "../../../models/session/domain-model";
import {Observable, of} from "rxjs";
import {UserModel} from "../../../models/session/user-model";
import {DomainModelLegacy} from "../../../models/session/domain-model-legacy";
import {SessionService} from "../../../services/session.service";
import {Session} from "../../../globals/session";
import * as jwt from "jwt-decode";
import {DomainService} from "../../../services/domain.service";
import {TranslateService} from "@ngx-translate/core";

interface Domain {
  name: string;
  id: number;
}

@Component({
  selector: 'app-users-delete',
  templateUrl: './users-delete.component.html',
  styleUrls: ['./users-delete.component.css']
})
export class UsersDeleteComponent implements OnInit {

  domains: DomainCollectionModel;
  domainArray: Observable<Domain[]>;
  selectedDomains: Domain[] = [];
  displayDefault: string;
  translations: any;

  displayDomainForm: boolean;
  @Input() user: UserModel;
  @Input() isAdmin: boolean;

  constructor(private formBuilder: FormBuilder,
              private securityService: SecurityService,
              private messageService: MessageService,
              private sessionService: SessionService,
              private session: Session,
              private domainService: DomainService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.get(['users.chooseDomains', 'users.confirmDelete'])
      .subscribe( translations => {
        this.translations = translations;
        this.displayDefault = translations['users.chooseDomains'];
      });
    this.displayDomainForm = this.isAdmin;
    this.securityService.getDomainsOfUser(this.user.userId).subscribe((response: DomainCollectionModel) => {
      if (response) {
        const domainsTmp: Domain[] = [];
        this.domains = response;
        this.domains.domains.forEach((domain: DomainModel) => {
          domainsTmp.push({name: domain.name, id: domain.id});
        });
        this.selectCurrentDomain(domainsTmp);
        this.domainArray = of(domainsTmp);
      }
    });
  }

  async selectCurrentDomain(userDomains: Domain[]) {
    const defaultDomain: { defaultDomain: number } = await this.domainService.getDefaultDomain().toPromise();
    const decodedToken = jwt(this.session.token);
    const currentDomainFromToken =  decodedToken['https://www.citymagine.com/domainId'] || 1;
    const domains = await this.sessionService.getDomains().toPromise();
    let currentDomain: DomainModelLegacy;
    if (currentDomainFromToken) {
      currentDomain = domains.domains.find(p => p.domainId === currentDomainFromToken);
    } else {
      currentDomain = domains.domains.find(p => p.domainId === defaultDomain.defaultDomain);
    }

    userDomains.forEach( (d: Domain) => {
      if (d.id === currentDomain.domainId) {
        this.selectedDomains.push({name: d.name, id: d.id});
        this.displayDefault = d.name;
      }
    });
  }

  selectDomain(event) {
    if (event.value.length === 0) {
      this.displayDefault = this.translations['users.chooseDomains'];
    }
  }

  deleteUser() {
    const domainIds: number[] = this.selectedDomains.map((domain: Domain) => domain.id);
    this.securityService.deleteUser(this.user.userId, domainIds).subscribe(resp => {
      if (resp) {
        this.messageService.add({key: 'bl', severity: 'success', summary: this.translations['users.confirmDelete'],
          detail: this.user.userName});
        window.location.reload();
      }
    });
  }

}
