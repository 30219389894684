import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {SnapshotComponent} from './snapshot.component';
import {SnapshotReceivedComponent} from './snapshot-received/snapshot-received.component';
import {SnapshotService} from './services/snapshot.service';
import {MapFunctionsService} from './services/mapfunctions.service';
import {SnapshotListComponent} from './snapshot-list/snapshot-list.component';
import {SnapshotEventComponent} from './snapshot-event/snapshot-event.component';
import {SnapshotMapComponent} from './snapshot-map/snapshot-map.component';
import {SafePipe} from '../../globals/pipes/safe.pipe';
import {LayoutModule} from '@angular/cdk/layout';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {ChartModule} from 'primeng/chart';
import {CardModule} from 'primeng/card';
import {InputSwitchModule} from 'primeng/inputswitch';

import {CalendarModule} from 'primeng/calendar';

import {ChipsModule} from 'primeng/chips';
import {DialogModule} from 'primeng/dialog';
import {FieldsetModule} from 'primeng/fieldset';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MenuModule} from 'primeng/menu';

import {CheckboxModule} from 'primeng/checkbox';
import {MessageModule} from 'primeng/message';
import {PanelModule} from 'primeng/panel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TooltipModule} from 'primeng/tooltip';
import {ToastModule} from 'primeng/toast'


import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';
import {CreateProcessComponent} from './snapshot-received/create-process/create-process.component';
import {ListboxModule} from 'primeng/listbox';
import {PaginatorModule} from 'primeng/paginator';
import {MessagesModule} from 'primeng/messages';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {SplitButtonModule} from 'primeng/splitbutton';

import {SnapshotSearchFormComponent} from './snapshot-received/snapshot-search-form/snapshot-search-form.component';

import {LoginComponent} from '../login/login.component';
import {ChangeDomainComponent} from './change-domain/change-domain.component';
import { SnapshotEventInfoComponent } from './snapshot-event/snapshot-event-info/snapshot-event-info.component';
import { SnapshotEventStatusComponent } from './snapshot-event/snapshot-event-status/snapshot-event-status.component';
import { SnapshotEventEditComponent } from './snapshot-event/snapshot-event-edit/snapshot-event-edit.component';
import { SnapshotEventGanttComponent } from './snapshot-event/snapshot-event-gantt/snapshot-event-gantt.component';
import { AddTagComponent } from './add-tag/add-tag.component';
import { SnapshotEventPropertiesComponent } from './snapshot-event/snapshot-event-properties/snapshot-event-properties.component';
import { SnapshotProjectComponent } from './snapshot-project/snapshot-project.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    SnapshotComponent,
    SnapshotReceivedComponent,
    SnapshotListComponent,
    SnapshotEventComponent,
    SnapshotMapComponent,
    SnapshotSearchFormComponent,
    CreateProcessComponent,
    ChangeDomainComponent,
    SafePipe,
    SnapshotEventInfoComponent,
    SnapshotEventStatusComponent,
    SnapshotEventEditComponent,
    SnapshotEventGanttComponent,
    AddTagComponent,
    SnapshotEventPropertiesComponent,
    SnapshotProjectComponent,
  ],
  imports: [
    LayoutModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BrowserModule,
    TableModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ListboxModule,
    ChartModule,
    CardModule,
    InputSwitchModule,
    PaginatorModule,
    ConfirmDialogModule,
    DialogModule,
    MenuModule,
    MessagesModule,
    MessageModule,
    SelectButtonModule,
    FieldsetModule,
    CalendarModule,
    DynamicDialogModule,
    PanelModule,
    TooltipModule,
    CheckboxModule,
    ProgressSpinnerModule,
    SplitButtonModule,
    ToastModule,
    ChipsModule,
    InputTextareaModule,
  ],
  providers: [
    SnapshotService,
    MapFunctionsService,
  ],
  exports: [RouterModule, SnapshotProjectComponent],
})
export class SnapshotModule {
}
