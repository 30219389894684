import {ISerializable} from '../iserializable';
import {UserModel} from './user-model';

export class UserCollectionModel implements ISerializable {

  public users: UserModel[];

  public totalCount: number;

  deserialize(input: any): this {

    this.users = input.users.map(item => new UserModel().deserialize(item));
    this.totalCount = input.totalCount;
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
