export enum MapImageEnum {
  IMAGE_CAMERA = 'imgCamera',
  URL_CAMERA = 'assets/images/camera.png',

  IMAGE_LOCATION_MARKER = 'imgLocationMarker',
  URL_LOCATION_MARKER = 'assets/images/borne-pr.png',

  IMAGE_GPS_RGP = 'imgGpsRpg',
  URL_GPS_RGP = 'assets/images/balise_rgp.png',
  IMAGE_LOCATION_MARKER_D = 'PR-D',
  IMAGE_LOCATION_MARKER_A = 'PR-A',
  IMAGE_LOCATION_MARKER_N = 'PR-N',
  IMAGE_LOCATION_MARKER_M = 'PR-M',
  IMAGE_LOCATION_MARKER_P = 'PR-P',
  URL_LOCATION_MARKER_Y = 'assets/images/pr-yellow.png',
  URL_LOCATION_MARKER_R = 'assets/images/pr-red.png'
}
