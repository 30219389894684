<form [formGroup]="newMasterdataForm"
      class="globalSearch ui-g ui-fluid snapshotForm">

  <input #hiddenInput style="opacity: 0; position: absolute; width: 0; height: 0;">

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.class' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="elementClass" disabled="true" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.type' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="type" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.classCode' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="classCode" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.category' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="category" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.description' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="description" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.geometry' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <p-dropdown formControlName="geometry" [options]="geometries" optionLabel="geometry" [(ngModel)]="selectedGeometry" class="form-control"></p-dropdown>
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.view' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <p-dropdown formControlName="view" [options]="views" optionLabel="view" [(ngModel)]="selectedView" class="form-control"></p-dropdown>
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.filter01' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="filter01" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.filter02' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="filter02" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.filter03' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="filter03" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.callBackFunction' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="callBackFunction" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.parent' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="text" pInputText formControlName="parent" class="form-control ui-g-12">
    </div>
  </div>

  <div class="appendFormName ui-g-12">
    <p class="labels">{{'masterdata.edit.zoom' | translate}}</p>
    <div class="form-group">
      <p-inputNumber formControlName="zoom" [showButtons]="true" min="1" max="100" class="form-control deselect-buttons" ></p-inputNumber>
    </div>
  </div>

</form>

