export enum ProcessCodeEnum {
  CAPTURE_BUILDER = 'CAPTURE_BUILDER',
  CHANGE_DOMAIN = 'CHANGE_DOMAIN',
  DEDUPLICATE_GROUND = 'DEDUPLICATE_GROUND',
  DEDUPLICATE_SIGN = 'DEDUPLICATE_SIGN',
  DEDUPLICATE_TRASH = 'DEDUPLICATE_TRASH',
  HORIZONTAL_ANALYSIS = 'HORIZONTAL_ANALYSIS',
  MODELING_AREAS = 'MODELING_AREAS',
  SEND_CAPTURE = 'SEND_CAPTURE',
  STITCHER = 'STITCHER',
  STRIPER = 'STRIPER',
  TRASH_ANALYSIS = 'TRASH_ANALYSIS',
  VERTICAL_ANALYSIS = 'VERTICAL_ANALYSIS',
  WARPER = 'WARPER',
}
