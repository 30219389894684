import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import { ElementClassModel } from 'src/app/models/masterData/element-class-model';
import {MasterDataService} from '../../../../../services/master-data.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-masterdata-edit-details',
  templateUrl: './masterdata-edit-details.component.html',
  styleUrls: ['./masterdata-edit-details.component.css']
})
export class MasterdataEditDetailsComponent implements OnInit, AfterViewInit {

  editForm: FormGroup;
  @Input() currentMasterdata: ElementClassModel;
  locale: string;
  views = [{view: 'PANO_360_L'}, {view: 'GROUND_L'}];
  geometries = [{geometry: 'Point'}, {geometry: 'LineString'}, {geometry: 'Polygon'}];
  selectedView;
  selectedGeometry;
  @ViewChild('hiddenInput') typeInput: ElementRef<HTMLInputElement>;

  constructor(private formBuilder: FormBuilder,
              private masterDataService: MasterDataService,
              private messageService: MessageService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.selectedView = {view: this.currentMasterdata.classViewType};
    this.selectedGeometry = {geometry: this.currentMasterdata.classGeoType};
    this.editForm = this.formBuilder.group({
      elementClass: [this.currentMasterdata.elementClass, Validators.required],
      type: [this.currentMasterdata.elementType, Validators.required],
      classCode: [this.currentMasterdata.classCode, Validators.required],
      parent: [this.currentMasterdata.classParent, Validators.required],
      category: [this.currentMasterdata.classCategory, Validators.required],
      description: [this.currentMasterdata.classDescription, Validators.required],
      geometry: [this.currentMasterdata.classGeoType, Validators.required],
      view: [this.currentMasterdata.classViewType, Validators.required],
      zoom: [this.currentMasterdata.classGeoZoom, Validators.required],
      filter01: [this.currentMasterdata.classFilter01, Validators.required],
      filter02: [this.currentMasterdata.classFilter02, Validators.required],
      filter03: [this.currentMasterdata.classFilter03, Validators.required],
      callBackFunction: [this.currentMasterdata.classCallBackFunction, Validators.required],
      isParent: [this.currentMasterdata.isParent, Validators.required],
    });
    if (this.isValidElementClassFormat(this.currentMasterdata.elementClass)) {
      this.editForm.get('type').valueChanges.subscribe(value => {
        this.updateElementClass();
      });
      this.editForm.get('classCode').valueChanges.subscribe(value => {
        this.updateElementClass();
      });
    }
  }

  isValidElementClassFormat(elementClass: string): boolean {
    if (elementClass) {
      const elementClassArray = elementClass.split('.');
      if (elementClassArray.length === 3 &&
        elementClassArray[0].toUpperCase() === this.currentMasterdata.elementType.toUpperCase() &&
        elementClassArray[1].length <= 3 &&
        elementClassArray[2].toUpperCase() === this.currentMasterdata.classCode.toUpperCase()) {
        this.locale = elementClassArray[1];
        return true;
      }
    }
    return false;
  }

  updateElementClass() {
    this.editForm.patchValue({ elementClass: this.editForm.get('type').value.toLowerCase() + '.' + this.locale + '.' +
        this.editForm.get('classCode').value.toUpperCase() });
  }

  updateMasterData(masterData: ElementClassModel): ElementClassModel {
    masterData.elementClass = this.f.elementClass.value;
    masterData.classDescription = this.f.description.value;
    masterData.elementType = this.f.type.value;
    masterData.classGeoZoom = this.f.zoom.value;
    masterData.classCallBackFunction = this.f.callBackFunction.value;
    masterData.classFilter01 = this.f.filter01.value;
    masterData.classFilter02 = this.f.filter02.value;
    masterData.classFilter03 = this.f.filter03.value;
    masterData.classViewType = this.f.view.value.view;
    masterData.classGeoType = this.f.geometry.value.geometry;
    masterData.classCategory = this.f.category.value;
    masterData.classParent = this.f.parent.value;
    masterData.classCode = this.f.classCode.value;
    masterData.isParent = false;
    return masterData;
  }

  get f() {
    return this.editForm.controls;
  }

  ngAfterViewInit() {
    // focus an hidden input to unfocus the button of p-inputNumber for zoom field in the form
    setTimeout(() => this.typeInput.nativeElement.focus(), 100);
  }

}
