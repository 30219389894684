import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AreasModel} from "../models/domain/areas-model";
import {HttpHeadersEnum} from "../enums/http-headers-enum.enum";
import {AuthorizationTypesEnum} from "../enums/authorization-types-enum.enum";
import {Session} from "../globals/session";
import {AreaModel} from "../models/domain/area-model";
import {DoneResponseModel} from "../models/done-response-model";
import {AreaArrayModel} from "../models/domain/area-array-model";
import { DomainCreationModel } from '../models/domain/domain-creation-model';
import {UserCollectionModelLegacy} from "../models/session/user-collection-model-legacy";
import {UserCollectionModel} from "../models/session/user-collection-model";
import {RequestHelper} from "../helpers/request.helper";
import {DomainCollectionModel} from "../models/session/domain-collection-model";

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  public static currentDomainId;

  constructor(private http: HttpClient, private session: Session, private requestHelper: RequestHelper) { }

  getDefaultDomain() {
    return this.http.get<{defaultDomain: number}>(environment.urlGetDefaultDomain);
  }

  setDefautDomain(domain) {
    return this.http.put(environment.urlSetDefaultDomain, {defaultDomain: domain});
  }

  setCurrentDomain(domain) {
    return this.http.post(environment.urlSetCurrentDomain(domain), {});
  }

  getDomains(): Observable<DomainCollectionModel> {
    const url = environment.urlgetAllDomains;
    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.http.get(url, options).pipe(map(data => new DomainCollectionModel().deserialize(data)),
      catchError((err) => {
        console.log(err);
        return throwError('Can not get domains list');
      })
    );
  }

  createDomain(domain: DomainCreationModel): Observable<DoneResponseModel> {
    const url = environment.urlCreateDomain;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.post(url, domain, options).pipe(map(data => {
        return new DoneResponseModel().deserialize(data);
      })
    );
  }

  getArea(areaId: string): Observable<AreaModel> {
    const url = environment.getAreas + '/' + areaId;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.get(url , options).pipe(map(data => new AreaModel().deserialize(data)),
      catchError(() => {
        return throwError('Area not found');
      })
    );
  }

  getAreas(page: number = 0, size: number = 12): Observable<AreasModel> {
    const url = environment.getAreas + '?page=' + page + '&size=' + size;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.get(url , options).pipe(map(data => new AreasModel().deserialize(data)),
      catchError(() => {
        return throwError('Areas not found');
      })
    );
  }

  getParentAreas(page: number = 0, size: number = 12): Observable<HttpResponse<AreasModel>> {
    const url = environment.getAreasParent + '?page=' + page + '&size=' + size;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    return this.http.get<AreasModel>(url , {headers, observe: "response"});
  }

  getCurrentUserAreas(): Observable<AreasModel> {
    const url = environment.getCurrentUserAreas;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.get(url , options).pipe(map(data => new AreasModel().deserialize(data)),
      catchError(() => {
        return throwError('Areas for current user not found');
      })
    );
  }

  getUserAreas(userId: number = 1153): Observable<AreaArrayModel> {
    const url = environment.getUserAreas + userId + '/areas';

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.get(url , options).pipe(map(data => new AreaArrayModel().deserialize(data)),
      catchError(() => {
        return throwError('Areas for user not found');
      })
    );
  }

  updateUserAreas(userId: number, areas: string[]): Observable<DoneResponseModel> {
    const url = environment.getUserAreas + userId + '/areas';

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.patch(url, areas, options).pipe(map(data => {
        return new DoneResponseModel().deserialize(data);
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  createArea(area: AreaModel): Observable<DoneResponseModel> {
    const url = environment.putAreas;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.post(url, {
      name: area.name,
      geometry: area.geometry,
      parentId: area.parentId,
      users: area.users
    }, options).pipe(map(data => {
        return new DoneResponseModel().deserialize(data);
      })
    );
  }

  updateArea(area: AreaModel): Observable<DoneResponseModel> {
    const url = `${environment.putAreas}/${area.id}`;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.patch(url, {
      name: area.name,
      geometry: area.geometry,
      parentId: area.parentId,
      users: area.users
    }, options).pipe(map(data => {
        return new DoneResponseModel().deserialize(data);
      })
    );
  }

  deleteArea(areaId: string) {
    const url = environment.getAreas + '/' + areaId;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + ' ' + this.session.token);
    const options = {headers};
    return this.http.delete(url , options);
  }

}
