<h2>Configurations settings</h2>
<hr>
<div class="globalSearch ui-g">
  <div class="infoSearch" *ngIf="validSearch">
    <p [ngClass]="{'marginAll': isMatched}"><span class="boldClass">Config Group:</span> {{ infoData.configGroup }} <br/>
      <span class="boldClass">Config Code:</span> {{ infoData.configCode }}</p>
  </div>
  <form (ngSubmit)="getConfiguration()" class="marginLeft"  [ngClass]="{'widthForm': isMatched}">
    <div class="ui-g" [ngClass]="{'ui-inputgroup': !isMatched}">
      <p-dropdown [options]="groupedConfig" [(ngModel)]="selectedConfig" [ngModelOptions]="{standalone:true}"
                  placeholder="Select a configuration code" [group]="true" class="groupedConfig"
                  [ngClass]="{'ui-g-12': isMatched}"></p-dropdown>
      <button pButton type="submit" [ngClass]="{'ui-g-12': isMatched, 'marginAll': isMatched}"
               label="Get Config"></button>
    </div>
  </form>
</div>
<hr>
<div class="ui-g ui-fluid">
  <div class="test" *ngIf="validSearch">
    <json-editor [options]="editorOptions"  [(ngModel)]="data" (change)="getData($event)"
                 style="width:100%; min-height: 600px;" class="jsonConfiguration"></json-editor>
    <button pButton type="submit"  label="Update Config" (click)="updateConfiguration()"></button>
  </div>
</div>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
