import {IFeatureProperties} from './ifeature-properties';
import {ISerializable} from '../iserializable';

export class FeatureObjectElementModel implements IFeatureProperties, ISerializable {

  public featureType: string;

  public elementIdentifier: string;
  public elementOrder: number;
  public elementTimestamp: string;

  public elementType: string;
  public elementClass: string;
  public elementCategory: string;

  public elementUrl: string;

  public elementName: string;
  public elementProperties: object = {};

  public viewIdentifier: string;
  public viewBbox: object;

  public snapshotIdentifier: string;
  public snapshotName: string;
  public elementQuality: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
