import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringUtils {

  constructor() {}

  static isNotEmpty(value: string): boolean {
    return (value !== undefined
          && value !== '');
  }

  static isEmpty(value: string): boolean {
    return !StringUtils.isNotEmpty(value);
  }
}
