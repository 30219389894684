<form class="globalSearch ui-g ui-fluid snapshotForm">
  <div class="globalSearch ui-g ui-fluid snapshotForm">
    <div class="appendFormName ui-g-12" *ngIf="isAdmin && displayDomainForm">
      <b>{{'users.chooseDomainsWhereDelete' | translate}}</b> <br>
      <div class="form-group ui-inputgroup searchName">
        <p-multiSelect [options]="domainArray | async" [(ngModel)]="selectedDomains" optionLabel="name"
                       defaultLabel="{{displayDefault}}" class="form-control ui-g-12" (onChange)="selectDomain($event)"
                       [ngModelOptions]="{standalone: true}"></p-multiSelect>
      </div>
    </div>
    <div class="appendFormName ui-g-12" *ngIf="!displayDomainForm" >
      <div class="alert-message">
        <i class="pi pi-exclamation-triangle"></i>
        <p>{{'users.youWillDelete' | translate}}<b>{{user.userName}}</b> {{'users.onTheFollowingDomains' | translate}}</p>
        <br><br><br>
      </div>

      <div style="margin-left: 20px; padding: 5px" *ngFor="let domain of selectedDomains">
        <p >{{domain.name}}</p> <br>
      </div>
    </div>
  </div>
</form>
