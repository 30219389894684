import {Injectable} from '@angular/core';
import {FeatureModel} from "../models/geojson/feature-model";
import {GeometryModel} from "../models/geojson/geometry-model";
import * as turf from "@turf/turf";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {FeatureTypesEnum} from "../enums/feature-types-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class GeometryHelper {

  constructor() {}

  static getDefaultPoint(): FeatureModel {

    let value: FeatureModel = new FeatureModel();
    value.geometry = new GeometryModel();
    value.geometry.type = GeometryTypeEnum.POINT;
    value.geometry.coordinates = [2.3707129698604206, 48.85722798031489]; // Paris

    return value;
  }

  static isValidFeature(value: FeatureModel): boolean {
    return (value !== undefined
          && value.type === FeatureTypesEnum.FEATURE
          && GeometryHelper.isValidGeometry(value.geometry))
  }

  static isValidGeometry(value: GeometryModel): boolean {
    return (value !== undefined
      && value.type !== undefined
      && value.type !== ''
      && value.coordinates !== undefined
      && value.coordinates.length > 0)
  }

  static getCentroid(geometry: GeometryModel): GeometryModel {
    let result: GeometryModel;

    if (geometry !== undefined) {
      let value = turf.centroid(geometry.toTurf());

      result = new GeometryModel();
      result.type = value.geometry.type;
      result.coordinates = value.geometry.coordinates;

      console.log(result.serialize());
    }

    return result;
  }

  static unionFromGeometryPolygon(geometry: GeometryModel[]) {
    if (geometry.length > 0) {
      const polygons = [];
      geometry.forEach(geo => {
        polygons.push(turf.polygon(geo.coordinates));
      });
      const unions = turf.union(...polygons);
      return unions;
    }
  }

  static unionFromFeaturesGeometry(features: FeatureModel[]) {
    const polygons = [];
    if (features.length > 0) {
      features.forEach((feature: FeatureModel) => {
        if (feature.geometry.type === 'Polygon') {
          polygons.push(turf.polygon(feature.geometry.coordinates));
        } else if (feature.geometry.type === 'MultiPolygon') {
          polygons.push(turf.multiPolygon(feature.geometry.coordinates));
        }
      });
      return turf.union(...polygons);
    }
  }
}
