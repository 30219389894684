<h2>Snapshots project</h2>
<hr>
<div class="snapshotView">
  <form [formGroup]="searchForm" (ngSubmit)="searchSnapshots()" class="globalSearch ui-g">
    <span class="numberOfSnapshotsSelected">Snapshots selected : {{numberSelection}}</span>
    <span class="totalDistance">Total distance : {{totalDistance}}km</span>
    <div class="ui-inputgroup detailButton" [ngClass]="{'ui-g-12': isMatched, 'marginRight': !isMatched}">
      <input type="text" pInputText placeholder="Snapshot Name" formControlName="research"
             class="ui-inputtext ui-corner-all ui-state-default ui-widget" [ngModel]="selectedName">
      <button pButton type="submit" label="Search"></button>
    </div>
    <p-splitButton label="Advanced Search" (onClick)="displayForm()" [model]="actionItems"
                   [ngClass]="{'ui-g-12': isMatched}"></p-splitButton>
    <app-snapshot-search-form (closeSnapshotSearchFormComponent)="closeSnapshotSearch($event)" ></app-snapshot-search-form>
    <p-splitButton label="Reset all processes" (onClick)="confirmResetAll()" class="splitMultipleReceived"
                   [model]="multipleItems" (onDropdownClick)="setSnapshots()"
                   [ngClass]="{'ui-g-12': isMatched, 'marginLeft': !isMatched}"></p-splitButton>
  </form>
  <p-paginator [rows]="12" [totalRecords]="totalCount" (onPageChange)="paginate($event)"
               [rowsPerPageOptions]="[12, 25, 50, 100, 200, 500]" #p></p-paginator>
  <p class="infoRow" *ngIf="!isMatched">items per page :</p>
  <p *ngIf="!isMatched" class="countRow">Number of snapshots : {{totalCount}}</p>
  <div *ngIf="!snapshotsLoaded" class="loaderContainer">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>



<div class="userList">
  <p-table [columns]="cols" [value]="snapshots" [(selection)]="selectedSnapshots" (onRowSelect)="onRowSelect($event)"
           (onRowUnselect)="onRowUnselect($event)" [responsive]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="checkBoxStyle" style="width: 3em">
          <p-tableHeaderCheckbox (click)="selectAll()"></p-tableHeaderCheckbox>
        </th>
        <th>
          Snapshot name
        </th>
        <th *ngFor="let col of columns" pTooltip="{{col.displayName}}" tooltipPosition="top" style="width: 3em">
          {{col.header}}
        </th>
<!--        <th>-->
<!--          Snapshot date-->
<!--        </th>-->
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-snapshot let-columns="columns">
      <tr>
        <td class="checkBoxStyle" style="width: 3em">
          <p-tableCheckbox [value]="snapshot"></p-tableCheckbox>
        </td>
        <td class="phoneSizeTab">
          <span class="ui-column-title">Snapshot name</span>
          {{snapshot.snapshotDisplayName}}
          <span *ngIf="!isMatched">
              <br/>
              <div *ngIf="snapshot.snapshotTags" class="snapshotTagContainer">
                <span *ngFor="let tag of snapshot.snapshotTags.tags" class="snapshotTag">{{tag}}</span>
              </div>
            </span>
        </td>
        <td *ngFor="let col of columns" class="phoneSizeTab" style="width: 3em">
          <span class="ui-column-title">{{col.header}}</span>
          <img src="assets/images/{{snapshot[col.field]}}.png" class="iconStatus"/>
        </td>
        <td>
          <div class="ui-inputgroup ui-rtl getList" dir="rtl">
            <div class="ui-rtl" dir="ltr">
              <p-splitButton label="Details" (onClick)="onEventSnapshot(snapshot)" class="splitReceived"
                             (onDropdownClick)="setSnapshot(snapshot)" [model]="tableItems"></p-splitButton>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
</div>
<app-create-process></app-create-process>
<app-add-tag></app-add-tag>
<app-change-domain [isPluginDock]="false"></app-change-domain>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
<p *ngIf="!isResult" class="disclaimer">No results...</p>

