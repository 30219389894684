import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from '../globals/session';
import {ConnectionOriginEnum} from '../enums/connection-origin-enum';
import {SessionModel} from '../models/session/session-model';
import {DoneResponseModel} from '../models/done-response-model';
import {DomainCollectionModelLegacy} from '../models/session/domain-collection-model-legacy';
import {RequestHelper} from '../helpers/request.helper';
import {StringUtils} from '..//helpers/string.utils';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private session: Session, private httpService: HttpClient, private requestHelper: RequestHelper) {
  }

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  displayStatus(errorMessage) {
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  getSession(): Observable<SessionModel> {
    const url = environment.urlGetSession;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_DESIGNER,
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      }
    }, options).pipe(map(data => new SessionModel().deserialize(data['session'])),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Connection not possible');
      })
    );
  }

  revokeSession(sessionIdentifier): Observable<DoneResponseModel> {
    const url = environment.urlRevokeSession;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_DESIGNER,
        sessionIdentifier
      }
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Closing session not possible');
      })
    );
  }

  getDomains(): Observable<DomainCollectionModelLegacy> {
    const url = environment.urlSessionGetDomains;
    return this.httpService.get(url).pipe(map(data => new DomainCollectionModelLegacy().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Domain list not accessible');
      })
    );
  }

  getSessionByLogin(domain: string, username: string, password: string) {
    this.session.token = btoa(domain + '@' + username + ':' + password);
    this.getSession().subscribe(sessionModel => {
      this.session.loadSession(sessionModel);
    });
  }

  getSessionByToken(token: string) {
    if (StringUtils.isNotEmpty(token)) {
      this.session.token = token;

      this.getSession().subscribe(sessionModel => {
        this.session.loadSession(sessionModel);
      });
    } else {
      console.log('User token missing');
    }
  }
}
