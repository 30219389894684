import {Component, OnInit} from '@angular/core';
import {Session} from './globals/session';
import {Router} from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit {
  title = 'CityDesigner';
  constructor(public session: Session,
              private router: Router,
              private translate: TranslateService,
              private authService: AuthService) {
    const languages: string[] = ['en', 'fr'];
    translate.addLangs(languages);
    translate.setDefaultLang('en');
    let locale = 'en';
    const storedLocale = window.localStorage.getItem('locale');
    if (storedLocale) {
      locale = storedLocale;
    } else {
      const navigatorLanguage = navigator.language.toLowerCase();
      if (navigatorLanguage && languages.includes(navigatorLanguage)) {
        locale = navigatorLanguage;
      } else {
        const splitNavigatorLanguage = navigatorLanguage.split('-')[0];
        for (const language of languages) {
          if (splitNavigatorLanguage === language || splitNavigatorLanguage === language.split('-')[0]) {
            locale = language;
            break;
          }
        }
      }
    }
    translate.use(locale);
    window.localStorage.setItem('locale', locale);
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe(isAuth => {
      if (!isAuth) {
        this.router.navigate(['']);
      } else {
        this.authService.getAccessTokenSilently({audience: environment.auth.audience, ignoreCache: true}).subscribe(token => this.session.token = token);
      }
    });
  }

}
