<h2>New Snapshots</h2>
<hr>
<div class="snapshotView">
  <form [formGroup]="searchForm" (ngSubmit)="searchSnapshots()" class="globalSearch ui-g">
    <span class="numberOfSnapshotsSelected">Snapshots selected : {{numberSelection}}</span>
    <span class="totalDistance">Total distance : {{totalDistance}}km</span>
    <div class="ui-inputgroup detailButton" [ngClass]="{'ui-g-12': isMatched, 'marginRight': !isMatched}">
      <input type="text" pInputText placeholder="Snapshot Name" formControlName="research" [ngModel]="selectedName"
             class="ui-inputtext ui-corner-all ui-state-default ui-widget">
      <button pButton type="submit" label="Search"></button>
    </div>
    <p-splitButton label="Upload({{numberSelection}})" (onClick)="uploadAll()" class="splitMultipleNew"
                   [model]="actionItems" [ngClass]="{'ui-g-12': isMatched}" (onDropdownClick)="setSnapshots()"></p-splitButton>
  </form>
  <p-paginator [rows]="12" [rowsPerPageOptions]="[12, 25, 50, 100, 200, 500]" [totalRecords]="totalCount"
               (onPageChange)="paginate($event)" #p></p-paginator>
  <p class="infoRow" *ngIf="!isMatched">items per page :</p>
  <p *ngIf="!isMatched" class="countRow">Number of snapshots : {{totalCount}}</p>
  <div *ngIf="!snapshotsLoaded" class="loaderContainer">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="userList">
    <p-table [columns]="cols" [value]="snapshots" [(selection)]="selectedSnapshots" (onRowSelect)="onRowSelect($event)"
             (onRowUnselect)="onRowUnselect($event)" [responsive]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="checkBoxStyle" style="width: 3em">
            <p-tableHeaderCheckbox (click)="selectAll()"></p-tableHeaderCheckbox>
          </th>
<!--          <th></th>-->
          <th>
            Snapshot name
          </th>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
          <th>
            Snapshot date
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-snapshot let-columns="columns">
        <tr>
          <td class="checkBoxStyle">
            <p-tableCheckbox [value]="snapshot"></p-tableCheckbox>
          </td>
          <td class="phoneSizeTab">
            <span class="ui-column-title">Snapshot name</span>
            <i *ngIf="snapshot.snapshotCheck" class="fa fa-exclamation-triangle orange" aria-hidden="true"
               pTooltip="{{snapshot.snapshotWarnCheck}}" tooltipStyleClass="tooltip"></i>
            {{snapshot.snapshotName}}
            <span *ngIf="!isMatched">
              <br/>
              <div *ngIf="snapshot.snapshotTags" class="snapshotTagContainer">
                <span *ngFor="let tag of snapshot.snapshotTags.tags" class="snapshotTag">{{tag}}</span>
              </div>
            </span>
          </td>
          <td *ngFor="let col of columns" class="phoneSizeTab">
            <span class="ui-column-title">{{col.header}}</span>
            {{snapshot[col.field]}}
          </td>
          <td class="phoneSizeTab">
            <span class="ui-column-title">Snapshot date</span>
            {{snapshot.snapshotDate | date : 'dd/MM/yyyy HH:mm:ss' :'UTC' }}
          </td>
          <td>
            <div class="ui-inputgroup ui-rtl newList" dir="rtl">
              <div class="ui-rtl" dir="ltr">
                <p-splitButton label="Upload" (onClick)="uploadSnapshot(snapshot)" class="splitNew"
                               (onDropdownClick)="setSnapshot(snapshot)" [model]="tableItems"></p-splitButton>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
<app-change-domain [isPluginDock]="true"></app-change-domain>
<app-add-tag></app-add-tag>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
<p *ngIf="!isResult" class="disclaimer">No results...</p>
