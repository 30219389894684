import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import {HttpClient} from '@angular/common/http';
import * as turf from '@turf/turf';
import {environment} from '../../../../environments/environment';
import {GeojsonService} from '../../../services/geojson.service';
import {GeometryModel} from '../../../models/geojson/geometry-model';
import {ElementTypesEnum} from '../../../enums/element-types-enum.enum';
import {MapLayerEnum} from '../../../enums/map-layer-enum.enum';
import {Session} from '../../../globals/session';
import {MapSourceEnum} from '../../../enums/map-source-enum.enum';
import {MapImageEnum} from '../../../enums/map-image-enum.enum';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class MapFunctionsService {

  @Output() changeDrawState: EventEmitter<boolean> = new EventEmitter();
  @Output() changeTypeCoord: EventEmitter<string> = new EventEmitter();
  @Output() changeDataGeo: EventEmitter<GeometryModel> = new EventEmitter();

  private counter = 0;
  private steps = 0;
  typeCoord: string;
  arrayOfLayers: any[];
  arrayOfSources: any[];
  dataGeojson: any;
  draw: any;
  isActiveDraw = false;
  LayerSourceObj: {};
  LayerSourceObjArray = [];
  map: any;
  multiCoord: any;
  objCoords: any;
  point = {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: []
      },
      properties: {bearing: 0}
    }]
  };
  pointViewer = {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: []
      },
      properties: {bearing: 0}
    }]
  };
  polyCoord: any;

  constructor(private http: HttpClient, private  geojsonService: GeojsonService, private session: Session) {
  }

  onToggleDraw() {
    this.isActiveDraw = false;
    this.changeDrawState.emit(this.isActiveDraw);
  }
  onChangeTypeCoord() {
    this.changeTypeCoord.emit(this.typeCoord);
  }
  onChangeDataGeo() {
    this.changeDataGeo.emit(this.dataGeojson);
  }
  onInitMapUser() {
    // @ts-ignore
    mapboxgl.accessToken = environment.accessMapboxToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10',
      preserveDrawingBuffer: true,
      center: [2.3707129698604206, 48.85722798031489], // Paris
      zoom: 6,
      transformRequest: (url, resourceType) => {
        if (url.startsWith('https://wxs.ign.fr/')) {
          return {
            url,
            headers: {Authorization: 'Basic ' + environment.mapIgnAuth}
          };
        } else if (url.startsWith(environment.urlApi)) {
          console.log(url);
          return {
            url,
            headers: {Authorization: 'Bearer ' + this.session.getToken() }
          };
        }
      }
    });
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: false,
        trash: false
      }
    });
    this.map.addControl(this.draw);
    this.arrayOfLayers = [];
    this.arrayOfSources = [];
  }
  onInitMap() {
    /* MAPBOX*/
    // @ts-ignore
    mapboxgl.accessToken = environment.accessMapboxToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10',
      center: [2.3707129698604206, 48.85722798031489], // Paris
      zoom: 6,
      transformRequest: (url, resourceType) => {
        if (url.startsWith('https://wxs.ign.fr/')) {
          return {
            url,
            headers: {Authorization: 'Basic ' + environment.mapIgnAuth}
          };
        } else if (url.startsWith(environment.urlApi)) {
          return {
            url,
            headers: {Authorization: 'Bearer ' + this.session.getToken()},
            credentials: 'include'
        };
        }
      }
    });
    this.loadImage(MapImageEnum.IMAGE_CAMERA, MapImageEnum.URL_CAMERA);

    // PR
    this.map.loadImage('assets/images/borne-pr.png', (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage('borne-pr', image);
    });
    this.map.loadImage('assets/images/balise_rgp.png', (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage('balise_rgp', image);
    });

    // Journey
    this.map.loadImage('assets/images/car.png', (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage('car', image);
    });

    // View
    this.map.loadImage('assets/images/camera.png', (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage('camera', image);
    });
    /* END MAPBOX LOADING*/
  }


  onLoadSnapshot(dataGeojson) {
    this.dataGeojson = dataGeojson;
    this.objCoords = this.dataGeojson.features[0].geometry.coordinates;
    const counting = 0;

    // next 2 functions allow us to detect the depth of the array and determinate if the object it's Polygon or MultiPolygon
    const maxDepth = (array) => {
      let maxVal = Number.MIN_VALUE;
      let item;
      array.forEach(val => {
        const depth = max(val);
        if (depth > maxVal) {
          maxVal = depth;
          item = val;
        }
      });
      return item;
    };

    const max = (array, count = 0) =>
      Array.isArray(array) ? max(maxDepth(array), count + 1) : count;

    const ArrayDepth = max(this.objCoords, counting);

    if (ArrayDepth === 4) {
      this.typeCoord = 'MultiPolygon';
    } else if (ArrayDepth === 3) {
      this.typeCoord = 'Polygon';
    } else if (ArrayDepth === 2) {
      this.typeCoord = 'LineString';
    } else {
      this.typeCoord = 'Point';
    }
    this.fitToContent();
  }

  fitToContent() {
    const arrayCoord = [];
    let bounds = '';
    if (this.typeCoord === 'MultiPolygon') {
      this.dataGeojson.forEach(polygon => {
        polygon[0].forEach(e => {
          arrayCoord.push(e);
        });
      });
      this.dataGeojson = this.multiCoord;
    } else if (this.typeCoord === 'Polygon') {
      this.dataGeojson[0].forEach((e) => {
        arrayCoord.push(e);
      });
      this.dataGeojson = this.polyCoord;
    } else {
      const coordinates = this.objCoords;
      this.typeCoord = 'LineString';
      bounds = coordinates.reduce((bound, coord) => {
        return bound.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
    }

    if (this.typeCoord !== 'LineString')  {
      bounds = arrayCoord.reduce((bound, coord) => {
        return bound.extend(coord);
      }, new mapboxgl.LngLatBounds(arrayCoord[0], arrayCoord[0]));
    }
    this.map.fitBounds(bounds, {
      padding: 20,
      maxZoom: 15
    });
  }

  // Image
  loadImage(imageName: string, url: string) {
    if (!this.map.hasImage(imageName)) {
      this.map.loadImage(url, (error, image) => {
        if (error) {
          throw error;
        }
        this.map.addImage(imageName, image);
      });
    }
  }

  removeLayer(layerName: string, sourceName: string = undefined) {
    if (this.map.getLayer(layerName) !== undefined) {
      this.map.removeLayer(layerName);
    }

    if (sourceName !== undefined ) {
      if (this.map.getSource(sourceName) !== undefined) {
        this.map.removeSource(sourceName);
      }
    }
  }

  addSymbolLabelLayerTiles(layerName: string, tileUrl: string, sourceLayer: string, sourceName: string, beforeWorkspace: boolean = false, layoutIcon: any = undefined, layoutLabel: any = undefined) {
    this.removeLayer(layerName);
    this.removeLayer(layerName + 'Label', sourceName);

    this.map.addSource(sourceName, {
      type: 'vector',
      tiles: [tileUrl]
    });

    const layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (typeof layerWorkspace !== 'undefined') {
      this.map.addLayer({
        id: layerName,
        type: 'symbol',
        source: sourceName,
        'source-layer': sourceLayer,
        layout: layoutIcon
      }, MapLayerEnum.LAYER_WORKSPACE);

      this.map.addLayer({
        id: layerName + 'Label',
        type: 'symbol',
        source: sourceName,
        'source-layer': sourceLayer,
        layout: layoutLabel
      }, MapLayerEnum.LAYER_WORKSPACE);

    } else {
      this.map.addLayer({
        id: layerName,
        type: 'symbol',
        source: sourceName,
        'source-layer': sourceLayer,
        layout: layoutIcon
      });

      this.map.addLayer({
        id: layerName + 'Label',
        type: 'symbol',
        source: sourceName,
        'source-layer': sourceLayer,
        layout: layoutLabel
      });
    }
  }

  addSymbolLayerTiles(layerName: string, tileUrl: string, sourceName: string, beforeWorkspace: boolean = false, layoutIcon: any = undefined, elementType?: string) {
    this.removeLayer(layerName, sourceName + 'Source' + elementType);

    this.map.addSource(sourceName + 'Source' + elementType, {
      type: 'vector',
      tiles: [tileUrl]
    });

    const layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (beforeWorkspace && typeof layerWorkspace !== 'undefined') {

      this.map.addLayer({
        id: layerName,
        type: 'symbol',
        source: sourceName + 'Source' + elementType,
        'source-layer': sourceName,
        layout: layoutIcon
      }, (beforeWorkspace) ? MapLayerEnum.LAYER_WORKSPACE : undefined);

    } else {

      this.map.addLayer({
        id: layerName,
        type: 'symbol',
        source: sourceName + 'Source' + elementType,
        'source-layer': sourceName,
        layout: layoutIcon
      });
    }


  }

  addCircleLayerTiles(layerName: string, tileUrl: string, sourceLayer: string, sourceName: string, beforeWorkspace: boolean = false, paint: any = undefined) {
    this.removeLayer(layerName, sourceName);

    this.map.addSource(sourceName, {
      type: 'vector',
      tiles: [tileUrl]
    });

    const layerWorkspace = this.map.getLayer(MapLayerEnum.LAYER_WORKSPACE);
    if (beforeWorkspace && typeof layerWorkspace !== 'undefined') {

      this.map.addLayer({
        id: layerName,
        type: 'circle',
        source: sourceName,
        'source-layer': sourceLayer,
        paint
      }, (beforeWorkspace) ? MapLayerEnum.LAYER_WORKSPACE : undefined);

    } else {
      this.map.addLayer({
        id: layerName,
        type: 'circle',
        source: sourceName,
        'source-layer': sourceLayer,
        paint
      });
    }
  }


  drawPolygon(objCoordinates) {
    this.LayerSourceObjArray = [];
    this.LayerSourceObj = {};
    this.onDeleteOnSwitchView();
    if (this.typeCoord === 'MultiPolygon' || this.typeCoord === 'Polygon') {
      this.dataGeojson = objCoordinates;
      let random = Math.random();
      const polygon1 = turf.polygon([[
        [180, -90],
        [-180, -90],
        [-180, 90],
        [180, 90],
        [180, -90]
      ]], {
        fill: '#F00',
        'fill-opacity': 0.1
      });
      const that = this;
      let i = 0;
      let sourceId = '';
      let layerId = '';
      const coord = objCoordinates;
      // if multipolygon, cut the mask with every wayZone polygon
      if (coord.length > 1 && this.typeCoord === 'MultiPolygon') {
        this.multiCoord = this.dataGeojson;
        coord.forEach(el => {
          random = Math.random();
          sourceId = 'mask - ' + i + random;
          layerId = 'polyline - ' + i + random;

          const polygon2 = turf.polygon(el, {
            fill: '#00F',
            'fill-opacity': 0.1
          });

          const difference = turf.difference(polygon1, polygon2);
          const source = {
            type: 'geojson',
            data: difference
          };
          that.map.addSource(sourceId, source);
          const layer = {
            id: layerId,
            source: sourceId,
            type: 'fill',
            paint: {
              'fill-color': 'black',
              'fill-opacity': 0.1
            }
          };
          that.map.addLayer(layer);
          this.LayerSourceObj = {
            source,
            layer
          };
          this.LayerSourceObjArray.push(this.LayerSourceObj);
          this.arrayOfLayers.push(layerId);
          this.arrayOfSources.push(sourceId);
          i++;
        });
      } else if (this.typeCoord === 'Polygon') {
        if (!this.polyCoord) {
          this.polyCoord = this.dataGeojson;
        }
        sourceId = 'mask' + i + random;
        layerId = 'polyline' + i + random;
        const polygon2 = turf.polygon(coord, {
          fill: '#00F',
          'fill-opacity': 0.1
        });
        const difference = turf.difference(polygon1, polygon2);
        const source = {
          type: 'geojson',
          data: difference
        };
        that.map.addSource(sourceId, source);
        const layer = {
          id: layerId,
          source: sourceId,
          type: 'fill',
          paint: {
            'fill-color': 'black',
            'fill-opacity': 0.1
          }
        };
        that.map.addLayer(layer);
        this.LayerSourceObj = {
          source,
          layer
        };
        this.LayerSourceObjArray.push(this.LayerSourceObj);
        this.arrayOfLayers.push(layerId);
        this.arrayOfSources.push(sourceId);
      }
    }
  }

  onDrawPolygon() {

    this.isActiveDraw = true;
    this.draw.changeMode('draw_polygon');
    this.map.on('draw.create', (e) => {
      const data = this.draw.getAll();
      if (data.features.length > 0) {
        const id = data.features[0].id;
        this.draw.delete(id);
      }
      const polygon = e.features[0];
      this.arrayOfLayers.forEach(layer => {
        const mapLayer = this.map.getLayer(layer);
        if (typeof mapLayer !== 'undefined') {
          this.map.removeLayer(layer);
        }
      });
      this.arrayOfSources.forEach(source => {
        const mapSource = this.map.getLayer(source);
        if (typeof mapSource !== 'undefined') {
          this.map.removeLayer(source);
        }
      });
      this.onToggleDraw();
      // this.onDeletePolygon();
      this.typeCoord = 'Polygon';
      this.onChangeTypeCoord();
      this.dataGeojson = polygon.geometry;
      this.onChangeDataGeo();
      // this.fitToContent();
      // to avoid errors drawing polygons,   turf.convex  creates a new polygon around the old one.
      const poly = turf.polygon(polygon.geometry.coordinates);
      const hull = turf.convex(poly);
      polygon.geometry.coordinates = hull.geometry.coordinates;
      // end turf.convex

      this.drawPolygon(polygon.geometry.coordinates);
      this.fitToContent();
    });
  }

  addRasterLayer(layerName: string, sourceName: string, url: string) {
    this.removeLayer(layerName, sourceName);

    this.map.addSource(sourceName, {
      "type": "raster",
      "url": url,
      "tileSize": 256
    });

    this.map.addLayer({
      "id": layerName,
      "type": "raster",
      "source": sourceName,
    });
  }

  moveLayerFirst(layerName: string) {
    this.map.moveLayer(layerName, this.getFirstLayerId());
  }

    // Map Layers
    getFirstLayerId(): string {
      let layers = this.map.getStyle().layers;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].source === 'composite') {
          return layers[i].id;
        }
      }
      return undefined;
    }

  onDeletePolygon() {

    this.arrayOfLayers.forEach(layer => {
      // tslint:disable-next-line:no-shadowed-variable
      const mapLayer = this.map.getLayer(layer);
      if (typeof mapLayer !== 'undefined') {
        this.map.removeLayer(layer);
      }
    });
    this.arrayOfLayers = [];

    this.arrayOfSources.forEach(source => {
      const mapSource = this.map.getSource(source);
      if (typeof mapSource !== 'undefined') {
        this.map.removeSource(source);
      }
    });
    this.arrayOfSources = [];
    this.dataGeojson = null;
    this.onToggleDraw();
  }

  onDeleteOnSwitchView() {

    this.LayerSourceObjArray.forEach(e => {
      const LayerSourceObjId = this.map.getLayer(e.layer.id);
      if (typeof LayerSourceObjId !== 'undefined') {
        // this.map.removeSource(e.layer.id);
      }
    });
    const l = this.map.getStyle().layers;
    l.map(e => {
      if (e.id.includes('polyline')) {
        this.map.removeLayer(e.id);
      }
    });
    this.LayerSourceObjArray = [];

    // const le = this.map.getStyle().layers;
  }

  carAnimation() {
    if (this.dataGeojson.features[0].geometry.coordinates.length > 0) {
      this.point.features[0].geometry.coordinates = this.dataGeojson.features[0].geometry.coordinates[this.counter];
      this.point.features[0].properties.bearing = turf.bearing(
        turf.point(this.dataGeojson.features[0].geometry.coordinates[this.counter >= this.steps ? this.counter - 1 : this.counter]),
        turf.point(this.dataGeojson.features[0].geometry.coordinates[this.counter >= this.steps ? this.counter : this.counter + 1])
      );
      if (this.counter < this.steps) {
        this.counter = this.counter + 1;
      } else {
        this.counter = 0;
      }
      return this.map.getSource('point').setData(this.point);
    }
  }

  onJourney() {
    this.map.addLayer({
      id: 'line-snapshot',
      type: 'line',
      source: {
        type: 'geojson',
        data: this.dataGeojson
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#b3b3b3',
        'line-width': 5,
        'line-opacity': .8
      }
    });

    this.map.addLayer({
      id: 'point',
      source: {
        type: 'geojson',
        data: this.point
      },
      type: 'symbol',
      layout: {
        'icon-image': 'car',
        'icon-size': 0.35,
        'icon-rotate': ['get', 'bearing'],
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      }
    });
    this.map.getSource('line-snapshot').setData(this.dataGeojson);
    this.steps = this.dataGeojson.features[0].geometry.coordinates.length - 1;
  }
  onPr() {
    this.geojsonService.getModelPoiCollection('PR_POINT', 100.00, this.typeCoord, this.objCoords).subscribe(resp => {
      if (resp) {
        this.map.addSource('prs', {
          type: 'geojson',
          data: resp
        });

        this.map.addLayer({
          id: 'bornes-pr',
          type: 'symbol',
          source: 'prs',
          layout: {
            'icon-image': 'borne-pr',
            'icon-size': 0.45,
            'icon-rotate': ['get', 'bearing'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true
          }
        });

        this.map.addLayer({
          id: 'bornes-label',
          type: 'symbol',
          source: 'prs',
          layout: {
            'text-field': '{poiCategory}\n{poiCode}',
            'text-font': [
              'DIN Offc Pro Medium',
              'Arial Unicode MS Bold'
            ],
            'text-size': 10
          }
        });
      }
    });
  }
  // onRGP() {
  //   this.geojsonService.getModelPoiCollection('BASE_RGP', 50000.00, this.typeCoord, this.objCoords).subscribe(resp => {
  //     if (resp) {
  //       this.map.addSource('rgps', {
  //         type: 'geojson',
  //         data: resp
  //       });
  //
  //       this.map.addLayer({
  //         id: 'balises-rgp',
  //         type: 'symbol',
  //         source: 'rgps',
  //         layout: {
  //           'icon-image': 'balise_rgp',
  //           'icon-size': 1,
  //           'icon-rotate': ['get', 'bearing'],
  //           'icon-rotation-alignment': 'map',
  //           'icon-allow-overlap': true,
  //           'icon-ignore-placement': true
  //         }
  //       });
  //
  //       this.map.addLayer({
  //         id: 'balises-label',
  //         type: 'symbol',
  //         source: 'rgps',
  //         layout: {
  //           'text-field': '\n\n\n{poiCode}',
  //           'text-font': [
  //             'DIN Offc Pro Medium',
  //             'Arial Unicode MS Bold'
  //           ],
  //           'text-size': 12
  //         }
  //       });
  //     }
  //   });
  // }
  onViewer() {
    this.map.addLayer({
      id: 'line-snapshotViewer',
      type: 'line',
      source: {
        type: 'geojson',
        data: this.dataGeojson
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#b3b3b3',
        'line-width': 5,
        'line-opacity': .8
      }
    });

    this.map.addLayer({
      id: 'pointViewer',
      source: {
        type: 'geojson',
        data: this.pointViewer
      },
      type: 'symbol',
      layout: {
        'icon-image': 'camera',
        'icon-size': 0.15,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      }
    });
    this.map.getSource('line-snapshotViewer').setData(this.dataGeojson);
  }
  onSign(currentSnapshot) {


  }
  // onTiles(snapshot) {
  //   const snapshotIdentifier = snapshot.snapshotIdentifier;
  //   const tileUrl = environment.urlRaster + snapshotIdentifier + '.json';
  //
  //   if (tileUrl && !this.map.getSource('tile-source')) {
  //     this.map.addSource('tile-source', {
  //       type: 'raster',
  //       url: tileUrl,
  //       tileSize: 256
  //     });
  //
  //     this.map.getSource('tile-source');
  //     this.map.addLayer({
  //       id: 'tile-layer',
  //       type: 'raster',
  //       source: 'tile-source',
  //       layout: {
  //         visibility: 'visible'
  //       }
  //     });
  //   }
  // }


}
