export enum ListTypesEnum {
  VERTICAL_CATEGORIES = 'VERTICAL_CATEGORIES',
  GROUND_CATEGORIES = 'GROUND_CATEGORIES',
  TRASH_CATEGORIES = 'TRASH_CATEGORIES',
  PROCESS = 'PROCESS',
  PROCESS_TYPE = 'PROCESS_TYPE',
  ELEMENT_TYPE= 'ELEMENT_TYPE',
  CLASS_CATEGORY_ = 'CLASS_CATEGORY_',
  PRODUCT_SIZE_GROUND = 'PRODUCT_SIZE_GROUND',
  PRODUCT_COLOR_GROUND = 'PRODUCT_COLOR_GROUND',
  PRODUCT_TYPE_GROUND = 'PRODUCT_TYPE_GROUND',
  PRICE_METHOD_GROUND = 'PRICE_METHOD_GROUND',
}
