import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {ConnectionOriginEnum} from '../enums/connection-origin-enum';
import {DoneResponseModel} from '../models/done-response-model';
import {UserCollectionModelLegacy} from '../models/session/user-collection-model-legacy';
import {RequestHelper} from "../helpers/request.helper";
import {ElementClassCollectionModel} from "../models/masterData/element-class-collection-model";
import {DomainCollectionModelLegacy} from "../models/session/domain-collection-model-legacy";
import {UserCollectionModel} from "../models/session/user-collection-model";
import {DomainCollectionModel} from "../models/session/domain-collection-model";
import {Session} from '../globals/session';
import {number} from "@amcharts/amcharts4/core";

@Directive()
@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private userSource = new BehaviorSubject('none');
  currentTransferUser = this.userSource.asObservable();

  constructor(private session: Session, private httpService: HttpClient, private requestHelper: RequestHelper) {
  }

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  displayStatus(errorMessage) {
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  resetPassword(userName): Observable<DoneResponseModel> {
    const url = environment.urlSecurityResetPassword;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_DESIGNER,
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      username: userName
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Fail in reset password');
      })
    );
  }

  createUser(mail, firstName, lastName, roles): Observable<DoneResponseModel> {
    const url = environment.urlSecurityCreateUser;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_DESIGNER,
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      firstname: firstName,
      lastname: lastName,
      email: mail,
      geometry: this.session.currentSession.user.userGeometry,
      roles
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Can not create user');
      })
    );
  }

  getUsers(pageSize: number, pageNumber: number, allUsers: boolean, userName: string = null): Observable<UserCollectionModel> {
    const url = environment.urlGetUsersOfDomain;
    let params = new HttpParams();
    const headers: HttpHeaders = this.requestHelper.createHttpHeaders();
    const allUsersStr = allUsers ? 'true' : 'false';
    params = params.append('pageSize', pageSize.toString());
    params = params.append('pageNumber', pageNumber.toString());
    params = params.append('allUsers', allUsersStr);
    if (userName) { params = params.append('userName', userName); }

    return this.httpService.get(url, {headers, params}).pipe(map(data => new UserCollectionModel().deserialize(data)),
      catchError((err) => {
        return throwError('Can not get users list');
      })
    );
  }
  getUserDetails(username: string): Observable<UserCollectionModelLegacy> {
    const url = environment.urlSecurityGetUsers;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_DESIGNER,
        responseLevel: [
          ResponseLevelEnum.ALL,
          ResponseLevelEnum.PAGING
        ]
      },
      username,
    }, options).pipe(map(data => new UserCollectionModelLegacy().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Can not get user details');
      })
    );
  }
  updateUser(user): Observable<DoneResponseModel> {
    const url = environment.urlSecurityUpdateUser;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_DESIGNER,
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      firstname: user.userFirstName,
      lastname: user.userLastName,
      email: user.userEmail,
      roles: user.userRoles,
      isBlocked: user.isBlocked,
      isAdmin: user.isAdmin,
      userId: user.userId
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot update user');
      })
    );
  }
  updateUserGeo(user): Observable<DoneResponseModel> {
    const url = environment.urlSecurityUpdateUser;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_DESIGNER,
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      userId: user.userId,
      roles: user.userRoles,
      geometry: {
        type: user.userGeometry.type,
        coordinates: user.userGeometry.coordinates
      },
      isGeoRestricted: user.isGeoRestricted
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Setting default zone not possible');
      })
    );
  }

  getDomainsOfUser(userId: number): Observable<DomainCollectionModel> {
    const url = environment.urlGetDomainsOfUser(userId);
    return this.httpService.get(url).pipe(map(data => new DomainCollectionModel().deserialize(data)),
      catchError((err) => {
        console.log(err);
        return throwError('Can not get domains list');
      })
    );
  }

  deleteUserLegacy(user): Observable<DoneResponseModel> {
    const url = environment.urlSecurityDeleteUser;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_DESIGNER,
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      userId: user.userId
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot delete user');
      })
    );
  }

  addRoles(userId: number,  domainIds: number[], roles: string[]): Observable<DoneResponseModel> {
    const url = environment.urlAddRole(userId);
    const headers = this.requestHelper.createHttpHeaders();
    let params = new HttpParams();
    for (const domainId of domainIds) {
      params = params.append('domainIds', domainId.toString());
    }
    for (const role of roles) {
      params = params.append('roles', role);
    }
    return this.httpService.post(url, null, {headers, params}).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot add roles');
      })
    );
  }

  deleteUser(userId: number,  domainIds: number[]): Observable<DoneResponseModel> {
    const url = environment.urlDeleteUser(userId);
    const headers = this.requestHelper.createHttpHeaders();
    let params = new HttpParams();
    for (const domainId of domainIds) {
      params = params.append('domainIds', domainId.toString());
    }
    params = params.append('allDomains', 'false');
    return this.httpService.patch(url, null, {headers, params}).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot delete user');
      })
    );
  }

  transferUser(user) {
    this.userSource.next(user);
  }
}
