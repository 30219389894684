<h2>Rules management</h2>
<hr *ngIf="isMatched">
<div class="globalSearch ui-g">
  <button *ngIf="isMatched" pButton type="button" label="Add a rule" class="widthButton"
          (click)="onAddField()"></button>
</div>
<hr>
<div class="rulesView">
  <div class="userList">
    <p-table [columns]="cols" [value]="listRules" [(selection)]="selectedValues" (onRowSelect)="onRowSelect()"
             (onRowUnselect)="onRowUnselect()" [responsive]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
  <!--        <th class="checkBoxStyle" style="width: 3em">-->
  <!--          <p-tableHeaderCheckbox (click)="selectAll()"></p-tableHeaderCheckbox>-->
  <!--        </th>-->
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
          <th>
            Activée
          </th>
          <th>
            <button pButton type="button" icon="fa fa-plus" (click)="onAddField()" style="display:block;"
                    class="ui-button-primary marginLeft" pTooltip="Add Field" tooltipPosition="top"></button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-listValue let-columns="columns">
        <tr>
  <!--        <td class="checkBoxStyle">-->
  <!--          <p-tableCheckbox [value]="listValue"></p-tableCheckbox>-->
  <!--        </td>-->
          <td *ngFor="let col of columns" class="phoneSizeTab">
            <span class="ui-column-title">{{col.header}}</span>
            {{listValue[col.field]}}
          </td>
          <td class="phoneSizeTab">
            <span class="ui-column-title">Activée</span>
            <p-inputSwitch [(ngModel)]="listValue.enabled" (ngModelChange)="onChangeEnabled(listValue)"></p-inputSwitch>
          </td>
          <td>
            <div class="ui-inputgroup ui-rtl" dir="rtl">
              <div class="ui-rtl" dir="ltr">
                <button pButton type="button" icon="fa fa-close" (click)="confirmDeleteSelf(listValue)"
                        class="ui-button-danger" pTooltip="Delete" tooltipPosition="top"></button>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <app-rules-create *ngIf="displayCreate" (getListValuesEvent)="createListValue()"></app-rules-create>


  </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
