import {ISerializable} from '../iserializable';
import {UserModel} from './user-model';
import {HeaderModel} from '../header-model';

export class UserCollectionModelLegacy implements ISerializable {

  public header: HeaderModel;
  public users: UserModel[];

  deserialize(input: any): this {
    Object.assign(this, input);

    this.users = input.users.map(item => new UserModel().deserialize(item));
    this.header = new HeaderModel().deserialize(input.header);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
