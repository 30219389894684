import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {HeaderComponent} from './header/header.component';
import {SideMenuComponent} from './side-menu/side-menu.component';
import {ButtonModule} from 'primeng/button';
import {ChartModule} from 'primeng/chart';
import {CardModule} from 'primeng/card';

import {CalendarModule} from 'primeng/calendar';

import {MenuModule} from 'primeng/menu';

import {SplitButtonModule} from 'primeng/splitbutton';

import {DomainModule} from '../domain/domain.module';
import {UsersModule} from '../users/users.module';
import {SnapshotModule} from '../snapshot/snapshot.module';
import {ModelingModule} from '../modeling/modeling.module';
import {ProcessingModule} from '../processing/processing.module';
import {SettingsModule} from '../settings/settings.module';
import {SidebarModule} from 'primeng/sidebar';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {LayoutModule} from '@angular/cdk/layout';
import {BrowserModule} from '@angular/platform-browser';
import {HomeComponent} from './home.component';
import {MessageService, ProgressSpinnerModule} from 'primeng';
import {TranslateModule} from "@ngx-translate/core";
import {DialogModule} from 'primeng/dialog';
import { DomainsAddComponent } from './header/domain-add/domains-add.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SideMenuComponent,
    HomeComponent,
    DomainsAddComponent
  ],
  imports: [
      FormsModule,
      ReactiveFormsModule,
      BrowserModule,
      ButtonModule,
      CommonModule,
      DomainModule,
      LayoutModule,
      MenubarModule,
      MenuModule,
      ModelingModule,
      PanelMenuModule,
      ProcessingModule,
      RouterModule,
      SettingsModule,
      SidebarModule,
      SnapshotModule,
      SplitButtonModule,
      UsersModule,
      ChartModule,
      CardModule,
      CalendarModule,
      ProgressSpinnerModule,
      TranslateModule,
      DialogModule
  ],
  exports: [
    SideMenuComponent,    HomeComponent

  ],
  bootstrap: [HomeComponent],
  providers: [
    MessageService
  ]
})
export class HomeModule {
}
