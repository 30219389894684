import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MasterDataService} from '../../../../services/master-data.service';
import {MessageService} from 'primeng/api';
import {JsonEditorComponent} from 'ang-jsoneditor';

@Component({
  selector: 'app-settings-list-update',
  templateUrl: './settings-list-update.component.html',
  styleUrls: ['../settings-lists.component.css']
})
export class SettingsListUpdateComponent implements OnInit {

  public newData: any;
  @ViewChild(JsonEditorComponent,{static:false}) editor: JsonEditorComponent;

  @Output() getListValuesEvent = new EventEmitter();

  @Input() listValue: string[];
  @Input() listCode: string;
  @Input() updateListValueForm: any;

  displayUpdate: boolean;

  options: any;
  isParsingError: boolean;
  errorMessage: string;
  displayEditor: boolean;

  currentValueCode: string;
  currentValueText: string;

  constructor(
               private masterDataService: MasterDataService,
               private messageService: MessageService) { }

  ngOnInit() {
    this.displayEditor = !!JSON.stringify(this.listValue[2]);
    this.currentValueCode = this.listValue[0];
    this.currentValueText = this.listValue[1];
    this.options = {
      mode: 'code',
      onError: (err) => {
        alert(err.toString());
      },
      onChange: () => {
        try {
          this.isParsingError = false;
           // JSON.stringify(this.editor.get());
          this.newData = JSON.stringify(this.editor.get());
        } catch (err) {
          this.isParsingError = true;
        }
      }
    };
    this.displayUpdate = true;
    console.log(this.newData);
  }

  updateListValue() {
    console.log(this.newData);
    if (this.newData !== undefined) {
      const listValueParameters = JSON.parse(this.newData);
      if (this.listValue[1] !== this.currentValueText) {
        this.listValue[1] = this.currentValueText;
      }
      this.masterDataService.updateListValue(this.listCode, this.listValue[0], this.listValue[1], listValueParameters).subscribe(resp => {
        if (resp) {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ mis à jour',
            detail: this.currentValueCode});
          this.getListValuesEvent.emit();
        }
      });
    } else {
      if (this.listValue[1] !== this.currentValueText) {
        this.listValue[1] = this.currentValueText;
      }
      this.masterDataService.updateListValue(this.listCode, this.listValue[0], this.listValue[1]).subscribe(resp => {
        if (resp) {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ mis à jour',
            detail: this.currentValueCode});
          this.getListValuesEvent.emit();
        }
      });
    }
  }

  getData(event) {
    this.newData = event;
  }

  getText(event) {
    this.currentValueText = event;
  }
  onHide(){
    this.getListValuesEvent.emit();
  }

}
