import {ISerializable} from '../iserializable';
import {GeometryModel} from '../geojson/geometry-model';

export class AreaModel implements ISerializable {

  public id: string;
  public name: string;
  public parentId: string;
  public users: number[];
  public geometry: GeometryModel;
  public children: AreaModel[];

deserialize(input: any): this {
  Object.assign(this, input);
  return this;
}

serialize() {
  return JSON.stringify(this);
}

toJson(): any {
  return JSON.parse(this.serialize());
}

// toTurf() : any {
//   return turf.helpers.geometry(this.type, this.coordinates);
// }
}
