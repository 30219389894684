import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersEditComponent} from '../users-edit/users-edit.component';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {SecurityService} from '../../../services/security.service';
import {MasterDataService} from '../../../services/master-data.service';
import {UserCollectionModelLegacy} from '../../../models/session/user-collection-model-legacy';
import {UserModel} from '../../../models/session/user-model';
import {UsersAddComponent} from '../users-add/users-add.component';
import {DialogService} from 'primeng/dynamicdialog';
import {Paginator} from 'primeng/paginator';
import {DomainService} from "../../../services/domain.service";
import {SessionService} from "../../../services/session.service";
import {UserCollectionModel} from "../../../models/session/user-collection-model";
import {UsersDeleteComponent} from '../users-delete/users-delete.component';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})

export class UsersListComponent implements OnInit {

  @ViewChild('p') paginator: Paginator;
  @ViewChild(UsersAddComponent) private userAdd: UsersAddComponent;
  @ViewChild('usersDeleteComponent') usersDeleteComponent: UsersDeleteComponent;

  arrayUsers: UserModel[];
  cols: any;
  confirmMessage: string;
  counter: number;
  init: number;
  isMatched: boolean;
  isResult: boolean;
  last: number;
  limitCount: number;
  numberOfUsers: number;
  numberSelection: number;
  offset: number;
  searchUserForm: FormGroup;
  selectedName: string;
  selectedUsers: UserModel[];
  tableItems: MenuItem[];
  tableItemsBlocked: MenuItem[];
  totalCount: number;
  user: UserModel;
  users: UserModel[];
  usersLoaded: boolean;
  displayDeleteUser = false;
  deleteButtonLabel: string;
  isAdmin: boolean;
  loadAddUser = false;
  translations: any;

  constructor(public breakpointObserver: BreakpointObserver,
              private confirmationService: ConfirmationService,
              private dialogService: DialogService,
              private formBuilder: FormBuilder,
              private messageService: MessageService,
              private masterDataService: MasterDataService,
              private securityService: SecurityService,
              private domainService: DomainService,
              private sessionService: SessionService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.get(['users.cancel', 'users.continue', 'users.confirm', 'users.cancelDelete',
      'users.confirmDelete', 'users.confirmCreate'])
      .subscribe( translations => {
      this.translations = translations;
      this.deleteButtonLabel = translations['users.continue'];
    });
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
        }
      });
    this.limitCount = 12;
    this.offset = 0;
    this.numberSelection = 0;
    this.users = [];
    this.arrayUsers = [];
    this.selectedName = '';
    this.sessionService.getSession().subscribe(response => {
      this.isAdmin = response.user.isAdmin;
      this.loadAddUser = true;
      this.securityService.getUsers(this.limitCount, this.offset, this.isAdmin).subscribe(
        (resp: UserCollectionModel) => {
          if (resp) {
            this.totalCount = resp.totalCount;
            resp.users.forEach((user) => {
              this.arrayUsers.push(user);
              this.numberOfUsers++;
            });
            this.users = this.arrayUsers;
            this.usersLoaded = true;
            this.isResult = this.numberOfUsers !== 0;
          }
        }
      );
    });
    this.searchUserForm = this.formBuilder.group({
      research: ['', Validators.required]
    });
    this.cols = [
      { field: 'userName', header: 'Nom d\'utilisateur' },
      { field: 'userFirstName', header: 'Prénom' },
      { field: 'userLastName', header: 'Nom' },
      { field: 'userEmail', header: 'Email' },
    ];
    // this.actionItems = [
    //   {label: 'Reset les filtres', icon: 'fa fa-backward', command: () => {
    //       this.resetSnapshots();
    //     }}
    // ];
    this.tableItems = [
      {label: 'Réinitialiser le mot de passe', icon: 'fa fa-key', command: () => {
          this.resetPassword(this.user);
        }},
      {label: 'Désactiver', icon: 'fa fa-ban', command: () => {
        this.user.isBlocked = true;
        this.securityService.updateUser(this.user).subscribe( resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Utilisateur désactivé',
              detail: this.user.userName});
          }
        });
      }},
      {label: 'Supprimer', icon: 'fa fa-close', command: () => {
          this.deleteUser();
        }}
    ];
    this.tableItemsBlocked = [
      {label: 'Réinitialiser le mot de passe', icon: 'fa fa-key', command: () => {
          this.resetPassword(this.user);
        }},
      {label: 'Réactiver', icon: 'fa fa-check', command: () => {
        this.user.isBlocked = false;
        this.securityService.updateUser(this.user).subscribe( resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Utilisateur réactivé',
              detail: this.user.userName});
          }
        });
      }},
      {label: 'Supprimer', icon: 'fa fa-close', command: () => {
          this.deleteUser();
        }}
    ];
  }

  addUserFunction() {
      this.messageService.add({key: 'bl', severity: 'success', summary: this.translations['users.confirmCreate']});
      this.paginator.changePage(0);
  }
  callToGetUsersDetails() {
    this.numberOfUsers = 0;
    this.usersLoaded = false;
    this.arrayUsers = [];
    this.users = [];
    this.securityService.getUsers(this.limitCount, this.offset, this.isAdmin, this.selectedName).subscribe(
      (resp: UserCollectionModel) => {
        if (resp) {
          this.totalCount = resp.totalCount;
          resp.users.forEach((user) => {
            this.arrayUsers.push(user);
            this.numberOfUsers++;
          });
          this.users = this.arrayUsers;
          this.usersLoaded = true;
          this.isResult = true;
        } else {
          this.usersLoaded = true;
        }
        if (this.numberOfUsers === 0) {
          this.isResult = false;
        } else {
          this.isResult = true;
        }
        this.selectedName = '';
      }
    );
  }
  paginate(event) {
    this.limitCount = event.rows;
    this.numberSelection = 0;
    this.selectedUsers = [];
    this.init = event.first;
    this.last = event.first + event.rows;
    this.offset = event.first / this.limitCount;
    this.callToGetUsersDetails();
  }
  searchUsers() {
    this.selectedName = this.f.research.value;
    this.paginator.changePage(0);
  }
  setUser(user) {
    this.user = user;
  }
  onRowSelect() {
    this.numberSelection++;
  }
  onRowUnselect() {
    this.numberSelection--;
  }
  selectAll() {
    if (this.numberSelection !== this.numberOfUsers) {
      this.numberSelection = this.numberOfUsers;
    } else {
      this.numberSelection = 0;
    }
  }
  onAddUser() {
    this.userAdd.display = true;
  }
  onEditUser(user) {
    this.securityService.getUserDetails(user.userName).subscribe((response: UserCollectionModelLegacy) => {
      user = response.users[0];
      this.securityService.transferUser(user);
      const ref = this.dialogService.open(UsersEditComponent, {
        header: user.userName,
        width: '80%',
        height: '500px',
        transitionOptions: '200ms',
        contentStyle: {'max-height': '100%', overflow: 'auto'},
      });
    });
  }
  resetPassword(user) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous réinitialiser le mot de passe de cet utilisateur : </b><br/><ul class="confirmList"><li>'
        + user.userName + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.securityService.resetPassword(user.userName).subscribe( resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Nouveau mot de passe envoyé',
              detail: user.userEmail});
          }
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Réinitialisation annulée'});
      }
    });
  }
  deleteUser() {
    this.displayDeleteUser = true;
  }

  confirmDeleteUser() {
    if (this.usersDeleteComponent.displayDomainForm) {
      if (this.usersDeleteComponent.selectedDomains.length !== 0) {
        this.deleteButtonLabel = this.translations['users.confirm'];
        this.usersDeleteComponent.displayDomainForm = false;
      }
    } else {
      this.usersDeleteComponent.deleteUser();
      this.paginator.changePage(0);
      this.deleteButtonLabel = this.translations['users.continue'];
      this.displayDeleteUser = false;
    }
  }

  cancelDeleteUser() {
    this.displayDeleteUser = false;
    this.deleteButtonLabel = this.translations['users.continue'];
    this.messageService.add({key: 'bl', severity: 'warn', summary: this.translations['users.cancelDelete']});
  }

  confirmDeleteAll() {
    if (this.numberSelection > 0) {
      this.confirmMessage = '<ul class="confirmList">';
      this.selectedUsers.forEach( user => {
        this.confirmMessage += '<li>' + user.userName + '</li>';
      });
      this.confirmMessage += '</ul>';
    }
    this.counter = 0;
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer tous ces utilisateurs : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.selectedUsers.forEach( user => {
          this.securityService.deleteUserLegacy(user).subscribe(resp => {
            if (resp) {
              this.messageService.add({key: 'bl', severity: 'success', summary: this.translations['users.confirmDelete'], detail: user.userName});
              this.counter++;
              if (this.counter === this.numberSelection) {
                this.paginator.changePage(0);
              }
            }
          });
          this.usersDeleteComponent.deleteUser();
          this.paginator.changePage(0);
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: this.translations['users.cancelDelete']});
      }
    });
  }
  get f() {
    return this.searchUserForm.controls;
  }
}
