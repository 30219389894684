import {Component, Input, OnInit} from '@angular/core';
import {ProcessingService} from '../../../../services/processing.service';
import {SnapshotCollectionModel} from '../../../../models/processing/snapshot-collection-model';
import {TaskService} from '../../../../services/task.service';
import {TaskCollectionModel} from '../../../../models/task/task-collection-model';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import animated from '@amcharts/amcharts4/themes/animated.js';
import material from '@amcharts/amcharts4/themes/material.js';
import * as moment from 'moment';
import {ProcessCodeEnum} from '../../../../enums/process-code-enum';

interface Diff {
  sec: number;
  min: number;
  hour: number;
}

@Component({
  selector: 'app-snapshot-event-gantt',
  templateUrl: './snapshot-event-gantt.component.html',
  styleUrls: ['./snapshot-event-gantt.component.css']
})

export class SnapshotEventGanttComponent implements OnInit {

  @Input() currentSnapshot: any;
  data: any;
  dataElement: any;
  dataElementTask: any;
  previousEndDate: number;
  currentStartDate: number;
  currentEndDate: number;
  dateDifference: number;

  constructor(private processingService: ProcessingService, private taskService: TaskService) { }

  ngOnInit() {

    // TEST AMCHARTS

    /* Chart code */
    // Themes begin
    //     am4core.useTheme(am4themes_dataviz);
    am4core.useTheme(material);
    am4core.useTheme(animated);
    // Themes end

    const chart = am4core.create('chartdiv', am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.paddingRight = 30;
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm:ss';
    // chart.dateFormatter.inputDateFormat = 'i';

    const colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;
    chart.data = [];

    this.processingService.getSnapshotByParent(this.currentSnapshot.snapshotParentIdentifier)
      .subscribe((resp: SnapshotCollectionModel) => {
      if (resp) {
        let i = 0;
        let k = 0;

        resp.snapshots.forEach( snapshot => {
          this.dataElement = {};
          if (snapshot.snapshotParentIdentifier) {
            this.taskService.getProcessTaskBySnapshotIdentifier(snapshot.snapshotIdentifier)
              .subscribe( (respTask: TaskCollectionModel) => {
                console.log(respTask);
                if (respTask) {
                  let j = 0;

                  respTask.tasks.forEach( task => {
                  this.dataElementTask = {};
                  this.dataElementTask.id = j;
                  this.dataElementTask.process = task.processCode;
                  this.dataElementTask.fromDate = moment(task.taskAckDate).format('YYYY-MM-DD HH:mm:ss');
                  this.dataElementTask.color = colorSet.getIndex(this.getColor(task.processCode)).brighten(0.5);

                  if (task.taskCloseDate) {
                    this.dataElementTask.toDate = moment(task.taskCloseDate).format('YYYY-MM-DD HH:mm:ss');
                    this.dataElementTask.duration = this.dateDiff(task.taskAckDate, task.taskCloseDate);
                    if (j === 0) {
                      this.dataElementTask.name = snapshot.snapshotName + '_0' + snapshot.snapshotParentPart;
                      this.previousEndDate = +new Date(this.dataElementTask.toDate);
                      chart.data.push(this.dataElementTask);

                    } else {
                      this.currentStartDate = +new Date(this.dataElementTask.fromDate);
                      this.dateDifference = this.previousEndDate - this.currentStartDate;
                      if (this.dateDifference < 0) {
                        this.dataElementTask.name = snapshot.snapshotName + '_0' + snapshot.snapshotParentPart;
                        this.previousEndDate = +new Date(this.dataElementTask.toDate);
                        chart.data.push(this.dataElementTask);

                      } else {
                        this.dataElementTask.name = '-' + '_0' + snapshot.snapshotParentPart;
                        chart.data.push(this.dataElementTask);
                      }
                      // this.dataElementTask.name = '-';
                    }
                    j++;

                  } else if (task.taskCancelDate) {
                    // this.dataElementTask.toDate = moment(task.taskCancelDate).format('YYYY-MM-DD HH:mm:ss');
                    // this.dataElementTask.duration = this.dateDiff(task.taskAckDate, task.taskCancelDate) + 'CANCELED';
                  } else {
                    // this.dataElementTask.toDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    // this.dataElementTask.duration = this.dateDiff(task.taskAckDate, task.taskCloseDate);
                    // this.dataElementTask.duration = 'En cours';
                  }
                  // this.dataElementTask.color = colorSet.getIndex(this.getColor(task.processCode)).brighten(0.5);

                  // if (j === 0) {
                  //   this.dataElementTask.name = snapshot.snapshotName + '_0' + snapshot.snapshotParentPart;
                  //   this.previousEndDate = +new Date(this.dataElementTask.toDate);
                  // } else {
                  //   this.currentStartDate = +new Date(this.dataElementTask.fromDate);
                  //   this.dateDifference = this.previousEndDate - this.currentStartDate;
                  //   if (this.dateDifference > 0) {
                  //     this.dataElementTask.name = snapshot.snapshotName + '_0' + snapshot.snapshotParentPart;
                  //     this.previousEndDate = +new Date(this.dataElementTask.toDate);
                  //   } else {
                  //     this.dataElementTask.name = '-' + '_0' + snapshot.snapshotParentPart;
                  //   }
                  //   // this.dataElementTask.name = '-';
                  // }
                  if (task.taskCloseDate) {
                    // chart.data.push(this.dataElementTask);
                  }
                  // this.dataElementTask.color = colorSet.getIndex(i).brighten(j);
                  // chart.data.push(this.dataElementTask);
                  // console.log(task);
                  // j++;
                  k++;
                  if (respTask.tasks.length === k) {
                    i++;
                    if (i === resp.snapshots.length - 1) {
                      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                      categoryAxis.dataFields.category = 'name';
                      categoryAxis.renderer.minGridDistance = 20;
                      categoryAxis.renderer.grid.template.location = 0;
                      categoryAxis.renderer.inversed = true;

                      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                      dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd HH:mm:ss';
                      dateAxis.renderer.minGridDistance = 70;
                      dateAxis.baseInterval = { count: 1, timeUnit: 'minute' };
                      // dateAxis.max = new Date(2019, 8, 27, 19, 0, 0, 0).getTime();
                      // dateAxis.min = new Date(2019, 7, 21, 14, 30, 0, 0).getTime();
                      dateAxis.strictMinMax = true;
                      dateAxis.renderer.tooltipLocation = 0;

                      const series1 = chart.series.push(new am4charts.ColumnSeries());
                      series1.columns.template.width = am4core.percent(80);
                      series1.columns.template.tooltipText = '{process}: {duration}';

                      series1.dataFields.openDateX = 'fromDate';
                      series1.dataFields.dateX = 'toDate';
                      series1.dataFields.categoryY = 'name';
                      series1.columns.template.propertyFields.fill = 'color'; // get color from data
                      series1.columns.template.propertyFields.stroke = 'color';
                      series1.columns.template.strokeOpacity = 1;

                      chart.scrollbarX = new am4core.Scrollbar();
                    }
                    k = 0;
                  }
                });

              }
            });
          }

        });

      }
    });



    // chart.data = [
    //   {
    //     name: 'John',
    //     fromDate: '2018-01-01 08:00',
    //     toDate: '2018-01-01 10:00',
    //     color: colorSet.getIndex(0).brighten(0)
    //   },
    //   {
    //     name: 'John',
    //     fromDate: '2018-01-01 12:00',
    //     toDate: '2018-01-01 15:00',
    //     color: colorSet.getIndex(0).brighten(0.4)
    //   },
    //   {
    //     name: 'John',
    //     fromDate: '2018-01-01 15:30',
    //     toDate: '2018-01-01 21:30',
    //     color: colorSet.getIndex(0).brighten(0.8)
    //   },
    //
    //   {
    //     name: 'Jane',
    //     fromDate: '2018-01-01 09:00',
    //     toDate: '2018-01-01 12:00',
    //     color: colorSet.getIndex(2).brighten(0)
    //   },
    //   {
    //     name: 'Peter',
    //     fromDate: '2018-01-01 11:00',
    //     toDate: '2018-01-01 16:00',
    //     color: colorSet.getIndex(4).brighten(0)
    //   },
    //   {
    //     name: 'Jane',
    //     fromDate: '2018-01-01 13:00',
    //     toDate: '2018-01-01 17:00',
    //     color: colorSet.getIndex(2).brighten(0.4)
    //   },
    //   {
    //     name: 'Peter',
    //     fromDate: '2018-01-01 16:00',
    //     toDate: '2018-01-01 19:00',
    //     color: colorSet.getIndex(4).brighten(0.4)
    //   },
    //
    //   {
    //     name: 'Melania',
    //     fromDate: '2018-01-01 16:00',
    //     toDate: '2018-01-01 20:00',
    //     color: colorSet.getIndex(6).brighten(0)
    //   },
    //   {
    //     name: 'Melania',
    //     fromDate: '2018-01-01 20:30',
    //     toDate: '2018-01-01 24:00',
    //     color: colorSet.getIndex(6).brighten(0.4)
    //   },
    //
    //   {
    //     name: 'Donald',
    //     fromDate: '2018-01-01 13:00',
    //     toDate: '2018-01-01 24:00',
    //     color: colorSet.getIndex(8).brighten(0)
    //   }
    // ];


  }

  getColor(processCode: string) {
    switch (processCode) {
      case ProcessCodeEnum.CAPTURE_BUILDER:
        return 0;
      case ProcessCodeEnum.DEDUPLICATE_GROUND:
        return 2;
      case ProcessCodeEnum.DEDUPLICATE_SIGN:
        return 4;
      case ProcessCodeEnum.DEDUPLICATE_TRASH:
        return 6;
      case ProcessCodeEnum.HORIZONTAL_ANALYSIS:
        return 8;
      case ProcessCodeEnum.MODELING_AREAS:
        return 10;
      case ProcessCodeEnum.SEND_CAPTURE:
        return 1;
      case ProcessCodeEnum.STITCHER:
        return 7;
      case ProcessCodeEnum.STRIPER:
        return 5;
      case ProcessCodeEnum.TRASH_ANALYSIS:
        return 3;
      case ProcessCodeEnum.VERTICAL_ANALYSIS:
        return 11;
      case ProcessCodeEnum.WARPER:
        return 9;
    }
  }

  dateDiff(olderDate, recentDate) {
    const date1 = +new Date(olderDate);
    const date2 = +new Date(recentDate);

    const diff: Diff = {
      sec: 0,
      min: 0,
      hour: 0,
    };
    let diff2;
    let tmp = date2 - date1;
    tmp = Math.floor(tmp / 1000);
    diff.sec = tmp % 60;
    tmp = Math.floor((tmp - diff.sec) / 60);
    diff.min = tmp % 60;
    tmp = Math.floor((tmp - diff.min) / 60);
    diff.hour = tmp % 24;
    if (diff.hour === 0) {
      diff2 = diff.min + 'min' + diff.sec + 'sec';
    } else {
      diff2 = diff.hour + 'h' + diff.min + 'min' + diff.sec + 'sec';
    }
    return diff2;
  }
}
