<h2>Deduplicate by zone</h2>
<hr>
<form [formGroup]="geoForm" (ngSubmit)="onSubmitGeo()" class="globalSearch deduplicate ui-g ui-fluid geoForm">
  <p>Which element do you want to deduplicate ?</p>
  <br/>
  <p-selectButton [options]="elements" [(ngModel)]="elementType" formControlName="elementType" class="ui-g-12 selectButton"></p-selectButton>

  <div class="ui-float-label ui-g-12 inputFloat searchGeo">
    <p-autoComplete class="ui-autocomplete" [suggestions]="filteredResults" (completeMethod)="search($event)"
                    [minLength]="0" autofocus="true" (onSelect)="onSelectArea()" id="geolocalisation"
                    formControlName="autocomplete">
    </p-autoComplete>
    <label class="labels" for="geolocalisation">Location</label>
  </div>
  <div id="map">
  </div>
  <div class="mapAction">
    <div pTooltip="Draw search polygon" tooltipPosition="left">
      <button pButton type="button" icon="fa fa-crop" class="buttonStyle2"
              [ngClass]="{'listChecked':isActiveDraw, 'listUnchecked': !isActiveDraw}"></button>
      <p-checkbox name="" value="" (click)="onDrawPolygon()"
                  inputId="" class="hiddenCheckbox"></p-checkbox>
    </div>
    <div pTooltip="Clear zone" tooltipPosition="bottom">
      <button pButton type="button" icon="fa fa-close" class="clearButton" (click)="clearAll()"></button>
    </div>
  </div>

  <div class="submitButtonGeo ui-inputgroup">
    <button pButton type="submit" label="Deduplicate"></button>
  </div>
</form>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>

