import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {MenuItem, MessageService} from 'primeng/api';
import {Session} from '../../../globals/session';
import {SessionService} from '../../../services/session.service';
import {SideMenuComponent} from '../side-menu/side-menu.component';
import {AuthService} from '@auth0/auth0-angular';
import {DomainCollectionModelLegacy} from 'src/app/models/session/domain-collection-model-legacy';
import {DomainService} from 'src/app/services/domain.service';
import {environment} from '../../../../environments/environment';
import {HomeComponent} from '../home.component';
import {TranslateService} from "@ngx-translate/core";
import * as jwt from "jwt-decode";
import { DomainsAddComponent } from './domain-add/domains-add.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild(DomainsAddComponent)
  private domainAdd: DomainsAddComponent;

  @ViewChild(SideMenuComponent, {static: false})
  private sideMenuComponent: SideMenuComponent;
  domain;
  isMatched;
  menuItems: MenuItem[];
  userItems: MenuItem[];
  domains: DomainCollectionModelLegacy;
  domainItems: MenuItem[];
  visibleSidebar;

  currentDomain: number;
  defaultDomain: number;
  domainList: {
    domainName: string,
    domainId: number
  }[] = [];

  loading = false;
  isAdmin = false;

  constructor(private router: Router,
              private session: Session,
              private homeComponent: HomeComponent,
              private sessionService: SessionService,
              public breakpointObserver: BreakpointObserver,
              private authService: AuthService,
              private domainService: DomainService,
              private translate: TranslateService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.translate.get(['menu.users', 'menu.domains', 'menu.modeling', 'menu.tasks.title', 'menu.tasks.hold',
      'menu.tasks.ack', 'menu.tasks.canceled', 'menu.tasks.closed', 'menu.snapshots.title', 'menu.snapshots.new',
      'menu.snapshots.processed', 'menu.snapshots.project', 'menu.areas', 'menu.parameters.title', 'menu.parameters.other', 'menu.parameters.config-codes',
      'menu.parameters.config-groups', 'menu.parameters.process', 'menu.parameters.roles', 'menu.parameters.process-types', 'menu.tools.title',
      'menu.tools.config', 'menu.tools.masterdata', 'menu.tools.deduplicate', 'menu.tools.rules', 'menu.pricing-grids', "locale.title", "locale.fr", "locale.en"
    ])
      .subscribe(translations => {
        this.menuItems = [
          {
            label: translations['menu.users'],
            icon: 'pi pi-users',
            routerLink: ['users/usersList'],
            visible: this.homeComponent.visibleUsers
          },
          {
            label: translations['menu.domains'],
            icon: 'pi pi-sitemap',
            routerLink: ['domain'],
            visible: this.homeComponent.visibleDomain
          },
          {
            label: translations['menu.modeling'],
            icon: 'pi pi-fw pi-refresh',
            routerLink: ['modeling'],
            visible: this.homeComponent.visibleModeling
          },
          {
            label: translations['menu.tasks.title'], icon: 'pi pi-list', items: [
              {label: translations['menu.tasks.hold'], routerLink: ['processing/onHoldList']},
              {label: translations['menu.tasks.ack'], routerLink: ['processing/ackList']},
              {label: translations['menu.tasks.canceled'], routerLink: ['processing/cancelList']},
              {label: translations['menu.tasks.closed'], routerLink: ['processing/closeList']}
            ],
            visible: this.homeComponent.visibleProcessing
          },
          {
            label: translations['menu.snapshots.title'], icon: 'pi pi-camera', items: [
              {label: translations['menu.snapshots.new'], routerLink: ['snapshot/snapshotList']},
              {label: translations['menu.snapshots.processed'], routerLink: ['snapshot/snapshotProcessed']},
              {label: translations['menu.snapshots.project'], routerLink: ['snapshot/snapshotProject']}
            ],
            visible: this.homeComponent.visibleSnapshots
          },
          {
            label: translations['menu.areas'], icon: 'pi pi-globe', routerLink: ['areas/areasList']
          },
          {
            label: translations['menu.parameters.title'], icon: 'pi pi-cog', items: [
              {label: translations['menu.parameters.other'], routerLink: ['settings/settingsConfig']},
              {
                label: translations['menu.parameters.config-codes'],
                routerLink: ['settings/settingsLists', 'CONFIG_CODE']
              },
              {
                label: translations['menu.parameters.config-groups'],
                routerLink: ['settings/settingsLists', 'CONFIG_GROUP']
              },
              {label: translations['menu.parameters.process'], routerLink: ['settings/settingsLists', 'PROCESS']},
              {label: translations['menu.parameters.roles'], routerLink: ['settings/settingsLists', 'ROLE']},
              {
                label: translations['menu.parameters.process-types'],
                routerLink: ['settings/settingsLists', 'PROCESS_TYPE']
              },
            ],
            visible: this.homeComponent.visibleSettings
          },
          {
            label: translations['menu.tools.title'], icon: 'pi pi-pencil', items: [
              {label: translations['menu.tools.config'], routerLink: ['tools/changeConfig']},
              {label: translations['menu.tools.masterdata'], routerLink: ['tools/masterdata']},
              {label: translations['menu.tools.deduplicate'], routerLink: ['tools/deduplicate']},
              {label: translations['menu.tools.rules'], routerLink: ['tools/rules']},
            ],
            visible: this.homeComponent.visibleTools
          },
          {
            label: translations['menu.pricing-grids'],
            icon: 'pi pi-money-bill',
            routerLink: ['grids'],
            visible: this.homeComponent.visibleGrids
          }
        ]
      });

    this.domainService.getDefaultDomain().subscribe(d => {
      this.defaultDomain = d.defaultDomain;
      const decodedToken = jwt(this.session.token);
      this.isAdmin = decodedToken["https://www.citymagine.com/isAdmin"] || false;
      const currentDomainFromToken =  decodedToken["https://www.citymagine.com/domainId"] || 1;
      this.sessionService.getDomains().subscribe(domains => {
        if (currentDomainFromToken) {
          this.currentDomain = domains.domains.find(p => p.domainId === currentDomainFromToken).domainId;
          DomainService.currentDomainId = this.currentDomain;
        } else {
          this.currentDomain = domains.domains.find(p => p.domainId === this.defaultDomain).domainId;
          DomainService.currentDomainId = this.currentDomain;
        }
        this.domain = domains.domains.find(p => p.domainId === this.currentDomain).domainName;
        // tslint:disable-next-line:no-shadowed-variable
        this.domainList = domains.domains.map(d => ({domainId: d.domainId, domainName: d.domainName}));
      });
    });
    this.breakpointObserver
      .observe(['(min-width: 1023px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
          // this.menuItems = this.sideMenuComponent.items;
        }
      });
  }

  logout() {
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + '//' + getUrl.host;
    this.authService.logout({
      returnTo: baseUrl,
    });
    this.router.navigate(['']);
  }

  addDomainFunction() {
    this.messageService.add({key: 'bl', severity: 'success', summary: 'Domaine créé'});
  }

  onAddDomain() {
    this.domainAdd.display = true;
  }

  selectCurrentDomain(domainCode: number) {
    if (domainCode !== this.currentDomain) {
      this.loading = true;
      this.domainService.setCurrentDomain(domainCode).subscribe((_res) => {
        this.authService.getAccessTokenSilently({audience: environment.auth.audience, ignoreCache: true})
          .subscribe(() => {
            window.location.reload();
          });
      }, (_) => this.loading = false);
    }
  }

  selectDefaultDomain(defaultDomainCode: number) {
    if (defaultDomainCode !== this.defaultDomain) {
      this.loading = true;
      this.domainService.setDefautDomain(defaultDomainCode).subscribe(res => {
        this.defaultDomain = defaultDomainCode;
        this.loading = false;
      }, (_) => this.loading = false);
    }
  }
  changeLocale(locale: string) {
    this.translate.use(locale);
    window.localStorage.setItem('locale', locale);
  }
}
