import {Component, OnInit, ViewChild} from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService, SelectItemGroup} from 'primeng/api';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {MasterDataService} from '../../../services/master-data.service';
import {ConfigCollectionModel} from '../../../models/masterData/config-collection-model';
import {ConfigModel} from '../../../models/masterData/config-model';

@Component({
  selector: 'app-user-settings',
  templateUrl: './change-config.component.html',
  styleUrls: ['./change-config.component.css']
})

export class ChangeConfigComponent implements OnInit {

  public editorOptions: JsonEditorOptions;
  public data: any;
  public newData: any;
  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;

  config: any;
  configGroup: string;
  groupConfig: any;
  groupedConfig: SelectItemGroup[];
  infoData: ConfigModel;
  isMatched: boolean;
  searchForm: FormGroup;
  selectedConfig: string;
  validSearch: boolean;

  constructor(public breakpointObserver: BreakpointObserver, private masterDataService: MasterDataService,
              private formBuilder: FormBuilder, private messageService: MessageService) {
    this.groupedConfig = [];
    this.masterDataService.getAllConfig().subscribe( (resp: ConfigCollectionModel) => {
      if (resp) {
        resp.configurations.forEach( config => {
          if (config.configGroup !== this.configGroup) {
            if (this.groupConfig) {
              this.groupedConfig.push(this.groupConfig);
            }
            this.groupConfig = {};
            this.groupConfig.items = [];
            this.groupConfig.label = config.configGroup;
            this.groupConfig.value = config.configGroup;
            this.config = {};
            this.config.label = config.configCode;
            this.config.value = config.configCode;
            this.groupConfig.items.push(this.config);
            this.configGroup = config.configGroup;
            if (this.configGroup === 'WARPING_CONFIG') {
              this.groupedConfig.push(this.groupConfig);
              this.groupConfig = {};
              this.groupConfig.items = [];
              this.config = {};
            }
          } else {
            this.config = {};
            this.config.label = config.configCode;
            this.config.value = config.configCode;
            this.groupConfig.items.push(this.config);
          }
        });
      }
    });
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'tree', 'view'];
  }

  ngOnInit() {
    this.validSearch = false;
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        this.isMatched = !state.matches;
      });
    this.searchForm = this.formBuilder.group({
      configGroup: ['', Validators.required],
    });
  }

  getConfiguration() {
    this.masterDataService.getConfigurations(this.selectedConfig).subscribe((resp: ConfigCollectionModel) => {
      if (resp.configurations[0].valueJson) {
        this.infoData = resp.configurations[0];
        this.data = this.infoData.valueJson;
        this.validSearch = true;
      } else if (resp.configurations[0].valueText) {
        this.infoData = resp.configurations[0];
        this.data = {};
        this.data.valueText = this.infoData.valueText;
        this.validSearch = true;
      } else {
        this.infoData = resp.configurations[0];
        this.infoData.valueJson = JSON.parse('{}');
        this.data =
        this.validSearch = true;
      }
    });
  }
  getData(event) {
    console.log(event);
    this.newData = event;
  }
  updateConfiguration() {
    if (this.data.valueText) {
      this.masterDataService.updateConfigurationText(this.selectedConfig, this.data.valueText).subscribe(resp => {
        // console.log(resp);
        if (resp) {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Configuration mise à jour'});
        }
      });
    } else {
      this.data = this.newData;
      this.masterDataService.updateConfigurationJson(this.selectedConfig, this.data).subscribe(resp => {
        if (resp) {
          this.messageService.add({key: 'bl', severity: 'success', summary: 'Configuration mise à jour'});
        }
      });
    }
  }
  get f() {
    return this.searchForm.controls;
  }
}
