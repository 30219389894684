 <div class="timeline" *ngFor="let data of dataTimeLine; let i = index">
    <div class="container"
         [ngClass]="{ 'right': data.eventStatusCode.includes('WIP'),
                          'left': !data.eventStatusCode.includes('WIP')}">
      <div *ngIf="data.eventStatus.statusCode" class="content">
        <p class="statusFont" (click)="clickEvent($event, i)">{{data.eventStatus.statusName}}</p>
        <p class="fontReason"><span>{{data.eventStatusReason}}</span></p>
        <p class="font"><span>{{data.eventDate | date:'dd/MM/yyyy HH:mm':'UTC'}}</span></p>
        <p *ngIf="data.eventDuration" class="durationFont">{{data.eventDuration}}</p>
      </div>
      <div *ngIf="!data.eventStatus.statusCode" class="content">
        <p class="errorDb">Missing info in database</p>
        <p class="font">{{data.eventStatusCode}}</p>
      </div>
    </div>
  </div>
  <p-chart type="doughnut" class="doughnut" [data]="data" height="300px" [options]="options"></p-chart>

