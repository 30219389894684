<div class="ui-g globalHeight">
  <div class="ui-g-12 ui-md-2 rightCol">
    <p-menu [model]="items"></p-menu>
  </div>
  <div class="ui-g-12 ui-md-10 ui-md-offset-3">
    <app-users-edit-modify [currentUser]="currentUser"  *ngIf="displayOne === 'baseEdit'"></app-users-edit-modify>
    <app-users-edit-roles  [currentUser]="currentUser"  *ngIf="displayOne === 'roleEdit'"></app-users-edit-roles>
    <app-users-edit-location  [currentUser]="currentUser"  *ngIf="displayGeo"></app-users-edit-location>
    <app-users-edit-grids  [currentUser]="currentUser"  *ngIf="displayOne === 'gridEdit'"></app-users-edit-grids>
  </div>
</div>
