import {Component, OnInit} from '@angular/core';
import {SnapshotModel} from '../../../models/processing/snapshot-model';
import {ProcessingService} from '../../../services/processing.service';
import {MessageService} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TagModel} from '../../../models/processing/tag-model';

@Component({
  selector: 'app-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.css']
})
export class AddTagComponent implements OnInit {

  displayTag: boolean;
  editForm: FormGroup;
  selectedSnapshots: SnapshotModel[];
  tags: string[];

  constructor(private processingService: ProcessingService,
              private messageService: MessageService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.displayTag = false;
    this.tags = [];
    this.editForm = this.formBuilder.group({
      tags: ['', Validators.required]
    });
  }

  onAddTag() {
    this.tags = this.f.tags.value;
    this.selectedSnapshots = JSON.parse(localStorage.getItem('selectedSnapshot'));

    this.selectedSnapshots.forEach( snapshot => {
      let counter = 0;
      this.tags.forEach( tag => {
        if (snapshot.snapshotTags) {
          if (snapshot.snapshotTags.tags.indexOf(tag) === -1) {
            snapshot.snapshotTags.tags.push(tag);
            if (counter === this.tags.length - 1) {
              this.updateSnapshot(snapshot);
            }
          }
        } else {
          snapshot.snapshotTags = new TagModel();
          snapshot.snapshotTags.tags = this.f.tags.value;
          this.updateSnapshot(snapshot);
        }
        counter++;
      });
    });
    this.displayTag = false;
  }

  updateSnapshot(snapshot) {
    this.processingService.updateSnapshot(snapshot).subscribe( resp => {
      if (resp) {
        this.messageService.add({key: 'bl', severity: 'success', summary: 'Étiquette ajoutée',
          detail: snapshot.snapshotName});
      }
    });
  }

  onCancel() {
    this.displayTag = false;
  }
  onHide() {
    this.displayTag = false;
  }
  get f() {
    return this.editForm.controls;
  }
}
