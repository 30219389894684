<h2>{{"sectors.list" | translate}}</h2>
<hr>
<p-paginator [rows]="12" [totalRecords]="totalCount" [rowsPerPageOptions]="[12, 25, 50]" (onPageChange)="paginate($event)" #p></p-paginator>
<p *ngIf="!isMatched" class="countRow">{{"sectors.count" | translate}} : {{totalCount}}</p>
<button pButton type="button" icon="fa fa-plus" (click)="onAddArea()" style="display:block;"
        class="ui-button-primary marginLeft"></button>

<p-tree (onNodeExpand)="onNodeExpand($event)" [value]="_treeNode"  [loading]="loading" >
  <ng-template let-node  pTemplate="default">
    <div class="node">
      <div class="node-name">{{node.data.name}}</div>
      <div class="node-actions">
        <button pButton type="button" icon="fa fa-edit" (click)="onAddArea(node.data.id, node.data.parentId)" style="display:block;"
                class="ui-button-success marginLeft"></button>
        <button pButton type="button" icon="fa fa-times" (click)="onDeleteArea(node.data.id, node.data.name)" style="display:block;"
                class="ui-button-danger marginLeft"></button>
      </div>
    </div>
  </ng-template>

</p-tree>
<p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
<p *ngIf="!_treeNode || _treeNode.length === 0" class="disclaimer">{{"sectors.no-results" | translate}}</p>
