import { Component, OnInit } from '@angular/core';
import {MessageService} from 'primeng/api';
import {GeojsonService} from '../../../services/geojson.service';
import {AsyncService} from '../../../services/async.service';
import {MasterDataService} from '../../../services/master-data.service';
import {ProcessingService} from '../../../services/processing.service';
import {SecurityService} from '../../../services/security.service';
import {SessionService} from '../../../services/session.service';
import {TaskService} from '../../../services/task.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.css']
})
export class ToastsComponent implements OnInit {

  toastMessageError: string;
  toastSummaryError: string;

  constructor(private messageService: MessageService, private geojsonService: GeojsonService,
              private asyncService: AsyncService,
              private masterDataService: MasterDataService,
              private processingService: ProcessingService,
              private securityService: SecurityService,
              private sessionService: SessionService,
              private taskService: TaskService,
  ) { }

  ngOnInit() {
    this.geojsonService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.error.message;
      this.toastSummaryError = error.error.code + ' - ' + error.error.status;
      this.showToastError();
    });
    this.asyncService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.error.message;
      this.toastSummaryError = error.error.code + ' - ' + error.error.status;
      this.showToastError();
    });
    this.masterDataService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.error.message;
      this.toastSummaryError = error.error.code + ' - ' + error.error.status;
      this.showToastError();
    });
    this.processingService.ErrorMessage.subscribe( error => {
      console.log(error);
      this.toastMessageError = error.error.message;
      this.toastSummaryError = error.error.code + ' - ' + error.error.status;
      this.showToastError();
    });
    this.securityService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.error.message;
      this.toastSummaryError = error.error.code + ' - ' + error.error.status;
      this.showToastError();
    });
    this.sessionService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.error.message;
      this.toastSummaryError = error.error.code + ' - ' + error.error.status;
      this.showToastError();
    });
    this.taskService.ErrorMessage.subscribe( error => {
      this.toastMessageError = error.error.message;
      this.toastSummaryError = error.error.code + ' - ' + error.error.status;
      this.showToastError();
    });
  }

  showToastError() {
    this.messageService.add({severity: 'error', summary: this.toastSummaryError, detail: this.toastMessageError});
  }
}
