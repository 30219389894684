import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnapshotService} from '../../services/snapshot.service';
import {MasterDataService} from '../../../../services/master-data.service';
import {StatusCollectionModel} from '../../../../models/masterData/status-collection-model';
import {ProcessingService} from '../../../../services/processing.service';
import {StatusModel} from '../../../../models/masterData/status-model';

@Component({
  selector: 'app-snapshot-search-form',
  templateUrl: './snapshot-search-form.component.html',
  styleUrls: ['./snapshot-search-form.component.css']
})
export class SnapshotSearchFormComponent implements OnInit {

  arrayStatus: StatusModel[];
  car360Check: boolean;
  display: boolean;
  fr: any;
  maxDateValue: Date;
  rangeDatesSnapshot: Date[];
  rangeDatesStatus: Date[];
  reflectoCheck: boolean;
  searchForm: FormGroup;
  selectedCar360: string;
  selectedReflecto: string;
  selectedType: string[];
  selectedStatus: StatusModel;
  statusCheck: boolean;
  statusList: StatusModel[];
  @Output() closeSnapshotSearchFormComponent: EventEmitter<boolean> = new EventEmitter();

  constructor(private snapshotService: SnapshotService, private formBuilder: FormBuilder,
              private masterDataService: MasterDataService,
              private processingService: ProcessingService) {
    this.arrayStatus = [];
    this.masterDataService.getStatusList().subscribe(
      (resp: StatusCollectionModel) => {
        if (resp) {
          this.arrayStatus = resp.statusList;
          this.statusList = [];
          this.statusList = this.arrayStatus;
          // console.log(this.statusList);
        }
      }
    );
  }

  ngOnInit() {
    this.display = false;
    this.searchForm = this.formBuilder.group({
      statusCode: ['', Validators.required],
      checkedCar360: ['', Validators.required],
      checkedReflecto: ['', Validators.required],
      checkedStatus: ['', Validators.required],
      identifier: ['', Validators.required],
      rangeDateSnapshot: ['', Validators.required],
      rangeDateStatus: ['', Validators.required],
      research: ['', Validators.required]
    });
    this.reflectoCheck = true;
    this.car360Check = true;
    this.selectedCar360 = 'CAR_360';
    this.selectedReflecto = 'REFLECTO';
    this.maxDateValue = new Date();
    this.processingService.snapshotName = '';
    this.processingService.snapshotIdentifier = null;
    this.processingService.statusCode = '';
    this.processingService.statusCodeContains = '';
    this.processingService.snapshotDateBegin = null;
    this.processingService.statusDateBegin = null;
    this.processingService.snapshotDateEnd = null;
    this.processingService.statusDateEnd = null;

    this.fr = {
      closeText: 'Fermer',
      prevText: 'Précédent',
      nextText: 'Suivant',
      today: 'Aujourd\'hui',
      clear: 'Reset',
      monthNames: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
      monthNamesShort: [ 'janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin',
        'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.' ],
      dayNames: [ 'dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi' ],
      dayNamesShort: [ 'dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.' ],
      dayNamesMin: [ 'D', 'L', 'M', 'M', 'J', 'V', 'S' ],
      weekHeader: 'Sem.',
      dateFormat: 'dd/mm/yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
    };
  }
  get f() {
    return this.searchForm.controls;
  }
  onHide() {
    this.display = false;
    this.closeSnapshotSearchFormComponent.emit(false);
  }
  searchSnapshots() {
    this.snapshotService.isSearch = true;
    this.processingService.snapshotName = this.f.research.value;
    this.processingService.snapshotIdentifier = this.f.identifier.value;
    if (this.selectedStatus && this.f.checkedStatus.value) {
      if (this.f.checkedStatus.value[0].statusCode === this.f.statusCode.value.statusCode) {
        this.statusCheck = !this.statusCheck;
        this.processingService.statusCodeContains = this.selectedStatus.statusCode;
        this.processingService.statusCode = '';
      } else {
        this.processingService.statusCode = this.selectedStatus.statusCode;
        this.processingService.statusCodeContains = '';
      }
    } else {
      this.processingService.statusCode = '';
      this.processingService.statusCodeContains = '';
    }
    const today = new Date().toISOString();
    if (this.rangeDatesSnapshot) {
      this.processingService.snapshotDateBegin = this.rangeDatesSnapshot[0].toISOString();
      // console.log(this.processingService.snapshotDateBegin);
      if (!this.rangeDatesSnapshot[1]) {
        this.processingService.snapshotDateEnd = today;
      } else {
        this.processingService.snapshotDateEnd = this.rangeDatesSnapshot[1].toISOString();
        // console.log(this.processingService.snapshotDateEnd);
      }
    } else {
      this.processingService.snapshotDateBegin = null;
      this.processingService.snapshotDateEnd = null;
    }
    if (this.rangeDatesStatus) {
      this.processingService.statusDateBegin = this.rangeDatesStatus[0].toISOString();
      if (!this.rangeDatesStatus[1]) {
        this.processingService.statusDateEnd = today;
      } else {
        this.processingService.statusDateEnd = this.rangeDatesStatus[1].toISOString();
      }
    } else {
      this.processingService.statusDateBegin = null;
      this.processingService.statusDateEnd = null;
    }
    if (!this.car360Check || !this.reflectoCheck) {
      if (this.car360Check) {
        this.processingService.snapshotType = this.selectedCar360;
      } else if (this.reflectoCheck) {
        this.processingService.snapshotType = this.selectedReflecto;
      } else {
        this.car360Check = true;
        this.reflectoCheck = true;
        this.processingService.snapshotType = '';
      }
    } else {
      this.processingService.snapshotType = '';
    }
    this.display = false;
  }
}
