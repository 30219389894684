<form [formGroup]="editForm" (ngSubmit)="onSubmitEditUser()"
      class="globalSearch ui-g ui-fluid snapshotForm">
  <div class="appendFormName ui-g-12">
    <p class="labels">{{'users.edit.email' | translate}}</p>
    <div class="form-group ui-inputgroup searchName">
      <input type="email" pInputText  formControlName="userEmail"
             class="form-control ui-g-12" disabled="true">
    </div>
  </div>
  <div class="appendFormName ui-g-12">
    <p class="labels">{{'users.edit.firstname' | translate}}</p>
    <div class="form-group ui-inputgroup searchName">
      <input type="text" pInputText  formControlName="userFirstName"
             class="form-control ui-g-12">
    </div>
  </div>
  <div class="appendFormName ui-g-12">
    <p class="labels">{{'users.edit.lastname' | translate}}</p>
    <div class="form-group ui-inputgroup searchName">
      <input type="text" pInputText  formControlName="userLastName"
             class="form-control ui-g-12">
    </div>
  </div>
  <div class="appendFormName ui-g-12">
    <p class="labels">{{'users.edit.admin' | translate}}</p>
    <div class="form-group ui-inputgroup">
      <input type="checkbox" formControlName="userAdmin" class="form-control ui-g-0">
    </div>
  </div>
  <div class="submitButton ui-inputgroup">
    <button pButton type="submit" label="{{'users.edit.modify' | translate}}"></button>
  </div>
</form>
