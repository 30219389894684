<div class="ui-g globalHeight">
  <div class="ui-g-12 ui-md-2 rightCol">
    <p-menu [model]="items"></p-menu>
  </div>
  <div class="ui-g-12 ui-md-10 ui-md-offset-3">
    <app-masterdata-edit-details #editDetailComponent [currentMasterdata]="currentMasterdata"  *ngIf="displayOne === 'detailsEdit'"></app-masterdata-edit-details>
<!--
     <app-masterdata-edit-icon [currentMasterdata]="currentMasterdata"  *ngIf="displayOne === 'roleEdit'"></app-masterdata-edit-icon>
-->
    <app-masterdata-edit-properties #editPropertiesComponent [currentMasterdata]="currentMasterdata"  *ngIf="displayOne === 'propertiesEdit'"></app-masterdata-edit-properties>
<!--
    <app-masterdata-edit-advanced [currentMasterdata]="currentMasterdata"  *ngIf="displayOne === 'gridEdit'"></app-masterdata-edit-advanced>
-->
  </div>
</div>
