import {Component, Input, OnInit} from '@angular/core';

interface Diff {
  sec: number;
  min: number;
  hour: number;
}

@Component({
  selector: 'app-snapshot-event-status',
  templateUrl: './snapshot-event-status.component.html',
  styleUrls: ['./snapshot-event-status.component.css']
})

export class SnapshotEventStatusComponent implements OnInit {

  @Input() currentSnapshot: any;

  arrayEvents: any[];
  arrayObjTemp: any[];
  data: any;
  dataTimeLine: any;
  objTemp: any;
  index: number;
  options: any;

  infoArray: any [];
  labelArray: string [];
  colors: string [];
  colorArray: any = ['#c6acc9', '#ecb4bf', '#fdd6b5', '#fdf3b8', '#a7e4ae', '#bfffe6', '#47d1d5', '#efe6eb',
    '#47d1d5', '#bfffe6', '#a7e4ae', '#fdf3b8', '#fdd6b5', '#ecb4bf', '#c6acc9', '#efe6eb'];

  static setGraphicData(data) {
    return  {
      labels: data.labels,
      datasets: [
        {
          data: data.datasets[0].data,
          backgroundColor:  data.datasets[0].backgroundColor,
          hoverBackgroundColor: data.datasets[0].backgroundColor
        }]
    };
  }

  static dateConversion(tmp) {
    const diff: Diff = {
      sec: 0,
      min: 0,
      hour: 0,
    };
    let diff2;
    // tmp = Math.floor(tmp / 1000);
    diff.sec = tmp % 60;
    tmp = Math.floor((tmp - diff.sec) / 60);
    diff.min = tmp % 60;
    tmp = Math.floor((tmp - diff.min) / 60);
    diff.hour = tmp % 24;
    if (diff.hour === 0) {
      diff2 = diff.min + 'min' + diff.sec + 'sec';
    } else {
      diff2 = diff.hour + 'h' + diff.min + 'min' + diff.sec + 'sec';
    }
    return diff2;
  }
  constructor() { }

  ngOnInit() {
    this.data = {...this.getGraphicData(this.currentSnapshot.snapshotEvents)};
    this.dataTimeLine = this.currentSnapshot.snapshotEvents;
    let a = 0;
    this.arrayEvents = [];
    this.dataTimeLine.forEach((event) => {
      if (this.data.datasets[0].data[a]) {
        event.eventDuration = SnapshotEventStatusComponent.dateConversion(this.data.datasets[0].data[a]);
      }
      a++;
      this.arrayEvents.push(event);
    });
    this.dataTimeLine = this.arrayEvents.reverse();
    this.options = {
      legend: {
        display: false,
      }
    };
    this.arrayObjTemp = [];
  }

  clickEvent(event, i) {
    const getElement = event.srcElement.innerHTML;
    event.srcElement.classList.add('selected');
    if (this.dataTimeLine[i].eventStatus.statusName === getElement && !this.arrayObjTemp[i]) {
      this.objTemp = {};
      this.index = i;
      this.objTemp.datas = this.data.datasets[0].data[this.index];
      this.objTemp.index = this.index;
      this.objTemp.label = this.data.labels[this.index];
      this.objTemp.color = this.data.datasets[0].backgroundColor[this.index];
      this.arrayObjTemp[i] = this.objTemp;
      this.data.datasets[0].data[this.index] = undefined;
      this.data.datasets[0].backgroundColor[this.index] = undefined;
      this.data.labels[this.index] = undefined;
      this.data = SnapshotEventStatusComponent.setGraphicData(this.data);
    } else {
      const obj = this.arrayObjTemp[i];
      const label = obj.label;
      const data = obj.datas;
      const color = obj.color;
      const index = obj.index;
      this.data.datasets[0].data[index] = data;
      this.data.datasets[0].backgroundColor[index] = color;
      this.data.labels[index] = label;
      this.arrayObjTemp[i] = false;
      this.data = SnapshotEventStatusComponent.setGraphicData(this.data);
      event.srcElement.classList.remove('selected');
    }
  }

  getGraphicData(data) {

    this.infoArray = [];
    this.labelArray = [];
    this.colors = [];
    let k = 0;

    for (let i = 0; i < data.length; i++) {
      for (let j = i; j > -1; j--) {
        if (data[i].eventStatus.statusPreviousCode === data[j].eventStatusCode) {
          // used if we want to avoid duplicates between failed* and his previous status code
          // if ( i + 1 < data.length && !data[i + 1].eventStatusCode.includes('FAIL')) {
          this.labelArray[i] = data[i].eventStatusCode;
          this.infoArray[i] = Math.trunc((+new Date(data[i].eventDate)) / 1000 - (+new Date(data[j].eventDate)) / 1000);
          this.colors[i] = this.colorArray[k];
          k++;
          if (k === this.colorArray.length) {
            k = 0;
          }
          j = -1;
          // }
        }
      }
    }
    return   {
      labels: this.labelArray,
      datasets: [
        {
          data: this.infoArray,
          backgroundColor: this.colors,
          hoverBackgroundColor: this.colors
        }]
    };
  }
}
