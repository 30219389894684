import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SnapshotService} from '../services/snapshot.service';
import {MapFunctionsService} from '../services/mapfunctions.service';
import {environment} from '../../../../environments/environment';
import {Session} from '../../../globals/session';
import {GeojsonService} from '../../../services/geojson.service';
import {ProcessingService} from '../../../services/processing.service';
import {MapImageEnum} from '../../../enums/map-image-enum.enum';
import {MapLayerEnum} from '../../../enums/map-layer-enum.enum';
import {MapSourceEnum} from '../../../enums/map-source-enum.enum';
import {MasterDataService} from '../../../services/master-data.service';

@Component({
  selector: 'app-snapshot-map',
  templateUrl: './snapshot-map.component.html',
  styleUrls: ['./snapshot-map.component.css']
})

export class SnapshotMapComponent implements OnInit, OnDestroy {

  stepInterval = 0;
  Ground: boolean;
  PR: boolean;
  Sign: boolean;
  Streetlight: boolean;
  Journey: boolean;
  Viewer: boolean;
  Satellite: boolean;
  iFrameUrl = '';
  iFrameHidden = true;
  currentSnapshot: any;
  snapshotList: boolean;
  riverSign: boolean;

  constructor(private router: Router, private geojsonService: GeojsonService, private snapshotService: SnapshotService,
              private mapFunctionsService: MapFunctionsService, private session: Session,
              private processingService: ProcessingService, private masterDataService: MasterDataService) {
  }

  ngOnInit() {
    this.mapFunctionsService.onInitMap();
    this.snapshotService.currentTransferSnapshot.subscribe(snapshot => this.currentSnapshot = snapshot);
    if (!this.currentSnapshot || this.currentSnapshot === 'none') {
      this.currentSnapshot = JSON.parse(window.localStorage.getItem('localSnapshot'));
    }
    this.mapFunctionsService.map.on('style.load', () => {
      const waiting = () => {
        if (!this.mapFunctionsService.map.isStyleLoaded()) {
          setTimeout(waiting, 200);
        } else {
          this.Journey = true;
          this.geojsonService.getSnapshotCollection(this.currentSnapshot).subscribe(resp => {
            if (resp) {
              this.mapFunctionsService.onLoadSnapshot(resp);
              if (this.mapFunctionsService.typeCoord === 'Point') {
                this.currentSnapshot.disableJPR = true;
              } else {
                this.mapFunctionsService.onJourney();
                this.stepInterval = window.setInterval(() => {
                  this.mapFunctionsService.carAnimation();
                }, 500);
              }
            }
          });
        }
      };
      waiting();
    });

    const idsImage = [];
    this.mapFunctionsService.map.on('styleimagemissing', (e) => {
      const id = e.id; // id of the missing image

      if (!idsImage.includes(id)) {
        idsImage.push(id);
        const elementClass = id.split('.');
        this.mapFunctionsService.loadImage(id, environment.urlMedia + '/v3/icon/' + elementClass[0] + '-'
          + elementClass[1] + '/element/' + elementClass[elementClass.length - 1] + '.png');
      }
    });

    this.snapshotList = this.processingService.snapshotList;

    this.mapFunctionsService.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_D, MapImageEnum.URL_LOCATION_MARKER_Y);
    this.mapFunctionsService.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_A, MapImageEnum.URL_LOCATION_MARKER_R);
    this.mapFunctionsService.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_N, MapImageEnum.URL_LOCATION_MARKER_R);
    this.mapFunctionsService.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_M, MapImageEnum.URL_LOCATION_MARKER_R);
    this.mapFunctionsService.loadImage(MapImageEnum.IMAGE_LOCATION_MARKER_P, MapImageEnum.URL_LOCATION_MARKER_R);
  }

  ngOnDestroy() {
    clearInterval(this.stepInterval);
  }

  onChangeJourney() {
    if (this.Journey) {
      this.mapFunctionsService.onJourney();
      this.stepInterval = window.setInterval(() => {
        this.mapFunctionsService.carAnimation();
      }, 500);
    } else if (!this.Journey) {
      this.mapFunctionsService.map.removeLayer('line-snapshot');
      this.mapFunctionsService.map.removeLayer('point');
      this.mapFunctionsService.map.removeSource('point');
      this.mapFunctionsService.map.removeSource('line-snapshot');
      clearInterval(this.stepInterval);
    }
  }

  onChangePR() {

    if (this.PR) {
      const tileUrl = environment.urlTilesPR;

      const layoutIcon = {
        'icon-image': 'PR-{pr_way_type}',
        'icon-size': 0.45,
        'icon-rotate': ['get', 'bearing'],
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      };

      const layoutLabel = {
        'text-field': '{pr_way_name}\n{pr_code}',
        'text-font': [
          'DIN Offc Pro Medium',
          'Arial Unicode MS Bold'
        ],
        'text-size': 10
      };

      this.mapFunctionsService.addSymbolLabelLayerTiles(MapLayerEnum.LAYER_LOCATION_MARKER, tileUrl, MapSourceEnum.SOURCE_LAYER_MARKER, MapSourceEnum.SOURCE_LOCATION_MARKER, true, layoutIcon, layoutLabel);
    } else {
      this.mapFunctionsService.removeLayer(MapLayerEnum.LAYER_LOCATION_MARKER + 'Label');
      this.mapFunctionsService.removeLayer(MapLayerEnum.LAYER_LOCATION_MARKER, MapSourceEnum.SOURCE_LOCATION_MARKER);
    }
  }

  onChangeSatellite() {
    if(this.Satellite){
      this.mapFunctionsService.addRasterLayer(MapLayerEnum.LAYER_SATELLITE, MapSourceEnum.SOURCE_SATELLITE, environment.mapStyleSatellite);
      this.mapFunctionsService.moveLayerFirst(MapLayerEnum.LAYER_SATELLITE);
    } else {
      this.mapFunctionsService.removeLayer(MapLayerEnum.LAYER_SATELLITE, MapSourceEnum.SOURCE_SATELLITE);
    }
  }

  onChangeViewer() {
    if (this.Viewer) {
        const tileUrl = environment.urlTilesViews + '?viewType=PANO_360_M' + '&snapshotIdentifier=' + this.currentSnapshot.snapshotIdentifier;

        const layout = {
          'icon-image': MapImageEnum.IMAGE_CAMERA,
          'icon-size': 0.15,
        };

        this.mapFunctionsService.addSymbolLayerTiles(MapLayerEnum.LAYER_IMAGE, tileUrl, MapSourceEnum.SOURCE_IMAGE, true, layout, 'PANO_360_M');

        this.mapFunctionsService.map.on('click', MapLayerEnum.LAYER_IMAGE, e => {
          const token = this.session.token;
          const mode = 'PANO_360_M';
          const showCompass = 'true';
          const showMesureTool = 'true';
          const showViewMetadata = 'true';
          const showNextView = 'true';
          const showResolutionImage = 'true';
          const showSettings = 'true';
          this.iFrameUrl = environment.urlCityview + '?viewIdentifier=' + e.features[0].properties['view_identifier']
            + '&mode=' + mode + '&token=' + token + '&showViewMetadata=' + showViewMetadata + '&showNextView=' + showNextView
            + '&showMesureTool=' + showMesureTool + '&showCompass=' + showCompass + '&showResolutionImage='
            + showResolutionImage + '&showSettings=' + showSettings;
          this.iFrameHidden = false;
        });
      } else {
        this.mapFunctionsService.removeLayer(MapLayerEnum.LAYER_IMAGE, MapSourceEnum.SOURCE_IMAGE);
        this.iFrameHidden = true;
    }
  }

  onChangeSign() {
    const elementType = 'SIGN';
    this.loadOrUnloadLayer(elementType, this.Sign);
  }

  onChangeRiverSign(){
    const elementType = 'RIVER';
    this.loadOrUnloadLayer(elementType, this.riverSign)
  }

  loadOrUnloadLayer(elementType: string, enabled: boolean){
    let tileUrl = environment.urlTilesElements;
    tileUrl = tileUrl + '?elementType=' + elementType + '&snapshotIdentifier=' + this.currentSnapshot.snapshotIdentifier;
    if (enabled) {

      const layout = {
        'icon-image': '{element_class}',
        'icon-size': 0.25,
        'icon-rotate': ['get', 'bearing'],
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      };
      const currentLayer = MapLayerEnum.LAYER_ELEMENT + elementType;
      this.mapFunctionsService.addSymbolLayerTiles(currentLayer, tileUrl, MapSourceEnum.SOURCE_ELEMENT, true, layout, elementType);
    } else {
      this.mapFunctionsService.removeLayer(MapLayerEnum.LAYER_ELEMENT + elementType, MapSourceEnum.SOURCE_ELEMENT);
    }
  }

 onChangeStreetlight() {
    const elementType = 'STREETLIGHT';
    let tileUrl = environment.urlTilesElements;
    tileUrl = tileUrl + '?elementType=' + elementType + '&snapshotIdentifier=' + this.currentSnapshot.snapshotIdentifier;
    if (this.Streetlight) {

      const paint = {
        'circle-color': '#0dcc33',
        'circle-radius': 3
      };

      const currentLayer = MapLayerEnum.LAYER_ELEMENT + elementType;
      this.mapFunctionsService.addCircleLayerTiles(currentLayer, tileUrl, MapSourceEnum.SOURCE_ELEMENT, MapSourceEnum.SOURCE_STREETLIGHT, true, paint);
    } else {
      this.mapFunctionsService.removeLayer(MapLayerEnum.LAYER_ELEMENT + elementType, MapSourceEnum.SOURCE_ELEMENT);
    }
  }

  onChangeGround() {
    const elementType = 'GROUND';
    let tileUrl = environment.urlTilesElements;
    tileUrl = tileUrl + '?elementType=' + elementType + '&snapshotIdentifier=' + this.currentSnapshot.snapshotIdentifier;
    if (this.Ground) {
      const layout = {
        'icon-image': '{element_class}',
        'icon-size': 0.25,
        'icon-rotate': ['get', 'bearing'],
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      };
      const currentLayer = MapLayerEnum.LAYER_ELEMENT + elementType;
      this.mapFunctionsService.addSymbolLayerTiles(currentLayer, tileUrl, MapSourceEnum.SOURCE_ELEMENT, true, layout, elementType);
    } else {
      this.mapFunctionsService.removeLayer(MapLayerEnum.LAYER_ELEMENT + elementType, MapSourceEnum.SOURCE_ELEMENT);
    }
  }
}
