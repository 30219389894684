import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Session} from '../../../globals/session';
import {TaskService} from '../../../services/task.service';
import {SessionService} from '../../../services/session.service';
import {DomainCollectionModelLegacy} from '../../../models/session/domain-collection-model-legacy';
import {DomainModelLegacy} from '../../../models/session/domain-model-legacy';
import {SnapshotModel} from '../../../models/processing/snapshot-model';

@Component({
  selector: 'app-change-domain',
  templateUrl: './change-domain.component.html',
  styleUrls: ['./change-domain.component.css']
})
export class ChangeDomainComponent implements OnInit {

  @Input() isPluginDock: boolean;

  currentDomain: string;
  currentSnapshotIdentifier: string;
  displayDomain: boolean;
  domains: DomainModelLegacy[];
  selectedDomain: DomainModelLegacy;
  selectedSnapshots: SnapshotModel[];

  constructor(private messageService: MessageService,
              private session: Session,
              private sessionService: SessionService,
              private taskService: TaskService) {
    this.domains = [];
    this.sessionService.getDomains().subscribe( (response: DomainCollectionModelLegacy) => {
      this.domains = response.domains;
    });

  }

  ngOnInit() {
    this.currentDomain = this.session.currentSession.domain.domainCode;
    this.displayDomain = false;
    // console.log(this.isPluginDock);
  }
  onChangeDomain() {
    this.selectedSnapshots = JSON.parse(localStorage.getItem('selectedSnapshot'));
    if (this.selectedDomain) {
      const domain = this.selectedDomain.domainCode;
      if (this.isPluginDock) {
        this.selectedSnapshots.forEach(snapshot => {
          this.taskService.changeDomainOnDock(snapshot.snapshotIdentifier, this.selectedDomain.domainCode).subscribe(resp => {
            if (resp) {
              this.messageService.add({
                key: 'bl', severity: 'success', summary: 'Transfert effectué',
                detail: snapshot.snapshotName + ' envoyé vers ' + domain
              });
            }
          });
        });
      } else {
        this.selectedSnapshots.forEach(snapshot => {
          this.taskService.changeDomainWithoutDock(snapshot.snapshotIdentifier, this.selectedDomain.domainCode).subscribe(resp => {
            if (resp) {
              this.messageService.add({
                key: 'bl', severity: 'success', summary: 'Transfert effectué',
                detail: snapshot.snapshotName + ' envoyé vers ' + domain
              });
            }
          });
        });
      }
      this.displayDomain = false;
    }
  }
  onCancel() {
    this.displayDomain = false;
  }
  onHide() {
    this.displayDomain = false;
  }
}
