<p-dialog header="Process creation" [(visible)]="displayProcess" [modal]="true" [responsive]="true"
          [style]="{'width': '800px', 'minWidth': '300px'}" (onHide)="onHide()" id="createProcess" [focusOnShow]="false"
          [transitionOptions]="'200ms'">
  <form (ngSubmit)="onCreateProcess()">
    <div class="snapshotForm ui-g">
      <div class="ui-g-12 ui-md-6">
        <p>Select a process :</p>
        <p-listbox [options]="processes" [(ngModel)]="selectedProcess" [ngModelOptions]="{standalone: true}" optionLabel="valueCode"
                   [style]="{'width':'100%'}"></p-listbox>
      </div>
      <div class="ui-g-12 ui-md-4 ui-md-offset-1 selectedCol">
        <div class="selectedDiv">
          <p class="selectedProcess" *ngIf="selectedProcess">{{selectedProcess.valueCode}}</p>
          <p class="selectedProcess" *ngIf="!selectedProcess">...</p>
        </div>
        <div class="form-group buttons">
          <button pButton type="submit" class="ui-button-primary ui-g-6" icon="fa fa-floppy-o" label="Start"></button>
          <button pButton type="button" class="ui-button-danger ui-g-6" icon="fa fa-times" label="Cancel"
                  (click)="onHide()"></button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
