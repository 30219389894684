import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MasterDataService} from '../../../services/master-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Session} from '../../../globals/session';
import {ListValueModel} from '../../../models/masterData/list-value-model';
import {TaskService} from '../../../services/task.service';
import {RulesCollectionModel} from '../../../models/task/rules-collection-model';
import {RuleModel} from '../../../models/task/rule-model';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css']
})
export class RulesComponent implements OnInit, OnDestroy {

  cols: any[];
  confirmMessage: string;
  counter: number;
  displayCreate: boolean;
  displayUpdate: boolean;
  isMatched: boolean;
  listCode: string;
  listName: string;
  listValues: ListValueModel[];
  listRules: RuleModel[];
  listRule: string[];
  numberOfValues: number;
  numberSelection: number;
  selectedValues: ListValueModel[];

  enabled: boolean;
  processRules: RuleModel[];

  constructor(public breakpointObserver: BreakpointObserver,
              private masterDataService: MasterDataService,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private formBuilder: FormBuilder,
              private taskService: TaskService,
              private session: Session) { }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        this.isMatched = !state.matches;
      });
    this.displayCreate = false;
    this.displayUpdate = false;
    this.getListRules();
    this.cols = [
      { field: 'ruleName', header: 'Nom' },
      { field: 'inCurrentStatusCode', header: 'In current status code' },
      { field: 'outProcessCode', header: 'Out process code' },
    ];
    this.listRule = [];
  }
  ngOnDestroy() {
    this.listRules = null;
  }
  onRowSelect() {
    this.numberSelection++;
  }
  onRowUnselect() {
    this.numberSelection--;
  }
  selectAll() {
    if (this.numberSelection !== this.numberOfValues) {
      this.numberSelection = this.numberOfValues;
    } else {
      this.numberSelection = 0;
    }
  }
  onAddField() {
    this.displayCreate = true;
  }
  getListRules() {
    this.listRules = [];
    this.taskService.getProcessRules().subscribe( (resp: RulesCollectionModel) => {
      if (resp) {
        this.listRules = resp.rules;
      }
    });
  }

  onChangeEnabled(rule: RuleModel) {
    this.processRules = [];
    this.processRules.push(rule);
    this.taskService.updateProcessRules(this.processRules).subscribe( resp => {
      if (resp) {
        this.messageService.add({key: 'bl', severity: 'success', summary: 'Règle mise à jour',
          detail: rule.ruleName});
      }
    });
  }

  createListValue() {
    this.displayCreate = false;
    this.getListRules();
  }


  confirmDeleteSelf(rule: RuleModel) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer cette règle : </b><br/><ul class="confirmList"><li>'
        + rule.ruleName + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.taskService.deleteProcessRules(rule.ruleId).subscribe(resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Règle supprimée',
              detail: rule.ruleName});
            this.getListRules();

          }
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });

  }
  confirmDeleteAll() {
    if (this.numberSelection > 0) {
      this.confirmMessage = '<ul class="confirmList">';
      this.selectedValues.forEach( listValue => {
        this.confirmMessage += '<li>' + listValue.valueCode + '</li>';
      });
      this.confirmMessage += '</ul>';
    }
    this.counter = 0;
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer tous ces champs : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.selectedValues.forEach( listValue => {
          this.masterDataService.deleteListValue(this.listCode, listValue.valueCode).subscribe(resp => {
            if (resp) {
              this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ supprimé de ' + this.listCode,
                detail: listValue.valueCode});
              this.getListRules();
            }
          });
        });

      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }


}
