import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from '../globals/session';
import {SnapshotCollectionModel} from '../models/processing/snapshot-collection-model';
import {DoneResponseModel} from '../models/done-response-model';
import {SnapshotUpdateModel} from '../models/processing/snapshot-update-model';
import {StatusEnum} from '../enums/status-enum.enum';
import {RequestHelper} from '../helpers/request.helper';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ProcessingService {

  snapshotDate: string;
  snapshotDateBegin: string;
  snapshotDateEnd: string;
  snapshotIdentifier: string;
  snapshotList: boolean;
  snapshotName: string;
  snapshotType: string;
  statusCode: string;
  statusCodeContains: string;
  statusCodeExcludeList: any;
  statusDate: string;
  statusDateBegin: string;
  statusDateEnd: string;
  snapshotTags: string[];

  constructor(private session: Session, private httpService: HttpClient, private requestHelper: RequestHelper) {
  }

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  displayStatus(errorMessage) {
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  getSnapshotsDetails(offset: number, count: number): Observable<SnapshotCollectionModel> {
    const url = environment.urlProcessingGetSnapshots;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.SNAPSHOT_GEO,
          ResponseLevelEnum.SNAPSHOT_EVENTS,
          ResponseLevelEnum.SNAPSHOT_DETAILS,
          ResponseLevelEnum.PAGING,
          // ResponseLevelEnum.STATUS_DETAILS,
        ],
        limitCount: count,
        limitOffset: offset
      },
      statusCode: this.statusCode,
      statusCodeContains: this.statusCodeContains,
      statusCodeExcludeList: this.statusCodeExcludeList,
      snapshotIdentifier: this.snapshotIdentifier,
      snapshotName: this.snapshotName,
      statusDateBegin: this.statusDateBegin,
      statusDateEnd: this.statusDateEnd,
      snapshotDateBegin: this.snapshotDateBegin,
      snapshotDateEnd: this.snapshotDateEnd,
      snapshotType: this.snapshotType,
      snapshotTags: this.snapshotTags
    }, options).pipe(map(data => new SnapshotCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Snapshot list not found');
      })
    );
  }

  getSnapshot(snapshotIdentifier): Observable<SnapshotCollectionModel> {
    const url = environment.urlProcessingGetSnapshots;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.SNAPSHOT_GEO,
          ResponseLevelEnum.SNAPSHOT_EVENTS,
          ResponseLevelEnum.SNAPSHOT_DETAILS,
          ResponseLevelEnum.STATUS_DETAILS,
          ResponseLevelEnum.SNAPSHOT_PROPERTIES
        ],
      },
      snapshotIdentifier
    }, options).pipe(map(data => new SnapshotCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Snapshot not found');
      })
    );
  }
  getSnapshotByParent(parentIdentifier): Observable<SnapshotCollectionModel> {
    const url = environment.urlProcessingGetSnapshots;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.SNAPSHOT_DETAILS
        ],
      },
      snapshotParentIdentifier: parentIdentifier
    }, options).pipe(map(data => new SnapshotCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Snapshot not found');
      })
    );
  }

  getSnapshotChilds(parentIdentifier): Observable<SnapshotCollectionModel> {
    const url = environment.urlProcessingGetSnapshots;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.SNAPSHOT_GEO,
          ResponseLevelEnum.SNAPSHOT_EVENTS,
          ResponseLevelEnum.SNAPSHOT_DETAILS,
          ResponseLevelEnum.STATUS_DETAILS,
        ],
      },
      snapshotParentIdentifier: parentIdentifier
    }, options).pipe(map(data => new SnapshotCollectionModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Snapshot child not found');
      })
    );
  }

  updateSnapshot(snapshot): Observable<DoneResponseModel> {
    const url = environment.urlProcessingUpdateSnapshot;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ],
      },
      snapshotIdentifier: snapshot.snapshotIdentifier,
      snapshotName: snapshot.snapshotName,
      snapshotComment: snapshot.snapshotComment,
      snapshotTags: snapshot.snapshotTags
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot update snapshot');
      })
    );
  }

  uploadSnapshot(snapshotIdentifier): Observable<SnapshotUpdateModel> {
      const url = environment.urlProcessingUpdateSnapshotStatus;

      const headers = this.requestHelper.createHttpHeaders();
      const options = {headers};
      return this.httpService.post(url, {
        header: {
          responseLevel: [
            ResponseLevelEnum.MINIMIZE
          ]
        },
        snapshotIdentifier,
        statusCode: StatusEnum.STATUS_CODE_CAPTURE_REQUESTED,
        statusReasonCode: StatusEnum.STATUS_REASON_CODE_USER_INPUT
      }, options).pipe(map(data => new SnapshotUpdateModel().deserialize(data)),
        catchError((err) => {
          this.displayStatus(err);
          return throwError('Snapshot not uploaded');
        })
      );
  }

  resetSnapshot(snapshotIdentifier): Observable<DoneResponseModel> {
    const url = environment.urlProcessingResetSnapshot;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      snapshotIdentifier,
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot reset snapshot');
      })
    );
  }

  cancelSnapshot(snapshotIdentifier): Observable<DoneResponseModel> {
    const url = environment.urlProcessingUpdateSnapshotStatus;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      snapshotIdentifier,
      statusCode: StatusEnum.STATUS_CODE_CANCEL,
      statusReasonCode: StatusEnum.STATUS_REASON_CODE_USER_INPUT
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot cancel snapshot');
      })
    );
  }
  removeSnapshot(snapshotIdentifier): Observable<DoneResponseModel> {
    const url = environment.urlProcessingRemoveSnapshot;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      snapshotIdentifier
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot remove snapshot');
      })
    );
  }
  removeChildSnapshot(parentIdentifier): Observable<DoneResponseModel> {
    const url = environment.urlProcessingRemoveSnapshot;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      snapshotParentIdentifier: parentIdentifier
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot remove child snapshot');
      })
    );
  }

}
