import {ISerializable} from '../iserializable';

export class DomainCreationModel implements ISerializable {

  /**
   *
   */
  constructor(associatedDomainId, name, group, locale) {
    this.associatedDomainId = associatedDomainId;
    this.name = name;
    this.group = group;
    this.locale = locale;
  }

  public associatedDomainId: number;
  public name: string;
  public group: string;
  public locale: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

}
