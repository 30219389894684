import {ISerializable} from '../iserializable';

export class ListValueModel implements  ISerializable {

  valueCode: string;
  valueText: string;
  listValueParameters: any;
  valueParameters: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
