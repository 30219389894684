<p-dialog header="{{'process-search.header' | translate}}" [(visible)]="display" [modal]="true" [responsive]="true"
          [style]="{'width': '800px', 'minWidth': '300px', 'max-height': '90%', 'overflow-y': 'auto'}" (onHide)="onHide()"
          [transitionOptions]="'200ms'" [focusOnShow]="false">
  <form [formGroup]="searchForm" (ngSubmit)="searchTasks()" class="globalSearch ui-g ui-fluid snapshotForm">
    <div class="ui-g-12 paddingDiv">
      <div class="appendFormTitle ui-g-12">
        <p  class="advancedSearchTitle">{{'process-search.by-process' | translate}}</p>
      </div>
      <hr>
      <div class="appendFormName ui-g-12 ui-lg-6">
        <div class="ui-inputgroup searchName">
          <p-dropdown [options]="processesType"
                      placeholder="{{'process-search.select-a-processtype' | translate}}" class="dropdownSearch" formControlName="processType"
                      optionLabel="valueCode"></p-dropdown>
        </div>
      </div>
      <div class="appendFormName ui-g-12 ui-lg-6">
        <div class="ui-inputgroup searchName">
          <p-dropdown [options]="processesCode"
                      placeholder="{{'process-search.select-a-processcode' | translate}}" class="dropdownSearch" formControlName="processCode"
                      optionLabel="valueCode"></p-dropdown>
        </div>
      </div>
    </div>
    <div *ngIf="closedTask" class="ui-g-12 paddingDiv">
      <div class="appendFormTitle ui-g-12">
        <p class="advancedSearchTitle">{{'process-search.by-task-result-code' | translate}}</p>
      </div>
      <hr>
      <div class="appendFormStatus ui-g-12">
        <p-checkbox name="group2"  [value]="selectedOk" formControlName="codeOk"
                    label="OK" binary="true" class="checkBoxStyle"></p-checkbox>
        <p-checkbox name="group3" [value]="selectedFailed" formControlName="codeFailed"
                    label="FAILED" binary="true" class="checkBoxStyle"></p-checkbox>
      </div>
    </div>
    <div class="ui-g-12 paddingDiv">
      <div class="appendFormTitle ui-g-12">
        <p  class="advancedSearchTitle">{{'process-search.by-period' | translate}}</p>
      </div>
      <hr>
      <div class="dateMainContainer">
        <div class="dateContainer ui-g-12">
          <p  class="dateContainerTitle">{{'process-search.request-date' | translate}}</p>
          <div class="dateDiv ui-g-12">
            <div class="appendForm appendFormDate ui-g-12">
              <p-calendar  showTime="true" hourFormat="24" [readonlyInput]="true"
                          [maxDate]="maxDateValue" dateFormat="dd MM yy" showButtonBar="true" [locale]="fr"
                           placeholder="{{'process-search.start-date' | translate}}" formControlName="dateBeginRequest"></p-calendar>
            </div>
            <div class="appendForm appendFormDate ui-g-12">
              <p-calendar  showTime="true" hourFormat="24" [readonlyInput]="true"
                          [maxDate]="maxDateValue" dateFormat="dd MM yy" showButtonBar="true" [locale]="fr"
                           placeholder="{{'process-search.end-date' | translate}}" formControlName="dateEndRequest"></p-calendar>
            </div>
          </div>
        </div>
        <div *ngIf="closedTask" class="dateContainer ui-g-12">
          <p class="dateContainerTitle">{{'process-search.close-date' | translate}}</p>
          <div class="dateDiv ui-g-12">
            <div class="appendForm appendFormDate ui-g-12">
              <p-calendar showTime="true" hourFormat="24" [readonlyInput]="true"
                          [maxDate]="maxDateValue" dateFormat="dd MM yy" showButtonBar="true" [locale]="fr"
                           placeholder="{{'process-search.start-date' | translate}}" formControlName="dateBeginClose"></p-calendar>
            </div>
            <div class="appendForm appendFormDate ui-g-12">
              <p-calendar  showTime="true" hourFormat="24" [readonlyInput]="true"
                          [maxDate]="maxDateValue" dateFormat="dd MM yy" showButtonBar="true" [locale]="fr"
                           placeholder="{{'process-search.end-date' | translate}}" formControlName="dateEndClose"></p-calendar>
            </div>
          </div>
        </div>
        <div *ngIf="canceledTask" class="dateContainer ui-g-12">
          <p class="dateContainerTitle">{{'process-search.cancel-date' | translate}}</p>
          <div class="dateDiv ui-g-12">
            <div class="appendForm appendFormDate ui-g-12">
              <p-calendar showTime="true" hourFormat="24" [readonlyInput]="true"
                          [maxDate]="maxDateValue" dateFormat="dd MM yy" showButtonBar="true" [locale]="fr"
                          placeholder="{{'process-search.start-date' | translate}}" formControlName="dateBeginCancel"></p-calendar>
            </div>
            <div class="appendForm appendFormDate ui-g-12">
              <p-calendar showTime="true" hourFormat="24" [readonlyInput]="true"
                          [maxDate]="maxDateValue" dateFormat="dd MM yy" showButtonBar="true" [locale]="fr"
                          placeholder="{{'process-search.close-date' | translate}}" formControlName="dateEndCancel"></p-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="submitButton ui-inputgroup">
      <button pButton type="submit" label="{{'process-search.search' | translate}}"></button>
    </div>
  </form>
</p-dialog>
