import {Component, Input, OnInit} from '@angular/core';
import {ProcessingService} from '../../../../services/processing.service';
import {MessageService} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-snapshot-event-edit',
  templateUrl: './snapshot-event-edit.component.html',
  styleUrls: ['./snapshot-event-edit.component.css']
})
export class SnapshotEventEditComponent implements OnInit {

  @Input() currentSnapshot: any;

  editForm: FormGroup;
  tags: string[];

  constructor(private processingService: ProcessingService,
              private messageService: MessageService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.tags = [];
    if (this.currentSnapshot.snapshotTags) {
      this.tags = this.currentSnapshot.snapshotTags.tags;
    }
    this.editForm = this.formBuilder.group({
      name: ['', Validators.required],
      comment: ['', Validators.required],
      tags: ['', Validators.required]
    });
  }


  editSnapshot() {
    this.currentSnapshot.snapshotName = this.f.name.value;
    this.currentSnapshot.snapshotComment = this.f.comment.value;
    this.currentSnapshot.snapshotTags = {};
    this.currentSnapshot.snapshotTags.tags = this.f.tags.value;
    this.processingService.updateSnapshot(this.currentSnapshot).subscribe( resp => {
      if (resp) {
        this.messageService.add({key: 'bl', severity: 'success', summary: 'Relevé modifié',
          detail: this.currentSnapshot.snapshotName});
      }
    });
  }
  get f() {
    return this.editForm.controls;
  }

}
