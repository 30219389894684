import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ElementClassModel} from "../../../../../models/masterData/element-class-model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MasterDataService} from "../../../../../services/master-data.service";
import {JsonEditorOptions} from "ang-jsoneditor";
import {TranslateService} from "@ngx-translate/core";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-masterdata-edit-properties',
  templateUrl: './masterdata-edit-properties.component.html',
  styleUrls: ['./masterdata-edit-properties.component.css']
})
export class MasterdataEditPropertiesComponent implements OnInit {

  @Input() currentMasterdata: ElementClassModel;
  editForm: FormGroup;
  editorOptions: JsonEditorOptions;
  properties: any;

  constructor(private formBuilder: FormBuilder,
              private masterDataService: MasterDataService,
              private translate: TranslateService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'tree', 'view'];
    this.properties = this.currentMasterdata.classProperties;
  }

  updateMasterData(masterData: ElementClassModel): ElementClassModel {
    masterData.classProperties = this.properties;
    return masterData;
  }

  get f() {
    return this.editForm.controls;
  }

}
