<div class="ui-g globalHeight">
  <div class="ui-g-12 ui-md-3 rightCol">
    <p-menu [model]="items"></p-menu>
  </div>
  <div class="ui-g-12 ui-md-9 ui-md-offset-3" [ngClass]="{'flexGrid': displayDetails || displayEdition || displayProperties}">
    <app-snapshot-event-info [currentSnapshot]="currentSnapshot" *ngIf="displayDetails" class="info ui-g-8" [ngClass]="{'detailsContainer': isMatched}"></app-snapshot-event-info>
    <app-snapshot-event-properties [currentSnapshot]="currentSnapshot" *ngIf="displayProperties" class="info ui-g-8" [ngClass]="{'detailsContainer': isMatched}"></app-snapshot-event-properties>
    <app-snapshot-event-status [currentSnapshot]="currentSnapshot" *ngIf="displayStatus" [ngClass]="{'detailsContainer': isMatched}" ></app-snapshot-event-status>
    <app-snapshot-event-gantt [currentSnapshot]="currentSnapshot" *ngIf="displayGantt" [ngClass]="{'detailsContainer': isMatched}" ></app-snapshot-event-gantt>
    <app-snapshot-event-edit [currentSnapshot]="currentSnapshot" *ngIf="displayEdition" class="editionContainer ui-g-8" [ngClass]="{'detailsContainer': isMatched}"></app-snapshot-event-edit>
  </div>
</div>

