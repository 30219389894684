<p-dialog id="listCreate" header="Create list value" [(visible)]="displayCreate"
          [style]="{width: '300px', height: '200px'}" (onHide)="onHide()">
  <form [formGroup]="createRuleForm" (ngSubmit)="createListValue()" class="updateForm ui-g ui-fluid">
    <div class="ui-g-12 paddingDiv">
      <div class="appendFormName ui-g-12">
        <div class="ui-inputgroup searchName">
          <input type="text" pInputText placeholder="Rule name" formControlName="ruleName"
                 class="ui-inputtext ui-corner-all ui-state-default ui-widget">
        </div>
      </div>
      <div class="appendFormStatus ui-g-12">
        <p-dropdown [options]="statusList" [(ngModel)]="selectedStatus" placeholder="In current status"
                    [showClear]="true" formControlName="inCurrent" optionLabel="statusCode"></p-dropdown>
      </div>
      <div class="appendFormStatus ui-g-12">
        <p-checkbox name="group1" [(ngModel)]="stitchedCheck" [value]="inIsStitched" formControlName="isStitched"
                    label="Is stitched" class="checkBoxStyle" (onChange)="changeStitched()"></p-checkbox>
      </div>
      <div class="appendFormStatus ui-g-12">
        <p-dropdown [options]="processList" [(ngModel)]="selectedProcess" placeholder="Out process code"
                    [showClear]="true" formControlName="outProcess" optionLabel="valueCode"></p-dropdown>
      </div>
      <div class="appendFormStatus ui-g-12">
        <p-checkbox name="group2" [(ngModel)]="enabledCheck" [value]="enabled" formControlName="enabled"
                    label="Is enabled" class="checkBoxStyle" (onChange)="changeEnabled()"></p-checkbox>
      </div>
      <div class="submitButton ui-inputgroup">
        <button pButton type="submit" label="Create" [disabled]="isParsingError"
                class="ui-button ui-button-text-only ui-button-text updateButton"></button>
      </div>
    </div>
  </form>
</p-dialog>
