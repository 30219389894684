import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnapshotService} from '../../snapshot/services/snapshot.service';
import {MasterDataService} from '../../../services/master-data.service';
import {ListCollectionModel} from '../../../models/masterData/list-collection-model';
import {TaskService} from '../../../services/task.service';
import {ListTypesEnum} from '../../../enums/list-types-enum';
import {ListValueModel} from '../../../models/masterData/list-value-model';

@Component({
  selector: 'app-process-search-form',
  templateUrl: './process-search-form.component.html',
  styleUrls: ['./process-search-form.component.css']
})
export class ProcessSearchFormComponent implements OnInit {

  @Output() searchDone = new EventEmitter<boolean>();
  @Input() taskCancelListComponent: boolean;
  @Input() taskDoneListComponent: boolean;

  canceledTask: boolean;
  closedTask: boolean;
  display: boolean;
  fr: any;

  listValues: ListValueModel[];
  maxDateValue: Date;
  processesCode: ListValueModel[];
  processesType: ListValueModel[];
  requestDateBegin: Date;
  requestDateEnd: Date;
  closeDateBegin: Date;
  closeDateEnd: Date;
  cancelDateBegin: Date;
  cancelDateEnd: Date;
  searchForm: FormGroup;
  selectedCode: string;
  selectedType: string;

  okCode: boolean;
  failedCode: boolean;
  selectedOk: string;
  selectedFailed: string;


  constructor(private snapshotService: SnapshotService, private formBuilder: FormBuilder,
              private masterDataService: MasterDataService,
              private taskService: TaskService) {
    this.listValues = [];
    this.masterDataService.getList(ListTypesEnum.PROCESS_TYPE).subscribe( (resp: ListCollectionModel) => {
      this.listValues = resp.lists[0].listValues;
      if (!this.listValues) {
        this.listValues = resp.lists[0].values;
      }
      this.processesType = [];
      this.processesType = this.listValues;
      this.masterDataService.getList(ListTypesEnum.PROCESS).subscribe( (secondResp: ListCollectionModel) => {
        this.listValues = [];
        this.listValues = secondResp.lists[0].listValues;
        if (!this.listValues) {
          this.listValues = secondResp.lists[0].values;
        }
        this.processesCode = [];
        this.processesCode = this.listValues;
      });
    });

  }

  ngOnInit() {
    this.canceledTask = this.taskCancelListComponent;
    this.closedTask = this.taskDoneListComponent;

    this.searchForm = this.formBuilder.group({
      processCode: ['', Validators.required],
      processType: ['', Validators.required],
      dateBeginRequest: ['', Validators.required],
      dateEndRequest: ['', Validators.required],
      dateBeginClose: ['', Validators.required],
      dateEndClose: ['', Validators.required],
      dateBeginCancel: ['', Validators.required],
      dateEndCancel: ['', Validators.required],
      codeOk: ['', Validators.required],
      codeFailed: ['', Validators.required],
    });
    this.okCode = true;
    this.failedCode = true;
    this.selectedOk = 'OK';
    this.selectedFailed = 'FAILED';
    this.maxDateValue = new Date();
    this.taskService.processCode = '';
    this.taskService.processType = '';
    this.taskService.requestDateBegin = null;
    this.taskService.requestDateEnd = null;
    this.taskService.closeDateBegin = null;
    this.taskService.closeDateEnd = null;
    this.taskService.cancelDateBegin = null;
    this.taskService.cancelDateEnd = null;
    this.taskService.taskResultCodes = null;
    this.display = false;
    this.fr = {
      closeText: 'Fermer',
      prevText: 'Précédent',
      nextText: 'Suivant',
      today: 'Aujourd\'hui',
      clear: 'Reset',
      monthNames: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
      monthNamesShort: [ 'janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin',
        'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.' ],
      dayNames: [ 'dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi' ],
      dayNamesShort: [ 'dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.' ],
      dayNamesMin: [ 'D', 'L', 'M', 'M', 'J', 'V', 'S' ],
      weekHeader: 'Sem.',
      dateFormat: 'dd/mm/yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
    };
  }
  get f() {
    return this.searchForm.controls;
  }
  onHide() {
    this.searchDone.emit(true);
  }
  searchTasks() {
    this.snapshotService.isSearch = true;
    if (this.f.processCode.value) {
      this.taskService.processCode = this.f.processCode.value.valueCode;
    }
    if (this.f.processType.value) {
      this.taskService.processType = this.f.processType.value.valueCode;
    }
    if (this.okCode || this.failedCode) {
      if (this.okCode && this.failedCode) {
        this.taskService.taskResultCodes = [this.selectedOk, this.selectedFailed];
      } else if (this.failedCode) {
        this.taskService.taskResultCodes = [this.selectedFailed];
      } else if (this.okCode){
        this.taskService.taskResultCodes = [this.selectedOk];
      }
    } else {
      this.taskService.taskResultCodes = [this.selectedOk, this.selectedFailed];
    }
    if (this.requestDateBegin) {
      this.taskService.requestDateBegin = this.requestDateBegin.toISOString();
    } else {
      this.taskService.requestDateBegin = null;
    }
    if (this.requestDateEnd) {
      this.taskService.requestDateEnd = this.requestDateEnd.toISOString();
    } else {
      this.taskService.requestDateEnd = null;
    }
    if (this.closeDateBegin) {
      this.taskService.closeDateBegin = this.closeDateBegin.toISOString();
    } else {
      this.taskService.closeDateBegin = null;
    }
    if (this.closeDateEnd) {
      this.taskService.closeDateEnd = this.closeDateEnd.toISOString();
    } else {
      this.taskService.closeDateEnd = null;
    }
    if (this.cancelDateBegin) {
      this.taskService.cancelDateBegin = this.cancelDateBegin.toISOString();
    } else {
      this.taskService.cancelDateBegin = null;
    }
    if (this.cancelDateEnd) {
      this.taskService.cancelDateEnd = this.cancelDateEnd.toISOString();
    } else {
      this.taskService.cancelDateEnd = null;
    }

    this.display = false;
  }

}
