import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {SettingsComponent} from './settings.component';
import {SettingsListsComponent} from './settings-lists/settings-lists.component';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {SettingsListCreateComponent} from './settings-lists/settings-list-create/settings-list-create.component';
import {SettingsListUpdateComponent} from './settings-lists/settings-list-update/settings-list-update.component';
import {SettingsConfigComponent} from "./settings-config/settings-config.component";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {KeyFilterModule} from 'primeng/keyfilter';
import {TranslateModule} from "@ngx-translate/core";
import {TooltipModule} from "primeng";

@NgModule({

  declarations: [SettingsComponent,
    SettingsListsComponent,
    SettingsListCreateComponent,
    SettingsListUpdateComponent,
    SettingsConfigComponent],
  imports: [
    ButtonModule,
    CommonModule,
    DialogModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgJsonEditorModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    ToastModule,
    ConfirmDialogModule,
    KeyFilterModule,
    DropdownModule,
    InputTextModule,
    TranslateModule,
    TooltipModule,
  ],
  providers: [

  ],
  exports: [RouterModule]
})
export class SettingsModule { }
