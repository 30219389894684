<h2>{{'processing.canceled.title' | translate}}</h2>
<hr>
<div class="processingView">
  <form [formGroup]="searchForm" (ngSubmit)="searchBy(all)" class="ui-g processForm globalSearch">
    <p-splitButton label="{{'processing.canceled.advanced-search' | translate}}" (onClick)="displayForm()" [model]="actionItems"
                   [ngClass]="{'ui-g-12': isMatched}"></p-splitButton>
    <app-process-search-form [taskCancelListComponent]="taskCancelListComponent"></app-process-search-form>
    <button *ngIf="numberSelection === 0" pButton type="button" label="{{'processing.canceled.restart-tasks' | translate:{numberSelection:numberSelection} }}"
            class="ui-button-secondary forbidden" disabled="true"
            [ngClass]="{'ui-g-12': isMatched, 'marginLeft': !isMatched, 'marginAll': isMatched}"></button>
    <button *ngIf="numberSelection !== 0" pButton type="button" label="{{'processing.canceled.restart-tasks' | translate:{numberSelection:numberSelection} }}"
            class="ui-button-primary" (click)="confirmRestartAll()"
            [ngClass]="{'ui-g-12': isMatched, 'marginLeft': !isMatched, 'marginAll': isMatched}"></button>
  </form>
  <p-paginator [rows]="12" [totalRecords]="totalCount" (onPageChange)="paginate($event)"
               [rowsPerPageOptions]="[12, 50, 100, 200]" #p></p-paginator>
  <p *ngIf="!isMatched" class="countRow">{{'processing.canceled.tasks-number' | translate:{totalCount:totalCount} }}</p>
  <div *ngIf="!processesLoaded" class="loaderContainer">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="userList cancelList">
    <p-table [columns]="cols" [value]="processes" [(selection)]="selectedProcesses" (onRowSelect)="onRowSelect()"
             (onRowUnselect)="onRowUnselect()" [responsive]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="checkBoxStyle" style="width: 3em">
            <p-tableHeaderCheckbox (click)="selectAll()"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
          <th>
            {{'processing.canceled.request-date' | translate}}
          </th>
          <th>
            {{'processing.canceled.cancel-date' | translate}}
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-process let-columns="columns">
        <tr>
          <td class="checkBoxStyle">
            <p-tableCheckbox [value]="process"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" class="phoneSizeTab">
            <span class="ui-column-title">{{col.header}}</span>
            {{process[col.field]}}
          </td>
          <td class="phoneSizeTab">
            <span class="ui-column-title">{{'processing.canceled.request-date' | translate}}</span>
            {{process.taskRequestDate | date : 'dd/MM/yyyy HH:mm:ss':'UTC' }}
          </td>
          <td class="phoneSizeTab">
            <span class="ui-column-title">{{'processing.canceled.cancel-date' | translate}}</span>
            {{process.taskCancelDate | date : 'dd/MM/yyyy HH:mm:ss':'UTC' }}
          </td>
          <td>
            <div class="ui-inputgroup ui-rtl" dir="rtl">
              <div class="ui-rtl" dir="ltr">
                <p-splitButton label="{{'processing.canceled.snapshot' | translate}}" (onClick)="onEventSnapshot(process)" [model]="items"
                               (onDropdownClick)="setProcess(process)" class="splitProcess"></p-splitButton>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
<p *ngIf="!isResult" class="disclaimer">{{'processing.canceled.no-results' | translate}}</p>
