import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MenuItem, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ElementClassModel} from "../../../../models/masterData/element-class-model";
import {MasterDataService} from "../../../../services/master-data.service";
import {DomainModelLegacy} from "../../../../models/session/domain-model-legacy";
import {DomainService} from "../../../../services/domain.service";
import {SessionService} from "../../../../services/session.service";
import {Session} from "../../../../globals/session";
import * as jwt from "jwt-decode";

@Component({
  selector: 'app-masterdata-add',
  templateUrl: './masterdata-add.component.html',
  styleUrls: ['./masterdata-add.component.css']
})
export class MasterdataAddComponent implements OnInit {

  newMasterdata: ElementClassModel;
  // should retrieve it
  locale = 'fr';
  displayOne: string;
  items: MenuItem[];
  @ViewChild('addDetailComponent') addDetailComponent;
  @ViewChild('addPropertiesComponent') addPropertiesComponent;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private masterDataService: MasterDataService,
    private messageService: MessageService,
    private domainService: DomainService,
    private sessionService: SessionService,
    private session: Session
  ) { }

  ngOnInit() {
    this.updateLocale();
    this.newMasterdata = new ElementClassModel();
    this.newMasterdata.elementType = 'SIGN';
    this.newMasterdata.classCode = 'A0';
    this.newMasterdata.classCategory = 'A';
    this.newMasterdata.elementClass = 'sign.' + this.locale + '.A0';
    this.newMasterdata.classGeoZoom = 8;
    this.translate.get(['masterdata.edit.details', 'masterdata.edit.icon',
      'masterdata.edit.properties', 'masterdata.edit.advanced']).subscribe(
      translations => {
        this.items = [
          {label: translations['masterdata.edit.details'], icon: 'pi fa fa-info-circle', command: () => {
              this.selectItem(0);
              this.displayOne = 'detailsAdd';
            }},
          {label: translations['masterdata.edit.icon'], icon: 'pi fa fa-pie-chart', command: () => {
              this.selectItem(1);
              this.displayOne = 'iconAdd';
            }},
          {label: translations['masterdata.edit.properties'], icon: 'pi fa fa-home', command: () => {
              this.selectItem(2);
              this.displayOne = 'propertiesAdd';
            }},
        ];
      }
    );
    this.selectItem(0);
    this.displayOne = 'detailsAdd';
  }

  async updateLocale() {
    this.locale = await this.getLocale();
  }

  async getLocale(): Promise<string> {
    const defaultDomain: { defaultDomain: number } = await this.domainService.getDefaultDomain().toPromise();
    const decodedToken = jwt(this.session.token);
    const currentDomainFromToken =  decodedToken["https://www.citymagine.com/domainId"] || 1;
    const domains = await this.sessionService.getDomains().toPromise();
    let currentDomain: DomainModelLegacy;
    if (currentDomainFromToken) {
      currentDomain = domains.domains.find(p => p.domainId === currentDomainFromToken);
    } else {
      currentDomain = domains.domains.find(p => p.domainId === defaultDomain.defaultDomain);
    }
    return currentDomain.domainLocale;
  }

  selectItem(index: number) {
    if (this.displayOne === 'detailsAdd') {
      this.newMasterdata = this.addDetailComponent.updateMasterData(this.newMasterdata);
    } else if (this.displayOne === 'propertiesAdd') {
      this.newMasterdata = this.addPropertiesComponent.updateMasterData(this.newMasterdata);
    }
    this.items.forEach(item => item.style = {});
    this.items[index].style = {'background-color': '#a7d2fc'};
  }

  onSubmitForm() {
    if (this.displayOne === 'detailsAdd') {
      this.newMasterdata = this.addDetailComponent.updateMasterData(this.newMasterdata);
    } else if (this.displayOne === 'propertiesAdd') {
      this.newMasterdata = this.addPropertiesComponent.updateMasterData(this.newMasterdata);
    }
    this.masterDataService.createElementClass(this.newMasterdata).subscribe(resp => {
      if (resp) {
        this.translate.get('masterdata.created').subscribe((translateResp: string) => {
          this.messageService.add({key: 'bl', severity: 'success', summary: translateResp,
            detail: this.newMasterdata.elementClass});
        });
      }
    });
  }

}
