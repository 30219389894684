<p-dialog header="Add tags for multiple snapshots" [(visible)]="displayTag" [modal]="true" [responsive]="true"
          [style]="{'width': '800px', 'minWidth': '300px'}" (onHide)="onHide()" id="createProcess"
          [transitionOptions]="'200ms'">
  <form [formGroup]="editForm" (ngSubmit)="onAddTag()">
    <div class="snapshotForm ui-g">
      <div class="appendFormName ui-g-12">
        <div class="globalSearch">
          <label for="tags" class="labelEdition">Tags</label>
          <p-chips id="tags" formControlName="tags" class="form-control ui-g-12 tagChips inputEdition"
                   [(ngModel)]="tags" placeholder="Write then press enter for new tag.."></p-chips>
        </div>
      </div>
      <div class="form-group buttons">
        <button pButton type="submit" class="ui-button-primary ui-g-6" icon="fa fa-floppy-o" label="Add"></button>
        <button pButton type="button" class="ui-button-danger ui-g-6" icon="fa fa-times" label="Cancel"
                (click)="onCancel()"></button>
      </div>
    </div>
  </form>
</p-dialog>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
