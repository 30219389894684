import {ISerializable} from '../iserializable';
import {StatusModel} from './status-model';

export class StatusCollectionModel implements ISerializable {

  statusList: StatusModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.statusList = input.statusList.map(list => new StatusModel().deserialize(list));

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
