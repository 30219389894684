import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';

import {DomainComponent} from './domain.component';

@NgModule({
  declarations: [DomainComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class DomainModule { }
