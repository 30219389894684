import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MapFunctionsService} from '../../snapshot/services/mapfunctions.service';
import {GeojsonService} from '../../../services/geojson.service';
import {SecurityService} from '../../../services/security.service';
import {Session} from '../../../globals/session';
import {MessageService} from 'primeng/api';
import {GeometryModel} from '../../../models/geojson/geometry-model';
import {ActivatedRoute, Router} from '@angular/router';
import {DomainService} from '../../../services/domain.service';
import {AreasModel} from '../../../models/domain/areas-model';
import {AreaModel} from '../../../models/domain/area-model';
import {UserCollectionModelLegacy} from '../../../models/session/user-collection-model-legacy';
import {UserModel} from '../../../models/session/user-model';
import {FeatureCollectionModel} from "../../../models/geojson/feature-collection-model";
import {FeatureModel} from "../../../models/geojson/feature-model";
import {FileUpload} from "primeng";
import {UserCollectionModel} from "../../../models/session/user-collection-model";

@Component({
  selector: 'app-areas-add',
  templateUrl: './areas-add.component.html',
  styleUrls: ['./areas-add.component.css']
})
export class AreasAddComponent implements OnInit {

  uploadedFiles: any[] = [];

  isActiveDraw: boolean;
  zoneSearch: string;
  geoForm: FormGroup;
  string: string;
  objFilteredResults: string[];
  response: any;
  filteredResults: string[];

  isGeometryRestriction: string[];
  isGeometryRestricted: boolean;

  users: UserModel[];
  selectedUsers: any[];

  selectedParent: AreaModel;

  areaId: string;
  parentId: string;
  currentArea: AreaModel;
  areas: AreaModel[];
  importedGeometry: FeatureCollectionModel | FeatureModel | GeometryModel;

  @ViewChild("fileUploadButton")
  fileUploadButton: FileUpload

  constructor(private mapFunctionsService: MapFunctionsService,
              private geojsonService: GeojsonService,
              private formBuilder: FormBuilder,
              private securityService: SecurityService,
              private session: Session,
              private router: Router,
              private route: ActivatedRoute,
              private domainService: DomainService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.mapFunctionsService.onInitMapUser();

    const urlParameters = Object.assign({}, this.route.snapshot.queryParams);
    if (urlParameters.areaId !== undefined) {
      this.areaId = urlParameters.areaId;
    } else {
      this.areaId = undefined;
    }
    if (urlParameters.parentId !== undefined) {
      this.parentId = urlParameters.parentId;
    } else {
      this.parentId = undefined;
      this.selectedParent = new AreaModel();
    }
    this.currentArea = new AreaModel();
    if (this.areaId !== undefined) {
      this.domainService.getArea(this.areaId).subscribe((response: AreaModel) => {
        this.currentArea = response;
        setTimeout(() => {
          this.mapFunctionsService.typeCoord = this.currentArea.geometry.type;
          this.mapFunctionsService.drawPolygon(this.currentArea.geometry.coordinates);
          this.mapFunctionsService.fitToContent();
        }, 1000);
      });
    }
    // TODO change currentArea filling
    this.mapFunctionsService.changeDrawState.subscribe((isActiveDraw: boolean) => {
      this.isActiveDraw = isActiveDraw;
      this.zoneSearch = '';
    });
    this.mapFunctionsService.changeDataGeo.subscribe((dataGeo: GeometryModel) => {
      if (!this.currentArea.geometry) {
        this.currentArea.geometry = new GeometryModel();
      }
      this.currentArea.geometry = dataGeo;
    });

    this.isActiveDraw = false;
    this.zoneSearch = '';

    this.geoForm = this.formBuilder.group({
      areaName: ['', Validators.required],
      autocomplete: ['', Validators.required],
      selectedUsers: ['', Validators.required],
      selectedParent: ['', Validators.required],
    });

    this.securityService.getUsers(10000, 0, false).subscribe(
      (resp: UserCollectionModel) => {
        if (resp) {
          resp.users.forEach((user) => {
            this.users = resp.users;
            this.selectedUsers = [];
          });
        }
      }
    );

    this.domainService.getAreas(0, 5000).subscribe((response: AreasModel) => {
      this.areas = [];
      response.content.forEach((area: AreaModel) => {
        if (area.id !== this.areaId) {
          this.areas.push(area);
        }
        if (this.parentId !== undefined) {
          if (this.parentId === area.id) {
            this.selectedParent = area;
          }
        }
      });
    });
  }

  search(event) {
    this.string = event.query;
    this.geojsonService.getNominatim(this.string).subscribe(message => {
      this.response = message;
      this.filteredResults = [];
      this.objFilteredResults = [];
      this.response.forEach(result => {
        let type = '';
        if (result.display_name) {
          type = result.display_name;
        }
        if (this.filteredResults[0]) {
          let differentElement = 0;

          for (const item of this.filteredResults) {
            if (type !== item) {
              differentElement++;
              if (differentElement === this.filteredResults.length) {
                if (type !== '') {
                  this.filteredResults.push(type);
                  this.objFilteredResults.push(result);
                }
              }
            }
          }
        } else {
          this.filteredResults.push(type);
          this.objFilteredResults.push(result);
        }
      });
    });
  }

  // TODO change currentArea filling
  onSelectArea() {
    this.string = this.g.autocomplete.value;
    this.geojsonService.getNominatim(this.string).subscribe(message => {
      this.response = message;
      this.mapFunctionsService.typeCoord = this.response[0].geojson.type;
      this.mapFunctionsService.dataGeojson = this.response[0].geojson.coordinates;

      if (this.currentArea === undefined) {
        this.currentArea = new AreaModel();
      }

      this.currentArea.geometry = new GeometryModel();
      this.currentArea.geometry.type = this.mapFunctionsService.typeCoord;
      this.currentArea.geometry.coordinates = this.mapFunctionsService.dataGeojson;
      this.mapFunctionsService.drawPolygon(this.response[0].geojson.coordinates);
      this.mapFunctionsService.fitToContent();
    });
  }

  // TODO change currentArea filling
  onSubmitGeo() {
    if (this.areaId !== undefined) {
      if (this.g.areaName.value) {
        this.currentArea.name = this.g.areaName.value;
      }
      this.currentArea.users = this.selectedUsers.map(user => user.userId);
      this.selectedParent.id !== undefined ? this.currentArea.parentId = this.selectedParent.id : this.currentArea.parentId = null;
      this.domainService.updateArea(this.currentArea).subscribe(_response => {
        this.messageService.add({key: 'bl', severity: 'success', summary: 'Le secteur ' + this.currentArea.name + ' a bien été mis à jour.'});
        this.router.navigate(['home/areas/areasList']);
      }, error => {
        this.messageService.add({key: 'bl', severity: 'error', summary: 'Modification impossible', detail: error.error.message});
      });
    } else {
      const area = new AreaModel();
      area.name = this.g.areaName.value;
      area.geometry = this.currentArea.geometry;
      area.users = [];
      this.selectedParent.id !== undefined ? area.parentId = this.selectedParent.id : area.parentId = null;
      if (this.selectedUsers.length > 0) {
        this.selectedUsers.forEach((user: UserModel) => {
          area.users.push(user.userId);
        });
      }
      area.users.push(this.session.currentSession.user.userId);
      this.domainService.createArea(area).subscribe(_response => {
        this.messageService.add({key: 'bl', severity: 'success', summary: 'Le secteur ' + area.name + ' a bien été créé.'});
        this.router.navigate(['home/areas/areasList']);
      }, error => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Ajout impossible', detail: error.error.message});
      });
    }
  }

  clearAll() {
    this.mapFunctionsService.onDeletePolygon();
    this.zoneSearch = '';
    this.currentArea.geometry = null;
  }

  onDrawPolygon() {
    this.isActiveDraw = !this.isActiveDraw;
    if (this.isActiveDraw) {
      this.mapFunctionsService.onDrawPolygon();
    }
  }

  get g() {
    return this.geoForm.controls;
  }

  readFile(e) {
    this.importedGeometry = new FeatureCollectionModel();
    for (const file of e.files) {
      this.uploadedFiles.push(file);
    }
    if (this.uploadedFiles.length > 0) {
      const reader = new FileReader();
      reader.readAsText(this.uploadedFiles[0], 'UTF-8');
      const that = this;
      reader.onload = (evt) => {
        that.importedGeometry = JSON.parse(evt.target.result as string);
        this.convertGeometry();
      };
      reader.onerror = (_evt) => {
        console.error('Unable to read file');
      };
    }
  }
  convertGeometry() {
    if(this.importedGeometry['features']){
      this.mapFunctionsService.typeCoord = this.importedGeometry['features'][0].geometry.type;
      this.mapFunctionsService.dataGeojson = this.importedGeometry['features'][0].geometry.coordinates;
      this.currentArea.geometry = <GeometryModel>this.importedGeometry['features'][0].geometry;
    } else if(this.importedGeometry['geometry']){
      this.mapFunctionsService.typeCoord = this.importedGeometry['geometry'].type;
      this.mapFunctionsService.dataGeojson = this.importedGeometry['geometry'].coordinates;
      this.currentArea.geometry = <GeometryModel>this.importedGeometry['geometry'];
    } else if(this.importedGeometry.hasOwnProperty("coordinates")){
      this.mapFunctionsService.typeCoord = this.importedGeometry['type'];
      this.mapFunctionsService.dataGeojson = this.importedGeometry['coordinates'];
      this.currentArea.geometry = <GeometryModel>this.importedGeometry;
    } else{
      return;
    }
    this.mapFunctionsService.drawPolygon(this.mapFunctionsService.dataGeojson);
    this.mapFunctionsService.fitToContent();
  }

  clearUploadedFile() {
    this.uploadedFiles = []
    this.fileUploadButton.clear()
    this.fileUploadButton.clearInputElement()
    this.importedGeometry = null;
    this.mapFunctionsService.onDeletePolygon()
  }
}
