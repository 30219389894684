import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {GeometryModel} from '../models/geojson/geometry-model';
import {SessionModel} from '../models/session/session-model';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {GeometryHelper} from '../helpers/geometry.helper';


@Directive()
@Injectable({
  providedIn: 'root'
})
export class Session {

  @Output() onChangeWorkspace: EventEmitter<GeometryModel> = new EventEmitter();

  isLogged: boolean;
  isLogging = true;
  token: string;
  currentSession: SessionModel;
  sessionIdentifier: string;
  sessionLocale: string;
  sessionProperties: any;
  _workspaceGeo: GeometryModel;
  username: string;

  constructor(private router: Router) {
  }
  // setWorkspace(geometry: GeometryModel) {
  //   if (GeometryHelper.isValidGeometry(geometry)) {
  //     this._workspaceGeo = geometry;
  //   } else {
  //     this._workspaceGeo = undefined;
  //   }
  //
  //   this.onChangeWorkspace.emit(geometry);
  // }
  //
  // getWorkspace(): GeometryModel {
  //   return this._workspaceGeo;
  // }
  getSessionIdentifier(): string {
    return (this.currentSession ? this.currentSession.sessionIdentifier : undefined);
  }
  getToken(): string {
    return this.token;
  }
  setWorkspace(geometry: GeometryModel) {
    if (GeometryHelper.isValidGeometry(geometry)) {
      this._workspaceGeo = geometry;
    } else {
      this._workspaceGeo = undefined;
    }
    this.onChangeWorkspace.emit(geometry);
  }

  getWorkspace(): GeometryModel {
    return this._workspaceGeo;
  }

  loadSession(sessionModel: SessionModel, route?: ActivatedRouteSnapshot) {
    if (sessionModel !== undefined) {
      this.sessionIdentifier = sessionModel.sessionIdentifier;
      this.isLogged = true;
      this.isLogging = false;
      this.currentSession = sessionModel;
      this.sessionLocale = sessionModel.user.userLocale;
      this.sessionProperties = sessionModel.user.userProperties;
      // this.setWorkspace(sessionModel.user.userGeometry);
      this.username = sessionModel.user.userName;

    } else {
      this.sessionIdentifier = undefined;
      this.isLogged = false;
      this.isLogging = false;
      this.currentSession = undefined;
      this.sessionLocale = 'en_GB';
      this.sessionProperties = undefined;

      // this.setWorkspace(undefined);
    }
  }

  hasRole(role: string): boolean {
    return this.currentSession.user.userRoles.includes(role);
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

}
