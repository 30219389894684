import {ISerializable} from '../iserializable';

export class ProcessConfigModel implements ISerializable {

  public configSample: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
