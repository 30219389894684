import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {RoleEnum} from '../../../../enums/role-enum.enum';
import {ToastsComponent} from '../../../main/toasts/toasts.component';
import { DomainService } from 'src/app/services/domain.service';
import { DomainCreationModel } from 'src/app/models/domain/domain-creation-model';

@Component({
  selector: 'app-domains-add',
  templateUrl: './domains-add.component.html',
  styleUrls: ['./domains-add.component.css']
})
export class DomainsAddComponent implements OnInit {

  private toast: ToastsComponent;

  addForm: FormGroup;
  display: boolean;
  @Output() addDomain: EventEmitter<boolean> = new EventEmitter();

  @Input()
  currentDomain: any;

  constructor(private formBuilder: FormBuilder, private messageService: MessageService,
              private domainService: DomainService) { }

  ngOnInit() {
    this.init();
  }
  onSubmit() {
    if (!this.f.domainName.value || this.f.domainName.errors) {
      this.messageService.add({key: 'bl', severity: 'warn', summary: 'Domaine non valide'});
    } else {
        const associatedDomainId = this.currentDomain;
        const domainName = this.f.domainName.value;
        const group = '';
        const locale = 'fr';
        this.domainService.createDomain(new DomainCreationModel(associatedDomainId, domainName, group, locale)).subscribe( resp => {
          if (resp) {
            this.addDomain.emit(true);
            this.hide();
            this.init();
          }
        }, error => {
          this.messageService.add({key: 'bl', severity: 'error', summary: 'Erreur lors de la création', detail: error.error.message});
        });
    } 
  }
  onCancel() {
    this.hide();
    this.init();
  }
  onHide() {
    this.hide();
    this.init();
  }
  init() {
    this.addForm = this.formBuilder.group({
      domainName: ['', Validators.required]
    });
  }
  hide(){
    this.display = false;
  }
  get f() {
    return this.addForm.controls;
  }
}
