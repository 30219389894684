import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-snapshot-event-properties',
  templateUrl: './snapshot-event-properties.component.html',
  styleUrls: ['./snapshot-event-properties.component.css']
})
export class SnapshotEventPropertiesComponent implements OnInit {

  @Input() currentSnapshot: any;

  constructor() { }

  ngOnInit() {
  }

}
