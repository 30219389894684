import {ISerializable} from '../iserializable';
import {StatusModel} from "../masterData/status-model";
import {GeometryModel} from "../geojson/geometry-model";
import {EventModel} from "./event-model";
import {TagModel} from "./tag-model";




export class SnapshotModel implements ISerializable {

  public isEnable: boolean;
  public statusDate: string;
  public status: StatusModel;
  public statusCode: string;
  public statusReason: string;
  public snapshotType: string;
  public snapshotOrigin: string;
  public snapshotDate: string;
  public snapshotDateBegin: string;
  public snapshotDateEnd: string;
  public snapshotIdentifier: string;
  public snapshotName: string;
  public snapshotVersion: string;
  public snapshotGeometry: GeometryModel;
  public snapshotEvents: EventModel[];
  public snapshotParentPart: number;
  public snapshotParentIdentifier: number;
  public timeSpend: number;
  public distance: string;
  public dateFromStatus: string;
  public snapshotTags: TagModel;
  public snapshotCheck: boolean;
  public snapshotWarnCheck: string;

  public snapshotDisplayName: string;

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
