import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {DialogService} from 'primeng/dynamicdialog';
import {Paginator} from 'primeng/paginator';
import { MasterDataService } from 'src/app/services/master-data.service';
import { ElementClassModel } from 'src/app/models/masterData/element-class-model';
import { ElementClassCollectionModel } from 'src/app/models/masterData/element-class-collection-model';
import { MasterdataEditComponent } from './masterdata-edit/masterdata-edit.component';
import {MasterdataAddComponent} from "./masterdata-add/masterdata-add.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-masterdata',
  templateUrl: './masterdata.component.html',
  styleUrls: ['./masterdata.component.css']
})

export class MasterdataComponent implements OnInit {

  @ViewChild('p')
  paginator: Paginator;

  // @ViewChild(MasterdataAddComponent)
  // private masterdataAdd: MasterdataAddComponent;

  arrayMasterdata: ElementClassModel[];
  cols: any;
  confirmMessage: string;
  counter: number;
  init: number;
  isMatched: boolean;
  isResult: boolean;
  last: number;
  limitCount: number;
  numberOfMasterdata: number;
  numberSelection: number;
  offset: number;
  searchMasterdataForm: FormGroup;
  selectedName: string;
  selectedMasterdatas: ElementClassModel[];
  selectedMasterdata: ElementClassModel;
  tableItems: MenuItem[];
  tableItemsBlocked: MenuItem[];
  totalCount: number;
  masterdata: ElementClassModel;
  elementClassModels: ElementClassModel[];
  masterdataLoaded: boolean;
  displayEditMasterdata = false;
  displayAddMasterdata = false;

  @ViewChild('editMasterdataComponent') editMasterdataComponent;
  @ViewChild('addMasterdataComponent') addMasterdataComponent;


  constructor(public breakpointObserver: BreakpointObserver,
              private confirmationService: ConfirmationService,
              private dialogService: DialogService,
              private formBuilder: FormBuilder,
              private messageService: MessageService,
              private masterdataService: MasterDataService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    // this.breakpointObserver
    //   .observe(['(min-width: 640px)'])
    //   .subscribe((state: BreakpointState) => {
    //     if (state.matches) {
    //       this.isMatched = false;
    //     } else {
    //       this.isMatched = true;
    //     }
    //   });
    this.limitCount = 50;
    this.numberOfMasterdata = 0;
    this.numberSelection = 0;
    this.offset = 0;
    this.elementClassModels = [];
    this.arrayMasterdata = [];
    this.selectedName = '';
    this.masterdataService.getElementClassList(this.offset, this.limitCount).subscribe(
      (resp: ElementClassCollectionModel) => {
        if (resp) {
          this.totalCount = resp.header.totalCount;
          resp.elementClasses.forEach((masterdata) => {
            this.arrayMasterdata.push(masterdata);
            this.numberOfMasterdata++;
          });
          this.elementClassModels = this.arrayMasterdata;
          this.masterdataLoaded = true;
          this.isResult = this.numberOfMasterdata !== 0;
        }
      }
    );
    this.searchMasterdataForm = this.formBuilder.group({
      research: ['', Validators.required]
    });
    this.cols = [
      { field: 'classIconFilePath', header: 'Icon' },
      { field: 'elementClass', header: 'Class' },
      { field: 'elementType', header: 'Type' },
      { field: 'classCategory', header: 'Category' },
      { field: 'classDescription', header: 'Description' },
    ];
    // this.actionItems = [
    //   {label: 'Reset les filtres', icon: 'fa fa-backward', command: () => {
    //       this.resetSnapshots();
    //     }}
    // ];
    // this.tableItems = [
    //   {label: 'Réinitialiser le mot de passe', icon: 'fa fa-key', command: () => {
    //       this.resetPassword(this.masterdata);
    //     }},
    //   {label: 'Désactiver', icon: 'fa fa-ban', command: () => {
    //     this.masterdata.isBlocked = true;
    //     this.masterdataService.updateMasterdata(this.masterdata).subscribe( resp => {
    //       if (resp) {
    //         this.messageService.add({key: 'bl', severity: 'success', summary: 'Utilisateur désactivé',
    //           detail: this.masterdata.masterdataName});
    //       }
    //     });
    //   }},
    //   {label: 'Supprimer', icon: 'fa fa-close', command: () => {
    //       this.confirmDeleteSelf(this.masterdata);
    //     }}
    // ];
    // this.tableItemsBlocked = [
    //   {label: 'Réinitialiser le mot de passe', icon: 'fa fa-key', command: () => {
    //       this.resetPassword(this.masterdata);
    //     }},
    //   {label: 'Réactiver', icon: 'fa fa-check', command: () => {
    //     this.masterdata.isBlocked = false;
    //     this.masterdataService.updateMasterdata(this.masterdata).subscribe( resp => {
    //       if (resp) {
    //         this.messageService.add({key: 'bl', severity: 'success', summary: 'Utilisateur réactivé',
    //           detail: this.masterdata.masterdataName});
    //       }
    //     });
    //   }},
    //   {label: 'Supprimer', icon: 'fa fa-close', command: () => {
    //       this.confirmDeleteSelf(this.masterdata);
    //     }}
    // ];
  }

  addMasterdataFunction() {
      this.messageService.add({key: 'bl', severity: 'success', summary: 'Utilisateur ajouté'});
      this.paginator.changePage(0);
  }
  callToGetMasterdataDetails() {
    this.numberOfMasterdata = 0;
    this.masterdataLoaded = false;
    this.arrayMasterdata = [];
    this.elementClassModels = [];
    this.masterdataService.getElementClassList(this.offset, this.limitCount, this.selectedName).subscribe(
      (resp: ElementClassCollectionModel) => {
        if (resp) {
          this.totalCount = resp.header.totalCount;
          resp.elementClasses.forEach((c) => {
            this.arrayMasterdata.push(c);
            this.numberOfMasterdata++;
          });
          this.elementClassModels = this.arrayMasterdata;
          this.masterdataLoaded = true;
          this.isResult = true;
        } else {
          this.masterdataLoaded = true;
        }
        if (this.numberOfMasterdata === 0) {
          this.isResult = false;
        } else {
          this.isResult = true;
        }
      }
    );
  }
  paginate(event) {
    this.limitCount = event.rows;
    this.numberSelection = 0;
    this.selectedMasterdatas = [];
    this.init = event.first;
    this.last = event.first + event.rows;
    this.offset = event.first / this.limitCount;
    this.callToGetMasterdataDetails();
  }
  searchMasterdata() {
    this.selectedName = this.f.research.value;
    this.paginator.changePage(0);
  }
  setMasterdata(masterdata) {
    this.masterdata = masterdata;
  }
  onRowSelect() {
    this.numberSelection++;
  }
  onRowUnselect() {
    this.numberSelection--;
  }
  selectAll() {
    if (this.numberSelection !== this.numberOfMasterdata) {
      this.numberSelection = this.numberOfMasterdata;
    } else {
      this.numberSelection = 0;
    }
  }

  onEditMasterdata(masterdata) {
    this.selectedMasterdata = masterdata;
    this.displayEditMasterdata = true;
  }

  submitEditMasterdataForm() {
    this.editMasterdataComponent.onSubmitForm();
  }

  submitAddMasterdataForm() {
    this.addMasterdataComponent.onSubmitForm();
  }

  confirmDeleteSelf(masterdata) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer cette classe : </b><br/><ul class="confirmList"><li>'
        + masterdata.masterdataName + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.masterdataService.deleteElementClass(masterdata).subscribe(resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'classe supprimée',
              detail: masterdata.masterdataName});
            this.paginator.changePage(0);
          }
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
  confirmDeleteAll() {
    if (this.numberSelection > 0) {
      this.confirmMessage = '<ul class="confirmList">';
      this.selectedMasterdatas.forEach( masterdata => {
        this.confirmMessage += '<li>' + masterdata.classCode + '</li>';
      });
      this.confirmMessage += '</ul>';
    }
    this.counter = 0;
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer toutes ces classes : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.selectedMasterdatas.forEach( c => {
          this.masterdataService.deleteElementClass(c).subscribe(resp => {
            if (resp) {
              this.messageService.add({key: 'bl', severity: 'success', summary: 'Classe(s) supprimée(s)', detail: c.classCode});
              this.counter++;
              if (this.counter === this.numberSelection) {
                this.paginator.changePage(0);
              }
            }
          });
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }

  get f() {
    return this.searchMasterdataForm.controls;
  }
}
