import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from '../globals/session';
import {DoneResponseModel} from '../models/done-response-model';
import {RequestHelper} from '../helpers/request.helper';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class AsyncService {

  constructor(private session: Session, private httpService: HttpClient, private requestHelper: RequestHelper) {
  }

  errorMessage: any;
  @Output() ErrorMessage: EventEmitter<any> = new EventEmitter();

  displayStatus(errorMessage) {
    this.errorMessage = errorMessage;
    this.ErrorMessage.emit(this.errorMessage);
  }

  deduplicateByArea(objectElementType, typeCoordinates, objectCoordinates): Observable<DoneResponseModel> {
    const url = environment.urlAsyncDeduplicateObjectElements;

    const headers = this.requestHelper.createHttpHeaders();
    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      elementType: objectElementType,
      elementGeo: {
        type: typeCoordinates,
        coordinates: objectCoordinates
      }
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError((err) => {
        this.displayStatus(err);
        return throwError('Cannot deduplicate this area');
      })
    );
  }
}
