<!--<div *ngIf="displayEdition" class="editionContainer ui-g-8" [ngClass]="{'detailsContainer': isMatched}">-->
  <form [formGroup]="editForm" (ngSubmit)="editSnapshot()" class="globalSearch ui-g ui-fluid snapshotForm">
    <div class="appendFormName ui-g-12">
      <div class="searchName">
        <label for="name" class="labelEdition">Snapshot name</label>
        <input type="text" pInputText placeholder="Snapshot Name" formControlName="name" id="name"
               class="ui-inputtext ui-corner-all ui-state-default ui-widget inputEdition"
               [(ngModel)]="currentSnapshot.snapshotName">
      </div>
    </div>
    <div class="appendFormName ui-g-12">
      <div class="searchName">
        <label for="textarea" class="labelEdition">Comment</label>
        <textarea type="textarea" id="textarea" pInputTextarea formControlName="comment" rows="6" cols="30" maxlength="500"
                  class="form-control ui-g-12 inputEdition textEdition" placeholder="Add your comment here.."
                  [(ngModel)]="currentSnapshot.snapshotComment"></textarea>
      </div>
    </div>
    <div class="appendFormName ui-g-12">
      <div class="searchName">
        <label for="tags" class="labelEdition">Tags</label>
        <p-chips id="tags" formControlName="tags" class="form-control ui-g-12 tagChips inputEdition"
                 [(ngModel)]="tags" placeholder="Write then press enter for new tag.."></p-chips>
      </div>
    </div>
    <div class="submitButtonUpdate ui-inputgroup">
      <button pButton type="submit" label="Update"></button>
    </div>
  </form>
<!--</div>-->
