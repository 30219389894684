import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TaskOnholdListComponent} from './task-onhold-list/task-onhold-list.component';
import {RouterModule} from '@angular/router';

/////////////////////////
import {ListboxModule} from 'primeng/listbox';
import {PaginatorModule} from 'primeng/paginator';
import {MessagesModule} from 'primeng/messages';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {ChartModule} from 'primeng/chart';
import {CardModule} from 'primeng/card';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog'

import {
  CalendarModule,
  CheckboxModule,
  MessageModule,
  ProgressSpinnerModule,
  SelectButtonModule,
  SplitButtonModule,
  TooltipModule
} from 'primeng';
import {MessageService} from 'primeng/api';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {TaskAckListComponent} from './task-ack-list/task-ack-list.component';
import {TaskDoneListComponent} from './task-done-list/task-done-list.component';
import {TaskCancelListComponent} from './task-cancel-list/task-cancel-list.component';
import {ProcessingComponent} from './processing.component';
import {ProcessSearchFormComponent} from "./process-search-form/process-search-form.component";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from "@ngx-translate/core";

/////////////////////////////

@NgModule({
  declarations: [
    TaskOnholdListComponent,
    TaskAckListComponent,
    TaskDoneListComponent,
    TaskCancelListComponent,
    ProcessingComponent,
    ProcessSearchFormComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        TableModule,
        ButtonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        ChartModule,
        CardModule,
        InputSwitchModule,
        ConfirmDialogModule,
        MessagesModule,
        MessageModule,
        PaginatorModule,
        SelectButtonModule,
        ListboxModule,
        SelectButtonModule,
        TooltipModule,
        ProgressSpinnerModule,
        SplitButtonModule,
        ToastModule,
        DialogModule,
        CalendarModule,
        CheckboxModule,
        TranslateModule
    ],
  providers: [MessageService],
  exports: [RouterModule]
})
export class ProcessingModule {
}
