import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MapFunctionsService} from '../../../snapshot/services/mapfunctions.service';
import {GeojsonService} from '../../../../services/geojson.service';
import {SecurityService} from '../../../../services/security.service';
import {MessageService} from 'primeng/api';
import {Session} from '../../../../globals/session';
import {ListboxModel} from '../../../../models/masterData/listbox-model';
import {ListModel} from '../../../../models/masterData/list-model';
import {ListValueModel} from '../../../../models/masterData/list-value-model';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {AreasModel} from '../../../../models/domain/areas-model';
import {AreaModel} from '../../../../models/domain/area-model';
import {DomainService} from '../../../../services/domain.service';
import {AreaArrayModel} from "../../../../models/domain/area-array-model";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'app-users-edit-location',
  templateUrl: './users-edit-location.component.html',
  styleUrls: ['./users-edit-location.component.css']
})
export class UsersEditLocationComponent implements OnInit {

  @Input() currentUser: any;

  currentAreas: ListboxModel[];
  displayMaxAreas: boolean;
  isMatched: boolean;
  lists: ListModel[];
  listValues: ListValueModel[];
  numberOfAreas: number;
  results: ListboxModel[];
  areaElement: ListboxModel;
  selectedArea: string;

  constructor(private mapFunctionsService: MapFunctionsService,
              public breakpointObserver: BreakpointObserver,
              private geojsonService: GeojsonService,
              private domainService: DomainService,
              private formBuilder: FormBuilder,
              private securityService: SecurityService,
              private session: Session,
              private messageService: MessageService) {
  }

  ngOnInit() {

    this.breakpointObserver
    .observe(['(min-width: 640px)'])
    .subscribe((state: BreakpointState) => {
      this.isMatched = !state.matches;
    });
    this.numberOfAreas = 0;
    this.domainService.getUserAreas(this.currentUser.userId).subscribe((response: AreaArrayModel) => {
      this.currentAreas = [];
      if (response.areas.length > 0) {
        response.areas.forEach(area => this.mapAsListBoxModel(area));
      }
      this.domainService.getAreas(0, 5000).subscribe((resp: AreasModel) => {
        this.results = [];
        resp.content.forEach((area: AreaModel) => {
          this.areaElement = new ListboxModel();
          this.areaElement.value = area.name;
          this.areaElement.label = area.name;
          this.areaElement.id = area.id;
          this.currentAreas.forEach(areaElement => {
            if (area.id === areaElement.id) {
              this.areaElement.disabled = true;
            }
          });
          if (!this.areaElement.disabled) {
            this.areaElement.disabled = false;
          }
          this.results.push(this.areaElement);
          this.numberOfAreas++;
        });
        this.checkMax();
      });
    });
  }

  mapAsListBoxModel(area: AreaModel) {
    let newListItem = new ListboxModel();
    newListItem.value = area.name;
    newListItem.label = area.name;
    newListItem.id = area.id;
    this.currentAreas.push(newListItem);
    if (area.children && area.children.length > 0) {
      area.children.forEach(child => this.mapAsListBoxModel(child))
    }

  }

  onSubmitArea() {
    const areas = [];
    this.currentAreas.forEach((area: ListboxModel) => {
      areas.push(area.id);
    });
    this.domainService.updateUserAreas(this.currentUser.userId, areas).subscribe(resp => {
      if (resp) {
        this.messageService.add({
          key: 'bl', severity: 'success', summary: 'Utilisateur modifié',
          detail: this.currentUser.userName
        });
      }
    }, (error: HttpErrorResponse) => {
      this.messageService.add({
        key: 'bl',
        severity: 'error',
        summary: 'Modification impossible',
        detail: error.error.message
      });
    });
  }

  updateAreaList() {
    const currentArea = this.selectedArea;
    this.results.forEach((role: ListboxModel) => {
      if (role.value === this.selectedArea) {
        role.disabled = false;
      }
    });
    let i = 0;
    this.currentAreas.forEach((role: ListboxModel) => {
      if (role.value === currentArea) {
        this.currentAreas.splice(i, 1);
      }
      i++;
    });
    this.checkMax();
  }

  updateCurrentList() {
    this.areaElement = new ListboxModel();
    this.areaElement.value = this.selectedArea;
    this.areaElement.label = this.selectedArea;
    this.results.forEach((area: ListboxModel) => {
      if (area.value === this.selectedArea) {
        area.disabled = true;
        this.areaElement.id = area.id;
      }
    });
    this.currentAreas.push(this.areaElement);
    this.checkMax();
  }


  checkMax() {
    this.displayMaxAreas = this.currentAreas.length === this.numberOfAreas;
  }
}
