<form [formGroup]="geoForm" (ngSubmit)="onSubmitGeo()" class="globalSearch ui-g ui-fluid geoForm">
  <div class="inputFloat searchGeo ui-g-12">
    <p class="labels">{{'sectors.sector-name' | translate}}</p>
    <div class="form-group ui-inputgroup searchName">
      <input type="text" pInputText formControlName="areaName" [(ngModel)]="currentArea.name"
             class="form-control ui-g-12" [placeholder]="currentArea && currentArea.name ? currentArea.name : 'ex: Lille'">
    </div>
  </div>
  <div class="inputFloat searchGeo ui-g-12">
    <p class="labels">{{'sectors.sector-users' | translate}}</p>
    <p-multiSelect formControlName="selectedUsers" [options]="users" [(ngModel)]="selectedUsers"
                   defaultLabel="{{'sectors.sector-users-label' | translate}}" optionLabel="userName"
                   display="chip"></p-multiSelect>
  </div>
  <div class="inputFloat searchGeo ui-g-12">
    <p class="labels">{{'sectors.parent-sector' | translate}}</p>
    <p-dropdown formControlName="selectedParent" [options]="areas" [(ngModel)]="selectedParent" optionLabel="name"
                [filter]="true" [showClear]="true"
                [placeholder]="selectedParent && selectedParent.name ? selectedParent.name : ('sectors.parent-sector-placeholder' | translate)"></p-dropdown>
  </div>
  <div class="ui-float-label ui-g-12 inputFloat searchGeo margin-top">
    <p class="labels">{{'sectors.sector-location' | translate}}</p>
    <div class="area-import">
      <p-autoComplete class="ui-autocomplete" [suggestions]="filteredResults" (completeMethod)="search($event)"
                      [minLength]="0" autofocus="true" (onSelect)="onSelectArea()" id="geolocalisation"
                      formControlName="autocomplete">
      </p-autoComplete>
        <p-fileUpload #fileUploadButton mode="basic" name="fileupload" [multiple]="false"
                      accept="application/geo+json,application/json,.geojson"
                      maxFileSize="10000000000" (onSelect)="readFile($event)"
                      chooseLabel="{{'sectors.geojson-import' | translate}}"
                      pTooltip="{{'sectors.geojson-import-warning' | translate}}"
                      ></p-fileUpload>
      <button pButton *ngIf="importedGeometry" type="button" icon="fa fa-close" class="clearButton" (click)="clearUploadedFile()"
              pTooltip="{{'sectors.geojson-import-delete' | translate}}" tooltipPosition="bottom"></button>

    </div>
  </div>
  <div id="map">
  </div>
  <div class="mapAction">
    <div pTooltip="{{'sectors.draw-polygon' | translate}}" tooltipPosition="left">
      <button pButton type="button" icon="fa fa-crop" class="buttonStyle2"
              [ngClass]="{'listChecked':isActiveDraw, 'listUnchecked': !isActiveDraw}"></button>
      <p-checkbox name="" value="" (click)="onDrawPolygon()"
                  inputId="" class="hiddenCheckbox"></p-checkbox>
    </div>
    <div pTooltip="{{'sectors.clear-polygon' | translate}}" tooltipPosition="bottom">
      <button pButton type="button" icon="fa fa-close" class="clearButton" (click)="clearAll()"></button>
    </div>
  </div>

  <div class="submitButtonGeo ui-inputgroup">
    <button pButton type="submit" label="{{'sectors.save-sector' | translate}}"
            [disabled]="!currentArea.geometry || geoForm.get('areaName').invalid"></button>
  </div>
</form>

<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
