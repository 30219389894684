import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Session} from '../../globals/session';
import {RoleEnum} from '../../enums/role-enum.enum';
import {UserModel} from '../../models/session/user-model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  currentUser: UserModel;
  visibleDomain = false;
  visibleModeling = false;
  visibleProcessing = false;
  visibleSettings = false;
  visibleSnapshots = false;
  visibleUsers = false;
  visibleTools = false;
  visibleGrids = false;
  visibleAreas = false;
  isMatched: boolean;

  constructor(public breakpointObserver: BreakpointObserver, private session: Session) { }

  ngOnInit() {
    this.currentUser = this.session.currentSession.user;
    const that = this;
    this.currentUser.userRoles.forEach((role) => {
      switch (role) {
        case RoleEnum.ROLE_DESIGN_TOOLS :
          that.visibleTools = true;
          break;
        case RoleEnum.ROLE_DESIGN_SETTINGS :
          that.visibleSettings = true;
          break;
        case RoleEnum.ROLE_DESIGN_USERS:
          that.visibleUsers = true;
          break;
        case RoleEnum.ROLE_DESIGN_DOMAINS:
          that.visibleDomain = true;
          break;
        case RoleEnum.ROLE_DESIGN_MODELING:
          that.visibleModeling = true;
          break;
        case RoleEnum.ROLE_DESIGN_PROCESSING:
          that.visibleProcessing = true;
          break;
        case RoleEnum.ROLE_DESIGN_SNAPSHOTS:
          that.visibleSnapshots = true;
          break;
        case RoleEnum.ROLE_DESIGN_GRIDS:
          this.visibleGrids = true;
          break;
        case RoleEnum.ROLE_DESIGN_AREAS:
          this.visibleAreas = true;
          break;
      }
    });
    this.breakpointObserver
      .observe(['(min-width: 1023px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
        }
      });
  }

}
