import {ISerializable} from '../iserializable';
import {HeaderModel} from '../header-model';
import {RuleModel} from './rule-model';

export class RulesCollectionModel implements ISerializable {

  header: HeaderModel;
  rules: RuleModel[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.header = new HeaderModel().deserialize(input.header);

    this.rules = input.rules.map(list => new RuleModel().deserialize(list));

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
