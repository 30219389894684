import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ElementClassModel} from "../../../../../models/masterData/element-class-model";

@Component({
  selector: 'app-masterdata-add-details',
  templateUrl: './masterdata-add-details.component.html',
  styleUrls: ['./masterdata-add-details.component.css']
})
export class MasterdataAddDetailsComponent implements OnInit, AfterViewInit {

  @Input() newMasterdata: ElementClassModel;
  newMasterdataForm: FormGroup;
  locale: string;
  views = [{view: 'PANO_360_L'}, {view: 'GROUND_L'}];
  geometries = [{geometry: 'Point'}, {geometry: 'LineString'}, {geometry: 'Polygon'}];
  selectedView = {view: 'PANO_360_L'};
  selectedGeometry = {geometry: 'Point'};
  @ViewChild('hiddenInput') typeInput: ElementRef<HTMLInputElement>;


  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.locale = this.newMasterdata.elementClass.split('.')[1];
    this.newMasterdataForm = this.formBuilder.group({
      elementClass: [this.newMasterdata.elementClass, Validators.required],
      type: [this.newMasterdata.elementType, Validators.required],
      classCode: [this.newMasterdata.classCode, Validators.required],
      parent: [this.newMasterdata.classParent, Validators.required],
      category: [this.newMasterdata.classCategory, Validators.required],
      description: [this.newMasterdata.classDescription, Validators.required],
      geometry: [this.newMasterdata.classGeoType, Validators.required],
      view: [this.newMasterdata.classViewType, Validators.required],
      zoom: [this.newMasterdata.classGeoZoom, Validators.required],
      filter01: [this.newMasterdata.classFilter01, Validators.required],
      filter02: [this.newMasterdata.classFilter02, Validators.required],
      filter03: [this.newMasterdata.classFilter03, Validators.required],
      callBackFunction: [this.newMasterdata.classCallBackFunction, Validators.required]
    });
    this.newMasterdataForm.get('type').valueChanges.subscribe(value => {
      this.updateElementClass();
    });
    this.newMasterdataForm.get('classCode').valueChanges.subscribe((value: string) => {
      this.updateElementClass();
      this.updateCategory(value.charAt(0));
    });
  }

  updateElementClass() {
    this.newMasterdataForm.patchValue({ elementClass: this.newMasterdataForm.get('type').value.toLowerCase() + '.' + this.locale + '.' +
        this.newMasterdataForm.get('classCode').value.toUpperCase() });
  }

  updateCategory(categoryLetter: string) {
    this.newMasterdataForm.patchValue({ category: categoryLetter.toUpperCase()});
  }

  updateMasterData(masterData: ElementClassModel): ElementClassModel {
    masterData.classDescription = this.f.description.value;
    masterData.elementType = this.f.type.value;
    masterData.classGeoZoom = this.f.zoom.value;
    masterData.classCallBackFunction = this.f.callBackFunction.value;
    masterData.classFilter01 = this.f.filter01.value;
    masterData.classFilter02 = this.f.filter02.value;
    masterData.classFilter03 = this.f.filter03.value;
    masterData.classViewType = this.f.view.value.view;
    masterData.classGeoType = this.f.geometry.value.geometry;
    masterData.classCategory = this.f.category.value;
    masterData.classParent = this.f.parent.value;
    masterData.classCode = this.f.classCode.value;
    masterData.isParent = false;
    masterData.elementClass = this.f.elementClass.value;
    return masterData;
  }
  get f() {
    return this.newMasterdataForm.controls;
  }

  ngAfterViewInit() {
    // focus an hidden input to unfocus the button of p-inputNumber for zoom field in the form
    setTimeout(() => this.typeInput.nativeElement.focus(), 100);
  }

}
