<div class="globalSearch ui-g ui-fluid snapshotForm">
  <div class="ui-g-12 ui-md-6">
    <p class="pRole">{{'users.edit.available-roles' | translate}} :</p>
    <p-listbox *ngIf="!isMatched" [options]="results" [(ngModel)]="selectedRole" [ngModelOptions]="{standalone: true}"
               [style]="{'width':'100%'}" (onDblClick)="updateCurrentList()"></p-listbox>
    <p-listbox *ngIf="isMatched" [options]="results" [(ngModel)]="selectedRole" [ngModelOptions]="{standalone: true}"
               [style]="{'width':'100%'}" (click)="updateCurrentList()"></p-listbox>
  </div>
  <div class="ui-g-12 ui-md-6">
    <p class="pRole">{{'users.edit.user-role' | translate}} :</p>
    <p *ngIf="!currentRoles" class="maxRoles">{{'users.edit.users-no-roles' | translate}}</p>
    <p-listbox *ngIf="currentRoles && !isMatched" [options]="currentRoles" [(ngModel)]="selectedRole" [ngModelOptions]="{standalone: true}"
               [style]="{'width':'100%'}" (onDblClick)="updateRoleList()"></p-listbox>
    <p-listbox *ngIf="currentRoles && isMatched" [options]="currentRoles" [(ngModel)]="selectedRole" [ngModelOptions]="{standalone: true}"
               [style]="{'width':'100%'}" (click)="updateRoleList()"></p-listbox>
  </div>
  <p *ngIf="displayMaxRoles" class="maxRoles">{{'users.edit.no-roles' | translate}} :</p>
  <div class="submitButtonRole ui-inputgroup">
    <button pButton type="submit" label="{{'users.edit.update-roles' | translate}}" (click)="onSubmitRole()"></button>
  </div>
</div>
