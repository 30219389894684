<div class="globalSearch ui-g ui-fluid snapshotForm">
  <div class="ui-g-12 ui-md-6">
    <p class="pRole">{{'users.edit.available-areas' | translate}} :</p>
    <p-listbox *ngIf="!isMatched" [options]="results" [(ngModel)]="selectedArea" [ngModelOptions]="{standalone: true}"
               [style]="{'width':'100%'}" (onDblClick)="updateCurrentList()"></p-listbox>
    <p-listbox *ngIf="isMatched" [options]="results" [(ngModel)]="selectedArea" [ngModelOptions]="{standalone: true}"
               [style]="{'width':'100%'}" (click)="updateCurrentList()"></p-listbox>
  </div>
  <div class="ui-g-12 ui-md-6">
    <p class="pRole">{{'users.edit.users-areas' | translate}} :</p>
    <p *ngIf="!currentAreas" class="maxRoles">{{'users.edit.user-no-areas' | translate}}</p>
    <p-listbox *ngIf="currentAreas && !isMatched" [options]="currentAreas" [(ngModel)]="selectedArea" [ngModelOptions]="{standalone: true}"
               [style]="{'width':'100%'}" (onDblClick)="updateAreaList()"></p-listbox>
    <p-listbox *ngIf="currentAreas && isMatched" [options]="currentAreas" [(ngModel)]="selectedArea" [ngModelOptions]="{standalone: true}"
               [style]="{'width':'100%'}" (click)="updateAreaList()"></p-listbox>
  </div>
  <p *ngIf="displayMaxAreas" class="maxRoles">{{'users.edit.no-areas' | translate}}</p>
  <div class="submitButtonRole ui-inputgroup">
    <button pButton type="submit" label="{{'users.edit.update-areas' | translate}}" (click)="onSubmitArea()"></button>
  </div>
</div>
