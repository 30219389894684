<h2>{{'masterdata.title' | translate}}</h2>
<hr>
<form [formGroup]="searchMasterdataForm" (ngSubmit)="searchMasterdata()" class="globalSearch ui-g">
  <div class="ui-inputgroup detailButton" [ngClass]="{'ui-g-12': isMatched, 'marginRight': !isMatched}">
    <input type="text" pInputText placeholder="{{'masterdata.masterdataname' | translate}}" formControlName="research"
           class="ui-inputtext ui-corner-all ui-state-default ui-widget" [ngModel]="selectedName">
      <button pButton type="submit" label="{{'masterdata.search' | translate}}" class="searchButton"></button>
  </div>
  <!-- <button *ngIf="isMatched" pButton type="button" label="{{'masterdata.add' | translate}}" class="ui-g-12 marginAll"
          (click)="onAddMasterdata()"></button>
  <button *ngIf="numberSelection === 0" pButton type="button" label="{{'masterdata.delete' | translate:{numberSelection: numberSelection} }}"
          class="ui-button-secondary forbidden" [ngClass]="{'ui-g-12': isMatched, 'marginAll': isMatched}"
          disabled="true" ></button>
  <button *ngIf="numberSelection !== 0" pButton type="button" label="{{'masterdata.delete' | translate:{numberSelection: numberSelection} }}"
          class="ui-button-danger" [ngClass]="{'ui-g-12': isMatched, 'marginAll': isMatched}"
          (click)="confirmDeleteAll()" ></button> -->
</form>
<p-paginator [rows]="100" [totalRecords]="totalCount" [rowsPerPageOptions]="[12, 25, 50, 100]" (onPageChange)="paginate($event)" #p></p-paginator>
<p *ngIf="!isMatched" class="countRow">{{'masterdata.count' | translate}} : {{totalCount}}</p>
<div *ngIf="!masterdataLoaded" class="loaderContainer">
  <p-progressSpinner></p-progressSpinner>
</div>
<div class="masterdataList masterdataList">
  <p-table [columns]="cols" [value]="elementClassModels" selectionMode="multiple" [(selection)]="selectedMasterdatas" (onRowSelect)="onRowSelect()"
           (onRowUnselect)="onRowUnselect()" [responsive]="true">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="checkBoxStyle" style="width: 3em">
          <p-tableHeaderCheckbox (click)="selectAll()"></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns">
          {{col.header}}
        </th>
        <th>
          <button pButton type="button" icon="fa fa-plus" (click)="displayAddMasterdata = true" style="display:block;"
                  class="ui-button-primary marginLeft"></button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-masterdata let-columns="columns">
      <tr>
        <td class="checkBoxStyle">
          <p-tableCheckbox [value]="masterdata"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" class="phoneSizeTab">
          <span class="ui-column-title">{{col.header}}</span>
          {{masterdata[col.field]}}
        </td>
        <td>
          <div class="ui-inputgroup ui-rtl" dir="rtl">
            <div class="ui-rtl" dir="ltr">
              <p-button *ngIf="!masterdata.blocked" label="{{'masterdata.modify' | translate}}"
                        (onClick)="onEditMasterdata(masterdata)" class="splitMasterdata"></p-button>
              <p-button *ngIf="masterdata.blocked" label="{{'masterdata.modify' | translate}}"
                        (onClick)="onEditMasterdata(masterdata)" class="splitMasterdata"></p-button>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-confirmDialog [style]="{width: '50vw'}" [transitionOptions]="'200ms'"></p-confirmDialog>
</div>
<p-toast [style]="{marginTop: '80px'}" position="bottom-left" key="bl"></p-toast>
<p *ngIf="!isResult" class="disclaimer">{{'masterdata.no-results' | translate}}</p>

<p-dialog *ngIf="displayEditMasterdata" header="{{('masterdata.edit.modificationOf' | translate) + ' '  + selectedMasterdata.elementClass}}"
          [(visible)]="displayEditMasterdata" [style]="{width: '80%', height: '500px'}" [contentStyle]="{maxHeight: '100%', overflow: 'auto'}"
          transitionOptions="200ms" [draggable]="false" modal="true">
  <app-masterdata-edit #editMasterdataComponent [currentMasterdata]="selectedMasterdata"></app-masterdata-edit>
  <p-footer>
    <p-button icon="pi pi-times" (click)="displayEditMasterdata=false" label="{{'masterdata.cancel' | translate}}"></p-button>
    <p-button icon="pi pi-check" type="submit" (click)="submitEditMasterdataForm()" label="{{'masterdata.edit.modify' | translate}}" styleClass="p-button-text"></p-button>
  </p-footer>
</p-dialog>

<p-dialog *ngIf="displayAddMasterdata" header="{{'masterdata.creation' | translate}}"
          [(visible)]="displayAddMasterdata" [style]="{width: '80%', height: '500px'}" [contentStyle]="{maxHeight: '100%', overflow: 'auto'}"
          transitionOptions="200ms" [draggable]="false" modal="true">
  <app-masterdata-add #addMasterdataComponent ></app-masterdata-add>
  <p-footer>
    <p-button icon="pi pi-times" (click)="displayAddMasterdata=false" label="{{'masterdata.cancel' | translate}}"></p-button>
    <p-button icon="pi pi-check" type="submit" (click)="submitAddMasterdataForm()" label="{{'masterdata.save' | translate}}" styleClass="p-button-text"></p-button>
  </p-footer>
</p-dialog>
