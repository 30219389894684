import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {MasterDataService} from '../../../services/master-data.service';
import {ListValueModel} from '../../../models/masterData/list-value-model';
import {ListCollectionModel} from '../../../models/masterData/list-collection-model';
import {Session} from "../../../globals/session";

@Component({
  selector: 'app-settings-lists',
  templateUrl: './settings-lists.component.html',
  styleUrls: ['./settings-lists.component.css']
})
export class SettingsListsComponent implements OnInit, OnDestroy {

  cols: any[];
  confirmMessage: string;
  counter: number;
  createListValueForm: FormGroup;
  displayCreate: boolean;
  displayUpdate: boolean;
  isMatched: boolean;
  listCode: string;
  listName: string;
  listValues: ListValueModel[];
  listValue: string[];
  numberOfValues: number;
  numberSelection: number;
  selectedValues: ListValueModel[];
  updateListValueForm: FormGroup;

  constructor(public breakpointObserver: BreakpointObserver,
              private masterDataService: MasterDataService,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private formBuilder: FormBuilder,
              private session: Session) {
    const listCode = 'listCode';
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.session.token) {
        this.listCode = this.route.snapshot.params[listCode];
        this.getListValues();
      }
    });
  }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
        }
      });
    this.displayCreate = false;
    this.displayUpdate = false;
    this.numberSelection = 0;
    this.cols = [
      { field: 'valueCode', header: 'Code' },
      { field: 'valueText', header: 'Texte' }
    ];

    this.listValue = [];
    this.updateListValueForm = this.formBuilder.group({
      research: ['', Validators.required]
    });
    this.createListValueForm = this.formBuilder.group({
      valueCode: ['', Validators.required],
      valueText: ['', Validators.required],
      listValueParameters: ['', Validators.required]
    });

    this.listCode = this.route.snapshot.params.listCode;
  }
  ngOnDestroy() {
    this.listValues = null;
  }
  onRowSelect() {
    this.numberSelection++;
  }
  onRowUnselect() {
    this.numberSelection--;
  }
  selectAll() {
    if (this.numberSelection !== this.numberOfValues) {
      this.numberSelection = this.numberOfValues;
    } else {
      this.numberSelection = 0;
    }
  }
  changeValue(listValue) {
    this.listValue = [];
    const formGroup = new FormGroup({});
    // tslint:disable-next-line:forin
    for (const key in listValue) {
      this.listValue.push(listValue[key]);
      const control: FormControl = new FormControl(listValue[key], Validators.required);
      formGroup.addControl(listValue[key], control);
    }
    this.updateListValueForm = formGroup;
    this.displayUpdate = true;
  }
  onAddField() {
    this.displayCreate = true;
    this.createListValueForm = this.formBuilder.group({
      valueCode: ['', Validators.required],
      valueText: ['', Validators.required],
      listValueParameters: ['', Validators.required]
    });
  }

  getListValues() {
    this.numberOfValues = 0;
    this.masterDataService.getList(this.listCode).subscribe( (resp: ListCollectionModel) => {
      if (resp) {
        this.listName = resp.lists[0].listName;
        this.listValues = resp.lists[0].listValues;
        if (!this.listValues) {
          this.listValues = resp.lists[0].values;
        }
        this.listValues.forEach( () => {
          this.numberOfValues++;
        });
      }
    });
  }


  updateListValue() {
    this.displayUpdate = false;
    this.getListValues();
  }

  createListValue() {
    this.displayCreate = false;
    this.getListValues();
  }


  confirmDeleteSelf(listValue) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer ce champ : </b><br/><ul class="confirmList"><li>'
        + listValue.valueCode + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.masterDataService.deleteListValue(this.listCode, listValue.valueCode).subscribe(resp => {
          if (resp) {
            this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ supprimé de ' + this.listCode,
              detail: listValue.valueCode});
            this.getListValues();

          }
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });

  }
  confirmDeleteAll() {
    if (this.numberSelection > 0) {
      this.confirmMessage = '<ul class="confirmList">';
      this.selectedValues.forEach( listValue => {
        this.confirmMessage += '<li>' + listValue.valueCode + '</li>';
      });
      this.confirmMessage += '</ul>';
    }
    this.counter = 0;
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer tous ces champs : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.selectedValues.forEach( listValue => {
          this.masterDataService.deleteListValue(this.listCode, listValue.valueCode).subscribe(resp => {
            if (resp) {
              this.messageService.add({key: 'bl', severity: 'success', summary: 'Champ supprimé de ' + this.listCode,
                detail: listValue.valueCode});
              this.counter++;
              if (this.counter === this.numberSelection) {
                this.getListValues();
                this.numberSelection = 0;
              }
            }
          });
        });

      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }

}
