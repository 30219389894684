import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Session} from '../globals/session';
import { SessionService } from '../services/session.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private router: Router, private session: Session, private sessionService: SessionService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.session.currentSession){
      return this.sessionService.getSession().pipe(map(session => {
        // Token are now loaded directly in the App component
        this.session.loadSession(session, route);
        if (this.session.token && this.session.sessionIdentifier) {
          window.sessionStorage.setItem('sessionIdentifier', this.session.sessionIdentifier);
          window.sessionStorage.setItem('currentSession', JSON.stringify(this.session.currentSession));
        } else if (window.sessionStorage.length > 0) {
          this.session.sessionIdentifier = window.sessionStorage.getItem('sessionIdentifier');
          this.session.currentSession = JSON.parse(window.sessionStorage.getItem('currentSession'));
          this.session.isLogged = true;
          this.session.isLogging = false;
          this.session.sessionLocale = this.session.currentSession.user.userLocale;
          this.session.sessionProperties = this.session.currentSession.user.userProperties;
        }
        return this.checkRole(route);
      }));
    } else {
      return this.checkRole(route);
    }
  }

  checkRole(route: ActivatedRouteSnapshot) {
    const user = this.session.currentSession.user;
    const expectedRole = route.data.expectedRole;
    let isAuthorized = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < user.userRoles.length; i++) {
      if (user.userRoles[i] === expectedRole) {
        isAuthorized = true;
      }
    }
    if (!isAuthorized) {
      this.router.navigate(['/home']);
    }
    return isAuthorized;
  }

}
