<p-dialog header="{{'header.new-domain'|translate}}" [(visible)]="display" [modal]="true" [responsive]="true"
          [style]="{'width': '500px', 'minWidth': '300px'}" (onHide)="onHide()" [transitionOptions]="'200ms'">
  <form [formGroup]="addForm" (ngSubmit)="onSubmit()" class="globalSearch ui-g ui-fluid snapshotForm">

    <span class="ui-float-label ui-g-12 inputFloat">
      <input type="text" id="domainName" pInputText formControlName="domainName" class="form-control ui-g-12"/>
      <label class="labels" for="domainName">{{'header.domain-name'|translate}}*</label>
    </span>

    <div class="form-group buttons">
      <button pButton type="submit" class=" ui-button-primary" icon="fa fa-floppy-o" label="Save"></button>
      <button pButton type="button" class=" ui-button-danger" icon="fa fa-times" label="Cancel"
              (click)="onCancel()"></button>
    </div>

  </form>
</p-dialog>
