import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {SnapshotService} from '../services/snapshot.service';
import {ConfirmationService, MenuItem, SelectItem} from 'primeng/api';
import * as moment from 'moment';
import {MapFunctionsService} from '../services/mapfunctions.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnapshotMapComponent} from '../snapshot-map/snapshot-map.component';
import {MessageService} from 'primeng/api';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {SnapshotModel} from '../../../models/processing/snapshot-model';
import {ProcessingService} from '../../../services/processing.service';
import {SnapshotCollectionModel} from '../../../models/processing/snapshot-collection-model';
import {ChangeDomainComponent} from '../change-domain/change-domain.component';
import {AddTagComponent} from '../add-tag/add-tag.component';
import {EventModel} from "../../../models/processing/event-model";
import { DialogService } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-snapshot-list',
  templateUrl: './snapshot-list.component.html',
  styleUrls: ['./snapshot-list.component.css']
})
export class SnapshotListComponent implements OnInit, OnDestroy {

  @ViewChild('p') paginator: Paginator;
  @ViewChild(ChangeDomainComponent) changeDomainComponent: ChangeDomainComponent;
  @ViewChild(AddTagComponent) private addTagComponent: AddTagComponent;

  private stepInterval = 0;
  actionItems: MenuItem[];
  arraySnapshots: any [];
  cols: any[];
  confirmMessage: any;
  display = false;
  init: number;
  isMatched: boolean;
  isResult: boolean;
  last: number;
  limitCount: number;
  numberOfSnapshots: number;
  numberSelection: number;
  offset: number;
  searchForm: FormGroup;
  selectedName: any;
  selectedSnapshots: any;
  snapshot: any;
  snapshots: SnapshotModel[];
  snapshotsLoaded = false;
  tableItems: MenuItem[];
  totalCount: number;
  totalDistance: number;
  types: SelectItem[];


  constructor(public breakpointObserver: BreakpointObserver,
              private confirmationService: ConfirmationService,
              private dialogService: DialogService,
              private formBuilder: FormBuilder,
              private mapfunctionsService: MapFunctionsService,
              private messageService: MessageService,
              private processingService: ProcessingService,
              private router: Router,
              private snapshotService: SnapshotService) {
    this.types = [
      {label: 'snapshotName', value: 'Nom du Snapshot'},
      {label: 'snapshotDate', value: 'Date du Snapshot'},
      {label: 'statusDate', value: 'Date du Statut'}
    ];
  }

  snapshotArray;

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
        }
      });
    this.totalDistance = 0;
    this.searchForm = this.formBuilder.group({
      research: ['', Validators.required]
    });
    this.cols = [
      // {field: 'snapshotCheck', header: 'test'},
      {field: 'distance', header: 'Distance'},
      {field: 'timeSpend', header: 'Durée'},
      {field: 'dateFromStatus', header: 'Date du dernier statut'},
      {field: 'statusCode', header: 'Dernier statut'}
    ];
    this.numberSelection = 0;
    this.selectedSnapshots = [];
    this.actionItems = [
      {label: 'Rafraîchir', icon: 'fa fa-refresh', command: () => {
          this.callToGetSnapshotsDetails();
        }},
      {label: 'Ajouter des étiquettes', icon: 'fa fa-tag', command: () => {
          if (this.selectedSnapshots.length > 0) {
            this.addTagComponent.displayTag = true;
          } else {
            this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
          }
        }},
      {label: 'Charger les relevés sur un autre domaine', icon: 'fa fa-map-pin', command: () => {
        if (this.selectedSnapshots.length > 0) {
          this.changeDomainComponent.displayDomain = true;
        } else {
          this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
        }
        }},
      {label: 'Supprimer les fichiers des relevés', icon: 'fa fa-close', command: () => {
          if (this.selectedSnapshots.length > 0) {
            this.confirmCancelAll();
          } else {
            this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
          }
        }},
    ];
    this.tableItems = [
      {label: 'Consulter le trajet', icon: 'fa fa-location-arrow', command: () => {
          this.displayJourney(this.snapshot);
        }},
      {label: 'Charger le relevé sur un autre domaine', icon: 'fa fa-map-pin', command: () => {
        this.changeDomainComponent.displayDomain = true;
        }},
      {label: 'Supprimer les fichiers du relevé', icon: 'fa fa-close', command: () => {
          this.confirmCancel(this.snapshot);
        }}
    ];
    this.snapshotService.changeStatusRequest.subscribe(endedRequest  => {
      this.snapshots = [];
      this.snapshotsLoaded = endedRequest;
      this.isResult = false;
    });
    this.snapshotService.changeStatus.subscribe( error => {
      if (error.error) {
        this.messageService.add({key: 'bl', severity: 'error', summary: error.error.code + ' (' + error.status + ')',
          detail: error.error.message, closable: false});
      }
    });
    this.snapshots = [];
    this.snapshotService.currentSnapshot.subscribe(message => this.snapshotArray = message);
    this.arraySnapshots = [];
    this.processingService.snapshotName = '';
    this.processingService.snapshotIdentifier = null;
    this.processingService.statusCode = 'JOURNEY_RECEIVED';
    this.processingService.statusCodeExcludeList = [];
    this.processingService.statusCodeContains = '';
    this.processingService.snapshotDateBegin = null;
    this.processingService.statusDateBegin = null;
    this.processingService.snapshotDateEnd = null;
    this.processingService.statusDateEnd = null;
    this.processingService.snapshotType = '';
    this.processingService.snapshotTags = [];
    this.offset = 0;
    this.init = 0;
    this.last = 12;
    this.limitCount = 12;
    this.numberOfSnapshots = 0;

    this.processingService.getSnapshotsDetails(this.offset, this.limitCount).subscribe(
      (resp: SnapshotCollectionModel) => {
        if (resp) {
          this.totalCount = resp.header.totalCount;
          if (resp.snapshots) {
            resp.snapshots.forEach((snapshot) => {
              snapshot.snapshotCheck = false;
              const arrayStatus = [];
              snapshot.snapshotEvents.forEach((event: EventModel) => {
                if (event.eventStatusCode === 'WARN_HEALTHCHECK') {
                  snapshot.snapshotCheck = true;
                  arrayStatus.push(event.eventStatusReason);
                  snapshot.snapshotWarnCheck = arrayStatus.join('\n');
                }
              });
              snapshot.timeSpend = this.snapshotService.dateDiff(snapshot);
              snapshot.distance = this.snapshotService.getDistance(snapshot).toString() + 'km';
              moment.locale('fr');
              snapshot.dateFromStatus = moment(new Date(snapshot.statusDate)).fromNow();
              this.arraySnapshots.push(snapshot);
              this.numberOfSnapshots++;
            });
            this.snapshots = this.arraySnapshots;
            this.snapshotsLoaded = true;
            this.isResult = true;
          } else {
            this.snapshotsLoaded = true;
            this.isResult = false;
          }
        }
      }
    );
  }
  ngOnDestroy() {
    clearInterval(this.stepInterval);
  }

  setSnapshot(snapshot) {
    this.snapshot = snapshot;
  }
  displayJourney(snapshot: SnapshotModel) {
    this.snapshotService.transferSnapshot(snapshot);
    window.localStorage.removeItem('localSnapshot');
    window.localStorage.setItem('localSnapshot', JSON.stringify(snapshot));
    this.processingService.snapshotList = true;
    this.dialogService.open(SnapshotMapComponent, {
      header: snapshot.snapshotName,
      width: '100%',
      height: '100%',
      transitionOptions: '200ms',
      contentStyle: {'max-height': '100%', overflow: 'auto'},
    });
  }
  paginate(event) {
    this.limitCount = event.rows;
    this.numberSelection = 0;
    this.totalDistance = 0;
    this.selectedSnapshots = [];
    this.init = event.first;
    this.last = event.first + event.rows;
    this.offset = event.first / this.limitCount;
    this.callToGetSnapshotsDetails();
  }
  callToGetSnapshotsDetails() {
    this.snapshotsLoaded = false;
    this.arraySnapshots = [];
    this.numberOfSnapshots = 0;
    this.processingService.getSnapshotsDetails(this.offset, this.limitCount).subscribe(
      (resp: SnapshotCollectionModel) => {
        if (resp) {
          this.totalCount = resp.header.totalCount;
          if (resp.snapshots) {
            resp.snapshots.forEach((snapshot) => {
              snapshot.snapshotCheck = false;
              const arrayStatus = [];
              snapshot.snapshotEvents.forEach((event: EventModel) => {
                if (event.eventStatusCode === 'WARN_HEALTHCHECK') {
                  snapshot.snapshotCheck = true;
                  arrayStatus.push(event.eventStatusReason);
                  snapshot.snapshotWarnCheck = arrayStatus.join('\n');
                }
              });
              snapshot.timeSpend = this.snapshotService.dateDiff(snapshot);
              snapshot.distance = this.snapshotService.getDistance(snapshot).toString() + 'km';
              moment.locale('fr');
              snapshot.dateFromStatus = moment(new Date(snapshot.statusDate)).fromNow();
              this.arraySnapshots.push(snapshot);
              this.numberOfSnapshots++;
            });
            this.snapshots = this.arraySnapshots;
            this.snapshotsLoaded = true;
          } else {
            this.snapshotsLoaded = true;
          }
          if (this.numberOfSnapshots === 0) {
            this.isResult = false;
          } else {
            this.isResult = true;
          }
          this.selectedName = '';
        }
      });
  }
  searchSnapshots() {
    this.selectedName = this.f.research.value;
    this.processingService.snapshotName = this.f.research.value;
    this.paginator.changePage(0);
  }
  get f() {
    return this.searchForm.controls;
  }
  onRowSelect(event) {
    this.totalDistance += this.snapshotService.getDistance(event.data);
    this.totalDistance = Math.round(this.totalDistance * 1000) / 1000;
    this.numberSelection++;
  }
  onRowUnselect(event) {
    this.totalDistance -= this.snapshotService.getDistance(event.data);
    this.totalDistance = Math.round(this.totalDistance * 1000) / 1000;
    this.numberSelection--;
  }
  selectAll() {
    if (this.numberSelection !== this.numberOfSnapshots) {
      this.numberSelection = this.numberOfSnapshots;
      this.totalDistance = 0;
      this.selectedSnapshots.forEach( snapshot => {
        this.totalDistance += this.snapshotService.getDistance(snapshot);
        this.totalDistance = Math.round(this.totalDistance * 1000) / 1000;
      });
    } else {
      this.numberSelection = 0;
      this.totalDistance =  0;
    }
  }

  setSnapshots() {
    window.localStorage.removeItem('selectedSnapshot');
    window.localStorage.setItem('selectedSnapshot', JSON.stringify(this.selectedSnapshots));
  }

  uploadSnapshot(snapshot: SnapshotModel) {
    this.processingService.uploadSnapshot(snapshot.snapshotIdentifier).subscribe( () => {
      this.messageService.add({key: 'bl', severity: 'success', summary: 'Téléchargement demandé',
        detail: snapshot.snapshotName});
      this.callToGetSnapshotsDetails();
    });
  }
  uploadAll() {
    if (this.selectedSnapshots.length > 0) {
      this.selectedSnapshots.forEach(snapshot => {
        this.uploadSnapshot(snapshot);
      });
    } else {
      this.messageService.add({key: 'bl', severity: 'warn', summary: 'Aucun relevé sélectionné'});
    }
  }
  cancelSnapshot(snapshot: SnapshotModel) {
    this.processingService.cancelSnapshot(snapshot.snapshotIdentifier).subscribe( () => {
      this.messageService.add({key: 'bl', severity: 'success', summary: 'Suppression des fichiers demandé',
        detail: snapshot.snapshotName});
      this.callToGetSnapshotsDetails();
    });
  }
  confirmCancel(snapshot: SnapshotModel) {
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer les fichiers de ce relevé : </b><br/><ul class="confirmList"><li>'
        + snapshot.snapshotName + '</li></ul>',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.cancelSnapshot(snapshot);
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }
  confirmCancelAll() {
    if (this.numberSelection > 0) {
      this.confirmMessage = '<ul class="confirmList">';
      this.selectedSnapshots.forEach( snapshot => {
        this.confirmMessage += '<li>' + snapshot.snapshotName + '</li>';
      });
      this.confirmMessage += '</ul>';
    }
    this.confirmationService.confirm({
      message: '<b>Voulez-vous supprimer tous ces relevés : </b><br/>' + this.confirmMessage,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.selectedSnapshots.forEach( snapshot => {
          this.cancelSnapshot(snapshot);
        });
      },
      reject: () => {
        this.messageService.add({key: 'bl', severity: 'warn', summary: 'Suppression annulée'});
      }
    });
  }

}
