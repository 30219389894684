export enum MapLayerEnum {
  LAYER_SATELLITE = 'satelliteLayer',
  LAYER_WORKSPACE = 'workspaceLayer',
  LAYER_MEASURE_POINTS = 'measurePoints',
  LAYER_MEASURE_LINES = 'measureLines',
  LAYER_CAMERA = 'cameraLayer',
  LAYER_JOURNEY = 'journeyLayer',
  LAYER_LOCATION_MARKER = 'locationMarkerLayer',
  LAYER_GPS_RGP = 'gpsRgpLayer',
  LAYER_IMAGE = 'imageLayer',

  LAYER_QUALITY_RED = 'qualityRedLayer',
  LAYER_QUALITY_GREEN = 'qualityGreenLayer',
  LAYER_QUALITY_ORANGE = 'qualityOrangeLayer',
  LAYER_QUALITY_WHITE = 'qualityWhiteLayer',
  LAYER_ELEMENT = 'elementLayer',

}
