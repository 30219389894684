<p-dialog id="listUpdate" header="{{'settings.other-lists.change-list-values' | translate}}"
          [style]="{width: '300px', height: '200px'}"  [(visible)]="displayUpdate" (onHide)="onHide()">
  <form [formGroup]="updateListValueForm"  class="updateForm ui-fluid">
    <div *ngFor="let data of listValue; let i = index" class="ui-inputgroup">
      <input *ngIf="i === 0" type="text" pInputText [placeholder]="data" [formControlName]="data"
             class="ui-inputtext ui-corner-all ui-state-default ui-widget updateInput" disabled="true">
      <input *ngIf="i === 1" type="text" pInputText [placeholder]="data" [formControlName]="data"
             class="ui-inputtext ui-corner-all ui-state-default ui-widget updateInput" (ngModelChange)="getText($event)">
      <json-editor *ngIf="displayEditor && i === 2" [options]="options" [(ngModel)]="listValue[i]"
                   style="width:100%;" class="jsonConfiguration"
                   [formControlName]="data"></json-editor>
      <json-editor *ngIf="!displayEditor && i === 2" [options]="options" (change)="getData($event)"
                   style="width:100%;" class="jsonConfiguration"></json-editor>
    </div>
    <p *ngIf="isParsingError" class="errorMessage">{{errorMessage}}</p>
    <button pButton label="{{'settings.other-lists.update' | translate}}" (click)="updateListValue()" [disabled]="isParsingError"
            class="ui-button ui-button-text-only ui-button-text updateButton"></button>
  </form>
</p-dialog>
